import React from 'react'
import styles from './MainText.module.css'

const Year2015_09 = () => {
  return (
    <div className={styles.wrap}>
      <h1 className={styles.title}>9. 지역위원은 균형의 추.</h1>
      <p>학교 운영에 있어서 최종 결재권은 교장에게 있지만, 주요 결정사항은 학교운영위원회의 심의를 반드시 거쳐야하기 때문에, 학교운영위원회는 학교장의 독단적인 운영을 견제할 수 있는 매우 중요한 권력 분산 기구이다.</p>
      <p>&nbsp;</p>
      <p>현재 공교육(국가가 주관하는 교육)이 규정하고 있는 학교 운영 방식은 학교자치이다.</p>
      <p>학교자치란, 학교를 운영하면서 결정해야할 (대부분의) 것들을 각 학교 내에서 자율적으로 결정하는 운영 방식이다.</p>
      <p>수요자인 학생과 학부모 그리고 공급자인 교사가, 지역마다 개인마다 다를 수 있는 ‘수요자의 니즈에 따른 교육과정 요구’와 ‘공급자의 교육철학과 업무능력에 따른 교육과정 제안’을 놓고 ‘합리적 논의를 거쳐 교육과정을 합의하여 운영하는 시스템’이다.</p>
      <p>이에 따라 학교장은 3개 주체의 중재자 성격의 운영 책임자가 되어야 하는데, 애석하게도 현실의 학교장은 학교자치가 시행되기 이전인 권위주의 시대 혹은 중앙집권 시대의 수직적 권력 소유자로서의 운영 책임자로 군림하고 있는 경우가 많다.(따라서 학교운영위원회의 견제 기능은 더더욱 필요한 것이 된다.)</p>
      <p>&nbsp;</p>
      <p>우선, 다음의 사례를 통해 ‘학교 운영과 관련하여’ 학부모와 학교장 간에 이해관계의 충돌이 발생할 수 있다는 것을 알아보자.</p>
      <p>&nbsp;</p>
      <p>사례 1.</p>
      <p>A학교는 2014년에 진부도교육청과 부종시청이 절반씩의 예산을 보내주어 최신 사양의 컴퓨터 35대를 갖춘 정보화 교실(컴퓨터실)을 운영할 수 있게 되었다.</p>
      <p>당시 여러 학생들이 방과 후 학교나 동아리 활동 등에서 3D 프린팅이나 코딩을 배우고 싶어 했는데, 불행이도 A학교에는 이를 가르치고 지도해 줄 교사가 없었으며, 당연하게도 해당 커리큘럼에 필요한 컴퓨터 프로그램도 구비되어 있지 않았다.</p>
      <p>이에 많은 학부모들이 학부모회를 통해 강좌 개설에 필요한 활동을 학교에 요청하였는데, 구체적으로는 3D 프린터의 구매, 코딩이나 모델링 수업에 필요한 컴퓨터 프로그램의 구매, 강좌 개설을 위한 강사의 모집 등이 이에 해당되었다.</p>
      <p>마침 A학교에는 이런 활동에 필요한 예산을 확보할 수 있는 다소의 여유가 있었는데, 안타깝게도 A학교의 교장은 이런 교육에는 관심이 없었다.</p>
      <p>그 교장은 개인적으로 오래전부터 목공에 관심이 많았는데, 2014년도 가을에 A학교 인근에 위치한 신설 중학교가 개교하면서 A학교의 학급이 1개 줄어들었을 때, 그때 생긴 교실 하나를 목공실로 사용하겠다고 선포하였다.</p>
      <p>그러고는 곧이어 목공 수업이 가능한 기술 교사를 초빙하였다.</p>
      <p>2015년에는 3D 프린팅이나 코딩 등의 수업을 원한다는 학생들의 수요를 무시하고, 가용할 수 있는 예산을 모두 사용하여 목공실 운영에 필요한 기계와 공구, 목재 등을 구매하였다.</p>
      <p>결국 A학교는 목공 동아리를 신설하였고, 방과 후 학교에도 목공 강좌를 개설하였다.</p>
      <p>당시 목공 수업을 원하는 학생도 있긴 있었지만, 앞서 말한 3D 프린팅이나 코딩을 원하는 학생의 수가 훨씬 더 많았었다.</p>
      <p>이에 학부모들의 불만이 상당히 컸으나, <span className={styles.quo}>“디지털 시대지만 아날로그 감성의 기술도 포기해서는 안 된다.”</span>는 교장의 뜻을 거스를 수는 없었다.</p>
      <p>&nbsp;</p>
      <p>사례 2.</p>
      <p>B학교에는 운동을 좋아하는 학생들이 유난히 많았다.</p>
      <p>그러다보니 방과 후 학교나 정규 동아리 인원을 뽑을 때 축구, 농구, 배구, 배드민턴 등의 종목에는 늘 지원자들의 경쟁이 치열했고, 탈락한 학생들은 자율 동아리를 신청하여서라도 좋아하는 운동을 하고 싶어 했다.</p>
      <p>그런데 문제는 장소였다. 학교 운동장이나 체육관은 늘 미어 터졌고, 때문에 이용하고자 하는 학생들은 순번을 정해서 이용해야 했다.(때문에 이용시간이 넉넉하지 않았다.)</p>
      <p>이 학교의 학부모들은 본관 건물 뒤쪽에 있는, 사용하지 않는 오래된 창고와 1980년대에 세워져 낡고 잘 관리하지 않는 역사 속 인물들의 동상 몇 개를 철거하면, 농구장 2개를 만들 수 있다면서 이를 학교에 건의하였다.</p>
      <p>또 얼마 전 인근의 채석장에서 학교의 시설비 용도로 기부한 학교발전기금으로, 군데군데 손상된 체육관의 바닥과 빗물이 침투하는 외벽을 보수하고, 체육관에도 냉난방시설을 갖추어달라고 건의하였다.</p>
      <p>그러나 운동과 같은 신체 활동보다는 정원 가꾸기가 취미였던 B학교의 교장은, 학부모들의 건의를 일부 받아들여 창고와 동상들은 철거했지만, 그 자리에 농구장이 아닌 ‘교육용’ 실내 식물원을 조성하였다.</p>
      <p>그리고 식물원 내부에 냉난방시설 등 4계절 운영에 필요한 각종 시설을 설치하고 꽃과 수목을 구매하느라 학교발전기금을 모두 사용하였다.</p>
      <p>B학교의 교장은, <span className={styles.quo}>“성장기 학생들에게 육체적 활동도 중요하지만, 그에 못지않게 정서적 안정이 중요한 것은 두말할 것도 없다. 식물과 친해지는 것은 인간에게 많은 이득을 안겨준다.”</span>며, 체육시설의 확충과 보수보다 실내 식물원을 만든 것이 더 교육적인 조치였음을 강조하였다.</p>
      <p>물론 잘 만들어진 해당 식물원은 관상용으로서 지역 신문에 학교의 자랑거리로 소개되었지만, 몇 몇 학생들을 제외한 대부분의 학생들에게는 관심 밖의 시설이었다.</p>
      <p>&nbsp;</p>
      <p>그 밖에도 셀 수 없이 많은 사례가 있지만, 일단 위 두 가지 사례만 놓고 보자.</p>
      <p>&nbsp;</p>
      <p>학생들이 원하는 정보화 교육이 아니라 목공 교육을 개설한 교장이나, 농구장을 만들고 체육관을 보수하는 대신 식물원을 만든 교장은 모두 자신이 옳다고 믿는 자신의 교육철학을 실천하였다.</p>
      <p>그들이 실상 자신들의 꿈이나 취미를 학교 예산을 통해 실현한 것이라고 한들, 사적인 욕망임을 입증하기는 불가능할 것이며, 그들의 주장(교육철학) 또한 보편적으로 금지된 것이거나 근거 없는 맹신이 아닌 다음에야 주관의 영역에 속하므로 비판하기는 어렵다.</p>
      <p>바른 글씨를 쓰는 것이 중요하다면서 학생들에게 매일 아침 글씨 연습을 시키는 교장이나, 예절 교육이 중요하다면서 매주 한 시간씩 전통 예절 교육을 실시하는 교장이나, 학생에게는 용기와 자립심이 중요하다면서 학급별로 학교 운동장에서 1박2일 캠핑을 실시하는 교장 등 자신의 소신을 가지고 특별한 교육을 행하는 모든 교장들의 교육철학 자체는 옳고 그름을 판단하기 어렵다.</p>
      <p>&nbsp;</p>
      <p>그러나 다만, 개인의 교육철학을 일방적으로 실행했다면, 그들의 행위는 옳지 않다고 판단할 수 있다.</p>
      <p>교육과정은 학교장의 뜻대로만 편성되어서는 안 된다.</p>
      <p>공교육의 운영 방식인 학교자치는, (학교운영위원회 심의를 통해) 각 운영 주체들의 뜻을 반영하여 운영하는 것이다.(「초·중등교육법」 제4장 제2절 학교운영위원회의 내용이 그러하다.)</p>
      <p>그 뜻을 반영하여 운영한다는 것을 보다 적극적인 표현으로 바꾸면, 각 운영 주체들의 합의에 의하여 운영한다고 할 수 있다.(뒤에서 ‘의결적 심의기구’라는 말에 대해 설명할 것이다.)</p>
      <p>다시 말해서 학교장이 개인의 교육철학을 실현하기 위해 독단적으로 학교를 운영하는 것은 위법이다.</p>
      <p>&nbsp;</p>
      <p>그런데 왜 당신 마음대로 했느냐고 물으면, 그들은 이렇게 대답할 것이다.</p>
      <p className={styles.quo}>“그렇지 않습니다. 목공실 운영 안건은 학교운영위원회의 심의를 거친 것이고, 필요한 예산을 배정한 것도 학교운영위원회에서 원안 가결된 사안입니다. 모든 것은 우리 학교의 교육 주체들의 동의를 얻어서 시행한 것입니다. 학부모님들과 여러 선생님들의 동의 없이는 아무 것도 할 수 없습니다.”</p>
      <p className={styles.quo}>“학교에서 제가 마음대로 결정할 수 있는 것은 거의 없습니다. 특히 학교발전기금은 일반 교육 예산과 달리 학교운영위원회에 의결권이 있습니다. 교장에게는 사용처를 결정할 수 있는 권한이 없습니다. 체육관 보수보다 실내 식물원을 조성하는 것이 낫겠다는 것은 학교운영위원회 결정사항입니다. 당시 운영위원들이 두 가지 안건을 놓고 토론하여 식물원 조성을 결정한 것입니다.”</p>
      <p>&nbsp;</p>
      <p>맞는 말이다.</p>
      <p>동의는 운영위원들이 해주었다.</p>
      <p>아마 평상시처럼 전원이 모두 교장이 제출한 안건에 찬성했을 것이다.</p>
      <p>어쩌면 몇 몇 학부모위원들이 그 안건에 아주 어렵게(!) 반대를 했을 수도 있는데, 그래봐야 교장의 꼭두각시인 교원위원들과 교장이 추천한 지역위원들이 모두 교장의 거수기였을 테니 안건들은 죄다 교장의 뜻대로 원안 가결되었을 것이다.</p>
      <p>&nbsp;</p>
      <p>이제 여러분들은 이해가 될 것이다.</p>
      <p>극히 적은 수지만 ‘독도 씨처럼 평상 시 자신의 주관대로 심의하는 사람’이 있을 때, 혹은 평소에는 거수기지만 위의 사례와 같이 ‘많은 학부모들이 원하는 것과 교장의 뜻이 명확하게 이해상충 될 경우에는 눈치를 보며 반대를 하는 학부모위원’이 있을 때, 바로 이럴 때 지역위원이 교장의 편이냐 학부모의 편이냐에 따라 농구대를 설치하느냐 식물원을 조성하느냐가 결정된다는 것을 말이다.</p>
      <p>&nbsp;</p>
      <p>일반적으로 교사는 ‘인사권을 쥔 직장 상사’인 교장의 결정에 따르는 가장 온순하고 가장 충직한 거수기이다.</p>
      <p>어떤 교원위원이 학교장의 의견에 반대할 수 있겠는가? 소위 ‘벌떡교사’라는 용감한 교사는 천연기념물 수준으로 드물기도 하거니와, 애초에 다른 교사들의 표를 얻어 선출 되는 교원위원이 될 가능성이 매우 낮다.(교무실에서 걸러진다는 뜻이다.)</p>
      <p>하지만 학부모위원은, 대부분의 경우 거수기로 행동하지만, 가뭄에 콩 나는 수준으로 드물게 학교장의 의지에 반하기도 한다.(주로 자신의 자녀에게 불리한 경우에 그렇다.)</p>
      <p>따라서 학교운영위원회에서는 죄다 원안 가결되는 것이 일상이지만, 극히 낮은 확률로 어쩌다 표 대결을 하는 상황이 발생할 수도 있다.</p>
      <p>그러면 결국 학부모 표와 교장 표로 나뉘어 대결하게 되는데, 이때 어느 한쪽도 과반을 확보할 수 없는 상황이므로 지역위원이 캐스팅보트를 쥐게 된다.</p>
      <p>&nbsp;</p>
      <p>상식적으로 본다면, 교육에 있어서의 수요자와 공급자는 항상 대립하는 관계는 아니지만 사실상 이해관계의 충돌이 자주 발생할 수 있는 관계인 것이 맞고, 따라서 수요자도 공급자도 아닌 지역위원을 (캐스팅보트를 쥔) ‘균형의 추’라고 불러야 마땅하다.</p>
      <p>하지만 현실에서는 균형을 잡는 추가 아니라 한쪽으로 완전히 기울어진 추로 작동하는 것이 지역위원이다.</p>
      <p>어차피 학부모위원들도 죄다 거수기지만, 만일을 위해 보험을 드는 것처럼, 내 사람을 지역위원으로 두어야 (그렇게 해서 과반의 표를 안정적으로 확보해야) 마음이 편한 것이 교장의 심리다.</p>
      <div className={styles.annotation_wrap}>
        <p>이것에 실패해서 곤혹을 겪게 되는 사례가 한비중학교에서 2016년에 발생한다.</p>
      </div>
      <p>그러기 위해서는 교장이 추천한 사람이 지역위원으로 당선되어야 하는데, 이왕이면 안전하게 무투표 당선되는 것이 좋고, 어차피 다른 학부모위원들은 자신 외에는 누가 지역위원이 되든 관심도 없고 선출 절차도 잘 모르니까, 그냥 교장 혼자 추천하는 것이 가장 쉽고 확실한 방법이 된다.</p>
      <div className={styles.annotation_wrap}>
        <p>교육에 있어서의 수요자와 공급자의 관계는 일반적인 시장에서의 관계와 다르다.</p>
        <p>수요자 입장에서는 선택을 달리 할 수 있는 대체재가 없다는 제한이 있고, 공급자 입장에서는 노력을 통해 서비스의 질과 양을 향상시켜도 가격에 반영되지 않는다는 제한이 있다.</p>
        <p>이런 제한된 시장에서는 서비스 품질에 대한 수요자와 공급자의 눈높이 차이가 이해관계의 충돌로 나타날 수밖에 없는데, 이와 같은 눈높이 차이를 좁히려면, <span className={styles.bold}>수요자와 공급자가 상대적이고 일방적인 관계가 아닌 상호 이해하고 협력하는 관계가 되어 함께 학교를 운영해야 한다.</span></p>
        <p>학교자치의 기본 개념이 이것이고, 학교운영위원회는 이러한 개념을 실현하기 위해 도입된 자치운영 기구이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>앞에서 말했듯이 공교육 기관인 학교의 운영 방식은 학교자치이고, 학교운영위원회는 학교자치를 보장하는 매우 중요한 권력 분산 기구이다.</p>
      <p>하지만 위와 같은 두 사례에서는, 민주적 합의를 위해 존재하는 심의기구인 학교운영위원회가 교장의 독단 운영을 견제하기는커녕, 교장의 뜻을 교육 주체 전부의 뜻인 것처럼 포장해주는 기만적인 역할을 한다.</p>
      <p>교장 혼자 결정해 놓고서도, <span className={styles.quo_mini}>‘모든 것이 학교의 구성원에 의해 결정되고 있다.’</span>고 주장할 수 있게 한다.</p>
      <p>또한 그렇게 주장함으로써, 교장의 결정 때문에 학교 운영에 문제가 발생해도, 그 책임을 학교 구성원에게 전가할 수 있게 한다.</p>
      <p>바로 전형적인 독재정치에서 볼 수 있는 어용기구화 된 것이다.</p>
      <p>&nbsp;</p>
      <p>이처럼 운영위원회가 독재의 기구로 변질 된 것은, 기본적으로는 ‘교육 주체로서의 주권을 가지고 심의에 임해야 하지만 그러지 못하는 거수기 학부모위원’과 ‘교장에 반대하지 못하는 교원위원’이 있기 때문이고, 나아가서는 ‘만일의 경우에도 교장의 뜻을 여유 있게 관철할 수 있도록 들어두는 보험으로서의 지역위원’이 있기 때문이다.</p>
      <p>&nbsp;</p>
      <p>지역위원이 어떤 의미를 가지는지, 왜 한비중학교의 임춘옥 교장을 비롯하여 많은 교장들이 위와 같은 위법 행위를 하고 있는지 어느 정도는 이해가 되었을 것이다.</p>
      <p>만일 아직 잘 모르겠다면, 그리고 이게 뭐 그렇게 대단한 일이냐고 되묻는다면, 아직 할 이야기는 많으니 더 듣고 생각해 보시라고 권한다.</p>
      <p>더 중요한 문제가 많이 있고, 생각지 못했던 일이 일어나는 놀라운 사례는 차고 넘친다.</p>
      <p>&nbsp;</p>
      <p>덧붙여, 앞으로 계속 중요하게 언급할 이야기지만, 위의 A학교와 B학교의 사례에서 학부모들이 각각 정보화 교육과정을 ‘요청’하고 체육시설 보수와 확충을 ‘건의’하였는데, 이는 바람직한 용어가 아니라는 것을 밝힌다.</p>
      <p>요청과 건의가 아니라 ‘요구’와 ‘제안’을 해야 한다.</p>
      <p>수용하지 않아도 무방한 의사 표현이 아니라, 회의 테이블에 올려놓고 논의를 하게 만드는 의사 표현을 해야 한다.</p>
      <p>그것은,</p>
      <p className={styles.bold}>수동적인 수요자가 아닌 교육 주체로서의 수요자의 권리다.</p>
    </div>
  )
}

export default Year2015_09