import React from 'react'
import styles from './MainText.module.css'

const Year2015_11 = () => {
  return (
    <div className={styles.wrap}>
      <h1 className={styles.title}>11. 다른 문제들(1).</h1>
      <p>자, 다시 앞으로 돌아가서 이야기를 이어가자.</p>
      <p>&nbsp;</p>
      <p>말했다시피 독도 씨는, 온 종일 마음이 불편했던 이유를 하나씩 되짚어 보면서, 첫 번째로 신경이 거슬렸던 지역위원 선출의 문제를 알아보기 위해 교육청에 문의를 남겼다.(문의의 내용은 조금 더 뒤에 살펴보자.)</p>
      <p>&nbsp;</p>
      <p>문의를 남기고 난 뒤, 독도 씨는 곰곰이 생각해 보았다.</p>
      <p className={styles.quo_mini}>‘아는 것보다 모르는 것이 더 많지만, 한비중학교의 학교운영위원회가 정상이 아니라는 것쯤은 확실히 알겠다. 도대체 무슨 심의가 그래? 다들 안건에 아무 관심도 없는 것 같아. 어떻게 그렇게 조용히 앉아만 있을 수 있나…….’</p>
      <p>독도 씨는, 어제의 분위기가 생각했던 것과 너무 달라서 크게 실망한 상태였다.</p>
      <p>&nbsp;</p>
      <p className={styles.quo_mini}>‘그러고 보니 졸업앨범 안건을 심의할 때는 질문을 좀 하더라. 윤미옥 위원하고 안미자 위원하고 또 누구였지? 아! 추한심 위원이었지. 아무래도 3학년 자녀를 둔 엄마들이니까 관심이 있었겠지. 음……, 다른 안건들은 자기 자녀하고 직접적인 관련이 없거나, 관련이 있어도 큰 영향을 주지 않는 것들이라서 무관심했을 것이고…….’</p>
      <p>그랬다. 학부모위원들은 자신의 자녀들과 관련이 깊은 안건에만 관심을 보였다.</p>
      <p>교원위원과 지역위원들은 아예 그만큼의 관심도 없었고.</p>
      <div className={styles.annotation_wrap}>
        <p>당연히 그래서는 안 된다.</p>
        <p>학부모위원은 자기 자녀의 대리가 아니라, 학부모들의 대표다.</p>
        <p>그러므로 개인의 관심사에만 집중하는 자세로 심의에 임하면 안 된다.</p>
      </div>
      <p>&nbsp;</p>
      <p className={styles.quo_mini}>‘죄다 가만히 있다가……. 그러다가 그냥 다 이의가 없으므로 원안 가결이래.’</p>
      <p>운영위원회 심의는 형식적이었다.</p>
      <p className={styles.quo_mini}>‘이런 걸 아마 날치기라고 하지?’</p>
      <p>정당한 논의 없이, 통과를 목적으로 하는, 형식적인 심의나 의결을 ‘날치기’라고 한다.</p>
      <p>&nbsp;</p>
      <p>모든 안건이 날치기로 처리되는 회의였기 때문에, 질문을 많이 했던 독도 씨는 두 시간 내내 다른 위원들의 곱지 않은 눈길을 견뎌야했다.</p>
      <p className={styles.quo_mini}>‘질문을 많이 하면 안 되는 거였나? 왠지 큰 죄를 지은 것 같은 느낌이었어. 허허허.’</p>
      <p>생각할수록 쓴웃음만 나왔다.</p>
      <p>&nbsp;</p>
      <p className={styles.quo_mini}>‘그리고 따져보면 명백히 잘못인 것도 한두 가지가 아니야. 시행령과 조례를 보면 학부모가 경비를 부담하는 사항을 심의할 때는, 사전에 학교 홈페이지에 그 내용을 게시하여 학부모가 열람할 수 있게 해야 한다고 적혀 있어.’</p>
      <p>독도 씨의 생각이, 다시 구체적인 문제에 접근하기 시작했다.</p>
      <p>&nbsp;</p>
      <p className={styles.quo_mini}>‘이번에 체험학습이랑 졸업앨범 안건은 학부모가 돈을 내는 안건인데도 홈페이지에 내용을 게시하지 않았어. 의견을 수렴하지 않았으니 법령을 위반한 거야. 학교가 이렇게 법령을 위반해도 되는 것일까? 누구라도 법령을 위반하면 안 되지만, 아이들을 가르치는 학교는 더욱 안 되는 것이 아닐까?’</p>
      <p>&nbsp;</p>
      <p>독도 씨는 새로운 문제점을 짚어 냈는데, 이는 의견 수렴의 문제였다.</p>
      <p>&nbsp;</p>
      <p>이제, 두 번째로 신경이 거슬렸던 의견 수렴의 문제를 살펴보자.</p>
      <p>「초‧중등교육법 시행령」에는, 운영위원회에서 ‘학부모가 경비를 부담하는 사항’을 심의할 경우, 미리 학부모의 의견을 수렴하여야 하고, 그 방법은 시·도의 조례에서 정하라고 명시되어 있다.</p>
      <p>진부도교육청의 「학교운영위원회 설치·운영 조례」는, 의견 수렴을 할 때, 먼저 학교 홈페이지에 5일 이상 심의할 내용을 게시하고, 이를 가정통신문 등으로 알리라고 규정하고 있다.</p>
      <p>또한 학부모는 열람기간 내에, 심의 내용에 대한 의견을 제출할 수 있으며, 운영위원회는 학부모가 제출한 의견을 심의에 반영하라고 정하고 있다.</p>
      <p>&nbsp;</p>
      <p>예를 들자면, 먼저 <span className={styles.quo_mini}>‘졸업 앨범을 제작할 예정입니다. 작년의 경우 150면(쪽) 5만 원이었는데 만족도가 높았습니다. 그래서 올해도 비슷한 조건으로 공고하고, 경쟁입찰로 업체를 결정할 예정입니다.’</span>라는 심의 안건의 내용을 홈페이지에 게시해야 한다.</p>
      <p>그러고 나서, <span className={styles.quo_mini}>‘졸업 앨범 기획(안)을 심의할 예정인데, 그 내용은 학교 홈페이지에 게시 되어 있습니다. 읽어 보시고 앨범의 품질이나 규격, 가격, 입찰 방법 등의 심의 사항에 대해, 다른 의견이나 추가로 제안할 내용이 있다면 5일 내에 의견을 주십시오. 의견을 반영하여 심의 하겠습니다.’</span>라는 내용의 가정통신문을 발송해야 한다.</p>
      <div className={styles.annotation_wrap}>
        <p>이왕이면 학부모회에서 여러 의견을 취합하여 제출하면 더욱 좋다.</p>
      </div>
      <p>&nbsp;</p>
      <p>전날 회의에서 심의한 ‘학년별 현장체험학습 운영(안)’과 ‘3학년 졸업앨범 제작 기획(안)’은 학부모가 돈을 내는(경비를 부담하는) 사항이므로, 법령에 따라 반드시 의견 수렴을 해야 하는 안건이다.</p>
      <p>그러나 한비중학교는 이 안건들을 학교 홈페이지에 게시하지도 않았고, 가정통신문으로 알리지도 않았다.</p>
      <p>그러니까 「초‧중등교육법 시행령」과 진부도교육청의 「학교운영위원회 설치·운영 조례」를 위반한 것이다.</p>
      <p>&nbsp;</p>
      <p>위에서 언급했듯이, 그리고 앞으로 계속해서 언급하겠지만, 학교의 운영은 교육 주체의 합의에 의해 이루어져야 한다.</p>
      <p>학교운영위원회는 교육 주체의 합의를 위한 대의 기구이다.</p>
      <p>대의 기구인 운영위원회가 교육 주체의 의견을 수렴하는 것은 너무나 당연한 절차이다.</p>
      <p>그 당연한 절차를 잘 지키라고 법령에서 특별히 의견 수렴 방법까지 구체적으로 정해주었건만 한비중학교는 지키지 않았다.</p>
      <p>물론 이번에만 국한된 일이 아니다.(또한 한비중학교에서만 국한된 일도 아니다.)</p>
      <p>독도 씨가, 학교 홈페이지에 올라온 작년과 재작년의 가정통신문, 학교운영위원회 회의록 등을 모조리 내려 받아 검토해본 결과, 의견 수렴을 의무화한 안건 중에, 심의 내용을 학교 홈페이지에 게시하고 가정통신문으로 언급한 안건은 단 하나도 없었다.(다른 학교의 홈페이지에서도 찾아보기 어려웠다.)</p>
      <p>&nbsp;</p>
      <p>이는 정부가 국민 생활과 밀접한 어떤 정책을 시행하면서, 여론을 일체 묻지 않고 독단적으로 정한 것과 다를 바가 없는 행위(밀실행정)인데, 보통 이런 일이 발생하면 언론에서는 비판하는 기사를 내고 국민들도 정부를 성토하기 마련이지만, 학교운영위원회에서는 이런 일이 숱하게 발생해도 아무도 그 절차를 문제 삼지 않는다.</p>
      <p>그동안 이런 식으로 처리해왔던 것에 익숙해져 있기 때문이다.</p>
      <div className={styles.annotation_wrap}>
        <p>앞에서 말한, <span className={styles.fbody}>‘학교를 졸업한 사람들이 민주주의를 잘못 배워서 잘못 이해하고 있다.’</span>는 것에는 이런 부분(잘못된 절차를 문제 삼지 않는 부분)도 포함된다.</p>
        <p>어떤 학교의 졸업 앨범의 가격이 인근 학교에 비해 지나치게 비싸게 결정되었다고 하자.</p>
        <p>학부모들은, <span className={styles.fbody}>“옆 학교는 5만 원 받던데, 우리 학교는 왜 앨범 가격이 10만 원이야?”</span>라고 모여서 불만을 이야기할 것이고, 그러다 보면 점차 목소리가 커지게 되어, 급기야 학부모회장이 대표로 교장실에 따지러 갈 것이다.(학부모회가 어용이면, 여기까지 오지도 않는다.)</p>
        <p>하지만 대개는, 조용히 차 한 잔 마시고, 덕담을 나누는 수준의 항의를 하고 나올 것이다.</p>
        <p>보통의 교장은, 왜 그렇게 비싼지 대충 설명하고 나서, <span className={styles.fbody}>“제가 부모님들의 마음을 잘 헤아리지 못해서 이런 일이 일어났네요. 제 불찰입니다. 하지만 운영위원회에서 결정이 난 사항이라 이제 와서 바꿀 수도 없고……, 다음에는 가격을 낮출 수 있도록 노력하겠습니다. 죄송합니다.”</span>라고 말할 것이다.</p>
        <p>그렇다.</p>
        <p>이렇게 따져봐야 바뀔 것은 없다.</p>
        <p>민주주의를 제대로 배웠다면, <span className={styles.fbody}>“왜 그렇게 결정한건지 내역과 그 이유를 알려 달라.”</span>고 묻고, <span className={styles.fbody}>“왜 내 의견은 묻지 않았나?”</span>라고 따져야 한다.</p>
        <p>앨범 제작 업체와 품질과 가격을 결정한 근거와 절차를 알려주지 않은 것과, 의견 수렴을 하지 않은 것이 잘못이지, 가격이 비싼 것이 잘못이 아니다.</p>
        <p>학교장은 가격이 비싼 이유를 조목조목 자세하게 설명할 수 있다.(그 근거가 마음에 들지 않는다고 해서, 결정을 무효화할 수는 없다.)</p>
        <p>처리 과정이 아닌 결과를 문제 삼아봐야 되돌리기도 어려울뿐더러, 같은 문제(밀실행정)가 계속 반복되는 것을 막을 수도 없다.</p>
        <p>처리 과정(절차)의 잘못을 지적해야 그 잘못을 고칠 수 있다.</p>
      </div>
      <p>&nbsp;</p>
      <p>그런데 왜 한비중학교는 의견 수렴 절차를 지키지 않았을까?</p>
      <p>얼마 뒤 독도 씨는 학교 행정실에서 임춘옥 교장과 면담을 하게 되는데, 그때 이와 관련한 대화도 하게 된다.</p>
      <p>자세한 대화 내용은 뒤에 서술하겠지만, 그 내용을 요약하자면 다음과 같다.</p>
      <p className={styles.quo_mini}>‘해당 규정을 본 적이 있다. 하지만 지켜야할 절차가 너무 많아서 모든 규정을 다 기억하기도 어렵고, 관련 업무가 너무 많아서 일일이 다 지키기도 어렵다. 그러니 좀 이해해 달라.’</p>
      <p className={styles.quo_mini}>‘그리고 어차피 홈페이지에 올려놓아도 아무도 읽지 않는다. 읽는다고 해도 의견을 내지 않는다. 그러니 사실상 불필요한 것이고, 불필요하다보니 절차에 소홀하게 된다.’</p>
      <p className={styles.quo_mini}>‘어쨌든 다음부터는 규정대로 할 테니 이번에는 넘어가 달라. 이걸 문제 삼으면 (법령 위반사항이므로) 이미 끝난 심의를 절차에 맞게 다시 해야 해서 일이 복잡해진다. 다음 주에 예정된 체험학습을 못갈 수도 있다. 아이들이 얼마나 실망하겠나.’</p>
      <div className={styles.annotation_wrap}>
        <p>여기서도 학생은 볼모가 된다. 학교장의 불법 행위 보다 학생들의 체험학습 불발이 더 큰 문제라고 여기기 때문에, 일반적으로 학부모들은 제보자(문제를 제기한 자)의 편에 서지 않는다. 그렇기 때문에, 법령 위반은 흔하게 발생하지만 이를 문제 삼는 사람은 드물다.</p>
        <p>교육청 역시 학교 홈페이지만 점검 해봐도 법령 위반을 손쉽게 잡아낼 수 있지만, 민원이 없다는 이유로 점검하지 않을뿐더러, 어쩌다 민원이 발생하면 이를 바로잡으려고 노력하는 것이 아니라 무마(취하)시키려고 노력한다.(팔이 안으로 굽는다는 이야기는 이미 했다.)</p>
        <p>사실상 교육청이 앞장서서 법을 사문화시키고 있다.</p>
        <p>그런데 여기서 정말 재밌는 것은, 관련 법령들은 계속해서 학교자치를 강화하는 방향으로 개정되어 왔다는 것이다.</p>
        <p><span className={styles.bold}>한쪽에서는 열심히 개정하고, 한쪽에서는 사문화시키고. 이것이 모두 한 조직(교육청) 내에서 생기는 일</span>이라니 재밌지 않은가?</p>
        <p>도대체 학교는 무엇을 하는 곳이고, 공교육은 무엇을 하고 있는 것일까?</p>
        <p>요지경이 아닐 수 없다.</p>
      </div>
      <p>&nbsp;</p>
      <p>임춘옥 교장의 말에는 겉으로 드러난 두 가지의 문제점이 발견 된다.</p>
      <p>&nbsp;</p>
      <p>첫째, 의견 수렴 절차는 그만한 당위성이 있기 때문에 복잡하고 귀찮아도 지켜야 한다.</p>
      <p>대의 기구는 구성원의 대표로서 의사 결정을 대신하는 것이므로, 구성원의 의사를 묻지 않는다면 존재 가치가 없다.(이럴 때 우리는 ‘민의가 왜곡되었다.’는 표현을 한다)</p>
      <p>의견 수렴은 운영위원회의 존재 이유와도 같다. 마땅히 지켜야할 절차이므로 이를 생략한 것은 가벼운 잘못이라 할 수 없다.</p>
      <p>&nbsp;</p>
      <p>둘째, 의견 수렴 절차는 결과(학부모가 의견을 내든 말든)와 무관한 절차이므로, 결과를 예단하고 그에 맞춰 행동해서는 안 된다.(설혹 의견이 없을 것이라 예상된다고 해도 수렴 절차를 생략해서는 안 된다는 뜻이다.)</p>
      <p>게다가 읽을 사람이 매우 적을 것이라는 판단은 예측 가능한 판단이지만, 아무도 읽지 않을 것이라는 판단은 섣불리 예측 할 수 없는 판단이다.(실제로 읽을 사람이 있을 수 있다는 것은 독도 씨가 있음으로써 충분히 증명된다.)</p>
      <p>어떤 작은 마을에 투표소를 차려야 하는데, 마을 사람들이 함께 야유회를 간다고 투표를 하지 않겠노라고 이장이 통보했다면, 선거 준비를 하지 않아도 될까? 아니면 누군가는 올지 모르니까 투표소를 준비한 다음, 어떻게든 투표를 하라고 사람들을 독려해야 하는 것일까? 어떤 것이 옳은 행동인가?</p>
      <p>&nbsp;</p>
      <p>이런 점들을 고려한다면, 법령 위반을 가볍게 보아서는 안 된다.</p>
      <p>&nbsp;</p>
      <p>그리고 임춘옥 교장의 말에 직접 드러난 것은 아니지만, 다른 정황들과도 연결해서 판단해보면 또 다른 문제점 두 가지를 발견할 수 있다.</p>
      <p>&nbsp;</p>
      <p>첫째, 이 잘못을 단순한 실수가 아닌 ‘고의’라 해석할 때 발견되는 문제인데, ‘운영위원회’라든가 ‘심의’라는 단어의 노출을 최소화시킴으로써, 학부모에게 대의 기구에 대한 정보(존재)를 감추려는 숨겨진 의도가 있다는 문제이다.</p>
      <p>이러한 의도는 장기적인 흐름 속에서 계속 발생하는 것인데, 교육계의 기득권(학교장의 독단적 학교 운영) 보호를 위한 여러 가지 행동의 하나라고 볼 수 있다.(대표적인 행동으로는 ‘형식적인 운영위원회 연수’를 들 수 있다.)</p>
      <p>1995년부터 30년이나 시행된 학교운영위원회 시스템이 아직도 국민들에게 생소하고 낯설다는 것이 이러한 해석을 뒷받침하는 현상이라 할 수 있다.</p>
      <div className={styles.annotation_wrap}>
        <p>구체적인 입법 절차를 모르는 사람들도, 정치에 무관심한 사람들도, 국회의원이 무엇을 하는 사람인지는 안다.</p>
        <p>매일 보이는 뉴스에서 빠지지 않는 단어이기 때문이다.</p>
        <p>하지만 1995년 이후로 학교를 다녔거나 다니고 있는, 자녀를 학교에 보냈거나 보내고 있는, 그 많은 <span className={styles.bold}>국민들의 대부분은, 학교 운영의 핵심 기구인 학교운영위원회의 운영위원이 뭘 하는 사람인지 모른다.</span></p>
        <p>어디에서 무슨 일을 하는 지 들은 바가 없기 때문이다.</p>
        <p className={styles.bold}>그들의 회의는 베일에 감추어져 있고 매번 날치기로 처리되고 있다.</p>
        <p>무려 30년이라는 긴 시간 동안.</p>
      </div>
      <p>&nbsp;</p>
      <p>둘째, 사실은 교장의 책임이 아닌데, 스스로 교장의 책임이라고 여기는 문제가 있다.</p>
      <div className={styles.annotation_wrap}>
        <p>보기에 따라서는 의견 수렴의 문제보다 더 심각한 문제일 수 있다.</p>
      </div>
      <p>위의 대화 내용 요약을 보면, 독도 씨의 의견 수렴 절차 지적에 대해, 임춘옥 교장은 유감을 표하면서 다음부터는 잘하겠다고 말했음을 알 수 있다.</p>
      <p>그런데 법령에서 명시한 의견 수렴의 책임자는 교장이 아니라 운영위원장이다!</p>
      <div className={styles.annotation_wrap}>
        <p>상기 시행령이나 상기 조례에서 해당 문구의 핵심만 발췌하면, ‘운영위원회는 학부모의 의견을 수렴해야 한다.’이다.</p>
        <p>그러므로 의견 수렴의 주체는 학교장이 아니라 운영위원회이다.</p>
        <p>(계속 이야기하지만 학교장과 운영위원회는 상호 독립된 기구이다.)</p>
        <p>또한 상기 조례에서 ‘위원장은 운영위원회를 대표한다.’고 하였으므로, 의견 수렴의 책임자는 운영위원장이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>이 문제 또한, 한비중학교 뿐 아니라 대부분의 학교에서 발생하는 일인데, 중요한 부분이므로 상세히 살펴보자.</p>
      <p>&nbsp;</p>
      <p>‘운영위원회가 한다, 혹은 해야 한다, 혹은 할 수 있다’라고 법령에 명시된 일은 심의(회의), 서류 제출 요구, 의견 수렴, 회의 공개, 회의록 작성 및 공개, 소위원회 구성과 업무 위임, 학교발전기금 조성 등이다.</p>
      <p>이러한 업무의 대부분은 운영위원들이 함께 회의를 통해 논의하고 정하는 일이다.</p>
      <p>그러나 이 중에서 회의 소집 및 진행, 각 위원에게 심의 안건 송부, 회의록 작성 및 공개, 회의 개최 공지(회의 공개), 안건의 사전 공지(의견 수렴) 등은 ‘운영위원’이 하는 일이 아니라 운영위원회의 대표자인 ‘운영위원장’이 하는 일이다.</p>
      <p>그리고 운영위원장의 업무를 처리하기 위해, 운영위원장은 간사를 위촉한다.</p>
      <p>&nbsp;</p>
      <p>간사는 주로 행정실 직원이 위촉된다.</p>
      <p>그런데 여기서 이상한 일이 발생한다.</p>
      <p>간사는 분명 운영위원장의 업무를 처리하는데, 업무 지시는 학교장으로부터 받는다.</p>
      <p>회의 소집일도 학교장의 결정에 따라 간사가 통보하고, 학교장이 제출할 안건을 결정하면 간사가 각 위원들에게 송부하고, 홈페이지에 공지하는 일도 모두 학교장의 지시에 따라 간사가 실행한다.</p>
      <p>운영위원장은 ‘회의 진행’과 ‘회의록을 확인하는 서명’만 하고, 나머지 운영위원장의 업무는 학교장이 결정하고 간사가 처리한다.</p>
      <p>&nbsp;</p>
      <p>왜 그럴까?</p>
      <p>학교장은 이렇게 말한다.</p>
      <p className={styles.quo}>“행정 업무는 복잡하다. 하던 사람이 해야 한다. 잘 모르는 일반인이 하려면 너무 힘들다. 운영위원장의 편의를 위해서 학교가 대신하는 것이다. ‘위원장님은 학교를 위해 노고가 많으신 분’이다. 그 정도는 학교에서 해드려야 한다. 당연한 일이다.”</p>
      <p>정말 고마운 마음씨이다.</p>
      <p>사실 학교장이 이렇게 말하면, 감사하다고 답하는 위원장이 많을 것이다.</p>
      <p>슬픈 일이지만, 충분히 이해한다.</p>
      <p>한편으로는, 학교 행정 업무의 책임자는 학교장이므로, 학교운영위원회 관련하여 발생하는 행정 업무는 학교장의 책임이라고 말하는 견해도 있다.</p>
      <p>이런 견해는, 학교장이 간사에게 지시하여 업무를 처리하는 것이 절차상 당연하다는 견해다.</p>
      <p>&nbsp;</p>
      <p>그런데 ‘편의를 위해 대신 해준다는 고마운 마음’도, ‘행정 업무라서 학교장이 처리해야 한다는 견해’도 모두 틀렸다.</p>
      <p>&nbsp;</p>
      <p>운영위원장의 일은 운영위원회의 일이다.</p>
      <p>거듭 말하지만 학교장과 독립된 기구인 운영위원회의 일을 학교장이 결정하여 처리하면 그것은 월권이다.</p>
      <p>&nbsp;</p>
      <p>업무의 결정(결재)은 운영위원장이 직접 해야 한다.</p>
      <p>그리고 간사에게 직접 업무 처리를 지시해야 한다.</p>
      <p>행정 절차나 업무에 대해 잘 모르면 배워야 한다.</p>
      <p>만일 그 정도 노고가 어렵다면 운영위원장직을 맡지 말아야 한다.</p>
      <p>운영위원장이 자신의 업무가 무엇인지 모르거나, 알면서도 외면하게 되면, 운영위원회는 학교장에게 종속될 수밖에 없다.</p>
      <p>그 결과, 학교자치의 실현은 요원하게 되고, 학교민주주의 수준은 바닥으로 내려앉게 되고, 학생들은 민주주의를 배울 수 없게 된다.</p>
      <p>&nbsp;</p>
      <p>자, 만일 누군가 운영위원장이 되어 학교장에게 이런 말을 한다고 하자.</p>
      <p className={styles.quo}>“고맙지만 저는 다 할 줄 압니다. 그러니까 앞으로 안건은 저에게 제출해 주세요. 제가 회의 일자를 잡고 소집하고 안건을 송부할게요. 그리고 학교 홈페이지에 공지할 일이 있으면 제가 간사에게 말해 둘게요. 간사가 운영위원회 업무 관련하여 제가 지시하는 일을 잘 처리할 수 있도록 교장선생님께 협조를 부탁드립니다. 혹시 제가 해야 할 일을 제대로 못하거나 문제가 있을 것 같으면 그때는 지적해 주시기 바랍니다.”</p>
      <p>어떤가?</p>
      <p>운영위원장이 이렇게 말하면 ‘위의 고마운 학교장’ 입장에서는 좋아해야 하지 않을까?</p>
      <p className={styles.quo}>“아이고, 위원장님. 노고를 마다 않고 직접 해 주신다니 감사합니다. 제가 한 결 편해지겠습니다.”<span className={styles.black}>라고 말해야 정상이지 않을까?</span></p>
      <p>&nbsp;</p>
      <p>그런데 아니다.</p>
      <p>운영위원장의 업무를 운영위원장이 직접 하겠다고 하면, 학교장은 좋아하지 않는다.</p>
      <p>학교장은 자신이 최고 책임자이고 싶고, 자신의 마음대로 학교를 운영하고 싶어 한다.</p>
      <p>그런데 독립된 기구인 운영위원회는 그 본질이 학교장을 견제하는 기구이다.</p>
      <p>따라서 학교장 입장에서는 운영위원회가 정상적으로 작동하는 것이 결코 좋은 일이 아니다.</p>
      <p>그래서 운영위원들을 거수기로 만들고, 운영위원장도 소위 바지사장처럼 실권이 없는 상태로 만들려고 한다.</p>
      <p>게다가 학교장은, 운영위원회에서는 대표가 아닌 한 사람의 운영위원이다.</p>
      <p>자기 위의 대표자가 있는 것이 탐탁지 않으니, 운영위원장을 들러리로 만들어야 마음이 편해진다.</p>
      <div className={styles.annotation_wrap}>
        <p>모든 학교장이 다 그렇지는 않다.</p>
        <p>아주 적은 수지만, 학교자치가 활성화되기를 바라는 학교장도 있다.</p>
      </div>
      <p>&nbsp;</p>
      <p>정리하자면, 실제 법령이 부여한 책임(의견 수렴 책임)은 운영위원장에게 있다.</p>
      <p>하지만 현실에서의 실무는 학교장이 틀어쥐고 실행하고 있다.</p>
      <p>운영위원장을 허수아비로 만들고, 실권은 학교장이 다 가져간다.</p>
      <p>그러다 보니, 법령 위반의 잘못을 지적하면, 학교장의 책임이 아닌데 학교장이 사과를 한다.</p>
      <p>&nbsp;</p>
      <p>결론을 내려 보자.</p>
      <p>운영위원회는 학부모가 경비를 부담하는 안건을 심의할 때는 미리 의견 수렴 절차를 거쳐야 한다.</p>
      <p>그러나 한비중학교는 그러지 않았다.</p>
      <p>이는 원래 운영위원장의 책임이지만, 실제로는 학교장이 업무를 진행하고 있으므로, 독도 씨는 학교장에게 잘못을 지적해 보았다.</p>
      <p>교장은 이 업무를 가볍게 보았노라고, 하지만 아무도 읽지 않으므로 문제되지 않노라고, 어쨌든 다음에는 잘 지키겠노라고 말했다.</p>
      <p>하지만 의견 수렴은 당위적 절차이며, 읽기 여부와 관계없이 수행해야할 절차이기 때문에 교장의 답변은 핑계에 지나지 않는다.</p>
      <p>또한 운영위원회 자체를 사람들에게 알리지 않으려는 마음이 기저에 깔려 있는 듯하고, 자신의 책임이 아닌데도 자신의 책임이라고 하는 것은, 운영위원회를 거수기와 바지사장의 집합체로 만들었기 때문인 것으로 판단된다.</p>
      <div className={styles.annotation_wrap}>
        <p>다음에 이어지는 회의 공개의 문제도 마찬가지로, 독도 씨는 학교장에게 문제를 지적한다.</p>
        <p>독도 씨가 이 문제들이 운영위원장의 책임임을 알게 되고, 학교장이 왜 운영위원장의 업무를 가로채려고 하는지 깨닫게 되는 것은 좀 더 뒤의 일이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>덧붙여, 법령 위반 문제와는 별도로, 교육과정으로서의 의견 수렴에 대해서도 생각해볼 필요가 있다.</p>
      <p className={styles.quo}>“먹고 살기도 바쁜 세상인데 알아서 해주세요. 선생님을 믿어요.”<span className={styles.black}>라는 사람들이 생각보다 많은 것이 사실이다.</span></p>
      <div className={styles.annotation_wrap}>
        <p>진짜로 교사를 믿는 순진한 사람도 있지만. 관성이 반이고 귀찮음이 반인 믿음을 가진 사람도 많다.</p>
        <p>알다시피 후자(믿는 척 하는 사람)의 경우, 자신에게 피해가 발생하면 오히려 더 교사와 학교를 격렬하게 비난하고 자비심 없이 공격한다.</p>
        <p>하지만 참여하지 않은 결과의 책임은 권리를 행사하지 않은 사람에게 있다.</p>
        <p>학교는 알아서 잘 굴러가는 조직이 아닐뿐더러, 교사는 만능이 아니다.</p>
      </div>
      <p>그런 그들이 볼 때 의견 수렴은 불필요한 일이고, 그래서 그들은 학교의 입장을 문제 삼지 않을 것이며, 학교 역시 그런 사람들을 염두에 두고 절차를 지키지 않는다.</p>
      <p>그러나 모두가 다 그렇게 생각하지는 않는다.</p>
      <p>그리고 모두가 다 그렇게 생각하지 않도록, 학교는 교육(연수)해야 한다.</p>
      <div className={styles.annotation_wrap}>
        <p>이런 교육(참여하게 하는 교육)은 학교(공교육)가 가르쳐야할 중요한 교육과정의 하나다.</p>
        <p>행정(운영) 참여를 통해 체득하는 학습의 효과는, 1차적으로는 학부모와 교직원에게 나타나지만, 이는 결국 학생들에게 영향을 미치게 된다.</p>
        <p>실무적인 실기 교육은 텍스트로만 배우는 것보다 더 효과적이다.</p>
        <p>학교는 학원과 달라서 ‘가나다’와 ‘구구단’만 가르치는 곳이 아님을 우리는 안다.</p>
        <p>공교육은 ‘학교자치, 학교민주주의’를 통해서 학생들에게 우리 사회의 정체성(헌법 정신, 민주주의와 공화주의)을 가르쳐야 한다.</p>
        <p>사회의 유지를 위한 공교육의 가장 중요한 교육과정은 바로 ‘민주주의’를 가르치는 것이고, 민주주의에서 빠져서는 안 되는 ‘참여 정신과 참여 방법’은 반드시 교육되어야 한다.(참여 없는 민주주의는 독재의 자양분이 된다.)</p>
        <p>하지만 현실의 학교는, 참여하게 하는 교육을 하기는커녕, 참여를 제한(의견 수렴을 배제)함으로써 그 반대의 것(독재)을 교육하고 있다.</p>
      </div>
      <p>&nbsp;</p>
      <p>참고로, <span className={styles.quo}>“다음부터는 잘 할 테니, 이번에는 그냥 넘어갑시다.”</span>는 말은 운영위원회 표준 클리셰의 하나다.</p>
      <p>누군가 어떤 안건에 대해 문제를 지적할 때 마다 반드시 나오는 말인데, 문제는 다음에도(1년 뒤에도) 또 같은 안건에서 또 같은 문제가 발생한다.</p>
      <p>물론 그때에도 <span className={styles.quo_mini}>‘다음부터는 잘 할 테니 이번에는 그냥 넘어가자.’</span>고 말한다.</p>
      <p>새로운 운영위원이 지적하면 처음이라 여기고 넘어갈 수 있고, 같은 운영위원이 <span className={styles.quo_mini}>‘작년에도 그래놓고 또?’</span>라고 지적하면, <span className={styles.quo_mini}>‘깜박했다. 다음에는 정말 잘 할 테니 이번 한 번만 더 넘어가자.’</span>라고 버티면 된다.</p>
      <p>어차피 운영위원의 임기에는 지역마다 2년에서 4년 사이의 연임 제한이 있고, 자녀가 졸업하거나 학교장이 다른 곳으로 떠나거나 하는 변수들을 고려하면, 두세 번만 버티면 지적을 당할 일이 없어지기 때문이다.</p>
      <p>결국 어처구니없게도, 잘못된 관행들은 웬만해서는 고쳐지지 않는다.</p>
    </div>
  )
}

export default Year2015_11