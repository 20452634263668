export const hamburger =
    <svg width="24" height="24" viewBox="0 0 24 24">
        <rect x="3" y="6" width="18" height="2" fill="#6B7280" />
        <rect x="3" y="11" width="18" height="2" fill="#6B7280" />
        <rect x="3" y="16" width="18" height="2" fill="#6B7280" />
    </svg>


export const hamburger_close =
    <svg width='24' height='24' viewBox="0 0 24 24">
        <line x1="4" y1="4" x2="20" y2="20" stroke="#6B7280" strokeWidth="2" />
        <line x1="20" y1="4" x2="4" y2="20" stroke="#6B7280" strokeWidth="2" />
    </svg>

export const mypage_icon =
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
    >
        <circle cx="12" cy="7" r="5" fill="#000" />
        <path
            d="M12 14c-5.33 0-8 2.67-8 8h16c0-5.33-2.67-8-8-8z"
            fill="#000"
        />
    </svg>

export const quotes =
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="512.5px" height="512.5px" viewBox="0 0 512.5 512.5"
        style={{ width: '50px',height:'50px', fill: 'gray' }}
    >
        <g>
            <path d="M112.5,208.25c61.856,0,112,50.145,112,112s-50.144,112-112,112s-112-50.145-112-112l-0.5-16
		c0-123.712,100.288-224,224-224v64c-42.737,0-82.917,16.643-113.137,46.863c-5.817,5.818-11.126,12.008-15.915,18.51
		C100.667,208.723,106.528,208.25,112.5,208.25z M400.5,208.25c61.855,0,112,50.145,112,112s-50.145,112-112,112
		s-112-50.145-112-112l-0.5-16c0-123.712,100.287-224,224-224v64c-42.736,0-82.918,16.643-113.137,46.863
		c-5.818,5.818-11.127,12.008-15.916,18.51C388.666,208.723,394.527,208.25,400.5,208.25z"/>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
    </svg>
