import React from 'react'
import styles from './MainText.module.css'

const Prologue15 = () => {
  return (
    <div className={styles.wrap}>
      <h1 className={styles.title}>15. 질문의 대답.</h1>
      <p>왜 학교 교실의 게시판에 ‘규정을 개정하는 방법’이 게시되지 않는가?</p>
      <p>&nbsp;</p>
      <p>규정의 전문을 가르쳐주지 않는 행위는, 학교장 독재를 위한 많은 장치들의 하나로서, 가장 핵심적인 장치 중 하나이다.</p>
      <p>학생이 규정을 개정하는 방법을 알게 되면, 이는 주권의 요구로 이어질 것이다.</p>
      <p>학생이 주권을 가지면 학교장 마음대로 학교 운영이 가능할까?</p>
      <p>(마찬가지로, 이 책의 본문에서는 “학부모가 주권을 제대로 행사하면 학교장 마음대로 학교 운영이 가능할까?”라는 질문도 던질 것이다.)</p>
      <p>&nbsp;</p>
      <p>학교장과, 학교장이 될 교사들, 그러한 교사들의 집단은, 애써 만들어 놓은 민주주의(학교자치) 시스템이 제대로 작동하게 될 것을 두려워한다.</p>
      <p>“교육과 행정에 무지한 비전문가인 학부모와 학생이 참견하고 간섭하면, 학교 운영이 엉망이 된다. 학교장이 교육철학을 펼치려는 일에 방해가 된다. 결국 학생들에게 피해가 간다.”라고 말한다.</p>
      <p>그들은, 교육(가르침)은 오로지 자신들만 가능한 자신들의 전유물이고, 학교를 운영하는 것도 오로지 교사의 권한이라고 주장한다.</p>
      <p className={styles.bold}>공교육의 패러다임이 수요자 중심으로 바뀐 지 30년째인데, 여전히 공급자 중심의 사고방식에서 벗어나지 못하고 있다.</p>
      <div className={styles.annotation_wrap}>
        <p>일종의 선민의식이고 엘리트주의이다.</p>
        <p>이와 관련한 이야기도 본문에 등장한다.</p>
      </div>
      <p>&nbsp;</p>
      <p>정말로 교육은 교사만이 이끌고 결정할 수 있는 전문영역의 일일까?</p>
      <p>학교가 학교장의 교육철학에만 의해 운영되는 것은 정당한 것일까?</p>
      <p>우리 학교 교장의 교육철학은 옳고 선한 것일까?</p>
      <p>학교장의 교육철학을 위해서 민주주의를 포기하는 것은, 공교육의 입장에서 옳은 것일까?</p>
      <p>&nbsp;</p>
      <p>위와 같은 질문에 대해서 찬반 논쟁이 격렬할 것이고, 근거가 되는 사례들은 양쪽으로 차고 넘칠 것이다.</p>
      <p>&nbsp;</p>
      <p>나는 이 책의 본문을 통해, 위의 질문에 대한 답을 할 것이다.</p>
      <p>하지만 그전에, 다음의 세 가지는 분명히 짚고 넘어가자.</p>
      <p className={styles.bold}>“선의에 기대지마라. 선의는 없는 것과 같다.”</p>
      <p className={styles.bold}>“누군가 못한다고 해서, 모두가 못하는 것은 아니다.”</p>
      <p className={styles.bold}>“공교육은 반드시! 민주주의를 제대로 가르쳐야 한다.”</p>
      <p>&nbsp;</p>
      <p>이제 “왜 학교 교실의 게시판에 ‘규정을 개정하는 방법’이 게시되지 않는가?”에 대한 대답을 해보자.</p>
      <p>&nbsp;</p>
      <p>그것은,</p>
      <p className={styles.bold}>민주주의를 驅逐하고 독재를 構築하기 위해서이다.</p>
    </div>
  )
}

export default Prologue15