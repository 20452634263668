import React from 'react'
import ss from './MainText.module.css'

const Year2015_18 = () => {
  return (
    <div className={ss.wrap}>
      <h1 className={ss.title}>18. 다른 문제들(3).</h1>
      <p>독도 씨의 다음 이야기를 하기 전에, 위에서 잠시 미루어 두었던, 한비중학교 운영위원회 첫 회의에서 독도 씨가 느꼈던 문제들에 대해 다시 살펴보자.</p>
      <p>지역위원 선출의 문제, 의견 수렴 절차의 문제, 회의 공개 원칙의 문제는 이미 살펴보았고, 학교 홈페이지 운영의 문제는 분량이 길어서 더 뒤로 미루고자 한다.</p>
      <p>남은 것은 상식의 문제, 교원위원의 침묵, 학부모위원의 이기심, 권위주의적 사고방식 등의 문제인데, 이에 대해 이야기해보자.</p>
      <p>&nbsp;</p>
      <p>네 번째, 상식의 문제.</p>
      <p>독도 씨는 상식을 벗어난 몇 가지 사실에 대해 적잖이 놀랐다.</p>
      <p>상식이라는 것은 사회 구성원들이 당연한 것으로 여기는 가치관, 이해력, 판단력, 사리분별 능력을 뜻한다.</p>
      <p>그런 의미에서 보면, 처음 겪는 상황에서도 마땅히 이해하고 판단할 수 있는 것들은 상식의 범주에 속한다고 말할 수 있다.</p>
      <p>만일 이해하지 못할 상황을 마주치게 된다면, 상식을 벗어난 상황이라고 할 수 있는데, 이와 같은 상황은 다음과 같았다.</p>
      <p>&nbsp;</p>
      <p>하나, 바뀐 것이 없는 규정의 심의.</p>
      <p>규정 심의는 규정을 제정하거나(만들거나) 개정(변경하거나 폐지)하는 사항을 심의하는 것이다.</p>
      <p>규정과 관련한 다른 심의, 이를테면 ‘상위 법령이 개정됨에 따라, 현행 규정이 바뀐 법령에 어긋남이 없는지 검토’하거나, ‘상황에 따른 규정의 해석과 적용을 결정’하는 등 특별한 심의가 있을 수 있지만, 일반적인 규정의 심의는 제·개정 심의를 의미한다.</p>
      <p>그런데 이미 시행중인, 아무 것도 바뀐 것이 없는 규정을 안건으로 올리는 것은 무슨 의미일까?</p>
      <p>제정도, 개정도, 다른 특별한 이유도 없이, 안건으로 올린 이유가 단지 <span className={ss.quo}>“매년 그래왔으니까요. 제 전임자도 그렇게 했고요.”</span>라면 이해할 수 있는 일일까?</p>
      <p>&nbsp;</p>
      <p>안건의 제안자를 이해하기도 어렵지만, 심의하는 사람을 이해하기도 어렵다.</p>
      <p>아무리 규정에 익숙하지 않은 학부모라도, 해당 안건을 들여다보면서 안건의 목적(<span className={ss.quo_mini}>‘뭘 심의하는 것일까?’</span>)을 고민해보았다면 이상하다는 생각이 들지 않았을까?</p>
      <p>게다가 이 안건을 제출한 교장이나, 이를 읽어보았을 교감은, 학교 규정을 만들고 관리하는 책임자이다.</p>
      <p>그들은 그간의 오랜 교직 생활에서 각종 규정과는 친숙하게 지냈을 것이며, 운영위원회에서의 심의 경력도 화려했을 사람들이다.</p>
      <p>그런데도 목적 없는 안건의 제안을 당당하게 결재한 것을 보면, 운영위원회 심의를 가볍게 보고 있었음이 아닐까?</p>
      <p>어느 것 하나 이해하기 어려운 일이다.</p>
      <p>&nbsp;</p>
      <p>둘, 부칙의 기재 방식.</p>
      <p>부칙은 규정이 제·개정될 때마다 본칙 혹은 기존 부칙의 밑에 덧붙여, 시행일이나 경과조치, 특례 등 부수적인 내용을 기록하는 부분이다.</p>
      <p>학교의 규정도, (상위 법령의 개정이나 학교 환경의 변화 등에 따라) 개정되는 일이 종종 발생한다.</p>
      <p>그럴 때마다 개정된 항목에는 개정 일자를 기록하고, 부칙에는 시행일을 기록하는 것이 올바른 개정 방식인데, 한비중학교 「학교급식소위원회 규정」의 경우 개정(일부 개정)을 하면서 이전의 부칙을 다 없애고 가장 마지막 부칙만 남겨 놓았기 때문에, 언제 제정되었는지 몇 차례 개정되었는지 알 수 있는 방법이 없었다.</p>
      <p>&nbsp;</p>
      <p>그렇다고 모든 규정이 다 그런 것은 아니었다.</p>
      <p>개정 일자며 부칙이 충실하게 기재되어 있는 규정도 있었다.</p>
      <p>독도 씨가 이해하기 어려웠던 것은 이 부분이었다.</p>
      <p>&nbsp;</p>
      <p>아예 모든 규정의 부칙 기재가 일관되게 엉터리였다면, 규정을 담당하는 교직원도 전문가가 아니고, 이를 심의하는 운영위원도 전문가가 아니라는 점에서, 몰라서 그랬을 것이므로, 좀 아쉽기는 해도 이해하지 못할 일은 아니었다.</p>
      <p>&nbsp;</p>
      <p>하지만 일부 규정이 제대로 작성되어 있었기 때문에, 누군가 제대로 작성된 규정과 그렇지 않은 규정을 모두 꼼꼼하게 읽어 보았다면, 「학교급식소위원회 규정」은 부칙의 기재가 엉터리라는 것을 쉽게 알아차렸을 것이다.</p>
      <p>그런 사람이 누가 있을까?</p>
      <p>&nbsp;</p>
      <p>모든 규정을 총괄 관리(결재)하는 사람은, 모든 규정을 꼼꼼하게 읽어 보아야 한다.</p>
      <p>학교의 운영 관리자라고 해서 모든 규정을 다 암기할 수는 없을 것이다.</p>
      <p>하지만 자신이 결재하는 규정은 최소한 정독하고 이해는 해야 하지 않을까?</p>
      <p>학교장은 제대로 작성된 규정을 자주 읽어보았을 것이므로, 부칙의 기재 방식에 대해 충분히 알았을 것이라 생각(상식적인 기대)해 볼 수 있다.</p>
      <p>그러므로 엉터리로 부칙을 기재한 규정이 존재한다는 것은, 이를 제대로 검수하지 않고 결재했다는 것을 의미한다.</p>
      <p>독도 씨는 이러한 학교장의 관리 소홀을 이해하기가 어려웠다.</p>
      <div className={ss.annotation_wrap}>
        <p>부칙은 본칙과 마찬가지로 규정의 일부다.</p>
        <p>만일 부칙의 기재방식을 전혀 모른다면, 학교 규정의 최상위 관리자로서는 문제가 있다고 볼 수 있다.</p>
      </div>
      <p>&nbsp;</p>
      <p>셋, 소위원회 위임 주체의 문제.</p>
      <p>운영위원회는 결산안 심의를 하기 전에, 예·결산소위원회에 심사를 위임할 수 있다.</p>
      <div className={ss.annotation_wrap}>
        <p>이렇게 심사를 위임하면 충분한 시간을 확보할 수 있기 때문에 꼼꼼하게 결산서를 들여다 볼 수 있다.</p>
        <p>특히 앞서 독도 씨가 임춘옥 교장에게 요청했던 세부 지출 내역과 같은 자료를 요구해서 확인하려면 시간이 많이 필요하기 때문에 심사위임은 필수라 할 수 있다.</p>
        <p>또한 예·결산에 익숙하지 않는 학부모라면 전문가 혹은 경력자를 위촉함으로써 잘 모르는 부분을 학습하고 이해할 수 있는 기회를 얻을 수도 있다.</p>
        <p>다만, 결산안 심의에 별로 관심이 없는 운영위원들이나, 심의가 날치기로 끝나기를 바라는 학교장은 심사위임을 선호하지 않는다.</p>
      </div>
      <p>&nbsp;</p>
      <p>심사위임은 운영위원들이 결정한다.</p>
      <p>그런데 2014년도 결산안을 ‘심사위임 할지 말지 결정하는 주체’는 누구일까?</p>
      <p>이번에 선출된 운영위원일까, 아니면 작년에 선출된 운영위원일까?</p>
      <p>이번 회의에서 논란이 된 부분이 바로 이 부분이다.</p>
      <p>&nbsp;</p>
      <p>우선, 운영위원회와 소위원회와의 관계를 통해 살펴보자.</p>
      <p>운영위원회는 소위원회에 어떤 업무를 위임하기도 하고, 위임한 업무를 취소할 수도 있다.</p>
      <p>업무의 위임여부는 회의에서 운영위원들이 의결하여 결정하는 것이고, 소위원회는 이에 따른다.</p>
      <div className={ss.annotation_wrap}>
        <p>특별히 소위원회 규정이 있고, 그 규정에서 지정한 업무를 수행하기도 한다.</p>
        <p>해당 소위원회 규정은 운영위원회에서 만든 것이므로, 이 경우는 매년 반복되는 업무를 자동으로 위임한 것이라 할 수 있다.</p>
      </div>
      <p>즉, 소위원회는 운영위원회에서 일을 시키기 위해 ‘현재’(지금) 운영위원들이 만드는 운영위원회의 하부조직이다.</p>
      <p>그래서 운영위원회의 임기가 끝나면 소위원회의 임기도 끝나게 된다.</p>
      <p>&nbsp;</p>
      <p>예·결산소위원회 역시 ‘현재’ 구성된 운영위원회가 그 조직을 구성하고, 예산안과 결산안을 심의하는데 필요한 일을 시키는 소위원회이다.</p>
      <p>그렇다면 작년도에 구성되어 지금은 임기가 끝난 운영위원회가, 금년도 운영위원회의 하부조직인 예·결산소위원회에 심사위임을 할지 말지 ‘미리’ 결정할 수 있겠는가?</p>
      <p>안 된다.</p>
      <p>그렇게 결정 했다면 월권이다.</p>
      <p>심사위임은 현재 운영위원회가 결정하는 일이다.</p>
      <p>&nbsp;</p>
      <p>이번에는, 예·결산안 심의는 어느 연도 운영위원회의 일인가 살펴보자.</p>
      <p>2014년도 예산안 심의는 2014년도 운영위원회의 심의 업무인가?</p>
      <p>아니다. 2013년도 운영위원회에서 심의한다.</p>
      <p>2014년도 결산안 심의는 2014년도 운영위원회의 심의 업무인가?</p>
      <p>아니다. 2015년도 운영위원회에서 심의한다.</p>
      <p>&nbsp;</p>
      <p>그렇다.</p>
      <p>곰곰이 생각해보면 그냥 단순하게 알 수 있는 일이다.</p>
      <p>예산은 내년도의 일이고, 결산은 작년도의 일이다.</p>
      <p>금년도 운영위원회는 금년도의 예·결산안을 심의하지 않는다.</p>
      <p>2014년도 운영위원회는 2013년도 결산안과 2015년도 예산안을 심의한다.</p>
      <p>2014년도 결산안과는 관계가 없다.</p>
      <p>&nbsp;</p>
      <p>2014년도 결산안 심의는 2015년도 운영위원회의 책임이므로, 심사위임 여부도 2015년도 운영위원회에서 결정하는 것이 옳다.</p>
      <p>독도 씨는 이를 상식의 문제라고 보았다.</p>
      <div className={ss.annotation_wrap}>
        <p>독도 씨가 초보 운영위원으로서 좌충우돌한지로부터 10년째인 2024년에도, 한비중학교에서는 여전히 학년 초에 심의하는 작년도 결산안의 심사 업무 위임 여부를 작년도 운영위원회의 결정에 따르고 있다.</p>
        <p>착각할 수는 있다. 그러나 이정도 설명하면 이해가 되어야 하는 것이 상식 아닌가?</p>
        <p>왜 아니라고 설명해도, <span className={ss.fbody}>“작년도 결산안이니까 작년의 결정에 따라야지요.”</span>라는 말에만 고개를 끄덕이는 것일까?</p>
      </div>
      <p>&nbsp;</p>
      <div className={ss.annotation_wrap}>
        <p>한편, 독도 씨는 이런 현상이 의도된 것이라는 의심도 한다.</p>
        <p>새로운 운영위원은, 전년도에 결산안을 위임하지 않기로 하였다고 말해주면, <span className={ss.fbody}>‘그런가보다’</span>하고 심의하고, 곧이어 올해 예산안과 결산안의 위임 여부를 묻게 되면 <span className={ss.fbody}>‘하던 대로 해야겠지’</span>라고 생각하고 위임하지 않는다.</p>
        <p>별로 고민하지 않는 사람들은 보통 관행에 따르려고 한다.</p>
        <p>독도 씨는, 사람들의 관성을 이용하여 예·결산 심의를 무력화하고자 하는 의도가 숨어 있을 것이라고 판단한다.</p>
      </div>
      <p>&nbsp;</p>
      <p>여기까지는, 첫 회의 이후로 독도 씨가 느꼈던 마음이 불편한 이유들 중 눈에 보이는 근거가 있는 문제들을 생각하고 정리한 것이다.</p>
      <p>그런데 위법한 지역위원 선출, 의견 수렴을 하지 않는 것, 회의를 공개하지 않는 것, 상식을 벗어난 회의 수준 등의 문제들은 비단 한비중학교만의 문제가 아니다.</p>
      <p>학교마다 조금의 차이는 있지만, 독도 씨가 직·간접으로 경험한 학교들은 대개 비슷했다.</p>
      <p>독도 씨는 그해 후반기에 교육시민단체에 가입하여 수년간 활동을 하였는데, 그때 만난 여러 사람들로부터 알게 된 사실은, 다른 학교에서도 회의 수준이나 관리자(교장, 교감)들의 발언 내용 등이 소름끼치도록 똑같다는 것이었다.</p>
      <p>이 또한 매우 재밌는 포인트인데, 이에 대해서는 뒤에 이야기할 기회가 있을 것이다.</p>
      <p>&nbsp;</p>
      <p>그리고 이제는 눈에 보이지 않는 것들에 대해 이야기할 차례다.</p>
      <p>이 문제들은, 독도 씨의 느낌을 위주로 그의 주관으로 판단한 문제이다.</p>
      <p>그러므로 보는 관점에 따라서는 문제가 아닐 수도 있다.</p>
    </div>
  )
}

export default Year2015_18