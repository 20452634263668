import React from 'react'
import styles from './Home.module.css'
import { useNavigate } from 'react-router-dom'
import interviewer1 from '../../img/home_interviewer1.png'
import interviewer2 from '../../img/home_interviewer2.png'
import interviewer3 from '../../img/home_interviewer3.png'

const Home = () => {

    const navigate = useNavigate()

    return (
        <div className='center-col' style={{ width: '100%', maxWidth: '1280px', height: 'auto', minHeight: '1000px', margin: '0 auto', justifyContent: 'flex-start' }}>
            <div className={styles.state1_wrap}>
                <div>
                    <p style={{ fontFamily: 'gg_head', marginBottom: '5px' }}>우리가 몰랐던 학교 이야기</p>
                    <h1 style={{ wordBreak: 'keep-all', fontFamily: 'gg_head' }}>학교는, 민주주의를 구축하고 독재를 구축한다.</h1>
                </div>
                <p className={styles.sub_content} style={{ marginTop: '70px' }} onClick={() => navigate('/school3.0')}>학교 3.0 바로가기</p>
                {/* <p className={styles.sub_content} onClick={() => navigate('/dokdo_column')}>독도 칼럼</p>
                <p className={styles.sub_content} onClick={() => navigate('/community')}>커뮤니티</p> */}
                {/* <h3 className={styles.quick_go_text} onClick={() => navigate('/school3.0')}>학교 3.0 바로가기 →</h3> */}
            </div>

            <div className={styles.state2_wrap}>
                <h3>[겁나게 아름다운 독자 인터뷰]</h3>
                <div className={styles.interview_wrap}>
                    <div>
                        <p className={styles.interview_text1}>“대한민국의 학교는 공정하고 합리적이며, 학생들의 미래를 위해 불철주야 노력하는 선생님들과, 선생님을 믿고 지원하는 든든한 학부모들과, 그런 어른들의 기대에 어긋나지 않도록 열심히 공부하는 밝고 건강한 학생들이 함께 만들어가는 민주적 자치 공동체입니다.”</p>
                        <p className={styles.interview_text2}>feat. 30년 외길 교육에만 헌신해 온, 이름을 밝히지 않은, 학교장들의 모임.</p>
                    </div>
                    <img className={styles.interviewer_img} src={interviewer1} />
                </div>
                <div className={styles.interview_wrap}>
                    <img className={`${styles.interviewer_img_reverse} ${styles.only_pc}`} src={interviewer2} />
                    <div>
                        <p className={styles.interview_text1}>“우리는 대한민국의 공교육을 믿습니다. 그것으로 충분합니다.”</p>
                        <p className={styles.interview_text2}>feat. 진보적이고 양심적인, 민주주의를 사랑하는, 교육적이고 혁신적인, 학부모 단체 일동.</p>
                    </div>
                    <img className={`${styles.interviewer_img_reverse} ${styles.only_mobile}`} src={interviewer2} />
                </div>
                <div className={styles.interview_wrap}>
                    <div>
                        <p className={styles.interview_text1}>“우리학교는 학생자치가 끝내주게 잘되는 학교랍니다. 학생들이 주도적으로 대토론회에 참여 하고요. 축제도 저희가 전부 기획하고 준비해요. 얼마 전에는 학생들이 교복 디자인에도 참여 했답니다. 저희 지역에서 학교민주주의 지수 1등인 것 자랑해도 되죠? 아, 근데 우리학교 학생회칙을 읽어본 적이 있냐고요? 그게 뭐죠?”</p>
                        <p className={styles.interview_text2}>feat. 학교자치와 학생자치 활성화를 위한 토론회 학생패널, 김빛나(가명, ○○고등학교 2학년)</p>
                    </div>
                    <img className={styles.interviewer_img} src={interviewer3} />
                </div>
            </div>




            {/* <img className={styles.home_img} src={`${process.env.PUBLIC_URL}/logo.png`} />

            <h3 className={styles.quick_go_text} onClick={() => navigate('/dokdo_column')}>독도 칼럼 바로가기 →</h3>
            <h3 className={styles.quick_go_text} style={{ marginBottom: '100px' }} onClick={() => navigate('/community')}>커뮤니티 바로가기 →</h3> */}

        </div>
    )
}

export default Home