import React from 'react'
import styles from './MainText.module.css'

const Prologue03 = () => {
  return (
    <div className={styles.wrap}>
      <h1 className={styles.title}>3. 우리가 학교에서 처음 배우는 것.</h1>
      <p>특별한 소수의 경우를 제외하고, 거의 모든 국민은 학교를 다녔고, 다니고 있고, 다니게 될 것이다.</p>
      <p>그러니까 이 글을 읽는 여러분 중 대부분은 학교에 대해 잘 알 테니, 내가 하는 이야기를 쉽게 이해할 수 있을 것이다.</p>
      <p>&nbsp;</p>
      <p>한 가지 알아두어야 할 점이 있는데, 본문에서의 학교 이야기는 1995년(5.31 교육개혁) 이후부터 지금까지의 학교에 대한 이야기이고, 서문에서의 학교 이야기는 2011년 이후의 학교에 대한 이야기이다.</p>
      <div className={styles.annotation_wrap}>
        <p>서문에서는 학교의 규정개정심의위원회 작동 절차를 다루고 있기 때문에, 「경기도 학생인권 조례」가 공포(2010년 10월 5일)되고 6개월이 지나, 규정개정심의위원회가 구성된 이후의 학교를 기준으로 이야기하고 있다.</p>
      </div>
      <p>그렇다고 해도(범위가 다소 좁혀졌다고 해도), 이야기의 본질은 2011년 이전이나 이후나 다를 바가 없다.</p>
      <div className={styles.annotation_wrap}>
        <p>학교가 가지고 있는 문제는 학교자치가 시작된 1995년 이후로 늘 같았다.</p>
      </div>
      <p>그러므로 여러분이(혹은 여러분의 자녀가) 2011년 이전에 학교를 다녔다고 해도, 서문에서 다루는 학교 이야기에 충분히 공감할 수 있을 것이다.</p>
      <p>&nbsp;</p>
      <p>그럼 이제, 이야기를 시작해보자.</p>
      <p>&nbsp;</p>
      <p>초·중·고등학교에 1학년으로 처음 입학했을 때, 또는 학년이 바뀌어서 매 학년 초 처음 교실에 들어갔을 때, 당신은(혹은 당신의 자녀는) 선생님으로부터 가장 먼저 무엇을 배웠는가?</p>
      <p>담임선생님의 이름을 알게 되고, 수업 시간표를 받게 되고, 번호도 정했을 것이고 그리고 나서 선생님은 무엇을 알려주었는가?</p>
      <p>&nbsp;</p>
      <p>순서의 차이는 있겠지만, 아마도 선생님은 당신에게 ‘학교에서 해야 할 일과 해서는 안 되는 일’을 가르쳤을 것이다.</p>
      <p>이른바 학교의 규정이다.</p>
      <p>특히 기억에 남았을 것은 학교생활규정으로, 두발·염색·화장에 관한 규정, 복장(교복) 규정, 장신구 착용 규정 등과 같은 개인적인 것부터 시작하여, 단체 생활에 필요한 여러 가지 질서 유지와 관련된 규정(예컨대 휴대전화 사용 규정 등)을 배웠을 것이다.</p>
      <p>또한 복도와 계단에서의 통행 시 주의사항(달리지 말아요!), 교우 관계 주의사항(친구를 괴롭히지 말아요!), 학업성적관리규정에 해당하는 지각·조퇴·결석 등에 대한 처리 기준과 행정 절차(지각·조퇴·결석 결석했을 때 제출해야할 서류 등) 등도 배웠을 것이다.</p>
      <div className={styles.annotation_wrap}>
        <p>1학년 때는 처음이니까 가르쳐 준 것이고, 그 이후에는 잊지 말라고 다시 한 번 상기시키는 의미로 가르쳐주었을 것이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>이 모든 것들은, 교과 공부에 우선하는 기본적인 질서 관련 규정들이고, 학교(사회)의 유지에 관련한 것들이다.</p>
      <p>모든 학교에서 모든 학생들이 입학과 동시에 이런 규정들을 배우게 된다.</p>
      <p>그리고 지켜야 한다.</p>
      <div className={styles.annotation_wrap}>
        <p>공교육의 목적인 ‘사회(학교)의 유지’에 해당되는 가장 중요한 교육과정이 입학과 동시에 시작된다.</p>
      </div>
      <p>&nbsp;</p>
      <p>당신은 아마도 학교생활 내내, 시작하자마자 배워야했던 해당 규정을 지키기 위해 무척 노력했을 것이다.</p>
      <p>물론 그 중 몇 가지는 지키기 싫어서, 규정을 어기되 걸리지 않는 방법을 찾기 위한 노력을 하기도 했을 것이다.</p>
      <p>규정을 지키기 어려운 학교일수록 학교생활은 힘들었을 것이고, 규정이 느슨하거나 지키지 않아도 웬만하면 내버려두는 학교라면 학교생활이 천국이었을 것이다.</p>
      <p>우리 모두를 돌아보자면, 규정을 모두 지켰던 모범생보다는 몇 개 정도는 지키지 않아서 혼났던(꾸지람을 들었던) 사람의 수가 훨씬 더 많았을 것이다.</p>
      <p>&nbsp;</p>
      <p className={styles.bold}>당신에게 있어서 규정은 편리함이었을까? 불편함이었을까?</p>
    </div>
  )
}

export default Prologue03