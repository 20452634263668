import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import AppLayout from './layout/AppLayout/AppLayout';
import Home from './page/Home/Home';
import Login from './page/Login/Login';
import School3 from './page/School3/School3';
import Dokdo from './page/Dokdo/Dokdo'
import Community from './page/Community/Community'
import School3_read from './page/School3_read/School3_read';
import School3_detail from './page/School3_detail/School3_detail';
import NotFound from './page/NotFound/NotFound';
import { useEffect, useRef } from 'react';
import AdminPage from './page/AdminPage/AdminPage';
import WritePost from './page/WritePost/WritePost';
import ReadPost from './page/ReadPost/ReadPost';

function App() {

  const location = useLocation()


  useEffect(() => {
    if (location.pathname.startsWith('/school3.0') || location.pathname.startsWith('/read')) {
      return
    } else {
      sessionStorage.removeItem('prologue')
      sessionStorage.removeItem('2015')
    }
  }, [location.pathname])

  return (
    <div>
      <Routes>
        <Route path='/' element={<AppLayout />}>
          <Route index element={<Home />} />
          <Route path='login' element={<Login />} />
          <Route path='signup' element={<Login />} />
          <Route path='school3.0' element={<School3 />} />
          <Route path='school3.0/:year' element={<School3_detail />} />
          <Route path='dokdo_column' element={<Community />} />
          <Route path='community' element={<Community />} />
          <Route path='read/:id' element={<School3_read />} />
          <Route path='admin-page' element={<AdminPage />} />
          <Route path='write' element={<WritePost />} />
          <Route path='read_post' element={<ReadPost />} />

          <Route path='not-found' element={<NotFound />} />
        </Route>

        <Route path='*' element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;