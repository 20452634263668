import React from 'react'
import ss from './MainText.module.css'

const Year2015_13 = () => {
  return (
    <div className={ss.wrap}>
      <h1 className={ss.title}>13. 교육청에 문의하다.</h1>
      <p>독도 씨가 첫 회의 다음날 짚어 낸 한비중학교 운영위원회 관련 문제들은, 지역위원 선출의 문제, 의견 수렴의 문제, 회의 공개의 문제 외에도 몇 가지가 더 있다.</p>
      <p>그것은 학교 홈페이지 운영의 문제, 상식의 문제, 교원위원의 침묵, 학부모위원의 이기심, 권위주의적 사고방식 등이다.</p>
      <p>그런데 이를 순서대로 다 이야기 하려니 좀 지루할 것 같다는 생각이 든다.</p>
      <p>&nbsp;</p>
      <p>그래서 나머지 다섯 가지 문제들은 순서를 조금 뒤로 미루고, 위에서 미루어 두었던 독도 씨가 교육청에 문의한 내용과 그 답변, 그리고 이어서 독도 씨가 임춘옥 교장과 면담을 하는 이야기를 먼저 하도록 하겠다.</p>
      <p>&nbsp;</p>
      <p>다음은 독도 씨가 국민신문고를 통해 질의한 내용이다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo_mini}>안녕하세요?</p>
      <p className={ss.quo_mini}>진부도의 모 학교 학부모 운영위원입니다.</p>
      <p className={ss.quo_mini}>제가 이 학교의 운영위원으로 당선된 후, 4월 첫 운영위원회의에서 지역위원 2명이 선출되어 있는 것을 보았습니다.</p>
      <p className={ss.quo_mini}>「초·중등교육법 시행령」에는, 지역위원은 학부모위원과 교원위원이 추천하고, 무기명투표로 선출하도록 되어 있습니다.</p>
      <p className={ss.quo_mini}>이런 절차를 위해서는 당연히 당선된 운영위원의 회의가 소집되어야 하겠죠.</p>
      <p className={ss.quo_mini}>그런데 이런 과정이 없었습니다.</p>
      <p className={ss.quo_mini}>학교장이 말하기를, 혼자 추천 했다고 합니다.</p>
      <p className={ss.quo_mini}>그래서 저는 추천의 기회를 받지 못했습니다.</p>
      <p className={ss.quo_mini}>물론 다른 운영위원들에게도 이와 관련한 공지가 없었습니다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo_mini}>질문입니다.</p>
      <p className={ss.quo_mini}>1. 이렇게 선출하였다면, 법령에 위배되는 것이 아닌가요?</p>
      <p className={ss.quo_mini}>2. 만일 법령을 위반한 것이라면 어떤 조치를 취할 수 있을까요?</p>
      <p className={ss.quo_mini}>답변 부탁드립니다.</p>
      <p>&nbsp;</p>
      <p>독도 씨는 위와 같은 문의를 진부도교육청 앞으로 접수했다.</p>
      <p>그리고 5일 뒤, 다음과 같은 답변을 받을 수 있었다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo_mini}>안녕하십니까?</p>
      <p className={ss.quo_mini}>진부도교육청 교육공동체기획단 학부모지원담당 주무관 김대강입니다.</p>
      <p className={ss.quo_mini}>항상 진부교육에 보내주시는 애정과 관심에 깊은 감사를 드립니다.</p>
      <p className={ss.quo_mini}>귀하의 민원 내용대로 지역위원 추천 시, 사전에 전체 학부모위원 및 교원위원에게 안내 없이 학교장이 단독으로 추천하여 지역위원을 선출하였다면, 지역위원 선출방식에 문제가 있었던 것으로 보여 집니다.</p>
      <p className={ss.quo_mini}>다만 귀하께서 질의하신 조치에 대해서는, 정확한 조사 결과 후에 판단해야 될 사안이라 생각됩니다.</p>
      <p className={ss.quo_mini}>귀하께서 제기하신 민원은 대상 학교가 명시되어 있지 않음을 참고로 말씀드리며, 향후 정확한 조사를 원하시면 지역교육지원청으로 의견을 주시기 바랍니다.</p>
      <p className={ss.quo_mini}>감사합니다.</p>
      <p>&nbsp;</p>
      <p>잘잘못을 잘 따지는 사람이지만, 독도 씨는 마음이 무척 여린 사람이다.</p>
      <p>궁금해서 알고는 싶었지만, 누군가를 곤경에 빠뜨리고 싶지는 않았기에, 학교명을 밝히지 않고 민원을 넣었던 것이다.</p>
      <div className={ss.annotation_wrap}>
        <p>하지만 지금의 독도 씨라면 처음부터 학교를 밝히고 바로 조사 요청을 했을 것이다.</p>
        <p>그것은 바로 잡아야할 문제이기 때문이다.</p>
      </div>
      <p>그랬기 때문에, 진부도교육청 담당자는 <span className={ss.quo_mini}>‘학교가 명시되어 있지 않아서 조사는 하지 않겠다. 조사를 원하면 지역교육지원청으로 연락하라.’</span>고 답변을 마무리하였다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo_mini}>‘조사를 통해서 증거가 나와야 확정할 수 있지만, 문의한 내용이 사실이라면 문제가 있다고 판단한다는 것이네. 음……, 학교명을 밝혔으면 알아서 조사했을까? 근데 위반임이 드러나면 어떻게 된다는 것을 알려줘야 조사를 요청하든지 말든지 하지.’</p>
      <p>독도 씨는 계속해서 고민했다.</p>
      <p className={ss.quo_mini}>‘경고만 하려나? 다시 뽑으라고 하려나? 알 수가 없네. 지금 같은 상황에서는 다시 뽑는다고 뭐가 달라질 것 같지도 않은데, 어찌해야 하나?’</p>
      <p>&nbsp;</p>
      <p>위에서 살펴본 것과 같이 지역위원 선출은 매우 중요한 일이다.</p>
      <p>하지만 당시의 독도 씨는 그런 사실을 잘 몰랐다.</p>
      <p>따라서 지역교육지원청(부종시교육지원청)에 조사를 의뢰하는 것이 실익이 없는 일이라 판단했고, 일단 법령 위반이 확실하다고 확인하는 선에서 만족하려고 했다.</p>
      <p>&nbsp;</p>
      <p>그런데 교육청 답변을 확인한 다음날, 독도 씨는 누군가의 전화를 받게 되었다.</p>
    </div>
  )
}

export default Year2015_13