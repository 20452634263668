import React from 'react'
import styles from './MainText.module.css'

const Prologue08 = () => {
  return (
    <div className={styles.wrap}>
      <h1 className={styles.title}>8. 그래도 학생이 주인이다?</h1>
      <p>어떤 이는 이렇게 말할 것이다.</p>
      <p>“학생이 학교의 주인이지만, 학생에게 주권을 줄 수는 없다. 사회적 판단을 하기에는 경험 부족 등으로 미숙한 미성년자이기 때문이다.”</p>
      <p>모든 국민이 국가의 주권자지만 주권의 행사(선거권)에 나이 제한(18세)이 따르듯이, 학생이 학교의 주인이지만 아직 미숙하므로 학교에서의 주권은 보호자인 학부모가 대리한다는 의미이다.</p>
      <p>&nbsp;</p>
      <p>게다가 비록 주권이 제한되지만 학생을 주인으로 대우하는듯한 조항도 존재한다.</p>
      <p>「초·중등교육법 시행령」(제59조의4 제2항과 제3항) 및 일부 시‧도교육청의 ‘학생인권조례’ 또는 관련 매뉴얼(‘학생생활규정 표준안’ 등) 등에서, 학생이 학교 규정의 제정·개정에 참여할 권리를 보장하거나 학교운영위원회 회의에 참석하여 학교 운영에 관한 의견을 제시할 수 있도록 권장하고 있다.</p>
      <p>&nbsp;</p>
      <p>이처럼 법령 등에서 제한적이나마 학생의 규정 개정 권리를 일부 보장(또는 권장)하고 있으므로, 미성년자이기 때문에 완전한 주권을 주지 않는 것일 뿐, 학생이 학교의 주인이라는 사실은 틀림이 없다고 주장한다.</p>
      <p>&nbsp;</p>
      <p>하지만 주인과 주인공은 다르다.</p>
      <p>현행 제도 하에서 학생은 주권을 가질 수 없으므로 학교의 주인이 아니다.</p>
      <p>위의 법령 등의 사례에서 명시된, 개정에 참여할 권리는 개정안을 발의할 수 있는 권리일 뿐이고 최종 결정할 수 있는 권리가 아니며, 권장되는 참석의 권한도 학교장이나 학교운영위원회에 의해 선의로 주어져야만 작동되는 불완전한 권리이다.</p>
      <p>&nbsp;</p>
      <p className={styles.bold}>선의에 기댄다는 것은 사실상 없는 것과 마찬가지다.</p>
      <p>&nbsp;</p>
      <p>미성년자라서 주권을 주지 않겠다는 논리는 찬반여부를 떠나 성립은 될 수 있는 논리이다.</p>
      <p>하지만 있지도 않은 권리를 예로 들어 주인이라고 하는 말은 어불성설이다.</p>
      <p>그냥, 학생은 학교의 주인공이다.</p>
      <p>주인이 시킨 대로 움직여야하는 꼭두각시 인형극의 주인공 인형이다.</p>
      <p>&nbsp;</p>
      <p>그렇지만 뭐, 그렇다고 치자.</p>
      <p>이제 우리는, 공교육의 더 큰 문제를 들여다보기 위해, 학생이 주인이냐 아니냐는 논란은 잠시 접어두고, 그나마 학생을 학교의 주인에 준하여 대우하기 위해 공교육(학교)이 제안한 학교생활규정에서의 학생의 권리에 대해 살펴보자.</p>
      <p>앞에서 내가 이상하다고 말한, 그것이다.</p>
    </div>
  )
}

export default Prologue08