import React from 'react'
import styles from './MainText.module.css'

const Prologue01 = () => {
    return (
        <div className={styles.wrap}>
            <h1 className={styles.title}>1. 학교 그리고 명제.</h1>
            <h1 className={styles.emphasis}>학교는, 민주주의를 구축하고 독재를 구축한다.</h1>
            <p>구축이란 단어는 일상생활에서 자주 쓰이는 단어는 아니지만, 아주 낯선 단어도 아니다.</p>
            <p>구축이란 단어는 동음이의어로 여러 의미가 있는데, 특히 어떤 세력을 몰아낸다는 의미의 구축(驅逐)과 어떤 체계(system)를 쌓아 올린다는 의미의 구축(構築)은 서로 완전히 반대 되는 뜻을 가진다.</p>
            <p>“악화가 양화를 구축한다.”는 말에서의 구축(驅逐)은 쫒아낸다는 의미를 가진 것이고, 반면에 “정부가 온라인 행정망을 구축하여 행정서비스의 속도와 질이 좋아졌다.”는 표현을 할 때의 구축(構築)은 시스템을 만들었다는 의미를 가진다.</p>
            <p>전자는 파괴, 후자는 창조라는 상반된 의미를 가진 동음이의어라니 재밌지 않은가?</p>
            <p>&nbsp;</p>
            <p>그렇다면 위의 첫 문장(명제)에서 첫 번째 구축과 두 번째 구축의 의미는 각각 어떤 것일까?</p>
            <p>&nbsp;</p>
            <p>나는 현장에서 10년째 좌절을 겪고 있고, 지난 3년간은 책을 쓰기 위해 노력했다.</p>
            <p>그 결과로 축약된 단 한 문장이 바로 “학교는, 민주주의를 구축하고 독재를 구축한다.”이다.</p>
            <p>&nbsp;</p>
            <p>이 책은 이 명제를 증명하는 경험담으로 가득 차 있다.</p>
            <p>답을 말하기도 전에 눈치 챈 사람들이 많을 텐데, 각각의 구축의 의미는 서문을 읽거나 본문을 조금만 읽어도 누구나 쉽게 알 수 있을 것이다.</p>
            <p>&nbsp;</p>
            <p>오늘날 우리 사회의 학교는, 우리 사회가 안고 있는 다양하고 심각한 많은 문제들의 근본 원인을 제공하고 있다.</p>
            <p>나는 그 근본 원인이 바로 ‘민주주의의 구축이고 독재의 구축’이라고 주장한다.</p>
            <p>당연한 말이겠지만, 이 근본적인 문제를 해결해야지만 이로부터 파생된 많은 문제가 해결될 것이다.</p>
            <p>만일 이 근본적인 문제가 해결되지 않는다면, 파생되어 해결되지 않은 문제들이 쌓이고 쌓여서 결국에는 우리 사회가 파국을 맞이할 것이라 생각한다.</p>
            <p>&nbsp;</p>
            <p className={styles.bold}>더 늦기 전에, 문제를 직시하자는 목소리를 내어 본다.</p>

        </div>
    )
}

export default Prologue01