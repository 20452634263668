import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styles from './Login.module.css'
import is31Day from '../../util/is31Day'
import api from '../../util/api'
import isValidEmail from '../../util/isValidEmail'
import dateProblem from '../../util/dateProblem'
import validateNickname from '../../util/validateNickname'

const Login = () => {

   const location = useLocation()
   const navigate = useNavigate()

   const [isLoginPage, setIsLoginPage] = useState(true)

   const [email, setEmail] = useState('')
   const [password, setPassword] = useState('')
   const [password2, setPassword2] = useState('')
   const [nickname, setNickname] = useState('')
   const [birth_year, setBirth_Year] = useState(undefined)
   const [birth_month, setBirth_Month] = useState(undefined)
   const [birth_day, setBirth_Day] = useState(undefined)
   const [gender, setGender] = useState('')
   const [emailCode, setEmailCode] = useState('')

   const [beforeEmailSend, setBeforeEmailSend] = useState(true)
   const [emailVerified, setEmailVerified] = useState(false)


   useEffect(() => {
      if (location.pathname === '/signup') {
         setIsLoginPage(false)
         resetSignupForm()
      } else if (location.pathname === '/login') {
         setIsLoginPage(true)
      }
   }, [location.pathname])

   useEffect(() => {
      if ((birth_year && birth_month && birth_day) && (dateProblem(birth_year, birth_month, birth_day) === true)) {
         setBirth_Day(undefined)
      }
   }, [birth_year, birth_month, birth_day])

   const handleSubmit = async () => {
      try {
         if (location.pathname === '/login') {
            if (!email || !password) {
               alert('아이디와 비밀번호를 입력해 주세요.')
               return
            }
            const response = await api.post('/login', {
               email,
               password
            })
            if (response.data.message === 'fail') {
               alert('아이디와 비밀번호를 확인해 주세요.')
               return
            } else if (response.data.message === 'success') {
               localStorage.setItem('token', response.data.token)
               window.location.href = '/'
               return
            }
            return
         }

         if (location.pathname === '/signup') {
            if (!email || !password || !password2 || !nickname || !birth_year || !birth_month || !birth_day || !gender) {
               alert('빈칸 없이 입력해 주세요.')
               return
            }
            if (isValidEmail(email) === false) {
               alert('올바른 이메일 형식이 아닙니다.')
               return
            }
            if (!emailVerified) {
               alert('이메일을 인증을 완료해 주세요.')
               return
            }
            if (password !== password2) {
               alert('비밀번호가 일치하지 않습니다.')
               return
            }
            if (validateNickname(nickname) === false) {
               alert('닉네임은 한글 8글자 이하, 영어 16글자 이하로 입력해 주세요.')
               return
            }
            const birth = { year: birth_year, month: birth_month, day: birth_day }
            const response = await api.post('/signup', {
               email,
               password,
               nickname,
               birth,
               gender,
            })
            if (response.data.message === 'exist-email') {
               alert('이미 사용 중인 이메일입니다.')
               return
            }
            if (response.data.message === 'exist-nickname') {
               alert('이미 사용 중인 닉네임입니다.')
               return
            }
            if (response.data.message === 'success') {
               navigate('/login')
               return
            }
         }
      } catch (err) {

      }
   }

   const handleKeyDown = (e) => {
      const key = e.code;
      switch (key) {
         case 'Enter':
            handleSubmit()
            break;
         case 'NumpadEnter':
            handleSubmit()
            break;
         default:
      }
   }

   const sendEmailCode = async () => {
      if (!email) {
         alert('이메일을 입력해 주세요.')
         return
      }
      if (isValidEmail(email) === false) {
         alert('올바른 이메일 형식이 아닙니다.')
         return
      }
      try {
         const response = await api.post('/send-email-code', { email: email })
         if (response.data === 'ok') {
            alert('인증 번호가 전송되었습니다.')
            setBeforeEmailSend(false)
            return
         } else {
            alert('알 수 없는 에러로 실패하였습니다. 다음에 다시 시도해 주세요.')
            return
         }
      } catch (err) {
         alert('알 수 없는 에러로 실패하였습니다. 다음에 다시 시도해 주세요.')
         return
      }
   }
   const verifyEmail = async () => {
      try {
         const response = await api.post('/verify-email-from-code', { email: email, code: emailCode })
         if (response.data === 'ok') {
            setEmailVerified(true)
         } else {
            alert('인증 번호가 일치하지 않습니다.')
         }
      } catch (err) {

      }
   }

   const resetSignupForm = () => {
      setEmail('')
      setPassword('')
      setPassword2('')
      setNickname('')
      setBirth_Year(undefined)
      setBirth_Month(undefined)
      setBirth_Day(undefined)
      setGender('')
      setEmailCode('')
      setBeforeEmailSend(true)
      setEmailVerified(false)
   }





   return (
      <div className='center-col basic-back' style={{ minHeight: 'calc(100dvh - 67px)', justifyContent: 'flex-start' }}>
         <h1 className={styles.title}>{isLoginPage ? '로그인' : '회원가입'}</h1>
         <div className={styles.form_wrap}>
            <p className={styles.explain_in_input}>이메일</p>
            <input className={`${styles.input} no-focus`} type='text' value={email} onChange={(e) => setEmail(e.target.value)} onKeyDown={handleKeyDown} />
            {!isLoginPage && (
               <>
                  <p className={styles.explain_in_input}>이메일 인증 번호</p>
                  <div className={styles.input_gender_wrap}>
                     <input className={`${styles.input_gender} no-focus`} type='text' style={{ width: '40%', textIndent: '15px', cursor: 'text' }} value={emailCode} onChange={(e) => setEmailCode(e.target.value)} />

                     {beforeEmailSend && (
                        <p className='btn-blue' style={{ height: '20px' }} onClick={() => sendEmailCode()}>인증 번호 전송</p>
                     )}
                     {(!beforeEmailSend && !emailVerified) && (
                        <>
                           <p className='btn-blue' style={{ height: '20px' }} onClick={() => sendEmailCode()}>재전송</p>
                           <p className='btn-blue' style={{ height: '20px', marginLeft: '10px' }} onClick={() => verifyEmail()}>확인</p>
                        </>

                     )}
                     {emailVerified && (
                        <p style={{ color: 'var(--basic-blue)', fontWeight: 'bold' }}>인증 완료</p>
                     )}
                  </div>
               </>
            )}
            <p className={styles.explain_in_input}>비밀번호</p>
            <input className={`${styles.input} no-focus`} type='password' value={password} onChange={(e) => setPassword(e.target.value)} onKeyDown={handleKeyDown} />
            {!isLoginPage && (
               <>
                  <p className={styles.explain_in_input}>비밀번호 확인</p>
                  <input className={`${styles.input} no-focus`} type='password' value={password2} onChange={(e) => setPassword2(e.target.value)} />
                  <p className={styles.explain_in_input}>닉네임<span className={styles.sub_explain}>(한글 8글자 이하, 영어 16글자 이하)</span></p>
                  <input className={`${styles.input} no-focus`} type='text' value={nickname} onChange={(e) => setNickname(e.target.value)} />
                  <p className={styles.explain_in_input}>생년월일</p>
                  <div className={styles.input_gender_wrap}>
                     <select className={`${styles.input_gender} no-focus`} style={{ width: '27%' }} value={birth_year} onChange={(e) => setBirth_Year(parseInt(e.target.value))}>
                        <option value={undefined}>연도</option>
                        {Array.from({ length: 125 }, (_, index) => {
                           const year = 2024 - index;
                           return <option key={year} value={year}>{year}년</option>
                        })}
                     </select>
                     <select className={`${styles.input_gender} no-focus`} value={birth_month} onChange={(e) => setBirth_Month(parseInt(e.target.value))}>
                        <option value={undefined}>월</option>
                        {Array.from({ length: 12 }, (_, index) => {
                           const month = 1 + index;
                           return <option key={month} value={month}>{month}월</option>
                        })}
                     </select>
                     <select className={`${styles.input_gender} no-focus`} value={birth_day} onChange={(e) => setBirth_Day(parseInt(e.target.value))}>
                        <option value={undefined}>일</option>
                        {Array.from({ length: 28 }, (_, index) => {
                           const day = 1 + index;
                           return <option key={day} value={day}>{day}일</option>
                        })}
                        {(birth_month !== 2 || (birth_month === 2 && birth_year % 4 === 0)) && (
                           <option value={29}>29일</option>
                        )}
                        {birth_month != 2 && (
                           <option value={30}>30일</option>
                        )}

                        {is31Day(birth_month) === true && (
                           <option value={31}>31일</option>
                        )}
                     </select>
                  </div>
                  <p className={styles.explain_in_input}>성별</p>
                  <div className={styles.input_gender_wrap}>
                     <p className={`${styles.input_gender} ${gender === 'male' && styles.gender_selected}`} onClick={() => setGender('male')}>남성</p>
                     <p className={`${styles.input_gender} ${gender === 'female' && styles.gender_selected}`} onClick={() => setGender('female')}>여성</p>
                  </div>
               </>
            )}


            <p className='btn-blue' style={{ width: '100%', boxSizing: 'border-box', marginTop: '10px' }} onClick={() => handleSubmit()}>
               {isLoginPage ? '로그인' : '회원가입'}
            </p>
         </div>

         {isLoginPage ? (
            <p className={styles.go_to_signup} onClick={() => navigate('/signup')}>계정이 없으신가요? <span className={styles.go_to_signup_blue}>회원 가입하기 →</span></p>
         ) : (
            <p className={styles.go_to_signup} onClick={() => navigate('/login')}>이미 계정이 있으신가요? <span className={styles.go_to_signup_blue}>로그인 하기 →</span></p>
         )}

      </div>
   )
}

export default Login