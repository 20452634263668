import React from 'react'
import styles from './Spinner.module.css'

const Spinner = () => {
    return (
        <div style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)' }}>
            <div className={styles.spinner} />
        </div>
    )
}

export default Spinner