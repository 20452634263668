import React from 'react'
import styles from './MainText.module.css'

const Year2015_06 = () => {
  return (
    <div className={styles.wrap}>
      <h1 className={styles.title}>6. 폐회 선언.</h1>
      <p>이후 ‘발전기금회계 결산(안)’, ‘3학년 졸업앨범 제작 기획(안)’ 등 몇 가지의 안건이 더 처리되어서, 총 10건의 안건에 대한 심의를 모두 마쳤다.</p>
      <p>&nbsp;</p>
      <p className={styles.quo}>“이상으로 오늘 안건은 모두 마쳤습니다. 기타사항이나 학교에 건의사항 등이 있으시면 말씀하시기 바랍니다.”</p>
      <p>장시간 회의 진행을 해서일까? 기분이 좋지 않아서일까? 운영위원장의 목소리가 처음처럼 활기차지는 않았다.</p>
      <p>&nbsp;</p>
      <p>6시 30분을 가리키는 시계를 바라보며 독도 씨는 생각했다.</p>
      <p className={styles.quo_mini}>‘안건 설명 간단하게 듣고, 질문 많아야 한 두 개 하고 끝나는데도 두 시간이나 걸리네. 제대로 심의하자면 어떤 안건은 논쟁도 있을 텐데, 그러면 몇 시간은 금방 지나가겠구나.’</p>
      <p>처음 기대와는 달리, 무언가 높은 벽이 앞을 가로 막고 있는 것 같은 이상한 기분이 들어서, 피로가 몰려오고 정신이 멍했다.</p>
      <p className={styles.quo_mini}>‘생각했던 것과는 많이 다르네. 나 혼자만 질문하고 다른 사람들은 거의 말이 없네. 원래 이런 건가? 아닌 것 같은데……’</p>
      <p>&nbsp;</p>
      <p className={styles.quo}>“추가로 다른 말씀이 없으므로 회의를 마치겠습니다. 이상으로 2015학년도 제1회 한비중학교 학교운영위원회 폐회를 선포합니다.”</p>
      <p>탕! 탕! 탕!</p>
      <p>&nbsp;</p>
      <p className={styles.quo}>“자. 위원님들 수고 많으셨습니다.”</p>
      <p>임춘옥 교장의 얼굴에 약간의 화색이 돌았다. 어쨌든 끝나서 좋은 것 같았다.</p>
      <p className={styles.quo}>“어휴, 두 시간 반이나 지났네요. 오늘 안건이 10건으로 많긴 했어도 이렇게 오래한 것은 5년 동안 처음입니다. 올해는 여러 가지로 색다른 해가 될 것 같네요.”</p>
      <p>독도 씨를 쳐다보면서 하는 윤미옥 위원장의 말에는 어쩐지 가시가 돋아 있다는 느낌이 묻어났다.</p>
      <p>&nbsp;</p>
      <p>운영위원들이 하나 둘씩 자리에서 일어나려는데 행정실장이 다급하게 말을 꺼냈다.</p>
      <p className={styles.quo}>“위원님들, 미리 말씀드렸듯이 오늘 첫 회의라서 끝나고 같이 하는 식사 자리를 마련했습니다. 아까 못 가신다던 차근덕 위원님 빼고 나머지 분들은 모두 가실 거죠? 장소는 여기서 차로 20분 거리에 있는 ‘조선해물찜’입니다. 차가 없으신 분들은 교장선생님 차랑 제 차로 함께 가시면 되고요. 원래 예약이 6시인데 너무 늦어져서……, 빨리 이동하셔야할 것 같습니다.”</p>
      <p className={styles.quo}>“그러게요. 원래 5시 30분이면 끝날 것 같았는데 다음에는 좀 더 빨리 끝낼 수 있도록 제가 잘 진행 해볼게요.”</p>
      <p>&nbsp;</p>
      <p>첫 회의 소집 시간은 오후 4시였다.</p>
      <p>약 30분 정도 인사하고 위원장을 선출했고, 그 다음에 회의를 시작했으니까, 위원장의 말대로라면 10개의 안건을 처리하는데 딱 한 시간 걸렸어야 했다.</p>
      <p>그런데 독도 씨가 질문과 발언을 많이 하다 보니, 회의 시간이 두 시간이 훌쩍 지났고, 회식 장소까지 이동하게 되면 결국 예약 시간보다 한 시간 가까이나 늦게 될 것이었다.</p>
      <p className={styles.quo_mini}>‘예약한 시간을 보니, 회의 시간이 늘 한 시간도 안 걸렸다는 이야기네. 심의하는 동안 아무 질문도 하지 않아야 가능한 시간일 텐데…….’</p>
      <p>독도 씨는 어쩐지 첫 회의의 후유증이 꽤 있을 것 같다는 예감이 들었다.</p>
      <p></p>
      <p></p>
      <p></p>
      <p></p>
      <p></p>
      <p></p>
    </div>
  )
}

export default Year2015_06