import React from 'react'
import styles from './MainText.module.css'

const Prologue16 = () => {
  return (
    <div className={styles.wrap}>
      <h1 className={styles.title}>16. 백년을 위한 걸음.</h1>
      <p>독재에도 장점이 있다.</p>
      <p>또한 민주주의에도 단점이 있다.</p>
      <p> 민주주의는 최고의 정치체제가 아닐 수도 있다.</p>
      <p>&nbsp;</p>
      <p>그럼에도 불구하고 학교는 왜 민주주의를 가르쳐야 하는가?</p>
      <p>공교육이기 때문이다.</p>
      <p className={styles.bold}>대한민국이 민주공화국이기 때문이다.</p>
      <p>&nbsp;</p>
      <p>알면 알수록 이상하다고 느껴지다가, 결국에는 좌절하고 마는 학교현장.</p>
      <p>&nbsp;</p>
      <p>주인이 주인으로서의 역할을 할 줄 모르고,</p>
      <p>다른 사람에게 의지하고,</p>
      <p>그 사람 탓만 하고 있다면,</p>
      <p>그 집이 잘되겠는가?</p>
      <p>그 집에 미래가 있겠는가?</p>
      <p>&nbsp;</p>
      <p>학교는 이제부터라도 민주주의를 제대로 가르쳐야 한다.</p>
      <p>&nbsp;</p>
      <p>30년째 시행되지 않고 있는 학교자치.</p>
      <p>아무도 지적하지 않으면 앞으로의 30년도 마찬가지일 것이다.</p>
      <p>&nbsp;</p>
      <p className={styles.bold}>100년 뒤에라도 올바른 교육이 자리 잡을 수 있게 하려면, 지금 시작해야 한다.</p>
    </div>
  )
}

export default Prologue16