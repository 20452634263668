import React from 'react'
import ss from './MainText.module.css'

const Year2015_23 = () => {
  return (
    <div className={ss.wrap}>
      <h1 className={ss.title}>23. 즐거운 운영위원 연수.</h1>
      <p className={ss.quo}>“견성도교육청은 오늘 ‘아름다운 선생님’ 인증서를 제작하여 수여했습니다. 지난 한 달 동안, 도내 교사들을 대상으로 학생, 학부모, 교사의 추천을 받은 100여 개의 감동 사연을 접수하였는데, 이 중 10명의 선생님을 아름다운 이야기의 주인공으로 선발하여 그 이야기를 공개하고 인증서를 수여했습니다.”</p>
      <p>&nbsp;</p>
      <p className={ss.quo_mini}>‘그저 그런 선생님들도 있지만, 훌륭한 선생님들도 많아. 마음이 훈훈해지네. 저런 분들에게서 배운 아이들이 잘 성장해서 훌륭한 어른이 되면 세상은 더 좋아질 거야. 부조리한 세상이지만 희망을 버리지는 말아야 해.’</p>
      <p>독도 씨는 자동차의 라디오에서 흘러나오는 뉴스를 듣고 있었다.</p>
      <p>그는 부종시청 인근에 위치한 부종교육지원청으로, 학부모운영위원 연수를 듣기 위해 가는 중이었다.</p>
      <p>&nbsp;</p>
      <p>얼마 전 독도 씨는 한비중학교 행정실로부터 한 통의 전화를 받았다.</p>
      <p className={ss.quo}>“나독도 위원님, 안녕하세요? 한비중학교 행정실 함진희 주무관입니다.”</p>
      <p className={ss.quo}>“네. 안녕하세요?”</p>
      <p className={ss.quo}>“다름이 아니라, 다음 주 수요일에 부종교육지원청에서 학부모운영위원 연수를 하거든요. 저희가 오늘까지 참가 인원을 파악하고 보고해야 해서요. 혹시 참가 가능하세요? 시간은 오전 10시입니다.”</p>
      <p className={ss.quo}>“오전이요?”</p>
      <p className={ss.quo}>“네. 오전 10시입니다.”</p>
      <p>&nbsp;</p>
      <p>학교나 교육청 등에서 근무하는 교육공무원(교사, 행정직)의 업무 중에는 학부모가 참여해야 하는 업무가 있다.</p>
      <p>예를 들자면, 학교운영위원회를 비롯하여 학부모가 참여해야 하는 각종 회의, 학부모를 대상으로 하는 연수(교육) 등이 있다.</p>
      <p>이런 일은, 교육공무원에게는 직장에서의 업무이므로 주로 평일 업무 시간(통상적으로 오전 9시~오후 6시)에 행하기 마련이다.</p>
      <p>하지만 직장을 다니는 학부모에게 이런 일은 직업 외의 일이다.</p>
      <p>따라서 평일 업무 시간에 이런 일에 참여하려면, 직장에서 휴가를 사용해야 한다.</p>
      <p>일 년에 한 두 번이라면 몰라도, 자주 있는 일이라면 사실상 참여가 불가능하다.</p>
      <p>그래서 학교운영위원이나 학부모회 임원, 기타 학교나 교육청에서 하는 행사에 참여할 수 있는 학부모는 대부분 전업주부이거나 프리랜서, 자영업자 등 근무시간 조절이 상대적으로 수월한 사람일 수밖에 없다.</p>
      <p>그렇다면 과연 이런 일들을 평일 업무 시간에 행하는 것은 합리적인 것일까?</p>
      <p>&nbsp;</p>
      <p>평일 업무 시간에 행사를 개최하면, 학부모 활동에서 자연스럽게 직장인은 배제될 수밖에 없다.</p>
      <p>약 절반 정도의 학부모가 교육에 참여할 권리를 보장받지 못하게 된다.</p>
      <div className={ss.annotation_wrap}>
        <p>통계청이 발표한 2023년 하반기 기준 18세 미만의 자녀를 둔 유배우 가구의 맞벌이 비율이 56.8%라고 하니, 전체 학부모 중 약 70~80%가 경제활동에 종사하고 있다고 추정할 수 있다.</p>
        <p>또한 경제활동 인구 중 임금 근로자의 비율은 약 70% 정도이므로, 학부모 중 직장인의 비율은 절반 정도라고 추정할 수 있다.</p>
        <p>다만, 2015년 당시의 직장인 비율은 최근의 추정치와 차이가 있을 수 있다.</p>
        <p>하지만 직장인이 배제되는 상황은 그때나 지금이나 변함없이 마찬가지이며, 비율의 차이가 어느 정도 있다고 해서 문제의 본질이 달라지지는 않는다.</p>
        <p>&nbsp;</p>
        <p>참고로, 교육에 참여할 권리는 「교육기본법」 제13조 제2항(부모 등 보호자는 보호하는 자녀 또는 아동의 교육에 관하여 학교에 의견을 제시할 수 있으며, 학교는 그 의견을 존중하여야 한다.)과 동법 제5조 제3항(국가와 지방자치단체는 학교운영의 자율성을 존중하여야 하며, 교직원·학생·학부모 및 지역주민 등이 법령으로 정하는 바에 따라 학교운영에 참여할 수 있도록 보장하여야 한다. 개정 2021.9.24.) 등에서 정하고 있고, 「초·중등교육법」 제31조에서 학교운영위원회를 설치하도록 함으로써 구체적인 방법을 명시하고 있다.</p>
      </div>
      <p>&nbsp;</p>
      <p>그런데 어떤 이는 이를 두고, 학부모의 권리와 교육공무원의 권리가 충돌하는 문제라고 말한다.</p>
      <p>직장인을 고려하여 업무 외 시간(휴일이나 저녁)에 행사를 치르면, 반대로 교육공무원에 대한 부당한 조치(연장 근무, 휴일 근무 등)가 될 수 있다는 것이다.</p>
      <p>그렇기 때문에 어쩔 수 없이 평일 업무 시간에 행사를 하는 것이라고 말한다.</p>
      <p>그런데 과연 그럴까?</p>
      <p>&nbsp;</p>
      <p>업무 특성상 저녁 시간이나 휴일에 근무가 필요하다면, 연장 근로에 대한 대가를 지급하고, 대체 휴무를 부여하면 된다.</p>
      <p>당직근무를 해야 하는 공무원들은 그것을 부당한 조치라고 여기는가?</p>
      <p>행사를 휴일이나 저녁에 하는 것이 업무상 반드시 필요한 일이라는 인식을 가진다면 부당한 조치라고 여기지 않을 것이다.</p>
      <p>합리적으로 복무규정을 만들고 지키면 된다.</p>
      <p>과연 이것이 어려운 일이라서 그동안 평일 업무 시간에 행사를 개최했을까?</p>
      <div className={ss.annotation_wrap}>
        <p>이미 저녁이나 휴일에 학부모 총회 등을 개최하는 학교가 있다.</p>
        <p>필요하다면, 회의나 연수도 저녁이나 휴일에 충분히 개최할 수 있다는 이야기이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>독도 씨는, 이해관계의 충돌 문제가 아니라고 말한다.</p>
      <p>이해관계의 충돌이라고 말하는 것은, 문제의 본질을 흐리기 위해 부각시키는 논리(논점 일탈)라고 말한다.</p>
      <p>이 현상의 근본적인 문제는 다른 곳에 있다.</p>
      <p>&nbsp;</p>
      <p>우리가 어떤 위원을 선출(또는 위촉)할 때, 두 개의 그룹이 있다고 가정하자.</p>
      <p>위원이 하는 일은 아무나(무작위로) 선출해서 맡길 수 있는 일이 아니라고 하자.</p>
      <p>하려는 의지가 있어야 하고, 필요한 역량을 갖추었거나, 그러한 역량을 계발시킬 수 있는 사람이어야 위원으로 선출할 수 있다고 하자.</p>
      <p>그리고 이러한 사람이 두 그룹 내에 비슷한 비율(높지 않은 비율)로 존재한다고 하자.</p>
      <p>그렇다면 두 그룹 모두에게 공정한 기회를 주고 선출하는 것과 한 그룹을 배제하고 선출하는 것 중에서 어떤 방식이 ‘적합한 위원’을 선출하기에 유리할까?</p>
      <p>누구를 선출하여도 별 차이가 없다면 전자나 후자나 특별히 유리할 것도 불리할 것도 없다.</p>
      <p>하지만 높지 않은 비율로 존재하는 ‘적합한 위원’을 선출하려 한다면, 한 그룹을 배제하고 선출하는 것은 확실히 불리한 일이 된다.</p>
      <p>&nbsp;</p>
      <p>학부모가 참가하는 각종 위원회의 위원에게는 일정한 수준의 자질이 요구된다.</p>
      <p>가장 중요한 것은 참여하려는 의지이고, 다음으로 중요한 것은 학교민주주의(학교자치)에 대한 이해이다.</p>
      <p>그리고 심사나 심의에 필요한 역량, 즉 행정(절차·규정)에 대한 이해, 토론(공적인 커뮤니케이션) 기술, 다양한 사회적 경험 등이 필요하다.</p>
      <p>‘적합한 위원’이란 이러한 자질을 갖춘 위원을 의미한다.</p>
      <p>&nbsp;</p>
      <p>참여하려는 의지를 가진 학부모의 수는 제한적이다.</p>
      <p>일정 수준의 책임을 부담해야 하고, 업무 난이도에 따라 부담이 있을 수 있으며, 상당한 시간을 할애해야 하기 때문에, 남다른 사명감이 있어야 의지를 가질 수 있다.</p>
      <p>또한 대다수의 학부모가 학교민주주의(학교자치)에 대한 이해가 부족하다.</p>
      <div className={ss.annotation_wrap}>
        <p>이에 대한 이야기도 계속 나왔고 나올 것이다.</p>
        <p>가까운 이야기로는, 앞장 “칼럼 - 원탁의 기사”에서 설명한 바 있으니 참고하길 바란다.</p>
      </div>
      <p>한편, 행정 업무 이해, 토론 기술 등은 부족하더라도 노력을 통해 충분히 성장시킬 수 있다.</p>
      <p>하지만 성장에 도움이 되어야할 학부모 연수의 내용이 부실하고, 정보의 공개 수준 등이 매우 낮아서 사실상 성장시키기 어렵다는 심각한 문제가 있다.</p>
      <p>그리고 다양한 사회적 경험은 심사나 심의에 크게 도움이 되는 역량인데, 이는 단기간에 습득하기 어렵기 때문에, 다양한 직종의 위원을 선출하여 다양한 경험을 확보하는 것이 필요하다.</p>
      <p>물론 아무리 다양한 직종의 학부모를 위원으로 선출해도, 전문성이 필요한 분야의 개수보다 위원의 수가 훨씬 적으므로, 위원의 선출만으로 필요로 하는 다양한 사회적 경험을 모두 확보하기는 불가능하다.</p>
      <p>따라서 외부 전문가를 활용(자문)하거나, 다양한 전문가가 포함되어 있는 전체 학부모의 의견을 적극적으로 수렴하는 행위가 절대적으로 필요하다.</p>
      <p>하지만 이 또한 학교민주주의에 대한 이해가 없으면 실천하기가 어렵다.</p>
      <p>&nbsp;</p>
      <p>정리하자면, 의지를 가진 사람은 흔하지 않고, 학교민주주의에 대한 이해가 있는 사람도 흔하지 않으며, 필요한 심사·심의 역량을 갖춘 사람도 적으며, 이를 성장시킬 수 있는 기회도 거의 없으며, 다양한 사회적 경험을 확보할 수 있는 여건도 갖추어지지 않은 상황이라고 할 수 있다.</p>
      <div className={ss.annotation_wrap}>
        <p>그야말로 총체적 난국이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>그러니까 결국, 자질을 갖춘 ‘적합한 위원’은 학부모 집단에 아주 낮은 비율로 존재한다고 볼 수 있다.</p>
      <p>가뜩이나 높지 않은 비율로 존재하는데, 절반의(직장인) 학부모를 배제하고 선출하려고 하니 ‘적합한 위원’을 찾기가 더 어려워진다.</p>
      <p>이처럼 ‘적합한 위원’을 선출할 수 있는 ‘경우의 수’가 절반으로 줄어든다면, 위원회가 ‘적합한 역량’을 발휘할 수 있는 ‘기댓값’도 절반으로 떨어진다.</p>
      <p>즉, 한 그룹(직장인)을 선출(위촉)에서 배제하면, 학교운영위원회를 비롯하여 각종 위원회의 평균 역량을 대폭 떨어뜨리는(감소시키는) 결과를 맞게 된다.</p>
      <div className={ss.annotation_wrap}>
        <p>참고로, 전체 학부모 집단은 이론적으로 세상의 모든 직종을 포함하고 있다.(만일 자녀를 낳지 않는 특이한 직종이 있다면 그 직종은 학부모 집단에 없다.)</p>
        <p>다양한 사회적 경험이 도움이 되는 위원회라면, 가급적 학부모 집단 내에서 고르게 위원을 선출하는 것이 유리하다.</p>
        <p>특정 직종(전업주부 등)에 편중된 위원회는 특정 내용(교복, 급식, 생활인권 등)의 심의에는 유리할 수 있지만, 그밖에 다양한 내용의 심의에 대응하는 것은 어려울 수 있다.</p>
        <p>그러니까, 특정 그룹을 배제하는 것은 그만큼 위원회의 역량을 감소시키는 일이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>직장인의 참여가 불가능한 시간에 행사(각종 회의나 연수 등)를 치름으로써, 직장인이 자연스럽게 배제되게 하는 것은, 회의나 연수의 대상자인 학부모위원(학교운영위원, 학부모회 임원, 기타 각종 위원회의 위원 등)의 선출 또는 위촉 시에 직장인이 지원하지 않게 되는 주요 원인으로 작용한다.</p>
      <p>다시 한번 말하지만, 직장인 그룹이 배제됨으로써, 의지와 역량을 갖춘 ‘적합한 위원’의 선출 가능성이 절반 이하로 떨어지고, 이로 인해 위원회의 역량이 제대로 발휘될 가능성도 절반 이하로 떨어진다.</p>
      <div className={ss.annotation_wrap}>
        <p>아는 사람은 알겠지만, 학교에는, 위원회의 역량이 떨어질수록 좋아하는 사람이 있다.</p>
        <p>그게 누군지는 굳이 말할 필요가 없을 것 같다.</p>
        <p>국회가 유명무실하다면 누가 제일 신나겠는가?</p>
        <p>① 민주적으로 국정을 운영하는 대통령</p>
        <p>② 독재하는 대통령</p>
        <p>양자택일이니 답을 맞히는 것이 그리 어렵지는 않을 것이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>학부모의 학교 운영 참여는 수요자 중심의 공교육 운영 방식에서 반드시 필요한 요소이다.</p>
      <p>학교자치의 핵심인 학교운영위원회 위원과 그 산하의 각종 소위원회 위원, 그 외에도 교복선정위원, 규정개정심의위원, 급식모니터링위원, 교원능력개발평가위원 등의 다양한 위원, 그리고 학부모회에 참여하는 임원과 대의원 등은 학교의 운영과 교육과정에 직접 참여하는 중요한 자원이다.</p>
      <div className={ss.annotation_wrap}>
        <p>학부모의 학교 운영 참여는 교육과정의 일부이고, 교사의 교육 활동 못지않게 중요하다.</p>
        <p>가정에서의 교육이 중요한 정도로, 학교에서의 사회화 교육이 중요한 정도로 말이다.</p>
        <p>학교는 학원과 달라서 교사의 수업만이 전부가 아니라는 것에 동의한다면, 이제 학부모의 학교 운영 참여도 교육과정이라는 점에 동의하고, <span className={ss.bold}>그 수준을 향상시키기 위해, 개인과 단위 학교의 노력과 관련 정책의 개발에 심혈을 기울여야 한다.</span></p>
      </div>
      <p>&nbsp;</p>
      <p>이들 위원에게는 참여 의지와 일정한 역량이 필요하므로, 무작위 선출은 불가하다.</p>
      <p>위에서 말했듯이 두 그룹 중 한 그룹을 배제해도 괜찮은 경우는, 누구를 선출하여도 별 차이가 없을 때만 할 수 있는 행위이다.</p>
      <p>그러므로 학교와 교육청이 직장인의 행사 참여를 원천적으로 봉쇄하여 직장인의 위원회 참여에 진입장벽이 발생해도 괜찮다고 여기는 것은, 누가 위원이 되어도 별 차이가 없다고 판단했을 때나 가능한 일이다.</p>
      <p>&nbsp;</p>
      <p>누가 되어도 차이가 없다?</p>
      <p>그것은 무슨 뜻인가?</p>
      <p><span className={ss.quo_mini}>‘누가 위원이 되어도 다 잘한다.’</span>라고 보기에는 무리가 있다.</p>
      <p>그렇다면 <span className={ss.quo_mini}>‘누가 되어도 역량을 성장시키면 된다.’</span>라고 여기는 것일까?</p>
      <p>부실한 학교운영위원회 연수나 학부모회 연수를 통해서는 역량 성장을 기대할 수 없으므로, 그런 판단은 아닌 것으로 보인다.</p>
      <p>그렇다면 무엇일까?</p>
      <p>혹시 <span className={ss.quo_mini}>‘누가 되어도 별 영향 없으니 아무나 하면 된다.’</span>라고 생각하는 것일까?</p>
      <p>그것도 아니면, 누가 위원이 되어도 별 차이가 없어서가 아니라, 직장인을 배제함으로써 <span className={ss.quo_mini}>‘위원회의 역량을 떨어뜨리자.’</span>는 의도가 있는 것일까?</p>
      <p>&nbsp;</p>
      <p><span className={ss.quo_mini}>‘누가 되어도 별 영향 없으니 아무나 하면 된다.’</span>는 것은, 겉으로는 학부모의 교육 참여가 중요하다고 하면서, 속마음으로는 중요하지 않은 일이니까, 대수롭지 않은 일이니까, 자질 따위는 신경 쓰지 않고 그저 보여주기 위해 아무나 선출하겠다는 마음일 것이고, <span className={ss.quo_mini}>‘위원회의 역량을 떨어뜨리자.’</span>는 것은 학부모 활동이 자신들에게 귀찮고 방해가 되므로 무력화 시키겠다는 마음일 것이다.</p>
      <p>과연 무엇일까?</p>
      <p>&nbsp;</p>
      <p>전자일 가능성도 있고, 후자일 가능성도 있다.</p>
      <p>어쩌면 둘 다일 수도 있다.</p>
      <p>&nbsp;</p>
      <p>전자는 학부모를 얕보는 심리다.</p>
      <p>학부모가 참여하는 일은 자질 따위는 필요 없는 허드렛일이고, 모든 행사는 겉치레에 불과하다고 말하는 것과 같다.</p>
      <p>후자는 학부모를 경계하는 심리다.</p>
      <p>민주주의를 반대할 수는 없으니 진열용 정책을 만들고, 실제로는 독재를 하겠다는 것이다.</p>
      <p>한쪽에서는 열심히 학교자치 활성화 정책을 만들고, 한쪽에서는 그것을 사문화 시키는 것이 교육계의 두 얼굴이다.</p>
      <p>1995년 5·31 교육개혁에서 단위 학교의 운영을 자치로 변환시킨 것은 쌍수를 들어 환영했지만, 그 자치가 학생자치·학부모자치·교원자치인 것은 싫었다는 이야기이다.</p>
      <p>그들이 원하는 학교자치란 곧 교장자치라는 이야기이다.</p>
      <p>&nbsp;</p>
      <p>훗날 독도 씨가 내린 결론은 다음과 같다.</p>
      <p className={ss.quo}>“학교나 교육청은 직장인을 배제함으로써, 학부모가 참여하는 여러 위원회의 역량을 떨어뜨리고 있다. 학부모(국민)를 우습게 여기는 마음이 있거나, 자신들의 기득권을 지키려고 하는 의도가 있다. 어떤 것이든 버려야할 마음이다. 처음부터 확 달라지지는 않겠지만, 당장 학부모 참여 행사·회의·연수를 저녁이나 휴일에 진행하도록 법령이나 지침을 정비하고, 학생·학부모·교사를 대상으로 하는 학교민주주의 교육을 강화하여, 지금부터라도 수요자 중심의 학교 운영을 정착시켜야 한다. 탐욕스러운 자들과 백면서생들이 주를 이루고 있고, 민주주의를 이해하거나 교육자로서의 사명감이 투철한 자들은 소수인, 현재 교육계의 힘만으로는 절대 공교육의 위기를 헤쳐 나갈 수 없다.”</p>
      <p>&nbsp;</p>
      <div className={ss.annotation_wrap}>
        <p>특정 직종에 편중된 구성보다는 다양한 직종(사회적 경험)의 학부모로 구성된 위원회가 유리할 수 있고, 특히 학부모의 의견 수렴이 반드시 필요하다는 것을 보여주는 사례는 매우 흔하다.</p>
        <p>한 가지만 살펴보자.</p>
        <p>&nbsp;</p>
        <p>대부분 학교의 각 위원들은 거의 전업주부로 구성되어 있다.</p>
        <p>교복선정위원회 또한 그러하다.</p>
        <p>교복 납품 업체를 선정(평가)할 때, 원단·디자인·편의성(입는 것과 관리하는 것) 등을 살펴보는 것은, 평소 옷을 세탁하고 관리하는 일을 하는 전업주부가 전문가이므로 보다 꼼꼼하게 살펴 볼 수 있다.</p>
        <p>따라서 교복선정위원회가 잘 작동하는 학교의 교복은 대체적으로 품질 만족도가 높다.</p>
        <p>하지만 입찰 방식이나 품질 보증 조건을 포함하여 각종 규정이나 절차에 익숙하지 않아서, 사후 서비스(A/S) 계약을 만족스럽게 하지 못하거나, 보다 좋은 조건(가격, 구성 등)으로 교복을 구매할 수 있는 기회를 놓치는 경우가 자주 발생한다.</p>
        <p>물론 전업주부 중에서도 경험이나 학습에 의해 입찰 관련 직종의 전문가 못지않은 업무 능력을 가진 사람이 있을 수 있는데, 그런 경우라면 좀 더 유리한 교복 구매가 가능하다.</p>
        <p>하지만 대부분의 경우에는 그렇지 못한 것이 현실이다.</p>
        <p>그러므로 물건을 구매하거나 납품하거나 이를 감독하는 업무가 포함된 직종의 학부모가 교복선정위원회에 참여한다면 교복 구매 절차가 보다 유리하게 전개될 것이다.</p>
        <p>만일 그런 직종의 학부모가 위원회에 없다면, 학부모회 등을 통해서 관련 직종에 종사하는 다른 학부모에게 의견을 물어보자.</p>
        <p>그렇다면 보다 좋은 조건으로 교복을 구매할 가능성이 높아진다.</p>
        <p>&nbsp;</p>
        <p>다행히 학교의 학부모 수는 많고, 그만큼 다양한 영역의 전문가가 있다.</p>
        <p>얼마나 이용할 수 있느냐가 관건이다.</p>
        <p>이것이, 의견 수렴이 반드시 필요한 이유이다.</p>
        <p>다양한 직종의 학부모가 모두 위원이 될 수도 없고, 그럴 필요도 없다.</p>
        <p>의견을 묻고 답을 기다리는 일이, 모든 학부모를 대상으로 열려 있으면 된다.</p>
        <p>&nbsp;</p>
        <p>덧붙여, 문맥을 오해하는 사람이 간혹 있기 때문에 부연설명을 하자면, 전업주부는 가장 많은 위원회에서 필요로 하는 가장 중요한 직종이다.</p>
        <p>교복이나 급식은 말할 것도 없고 생활인권 분야에서도 빠질 수 없는 직종이다.</p>
        <p>학교는 학생이 주인공이고 목적이다.</p>
        <p className={ss.bold}>무엇보다도 학생에 대한 이해가 중요하다.</p>
        <p>전업주부는 상대적으로 육아 시간이 많으므로, 학생 자녀와의 공감대 형성이 보다 잘되기 마련이어서, 대개 학생에 대한 이해가 입체적이고 풍부하다.</p>
        <p>교사와는 다른 관점에서의 이해를 제공할 수 있다.</p>
        <p>그러므로 전업주부는 학부모 활동에서 빠질 수 없는 핵심 직종임이 틀림없다.</p>
        <p>다만, 편중될 경우 그렇지 않은 경우에 비해 평균 역량이 떨어질 수 있다.</p>
        <p>따라서 독도 씨가 생각하는 이상적인 학부모위원이나 학부모대표단의 구성은 직장인과 전업주부가 고루 섞인 구성이다.</p>
        <p>다양한 관점은, 다양한 해결책을 보여주기 마련이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>부종교육지원청의 학부모운영위원 연수 시간 관련하여 글이 길어졌는데, 이쯤에서 정리하고 마지막으로 「초·중등교육법 시행령」 제59조의2 제3항을 보고 넘어가자.</p>
      <p><span className={ss.quo_mini}>‘국·공립학교에 두는 운영위원회의 위원장은 회의 일시를 정할 때에는 일과 후, 주말 등 위원들이 참석하기 편리한 시간으로 정하여야 한다.’</span>는 내용이다.</p>
      <p>&nbsp;</p>
      <p>바로 직장인 학부모의 참여 권리를 보장하는 조항이다.</p>
      <p>계속 말하지만, 법령은 이렇게 훌륭하게 만들어져 있다.</p>
      <p>지키지 않아서 사문화되는 것이 문제일 뿐.</p>
      <p>&nbsp;</p>
      <p>실제 대부분의 학교에서 회의가 소집되는, 압도적으로 많은 시간은 평일 오후 4시다.</p>
      <p>왜 그런지는 찾아볼 것도 없다.</p>
      <p>교사들의 수업이 끝나는 시간이다.</p>
      <p>수업 끝나고 회의 마치고 퇴근하는 코스로 짠 시간이다.</p>
      <p>&nbsp;</p>
      <p>만일 당신이, 직장인 학부모위원이 휴가를 사용하지 않고 참여할 수 있도록, 회의 시간을 오후 7시쯤 하자고 말하면, 들려오는 대답은 딱 2가지일 것이다.</p>
      <p><span className={ss.quo}>“아니, 교사가 무슨 죄라고 7시까지 기다려요? 안건 설명하는 교사까지 포함하면 그 많은 교사더러 단지 몇 사람을 위해서 대기하라는 거잖아요. 도대체 교사의 인권이나 삶의 질은 왜 생각하지 않는 거죠?”</span>라는 교원위원의 항변과, <span className={ss.quo}>“어머, 애들 저녁밥 줘야 하는데 왜 그 시간에 회의를 해요? 낮에 한가할 때 안하고?”</span>라는 학부모위원의 대답을 들을 수 있을 것이다.</p>
      <p>&nbsp;</p>
      <p>만일 모든 위원이 오후 4시를 원한다면, 그 시간에 하면 된다.</p>
      <p>하지만 그렇지 않다면(누구라도 저녁 시간을 원한다면) 법령에 따라야 한다.</p>
      <p>독도 씨가 하고 싶은 말은, 무조건 저녁에 하자는 것이 아니라, 회의 소집 시간이 직장인의 참여를 막는 장벽이 되어서는 안 된다는 것이다.</p>
      <p>한 사람을 위해 열 사람이 기다려야 한다고 해도, 그 한 사람은 전체 학부모 수만큼의 책임을 가진 사람이다.</p>
      <p>기다려줄 수 있어야 한다.</p>
      <p>또한 아이들의 저녁을 차려주는 것은 매우 중요한 일이지만, 이를 조정하는 것이 직장인이 휴가를 내고 참여해야할 만큼의 어려운 일이라고는 생각하지 않는다.</p>
      <p>운영위원회 회의는 결국 우리 자녀를 위한 선택이므로, 이를 감안하여 합리적으로 대처해야 한다.</p>
      <p>&nbsp;</p>
      <p>직장인의 참여 권리는, 수요자 중심의 교육과정 편성 및 수요자 중심의 학교 운영에 참여할 수 있도록 보장받은 보편적인 학부모의 권리다.</p>
      <p>하지만 이 권리는 30년째 은폐되고 있다.</p>
      <p>이미 높은 장벽이 세워져 있기 때문에, 사람들은 <span className={ss.quo_mini}>‘직장인은 그런 거 못해.’</span>라고 생각한다.</p>
      <p>암담한 현실이다.</p>
      <p>&nbsp;</p>
      <p>자, 이제 다시 독도 씨와 한비중학교 함진희 주무관과의 대화로 돌아가자.</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“음, 수요일 오전 10시면 참가 가능하기는 한데…….”</p>
      <p className={ss.quo}>“아. 그럼 참가하시겠어요? 다른 위원님들이 다 바빠서 못 간다고 하셔서 저희가 좀 곤란하거든요.”</p>
      <p>함진희 주무관의 목소리가 반가움을 표현하고 있었다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“왜 곤란해요?”</p>
      <p className={ss.quo}>“아무도 안가면 좀 눈치가 보여서요. 지원청에서 하는 행사인데 자리를 채워줘야죠. 보통 2명 정도는 간다고 보고를 해야 하거든요.”</p>
      <p className={ss.quo}>“그렇군요.”</p>
      <p>독도 씨는 중얼거렸고, 함진희 주무관은 고무되어 말하고 있었다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“네. 그런데 몇 번 가보신 분들한테 또 참가해 달라고 부탁하기도 어려워서요.”</p>
      <p className={ss.quo}>“알겠어요. 저는 참가할게요.”</p>
      <p className={ss.quo}>“아, 감사합니다. 그럼 가시는 것으로 보고할게요.”</p>
      <p className={ss.quo}>“네, 그러세요.”</p>
      <p>독도 씨는 궁금한 것이 많아서, 연수가 있다는 이야기를 듣는 순간 가려고 마음을 먹은 터였다.</p>
      <p>그런데 교육지원청의 행사에 단위 학교가 자릿수를 채워주어야 한다는 이야기가 신경에 거슬려서 연수에 대한 기대가 크게 줄어들었다.</p>
      <p className={ss.quo_mini}>‘이것 참 웃기는군. 상위기관에서 행사를 하면 하위기관에서 실적을 채워야한다는 이야기는 내가 어렸을 적에나 있었던 이야기 아닌가? 요즘도 그런가? 아직도 멀었군. 이런 위계질서는 정말 진절머리가 난다.’</p>
      <p>&nbsp;</p>
      <p>어쨌든, 그렇게 해서 독도 씨는 학부모운영위원 연수에 참가하기로 했고, 행사 당일이 되어 부종교육지원청으로 차를 몰고 가는 중이었다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“공보수 견성도교육감은 남다른 사명감을 가지고 학생 교육에 헌신해 오신 여러 선생님들의 노고에 감사드린다는 말을 전했습니다. 한편 최우수 사연의 주인공 변소라 선생님의 수상 소식을 접한 동료 교사 백점만 선생님은, 오늘날 교권이 심각하게 도전받는 가운데 변소라 선생님의 미담은 모든 이에게 귀감이 될 것이라면서, 아무쪼록 우리 사회가 교권을 좀 더 존중하는 계기가 되기를 바란다고 하였습니다.”</p>
      <p>&nbsp;</p>
      <p>내비게이션이 지원청에 도착했음을 알리자 독도 씨는 라디오를 껐다.</p>
      <p>9시 40분인데 주차장은 거의 다 차 있었다.</p>
      <p>나중에 들은 이야기지만, 행사가 있는 날이면 어김없이 주차장이 가득차기 때문에 좀 일찍 와야 주차가 가능하다고 한다.</p>
      <p>옥외 주차장을 한 바퀴 돌아서 빈자리 하나를 간신히 찾아 주차한 다음, 독도 씨는 지원청 건물로 걸어갔다.</p>
      <p>입구에는 “학부모운영위원 연수, 2층 대회의실”이라는 팻말이 서 있었다.</p>
      <p>그리고 2층 대회의실 입구에는 방명록을 적을 수 있는 책상이 3개 놓여 있었고, 입장하는 학부모를 위한 생수와 연수 자료를 배포하고 있었다.</p>
      <p>&nbsp;</p>
      <p>학교와 이름을 적어야 하는 방명록에 ‘한비중학교 나독도’라고 쓰고 연수 자료를 받는 순간, 마침 그 옆에 서 있던 어떤 사람이 방명록을 보더니 반갑게 인사를 건넸다.</p>
      <p className={ss.quo}>“아! 나독도 위원님이시군요?”</p>
      <p>독도 씨는 낯선 사람이 아는 척을 하자 순간 놀랐다.</p>
      <p className={ss.quo_mini}>‘어? 누구지?’</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“안녕하세요? 전에 전화 드렸던 조진일입니다. 반갑습니다.”</p>
      <p>그는 환하게 웃으며 독도 씨에게 오른 손을 내밀었다.</p>
      <p className={ss.quo}>“아! 안녕하세요? 반갑습니다.”</p>
      <p>독도 씨는 얼마 전에 전화로 대화를 나누었던 부종교육지원청 대외협력팀 조진일 팀장을 기억해내었다.</p>
      <p>조진일 팀장은 악수를 하며, 독도 씨에게 극적인 반가움을 표현했다.</p>
      <p className={ss.quo}>“드디어 뵙네요! 한번 찾아뵈려고 했었는데 영 시간이 안 나서 못 갔어요. 오신다는 보고는 받았는데, 마침 여기서 딱 만났네요.”</p>
      <p>&nbsp;</p>
      <p>반갑게 맞아주니 좋기는 했지만, 독도 씨는 그가 왜 그렇게 반가워하는지 알 수가 없었다.</p>
      <p className={ss.quo}>“지난번에는 전화 주셔서 감사했습니다. 제가 처음이라 모르는 것이 많은데 앞으로 잘 부탁드리겠습니다.”</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“네. 앞으로 자주 뵐 것 같네요. 뭐든 아무 때나 문의 주세요. 제가 아는 한 성실히 답변 드리겠습니다. 그리고 무슨 문제가 있으면 저한테 먼저 알려주세요. 웬만하면 해결이 될 겁니다.”</p>
      <p>조진일 팀장은 오랜 경험으로 독도 씨 같은 사람들이 가끔 나타난다는 것을 알고 있었다.</p>
      <p>A와 B를 더하면 C가 나오는 것으로 약속된 질서가 있을 때, C가 아닌 D가 나오면 왜 그렇게 나오는지 이유를 알아야 하고, 그것이 약속되지 않은 질서라면 이의를 제기하는 그런 사람 말이다.</p>
      <p>그가 만난 학부모 운영위원들은 대개, <span className={ss.quo_mini}>‘학교에는 A가 있고 B가 있구나. 어? C도 있고 D도 있네? 뭐가 되었든 내가 할 일만 알려주세요.’</span>라고 한다.</p>
      <p>그들도 C가 나와야 하는데 D가 나온 것이 이상하다는 생각은 잠시나마 할 것이다.</p>
      <p>다만, 그들은 그것에 깊은 관심을 주지 않는다.</p>
      <p>그러거나 말거나, 그런 일은 학교에서 알아서 할 일이라고 여기고 만다.</p>
      <p>그런 그들에게 무엇인가를 요청하면 그들은 성실하게 응답 해주며, 교육에 대한 관심이 지대하기 때문에 해주는 것이라 말한다.</p>
      <p>하지만 그들이 열심히 응답하는 이유는 교육에 대한 관심 때문이 아니다.</p>
      <p>그들의 마음에는 오로지 자기 자녀에 대한 관심만 가득 차 있다.(간혹 자녀보다 자기 자신에게 더 관심이 있는 경우도 있다.)</p>
      <p>그런 그들에게는 ‘C나 D가 A와 B의 작용에 의한 결과라는 것’은 하등의 관심사가 아니다.</p>
      <p>그냥 A, B, C, D가 보일 뿐이다.</p>
      <p>다만, 자신의 자녀가 C를 가져야 하는데 D를 받게 되면 그때는 부당하다고 따진다.</p>
      <p>심지어 A와 E를 더했기 때문에 D가 나온 것인데도 C가 나와야 한다고 따지는 경우도 있다.</p>
      <p>그래서 조진일 팀장은 잘 안다.</p>
      <p>차라리 독도 씨와 같이 절차와 규정을 파악하고 대응하는 사람이 업무적으로는 상대하기 낫다는 것을.</p>
      <p className={ss.quo_mini}>‘어차피 이런 사람도 있고 저런 사람도 있어. 알고 보면 다 거기서 거기야. 깔끔하게 줄 건 주고, 받을 건 받는 그런 사람이 낫지. 물러도 질서 없는 사람보다는 날카로워도 질서 있는 사람이 덜 위험해.’</p>
      <p>그런 이유로, 조진일 팀장은 독도 씨와 같은 사람들에게 호감을 가지는 편이다.</p>
      <p>그리고 부종시 내에 그런 사람은 몇 명 더 있다.</p>
      <p>&nbsp;</p>
      <p>한편, 독도 씨는 이때만 해도 조진일 팀장에 대해 잘 몰랐었다.</p>
      <p>나중에 알게 된 조진일 팀장은, 조직에 마이너스가 될지라도 아닌 것은 아니라고 말하는 기본적인 소양이 있는 사람이다.</p>
      <p>다만, 앞서 잠깐 말했듯이 자신을 크게 내주면서까지 싸우지는 않는다.</p>
      <p>리스크 관리를 적절하게 하는 공무원이다.</p>
      <p>그렇다고 해도 독도 씨는, <span className={ss.quo_mini}>‘그만해도 어딘가?’</span>라며 그를 좋게 평가한다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“알겠습니다. 감사합니다.”</p>
      <p>독도 씨가 대답하자, 조진일 팀장은 대회의실 안쪽으로 독도 씨를 안내했다.</p>
      <p className={ss.quo}>“창가는 좀 더워요. 가운데 쯤 앉으세요. 연수 잘 들으시고요. 이따 연수 끝나고 또 뵐게요.”</p>
      <p>그러고 나서 그는, 마침 2층으로 올라오는 다른 지인을 맞으러 걸음을 옮겼다.</p>
      <p className={ss.quo}>“아니, 만근 선생님! 어쩐 일이세요?”</p>
      <p>&nbsp;</p>
      <p>독도 씨는 가운데 줄의 맨 뒤쪽에 자리를 잡았다.</p>
      <p>앞의 강단에서는 오늘의 강사가 화면을 보며 강의를 준비하고 있었다.</p>
      <p>200석이라는 회의실은 3분의2쯤 차 있었고, 삼삼오오 모여 앉은 학부모들은 각자 이야기를 나누거나 연수 자료를 펼쳐보고 있었다.</p>
      <p>&nbsp;</p>
      <p>독도 씨도 지원청에서 나누어준 오늘의 연수 자료를 펼쳐 보았다.</p>
      <p>제목은 “2015학년도 학교운영위원회 연수 자료”라고 적혀 있었고, 작성자는 ‘진부도교육청 교육공동체기획단’이라고 적혀 있었다.</p>
      <p>지원청에서 작성한 자료가 아님을 알 수 있었다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo_mini}>‘어디 보자. 무슨 내용이 들어가 있나.’</p>
      <p>총 10페이지의 인쇄물 첫 장에는 학교운영위원회의 정의와 법령 근거 등이 적혀 있었다.</p>
      <p>어디서나 찾아볼 수 있는 가장 기본적인 내용이었다.</p>
      <p>그리고 다음 페이지에는 학교운영위원회 위원의 권한이 적혀 있었다.</p>
      <p>학교운영 참여권, 중요사항 심의·자문권, 보고 요구권 등이 있는데, 여기서 보고 요구권이란 학교장이 학교운영위원회의 심의 결과와 다르게 시행하거나, 심의 사항인데도 심의를 거치지 않고 시행할 경우, 그 사유를 지체 없이 학교운영위원회에 보고해야 한다는 것이다.</p>
      <div className={ss.annotation_wrap}>
        <p>이와 관련해서는 추후 ‘심의와 의결의 차이’라는 주제로 심도 있게 다룰 예정이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>그리고 이어지는 내용으로는 학교운영위원의 자격, 자세, 의무 등이 있었고, 기타 선출방식이라든가 위원의 정수, 구성 비율 등 인터넷을 검색하면 줄줄 나오는 법령의 기본적인 내용들이 포함되어 있었다.</p>
      <p>&nbsp;</p>
      <p>여기서 재미있는 것을 하나 살펴보자.</p>
      <p>이 연수 자료에는 학교운영위원의 자세를 이렇게 설명하고 있었다.</p>
      <p>첫째, 학교 구성원의 의견을 적극적으로 수렴하여야 한다.</p>
      <p>둘째, 제반 규정을 준수하여야 한다.</p>
      <p>셋째, 민주적 의사 결정을 하여야 한다.</p>
      <p>이 내용이 재미있다고 하는 것은, 실제 대부분의 학교운영위원은 학교 구성원의 의견을 적극적으로 수렴하지 않으며, 제반 규정을 준수하기는커녕 제반 규정을 거의 모르며, 민주적 의사 결정 또한 하지 않기 때문이다.</p>
      <p>그러니까 당시(2015년) 기준으로 보면, 20여 년 동안 연수를 하는데도 대부분 그 자세를 지키지 않고 있다는 이야기이다.(30년째인 지금도 마찬가지다.)</p>
      <p>이건 너무 비효율적인 연수가 아닌가?</p>
      <p>&nbsp;</p>
      <p>이런 독도 씨의 주장을 놓고, 이는 일반화할 수 없는 개인적인 경험이며, 실제로는 그렇지 않다고 말하는 논객들이 가끔 있다.</p>
      <div className={ss.annotation_wrap}>
        <p>엉터리로 하지 않고, 제대로 전수조사 하면 독도 씨의 주장대로일 것이다.</p>
        <p>최근에 어떤 의견 수렴을 했는지 구체적으로 말하게 하고, 특정 규정을 아는지 물어보고, 안건의 토론 시간은 평균 얼마인지, 회의록의 내용 등을 정확히 조사해보면 답이 나온다.</p>
        <p>독도 씨가 이후 10여 년에 걸쳐서 만나본 여러 지역 수십여 개의 학교의 운영위원회는 대동소이했다.</p>
      </div>
      <p>&nbsp;</p>
      <p>당연히 독도 씨는 전국 모든 학교운영위원회의 상황을 다 알지는 못한다.</p>
      <p>&nbsp;</p>
      <p>하지만 그럼에도 불구하고 그렇게 주장하는 이유는, 시스템이 그러하기 때문이다.</p>
      <p>학부모위원이 학부모의 의견을 수렴할 수 있는 방법에는 무엇이 있을까?</p>
      <p>법령(시·도의 조례)에서는 학교홈페이지, 학부모총회, 가정통신문 등을 이용하라고 한다.</p>
      <p>이는 운영위원회가 해야 할 일이다.</p>
      <p>그런데 학교홈페이지나 가정통신문을 운영위원장이 이용할 수 있을까?</p>
      <p>그런 학교가 있는지 잘 모르겠다.</p>
      <p>들어본 적이 없다.</p>
      <div className={ss.annotation_wrap}>
        <p>학교장이 의견 수렴하면 된다고 하는 사람이 있는데, 이는 명백한 월권이다.</p>
        <p>학교장 편의대로 하는 의견 수렴의 폐해는 종종 발생한다.</p>
        <p>누누이 이야기하지만, <span className={ss.bold}>학교장의 선의에 기대는 것은 없는 것과 같다.</span></p>
      </div>
      <p>하지만 학부모총회나 학부모회 대의원회를 통해 의견을 수렴해 달라고, 운영위원장이 학부모회에 요청할 수는 있다.</p>
      <p>그런데 학부모회는 법정기구인가?</p>
      <p>그리고 운영위원회의 요청을 들어주어야 할 어떤 근거가 있는가?</p>
      <p>아니다, 없다.</p>
      <p>그러니까 학부모회가 구성이 되어야 하고, 제대로 자치 운영되어야 하고, 학교운영위원회와 학부모회가 유기적으로 소통하고 있어야 하는 등의 전제조건이 성립할 때 의견 수렴이 가능하다.</p>
      <p>그러한 전제조건을 시스템으로 보장하지 않으면, 없는 것과 다를 바 없다.</p>
      <div className={ss.annotation_wrap}>
        <p>실제 의견 수렴이 일어나지 않는 중요한 이유가 두 가지가 있는데, 하나는 제대로 심의하지 않는다는 것이고, 다른 하나가 의견 수렴 방법(시스템)이 없다는 것이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>또한 제반 규정을 준수하기는커녕 제반 규정을 거의 모른다고 했는데, 규정을 어기는 사례는 이미 여러 가지 보았으니, 규정을 아예 몰라서 문제인 사례를 하나 살펴보자.</p>
      <p>혹시 당신이 운영위원이라면, 당신은 안건을 발의해본 적이 있는가?</p>
      <p>대부분의 운영위원에게 이를 물어보면 <span className={ss.quo}>“안건은 학교장만 제출하는 것 아닌가요?”</span>라고 말한다.</p>
      <p>아니다.</p>
      <p>운영위원도 발의할 수 있다.</p>
      <p>간혹 어떤 이는, <span className={ss.quo}>“안다. 하지만 그동안 한 번도 발의할 필요가 없었다.”</span>라고 말한다.</p>
      <p>축하한다.</p>
      <p>안건을 발의할 필요가 없다는 것은, 그 학교가 아주 이상적으로 운영되고 있다는 뜻이다.</p>
      <p>그런 학교가 있다는 것이 의아한 일이긴 하다만, 있다고 하니 인정한다.</p>
      <p>&nbsp;</p>
      <p>우리는 만일, 학생의 학교생활이나 학부모 활동 중에 무언가 불편한 점이 있다면, 그것을 안건으로 발의해서 상정한 후 논의할 수 있어야 한다.</p>
      <div className={ss.annotation_wrap}>
        <p>2017년의 일이었다.</p>
        <p>한비중학교의 일부 학부모들은, 폴리스 활동을 하는 자원 봉사 학부모들이, 따로 학부모를 위한 공간이 없어서, 건물 입구 한 구석에서 녹색 조끼를 착용해야 하고, 조끼를 보관할 곳이 없으므로 경비실의 캐비닛을 함께 사용해야 하는 것이 불편하다고 했다.</p>
        <p>그래서 학교장에게 학부모 활동을 위한 공간을 확보해달라고 몇 번 건의했지만, 차일피일 미루면서 해결이 되지 않자, 독도 씨는 “학부모 상주실 제안(안)”을 발의하였다.</p>
        <p>한비중학교 운영위원회는 이 안건을 회의에 상정하여 논의했고, 논의 결과 현재 운영위원회실을 학부모 상주실 겸용으로 사용하기로 결정하였고, 새로운 캐비닛을 구매하기로 하였다.</p>
        <p>앞서 <span className={ss.bold}>“건의가 아닌 제안, 요청이 아닌 요구를 하라.”</span>고 한 것이 이런 것이다.</p>
        <p>한비중학교에서는, 이 일을 포함하여 몇 가지 안건이 학부모위원 발의로 상정되었는데, 이와 관련한 자세한 이야기는 2016년과 2017년 편에서 볼 수 있다.</p>
      </div>
      <p>&nbsp;</p>
      <p>학부모위원이 안건을 발의해야 할 필요가 발생하는 학교는 부지기수일 것이다.</p>
      <p>그런데 안건을 발의할 수 있다는 규정을 모르니까 못하는 것이다.</p>
      <p>한번 전수조사해보면 재밌을 것이다.</p>
      <p>전국 1만2천여 개 학교에서 연간 처리(심의)되는 안건의 수가 수십만 개일 텐데, 과연 학부모위원이 발의하여 심의한 안건은 몇 개일까?</p>
      <p>학교 평균 연간 1개 미만일 것이라고 확신하며, 아마 0에 근접하는 숫자일 것이라 추정한다.</p>
      <p>한비중학교의 경우에도 독도 씨가 운영위원장이었던 두 해 다섯 건을 제외하고 학부모가 안건을 발의한 경우는 10년간 한 건도 없었다.</p>
      <p>&nbsp;</p>
      <p>물론 이 외에도 필수적으로 알아야할 규정인데 모르고 있는 것이 한두 가지가 아니다.</p>
      <p>알아야만 하는 규정을, 교육청도 학교도 학부모위원에게 알려주지 않는다.</p>
      <p>뭐, 당연하게도, 이번 부종교육지원청의 학부모운영위원 연수의 내용에도 없었다.</p>
      <p>&nbsp;</p>
      <p>그리고 민주적 의사 결정이 되지 않고 있다는 것은 따로 설명할 필요가 없다.</p>
      <p>지금까지의 사례만 해도 충분하고, 앞으로도 끊임없이 나올 테니까.</p>
      <p>&nbsp;</p>
      <p>이처럼, 연수에서 학교운영위원의 자세를 열심히 떠들어봐야, 학교의 운영 시스템을 규정하는 법령이 미비(학부모회 법정 기구화를 대형 교사 단체가 반대한다는 이야기는 앞에서 했다)되어 있고, 제대로 가르쳐주지 않아서 모르고, 거수기 일색으로 운영되는 학교운영위원회이기 때문에, 올바른 자세를 지킬 수도 지킬 리도 없다.</p>
      <p>그런데도 매년 같은 내용의 연수를 하고 있으니 재미있다는 것이다.</p>
      <p>&nbsp;</p>
      <p>그리고 재미있는 것이 하나 더 있는데, 운영위원의 의무 중에 ‘지위남용 금지의 의무’를 설명한 내용이다.</p>
      <p>이 내용은 특별히 빨간색으로 인쇄되어 있었는데, 다음과 같다.</p>
      <p className={ss.quo_mini}>‘학교운영위원회 위원이 그 지위를 남용하여 해당 학교와의 거래 등을 통하여 재산상의 권리·이익을 취득하거나 다른 사람을 위하여 그 취득을 알선한 경우에는 운영위원회 의결로 그 자격을 상실하게 할 수 있다.’</p>
      <p>이런 일이 실제 발생할까?</p>
      <p>이 부분은 내용이 길어서, 다음으로 설명을 미루겠다.</p>
      <p>미리 귀띔을 하자면, 이런 일들은 실제로 종종 발생한다.</p>
      <p>그러니까 빨간색으로 강조를 했겠지.</p>
      <p>&nbsp;</p>
      <p>그리고 연수 자료의 후반부에는, 불법 찬조금 근절 캠페인 자료, 학교 폭력 예방 자료, 그리고 진부도교육청이 야심차게 추진하고 있는 드림스쿨에 대한 홍보 자료가 첨부되어 있었다.</p>
      <p>&nbsp;</p>
      <p>이게, 연수 자료의 전부였다.</p>
      <p>독도 씨가 새롭게 배울 것은 하나도 없었다.</p>
      <p className={ss.quo_mini}>‘이게 뭐야……. 심의에 도움이 되는 실무 연수가 아니네.’</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“학교운영위원회는 민주성·투명성·공정성을 기반으로 운영되는 기구입니다.”</p>
      <p>어느새 강사는 자기소개를 마치고 강의를 진행하고 있었다.</p>
      <p>강의는, 그냥 자료를 읽는 수준이었다.</p>
      <p>청중들 중에 강의의 내용에 집중하는 이는 별로 없었다.</p>
      <p>소곤소곤 자신들만의 이야기에 몰두하거나, 오전인데도 졸고 있거나, 다른 책을 보거나, 창밖을 내다보는 사람들이 대부분이었다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“선생, 처음 보는 얼굴인데? 운영위원 처음 하시나 봐요?”</p>
      <p>독도 씨의 옆자리에 앉아 있는 어떤 사람이, 갑자기 독도 씨에게 말을 걸었다.</p>
      <p className={ss.quo}>“예? 아……. 처음입니다.”</p>
      <p>안경을 쓰고 모자를 눌러쓴 남자가 조용히 웃고 있었다.</p>
      <p>가만히 보니, 좀 전에 조진일 팀장이 독도 씨를 안내하고 곧바로 맞으러 갔던 그 지인이었다.</p>
      <p>이제는 빈자리가 드문드문 남아 있어서, 대략 150명 이상이 참석하고 있는 강의실(대회의실)에, 강사와 독도 씨, 그리고 문 쪽에 앉아 있는 조진일 팀장, 그리고 이 사람만 남자였다.</p>
      <p>여기에 온 학부모 운영위원은 대부분이 여자였다.</p>
      <p>아마도 대부분 전업주부일 터였다.</p>
      <p className={ss.quo}>“아빠들이 운영위원을 하는 것은 참 드문 일이죠. 부종시 전체에 몇 명 없어요. 그러다보니 이런데 몇 번 왔다갔다 하다보면 서로 알게 됩니다. 우리 통성명이나 합시다. 나는 김대춘이라고 합니다. 호는 만근이에요. 만근 김대춘. 앞으로 저를 부를 일이 있으면 만근이라고 불러주세요.”</p>
      <p className={ss.quo_mini}>‘허! 호라고? 와, 특이한 분이네.’</p>
      <p>김대춘 씨, 조진일 팀장이 ‘만근 선생님’이라고 불렀던 이 남자는 딸아이가 초등학교 1학년 때부터 운영위원을 5년째 하고 있으며, 도시와 농촌이 함께 있어서 도농복합도시라 일컫는 부종시의 중앙부, 그러니까 도시의 끝 부분이자 농촌이 시작되기 직전인 도농동에 살고 있었다.</p>
      <p>특이하게 자신의 호를 만들어서 주변에 알려주었으며, 지인들은 그의 요청에 따라 이름대신 호를 불러주었다.</p>
      <p className={ss.quo}>“아, 저는 나독도라고 합니다. 반갑네요. 그런데 정말 남자는 드문가보네요. 여기에는 우리 둘 밖에 안보이네요.”</p>
      <p>장내를 한번 둘러보는 독도 씨를 보며, 만근 씨는 이렇게 말했다.</p>
      <p className={ss.quo}>“아빠들이 많이 참여해야 하는데, 그래야 뭔가 좀 달라질 텐데. 이건 뭐 맨날 똑같은 이야기만 중얼거리고, 학교 가봐야 제대로 하지도 못하고. 에이, 쯧…….”</p>
      <p>&nbsp;</p>
      <p>독도 씨는 만근 김대춘 씨에게로 고개를 돌렸다.</p>
      <p className={ss.quo_mini}>‘어? 이 사람도 뭔가 불만이 있는 것 같네?’</p>
      <p>만근 씨는 툴툴 거리며 말을 이었다.</p>
      <p className={ss.quo}>“재미없죠? 이거 들으나 마나에요. 아무 내용도 없어요. 그냥 거수기들 한 번씩 모아 놓고, ‘우리 위원님들’하고 우쭈쭈 해주려고 하는 짓거리에요. 그러면 또 저 사람들은 어깨에 뽕이 꽉 차서 구름을 둥둥 떠다니죠. 이놈들은 학부모를 아주 졸(卒)로 보고 있어요.”</p>
      <p>만근 씨의 이야기에는 거침이 없었다.</p>
      <p>허허, 이게 5년 차의 관록인가?</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“하하하! 그렇군요. 뭐, 저도 학교운영위원회가 뭔가 이상하다는 생각은 계속 하고 있는데요. 저만 이상하다고 생각하는 것이 아닌가 봐요?”</p>
      <p>갑자기 독도 씨의 마음에서 반가움이 솟아올랐다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“제 정신인 사람이라면 다 그렇게 생각하는 게 정상이죠. 나는 무식해서 토론 같은 것 해본 적이 없는데, 배웠다는 교사들이 저보다 더 못해요. 아주 상(上)등신들이에요.”</p>
      <p>교사들이 들으면 입에 거품을 물을만한 이야기였다.</p>
      <p className={ss.quo}>“아이고, 말씀이 시원시원하시네요. 그래도 교사들이 들으면 기분 나빠하겠어요.”</p>
      <p>독도 씨는 주변에 자신들의 이야기를 듣는 사람이 있는지 한번 둘러보았다.</p>
      <p>다행히도 맨 뒷줄에 앉아 있는 사람은 그 둘 뿐이었고, 주변에는 빈자리가 더 많았다.</p>
      <p className={ss.quo}>“기분 나빠도 어쩔 수 없죠. 사실인데요. 뭘…….”</p>
      <p>만근 씨는 독도 씨보다 훨씬 더 큰 분노를 가지고 있는 듯 했다.</p>
      <p>&nbsp;</p>
      <p>만근 씨는 독도 씨 쪽으로 고개를 숙이며 목소리를 줄여서 말했다.</p>
      <p className={ss.quo}>“저기 조진일 팀장도 다 알아요. 저 친구 우리한테 맨 날 깨져요. 자기 딴에는 무슨 문제가 생기면 본인 선에서 해결하려고 하는데, 뭣도 모르는 사람들이야 사탕발림에 다 넘어가지만, 우리한테는 안 통하죠. 뭐가 잘못인지 딱딱 짚어주면 인정 안하고는 못 배겨요. 그러면 또 학교 안 다치게 하려고 별의별 쌩 쇼를 다하지요. 그래서 우리한테는 평소에도 쩔쩔 매요. 우리가 협조 안 해주면 일이 커지거든요. 하긴 뭐 우리도 도움을 많이 받죠. 아무래도 지원청은 교사들을 잘 다루니까. 우리가 조진일 팀장을 필요로 할 때도 있어요.”</p>
      <p>새로운 세계의 이야기였다.</p>
      <p>뭔가 모르는 세상이 있었다.</p>
      <p>잘은 모르겠지만, 우리라고 하는 집단이 있고, 지원청과 이 집단이 때로는 도움을 주고받는다는 이야기 같았다.</p>
      <div className={ss.annotation_wrap}>
        <p>교사는 교육청(지원청)에게 을(乙)이고, 교육청(지원청)은 학부모에게 을이고, 학부모는 교사에게 을이라는 말이 있다.</p>
        <p>대체적으로 잘 들어맞는 말이다.</p>
      </div>
      <p>하지만 가끔 자기 자신을 ‘우리’라는 복수형으로 표현하는 사람들도 있다는 것을 알기 때문에 독도 씨는 질문을 해야 했다.</p>
      <p className={ss.quo}>“우리라고요? 그게 누구를 말씀하시는 건가요?”</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“빤하죠. 아빠들이요. 제가 아빠들 몇 명 없다고 했잖아요. 내가 아는 현직 학부모운영위원은 5명쯤 되나? 가만있어 보자. 공정지구의 한통수 씨, 봉대의 씨, 그리고 임청읍에 장복철 씨, 구마동 차성주 씨, 그리고 나하고……. 이제 선생님까지 6명이네요. 거 뭐 더 있겠지만, 운영위원회가 개판이라고 생각하고 싸우는 사람들은 이 정도에요.”</p>
      <p>만근 씨는 손가락을 접어가면서 하나하나 꼽았다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“아니, 근데 저를 처음보시는 데, 제가 싸우는 사람인지 아닌지 어떻게 아시고……”</p>
      <p>독도 씨가 의문을 표하자, 만근 씨가 대답했다.</p>
      <p className={ss.quo}>“저기 조진일 팀장이 그러던데요? 저하고 같은 부류라고.”</p>
      <p>아 그랬구나.</p>
      <p>독도 씨는 이 동네가 생각보다 좁을 수 있다는 것을 깨달았다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“우리가 서로 뭉칠 필요가 있어요. 이놈들은 정말 비상해요. <span className={ss.bold}>학부모를 꼬드겨서 우리를 학부모의 적으로 만들고 고립시킬 줄 안다니까요.</span> 그러니까 어려운 일 있으면 연락주세요. 혼자 끙끙 앓지 마시고. 아, 그리고 공정지구에 사시면 한통수 씨나 봉대의 씨를 찾아가 보세요. 그분들은 부종교육포럼이라고 교육시민단체를 운영하고 있는데, 많은 도움이 될 거에요.”</p>
      <p>나중에 들은 이야기지만, 만근 씨가 말하는 ‘이놈들’은 학교의 교사들과 교육청(지원청)의 공무원 모두를 싸잡아 하는 말이었다.</p>
      <p className={ss.quo}>“저는 정치를 혐오해서 시민단체는 안 좋아해요. 한통수 씨가 거기 대표인데, 그 사람이 좀 정치적이라서 그 단체가 정치적인 일을 좀 하더라고요. 그래서 저는 거기 참여는 안 해요. 그래도 거기 있는 봉대의 씨하고는 친해요. 그 분은 중립적이라서. 흐흐흐.”</p>
      <p>만근 씨는 독도 씨에게 가뭄에 단비 같은 정보를 주고 있었다.</p>
      <p className={ss.quo_mini}>‘부종교육포럼이라……. 시민단체라면 정보가 많을 수도 있겠네?’</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“와, 좋은 정보네요. 제가 찾던 정보입니다. 학교운영위원회 관련해서 제가 궁금한 것들이 많은데, 어느 정도 해결할 수 있을지도 모르겠네요.”</p>
      <p className={ss.quo}>“그럴 거예요. 특히 봉대의 씨가 아는 게 많아요. 그래서 부종시에서는 학교장의 블랙리스트 1위에 올라 있는 사람이죠.”</p>
      <p className={ss.quo}>“블랙리스트요? 그런 것도 있나요?”</p>
      <p>독도 씨는 끝도 없이 쏟아지는 새로운 정보에 정신이 없었다.</p>
      <p className={ss.quo}>“거, 학교장들이 모여요. 장학협의회인가 뭔가. 주기적으로 모이는데, 거기서 자기들끼리 정보를 주고받아요. 누구 조심해라, 누가 이번에 이상한 짓을 했다, 그렇게 블랙리스트를 작성해 놓고서, 혹시 리스트에 있는 사람이 학부모위원으로 지원하거나 지역위원으로 추천 받으면 기를 쓰고 떨어뜨리려고 노력을 하죠. 다른 학부모들을 이용해서 말이에요. 블랙리스트에 있는 사람이 운영위원이 되면, 교장 입장에서는 그 해 농사는 다 물 건너 간 거죠.”</p>
      <p className={ss.quo}>“예? 그래요? 와, 몰랐네.”</p>
      <p className={ss.quo}>“흐흐흐. 뭐, 금방 다 알게 되요. 나독도 선생도 곧 블랙리스트에 올라갈걸요?”</p>
      <p>독도 씨는 새로운 정보가 너무 마음에 들었다.</p>
      <p className={ss.quo_mini}>‘연수는 엉터리인데, 엉뚱한 곳에서 정보를 얻네. 좋아, 아주 좋아.’</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“우리 행정실장님 불쌍해서 안 오려다가 왔는데, 덕분에 동지 한 명 더 만났네요. 앞으로 도울 일 있으면 서로 도웁시다.”</p>
      <p className={ss.quo}>“그래야죠. 근데 학교마다 학부모위원이 꼭 참석해야 되나 봐요?”</p>
      <p className={ss.quo}>“그렇죠. 방명록에 쓰인 이름 개수가 청중 수의 근거가 되잖아요. 인원이 많으면 강사에게 지급하는 강의료를 더 줄 수 있을걸요? 20%인가, 30%인가? 저 강사들 다 퇴직한 교장 나부랭이들인데, 강사료 더 챙겨주려면 꽉꽉 채워야죠. 그래서 지원청에서 학교를 쪼아대는 거죠. 학교야 지원청에 찍히면 불이익이 많으니까, 머릿수 채우려고 불쌍한 학부모한테 사탕발림해서 보내는 거예요. 재미도 없고, 내용도 없는, 그야말로 시간 낭비인 일인데.”</p>
      <p>어느새 강사는 마지막 장을 설명하고 있었다.</p>
      <p>&nbsp;</p>
      <p>그러나 아랑곳 않고, 만근 씨의 이야기는 계속되었다.</p>
      <p className={ss.quo}>“예전에 그 대머리 아저씨 때, 유명한 3S 정책이라고 알죠? 우민화 정책. 그게 학교에서도 똑같아요. 학부모를 바보로 만드는 거죠. 애들 성적도 좀 주고, 체육대회 같은 행사 때 다른 학부모는 땡볕에 서 있는데 운영위원이라고 교장 옆에 특별석에 앉혀주고, 운영위원회 회의 끝나면 밥이랑 술이랑 사고, ‘우리 위원님! 우리 위원님!’하면서 그냥 뭐 우쭈쭈 해주는 거예요. 그럼 또 골빈 여자들이 좋다고 헤헤거리고.”</p>
      <div className={ss.annotation_wrap}>
        <p>만근 씨는 엄마들의 소위 치맛바람을 극도로 싫어하는 사람이었다.</p>
        <p>그래서 여성을 비하하는 발언을 자주 내 뱉었다.</p>
        <p>이야기의 전개상 어쩔 수 없이 표현하기는 했지만, 독도 씨의 의견은 다르다는 것을 밝혀둔다.</p>
      </div>
      <p className={ss.quo}>“말하다 보니 또 짜증나네.”</p>
      <p>다소 흥분했는지, 만근 씨의 얼굴은 상기되어 있었다.</p>
      <p>&nbsp;</p>
      <p>그리고 이제 강의가 끝났다.</p>
      <p>강사의 인사와 함께 박수 소리가 났고, 닫혀 있던 강의실의 문이 열렸다.</p>
      <p>조진일 팀장이 독도 씨와 만근 씨가 있는 곳으로 걸어왔다.</p>
      <p className={ss.quo}>“어땠어요? 두 분한테는 별로였을 것 같은데.”</p>
      <p>싱글거리며 묻는 조진일 팀장에게는 감출 것이 없다는 투로 만근 씨가 대답했다.</p>
      <p className={ss.quo}>“말해 뭣해요. 제발 좀 이런 쓰레기 강의는 하지 말라고요.”</p>
      <p className={ss.quo}>“제가 무슨 힘이 있나요? 위에서 강사 찍어주고 하라고 하면 해야죠. 저도 힘들어요. 선수들끼리 좀 위로도 해주고 그래야지요. 저한테 너무 그러지 마세요.”</p>
      <p>조진일 팀장은 웃는 얼굴로 죽는 소리를 했다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“그럽시다. 뭐. 빤한 얘기는 그만하고, 밥이나 먹으러 갑시다. 요 앞 순대국 잘하는 집에 가요. 내가 순대국 쏠게.”</p>
      <p>만근 씨가 점심을 사겠다고 하자 조진일 팀장이 만류했다.</p>
      <p className={ss.quo}>“아이고, 무슨 말씀을. 여기는 제 나와바리인데 두 분을 제가 모셔야지요. 비싼 것은 못 사드려도 순대국은 제가 살 수 있습니다.”</p>
      <p>&nbsp;</p>
      <p>지난 수개월간 학교에서 있었던 일들은 답답함 그 자체였다.</p>
      <p>실망과 좌절과 분노를 일으키는 일들의 연속이었다.</p>
      <p>그런데 오늘, 독도 씨는 뜻하지 않은 만남을 통해 어떤 실마리를 찾았다.</p>
      <p>적어도 자신 혼자만 느낀 문제가 아니라는 것을 알게 된 것이다.</p>
      <p>여러 지역에서, 여러 사람이 문제를 인식하고 이의를 제기하고 있다는 것을 알게 되었다.</p>
      <p>&nbsp;</p>
      <p>이건 뭐, 할 만하잖아!</p>
      <p>어디 한번 가보자.</p>
      <p>최형식 위원에 이어서 만근 김대춘 씨가 독도 씨의 가슴에 희망의 불을 지폈다.</p>
      <p>&nbsp;</p>
      <p>특별히 달라진 것은 없었다.</p>
      <p>그저 몇 가지 정보를 얻었을 뿐이다.</p>
      <p>하지만, 독도 씨는 오로지 혼자 견뎌야할 시간이라고 생각했던 학교에서의 싸움이, 혼자 하는 싸움이 아니라는 것을 알게 되어 가슴이 벅차오르는 것을 느꼈다.</p>
      <p>눈물이 찔끔 나왔다.</p>
      <p>&nbsp;</p>
      <p>독도 씨는 창밖의 하늘을 올려다보며 조그맣게 중얼거렸다.</p>
      <p className={ss.quo}>“저 순대국 정말 좋아해요.”</p>
    </div>
  )
}

export default Year2015_23