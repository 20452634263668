import React from 'react'
import styles from './MainText.module.css'

const Year2015_05 = () => {
  return (
    <div className={styles.wrap}>
      <h1 className={styles.title}>5. 첫 번째 회의.</h1>
      <p className={styles.quo}>“첫 번째 안건으로 ‘학년별 현장체험학습 운영(안)’을 상정하겠습니다.”</p>
      <p>위원장이 의사봉을 3번 두드렸다.</p>
      <p className={styles.quo}>“본 건 설명은 담당선생님께서 해주시기 바랍니다.”</p>
      <p>&nbsp;</p>
      <p>운영위원회실 바깥에 대기하고 있던 교사 한 사람이 들어와서는 안건 설명을 시작했다.</p>
      <p className={styles.quo}>“안녕하세요? 예체능교육부장 한영택입니다. 이번 학기 학생들의 현장체험학습 운영계획에 대해 설명 드리겠습니다.”</p>
      <p>&nbsp;</p>
      <p>독도 씨가 어렸을 때는 소풍이라고 했는데, 요즘 학교는 현장체험학습이라는 용어를 사용한다.</p>
      <p>답답한 교실을 벗어나 학교 밖으로 하루 나가는 일이니, 용어가 달라졌어도 학생들이 좋아하기는 마찬가지일 것이다.</p>
      <p>담당교사의 설명에 따르면, 학년별로 가는 곳이 다른데 장소 결정은 현장체험학습 활성화 위원회에서 학생들의 안전, 교육적 효과, 선호도 등을 고려해서 복수의 후보 장소를 선정한 뒤, 학생들의 투표로 최종 결정했다고 한다.</p>
      <p>그리고 이어서 각자 부담해야할 1인당 소요 경비와 대중교통 이용 계획, 점심 식사 계획 등을 설명하였고, 안전사고 예방을 위한 사전 교육계획에 대해서도 설명을 하였다.</p>
      <p>&nbsp;</p>
      <p>담당교사의 안건 설명이 끝나자 운영위원장이 말했다.</p>
      <p className={styles.quo}>“네. 제안 설명 잘 들었습니다. 위원님들께서는 궁금하신 사항이나 의견이 있으시면 말씀해 주십시오.”</p>
      <p>&nbsp;</p>
      <p>안건의 설명을 들으며 문서를 다시 읽어보니, 인솔 계획에도 무리가 없었고 사전답사도 꼼꼼하게 했으며, 안전사고 예방을 위한 사전교육도 실시한다고 되어 있었다.</p>
      <p>다만, 학생들의 선호도가 제대로 반영된 것인지는 다소 의문이 있어 보였다.</p>
      <p>독도 씨 개인적으로는 왕릉이나 고궁 같은 곳도 체험학습 장소로서 좋은 곳이라 생각하지만, 대부분의 학생들이 역사적인 장소 보다는 놀이공원이나 공연장, 각종 테마파크를 더 선호할 텐데, 장소가 고궁으로 결정된 것은 애초에 선택지에 있는 후보 장소들이 제한적이었던 것은 아니었을까?</p>
      <p>독도 씨는 일단 가장 중요한 안전에 관한 부분을 살펴보기로 했다.</p>
      <p>&nbsp;</p>
      <p className={styles.quo}>“학생들이 활동하는 도중에 크고 작은 사고가 있을 수 있는데, 물론 아무 일이 없어야겠지만 혹시 경미한 상처 등이 발생했을 시에 대처할 수 있는 상비약이 든 응급상자 같은 것이 준비가 되었나요? 그리고 급히 병원에 가야할 상황이 발생했을 때 즉시 이송할 수 있는 방법이 준비되었나요? 또한 응급실을 운영하는 인근 병원을 확인해 두었는지요?”</p>
      <p>&nbsp;</p>
      <p className={styles.quo}>“각종 사건·사고 발생 유형에 따른 대처 방법에 대해서는 인솔교사나 학생 모두 사전교육을 받습니다. 응급 상황 발생 시에, 인솔교사가 대처하기 어려운 경우 경찰이나 119에 연락하고, 대처 가능할 경우 신속하게 직접 병원으로 데려갈 수 있도록 교육하고 있습니다. 인근 병원의 위치나 문의 가능한 전화번호는 인쇄하여 지참토록 할 예정입니다. 간단한 처치용 상비약은 보건교사와 의논하여 준비토록 하겠습니다.”</p>
      <p>&nbsp;</p>
      <p>독도 씨와 담당교사는 계속해서 질문과 답변을 주고받았다.</p>
      <p className={styles.quo}>“사건·사고 발생 유형에는 구체적으로 어떤 것이 있고 그 대처 방법은 어떤 것인지도 안건에 첨부해 놓으면 좋을 것 같습니다. 실제 교육 자료가 충실하게 작성되고 있는지를 파악하는 것도 중요한 심의 대상이라고 생각합니다.”</p>
      <p className={styles.quo}>“네. 알겠습니다. 다음부터는 그렇게 하겠습니다.”</p>
      <p>&nbsp;</p>
      <p>독도 씨의 질의가 끝나고 몇 초가 지나자 운영위원장이 좌중에 질문을 했다.</p>
      <p className={styles.quo}>“다른 질의할 내용이 있습니까?”</p>
      <p className={styles.quo}>“없습니다.”</p>
      <p>몇 몇 위원이 대답하자 운영위원장이 의사봉을 두드리며 말했다.</p>
      <p className={styles.quo}>“이의가 없으므로 ‘학년별 현장체험학습 운영(안)’은 원안대로 가결되었음을 선포합니다.”</p>
      <p>탕! 탕! 탕!</p>
      <p>&nbsp;</p>
      <p className={styles.quo}>“이어서 두 번째 안건으로 ‘학교급식소위원회 구성 및 규정(안)’을 상정하겠습니다.”</p>
      <p>위원장이 다시 의사봉을 3번 두드렸다.</p>
      <p className={styles.quo}>“본 건 설명은 담당선생님께서 해주시기 바랍니다.”</p>
      <p>&nbsp;</p>
      <p className={styles.quo}>“안녕하세요. 영양교사 우연숙입니다. 금년도 학교급식소위원회를 구성하고자 합니다. 우리 학교 급식소위원회는 학부모 2명과 교직원 2명, 그리고 학교운영위원회 위원 2명으로 구성됩니다. 따라서 운영위원님들 중에서 두 분을 추천해주시기 바랍니다.”</p>
      <p>다소 차가운 인상의 영양교사가 운영위원 2명을 추천해달라고 하였다.</p>
      <p>&nbsp;</p>
      <p>학교급식소위원회는 학교급식이 만족스럽게 운영될 수 있도록 지원과 감시를 하는 소위원회다.(소위원회는 학교운영위원회의 활동을 돕기 위해, 그 아래에 설치하는 조직을 말한다.)</p>
      <p>학교급식에서의 중요한 사항으로는 위생·안전관리, 영양관리, 운영관리 등이 있다.</p>
      <p>실무는 영양교사가 담당하고 주요 결정사항은 학교장의 결재에 의해 이루어진다.</p>
      <p>그리고 그 결정사항은 학교운영위원회의 심의를 거친다.</p>
      <p>가장 중요한 결정은 연간 급식운영계획으로, 학생들에게 공급할 영양관리기준, 급식 운영비용(식재료비, 인건비, 운영경비 등), 식재료 선정 기준 등을 정하고, 급식 만족도 조사 결과 분석을 통한 급식개선 방안 수립 등 학교급식의 목표(운영 방향)를 결정한다.</p>
      <p>이외에도 학교급식을 운영함에 있어서 크고 작은 다양한 결정사항이 존재하는데 이러한 결정과 실행 내용 등이 적절히 이루어지고 있는지 모니터링하고, 필요할 경우 제안활동을 하거나 지원활동을 할 수 있는 소위원회가 학교급식소위원회이다.</p>
      <p>&nbsp;</p>
      <p>학교급식은 학생들에게 있어서 생활인권(생활규정) 분야와 함께 학교생활의 만족도를 결정짓는 가장 중요한 요소의 하나이며, 성장기 자녀들의 먹거리인 만큼 학부모들에게도 매우 중요하게 여겨지는 부분이다.</p>
      <p>또한 단위 학교의 연간 예산에서 가장 큰 부분을 차지하고 있기 때문에 예산의 공정하고 올바른 집행이라는 측면에서도 학교급식의 합리적인 운영은 중요하며, 단지 먹는 행위일 뿐 아니라 (예절, 보건위생, 영양, 환경 등) 교육의 일환이므로, (교육급식이라는 용어를 사용할 만큼) 교육과정에 있어서도 학교급식은 중요한 부분을 차지하고 있다.</p>
      <p>학교급식에 관한 여러 가지 이슈나, 학교급식소위원회의 활동과 그 중요성에 대해서 독도 씨는 훗날 누구보다 더 열심히 이야기 하게 되지만, 한비중학교에서의 첫 해에는 모르는 것이 더 많았기에 분노와 좌절만 겪게 된다. 물론 앞으로 그가 어떤 좌절을 겪을지, 이제 첫 회의에 참석한 그로서는 짐작조차 할 수 없었다.</p>
      <p>&nbsp;</p>
      <p className={styles.quo}>“누구 하시고 싶은 분 있으실까요? 저는 이번에 맡은 위원회가 많아서……, 한 10개쯤 되는 것 같아요. 그래서 급식은 안하려고요.”</p>
      <p>윤미옥 위원장은 10개쯤 되는 위원회에 참여하고 있는 듯하다. 무슨 위원회가 그리 많을까?</p>
      <p className={styles.quo}>“제가 할게요. 이번 학부모총회 때 참석을 못했더니 올해는 맡은 위원회가 없네요.”</p>
      <p>작년에 이어서 올해로 2년차 학부모위원인 주영숙 위원이 손을 들었다.</p>
      <p className={styles.quo}>“급식소위원회가 제일 일이 많더라고요. 제가 보니까 부위원장님이 의욕이 충만하신 것 같은데 한번 해보세요. 보람 있는 일이에요.”</p>
      <p>윤미옥 위원장이 독도 씨를 추천했는데, 독도 씨가 느끼기엔 진심으로 추천하는 것이 아니라 왠지 비꼬는 듯했다. 아무래도 애송이 주제에 쓸데없는 질문(지역위원 선출 관련)을 해서 화기애애했던 분위기를 시작부터 싸늘하게 만든 것에 대한 불만이었을 것이라 짐작했다.</p>
      <p>&nbsp;</p>
      <p className={styles.quo}>“아, 그런가요? 제가 해보겠습니다.”</p>
      <p>독도 씨는 궁금한 것이 정말 많았기에, 뭐든 사양하는 것이 없었다. 부위원장 추천부터 급식소위원회 추천까지 다 수용했다.</p>
      <p>&nbsp;</p>
      <p className={styles.quo}>“그럼 운영위원 2명은 주영숙 위원님과 나독도 위원님으로 정합니다.”</p>
      <p>탕! 탕! 탕! 의사봉 소리와 함께 위원장의 음성이 이어졌다.</p>
      <p className={styles.quo}>“계속해서 ‘학교급식소위원회 규정(안)’에 대해 설명해주시죠.”</p>
      <p>&nbsp;</p>
      <p>영양교사가 다시 설명을 시작했다.</p>
      <p className={styles.quo}>“본교 ‘학교급식소위원회 규정(안)’의 상세한 내용은 나눠드린 문서를 참조해 주시면 되겠습니다. 주요 내용은 급식소위원회의 기능과 구성인데, 급식소위원회의 기능은 학교급식 식재료 검수, 조리 과정 등 위생 점검, 업체 선정방법 및 선정기준에 대한 의견 제시 및 현장 방문, 학교급식개선에 관한 활동을 하고 기타 학교급식과 관련하여 학교운영위원회에서 위임 결정한 사항을 진행하는 것입니다.”</p>
      <p className={styles.quo}>“네. 말씀 잘 들었습니다. 이와 관련하여 질의할 내용 있으십니까?”</p>
      <p>위원장은 위원들에게, 안건의 심의와 관련하여 필요한 내용을 질문하라고 하였다.</p>
      <p>&nbsp;</p>
      <p className={styles.quo}>“혹시 이 규정이 이전 규정과 다릅니까?”</p>
      <p className={styles.quo}>“아닙니다. 작년과 같습니다. 바뀐 내용은 없습니다.”</p>
      <p>독도 씨의 질문에 대한 영양교사의 답변을 듣고, 독도 씨는 이것도 좀 이상하다 생각이 되었다.</p>
      <p className={styles.quo}>“제가 사전에 안건을 읽어보았거든요. 규정안이……, 이것이 새로운 규정을 제정하는 건지 기존 규정을 개정하는 건지 표시가 안 되어 있더라고요. 부칙에는 ‘본 규정은 2015년 5월 1일부터 시행한다.’라고만 되어 있고, 이전 기록은 없고요.”</p>
      <p>영양교사는, ‘무슨 소리를 하려는 것일까?’라는 표정으로 독도 씨를 바라보고 있었다.</p>
      <p>&nbsp;</p>
      <p className={styles.quo}>“그래서 학교 홈페이지에서 학교 규정을 찾아보니까, 2011년에 제정된 「학교급식소위원회 규정」이 있더라고요. 당시 제정된 내용하고 이번에 올라온 규정안의 내용이 달라서 저는 이번 안건이 개정이라고 판단했어요.”</p>
      <p>독도 씨는 쉬지 않고 이야기를 이어나갔다.</p>
      <p className={styles.quo}>“또 제가 아무래도 처음이라서 모르는 게 많으니까, 작년 회의는 어떻게 진행되었는지 궁금해서, 학교 홈페이지에 있는 작년 운영위원회 회의록을 다운 받아서 읽어 보았는데요, 작년 첫 회의 때도 ‘학교급식소위원회 규정(안)’을 처리했더라고요. 안건 내용은 첨부가 안 되어 있어서 알 수 없지만. 어쨌든 2011년에 제정되었고, 몇 번인지는 모르겠지만 작년에도 개정되었고, 올해도 개정되는 구나라고 판단한거지요. 그런데 작년하고 바뀐 게 없다고요?”</p>
      <p>&nbsp;</p>
      <p className={styles.quo}>“네. 바뀐 게 없습니다. 홈페이지에 있는 규정은 말씀하신대로 처음 만들어진 내용이고요. 그 후로 한두 번 바뀐 것이라 알고 있습니다. 아마 2013년에 마지막으로 바뀌었던 것 같고, 작년에는 바뀐 게 없고, 올해도 바뀐 게 없습니다.”</p>
      <p>영양교사는 대수롭지 않게 답변했는데, 독도 씨는 순간 어처구니가 없는 일이라 생각했다.</p>
      <p className={styles.quo}>“아니, 규정이 바뀌지 않는데 왜 규정안을 심의하지요?”</p>
      <p className={styles.quo}>“매년 그래왔으니까요. 제 전임자도 그렇게 했고요.”</p>
      <p className={styles.quo}>“네? 그게 무슨…….”</p>
      <p>&nbsp;</p>
      <p>좌중에 잠시 침묵이 흐른 뒤, 위원장의 의사 진행 발언이 이어졌다.</p>
      <p className={styles.quo}>“다른 질의가 있습니까?”</p>
      <p className={styles.quo}>“없습니다.”</p>
      <p className={styles.quo}><span className={styles.black}>몇 몇 위원이 없다고 답변하자 위원장은,</span> “그럼 본 안건은 가결되었음을…….”</p>
      <p className={styles.quo}>“잠깐만요!”</p>
      <p>독도 씨가 황급히 위원장의 발언을 제지하였다.</p>
      <p className={styles.quo}>“규정이 바뀌지 않았으면 심의할 필요가 없는 것 아닌가요?”</p>
      <p className={styles.quo}>“심의하면 안 되는 것도 아니잖아요? 그냥 하면 되죠. 다른 문제가 있는 것도 아닌데 그렇게 까다롭게 할 필요가 있나요?”</p>
      <p>윤미옥 위원장의 말투에는 약간의 짜증스러움이 배어 나왔다.</p>
      <p>물론 다른 위원들의 표정도 곱지는 않았다.</p>
      <p>독도 씨는 입을 다물 수밖에 없었다.</p>
      <p>&nbsp;</p>
      <p className={styles.quo}>“자, 그럼 ‘학교급식소위원회 규정(안)’은 이의가 없으므로 원안대로 가결되었음을 선포합니다.”</p>
      <p>탕! 탕! 탕! 다시 한번 의사봉 소리가 회의실에 울려 퍼졌다.</p>
      <p>&nbsp;</p>
      <p className={styles.quo}>“이어서, ‘2014년도 학교회계 세입세출결산서(안)’을 상정하겠습니다.”</p>
      <p>위원장이 다시 한번 의사봉을 두드리며 의사 진행 발언을 이어나갔다.</p>
      <p className={styles.quo}>“본 건 제안 설명을 담당선생님께서 해주시기 바랍니다.”</p>
      <p>&nbsp;</p>
      <p>그러자 행정실장이 설명을 시작했다.</p>
      <p className={styles.quo}>“네. 행정실장 김미희입니다. 우선 결산서 1페이지에 내용 요약된 부분을 보시기 바랍니다. 2014년도 세입결산액은 14억9천5백9십5만3천3백5십 원이고, 세출결산액은 14억2천3백5십5만7천9백3십 원으로 세계잉여금은…….”</p>
      <p>&nbsp;</p>
      <p>보통의 학부모위원이 가장 어려워하는 심의가 학교회계의 예산과 결산 심의다.</p>
      <p>대부분의 다른 심의 안건은 읽으면 어떤 내용인지 알 수 있는데, 이 안건은 빽빽한 숫자의 나열로 이루어져 있어서 마치 암호를 대하는 것처럼 내용을 파악하기 어렵다.</p>
      <p>또한 행정에 익숙하지 않은 사람은 모르는 용어가 다수 등장하며, 사용해본 적 없는 양식이라 학부모의 눈에는 매우 생소하다.</p>
      <p>알고 보면, 예·결산 자료를 지나치게 간단하게 작성하기 때문에, 오히려 정보가 턱없이 부족하다는 지적을 해야 마땅한 일인데, 대부분의 학부모위원은 안건을 펼치는 순간 복잡하고 알 수 없는 내용으로 판단하고 처음부터 그냥 한 귀로 듣고 한 귀로 흘려보내고 만다.</p>
      <p>&nbsp;</p>
      <p>예산과 결산의 심의는 학교운영위원회의 가장 중요한 기능 중의 하나임에도 불구하고, 가장 형편없이 심의되고 있는 안건이다.</p>
      <p>오죽하면 진부도교육청 조례인 「학교운영위원회 설치·운영 조례」에서는 특별히 예·결산소위원회를 의무적으로 설치하라고 명시할 정도이다.</p>
      <p>훗날 독도 씨는 학교의 심각하고 많은 문제점의 근본 원인 중 하나가, 누군가(!) 학교의 자치 운영을 가로막고 독단 운영을 하는 것에서 비롯된다고 판단하게 되는데, 학교 운영을 독단으로 하는 이유 중 가장 강하고 결정적인 이유가 견제 없는 예산의 운용을 위한 것이라 보았다.</p>
      <p>그렇기 때문에 다른 심의의 형식적인 처리에도 필요하지만, 특히 예·결산 심의를 무력화하기 위해서는 학교운영위원회를 거수기로 만드는 것이, 그 누군가에게 있어서 학년 초에 첫 번째로 해야 할 가장 중요한 일일 것이라 생각하게 된다.</p>
      <p>참고로, 거수기는 손을 드는 기계란 뜻으로, 아무 의견 없이 시키는 대로 손을 드는 사람 혹은 단체를 말하며, 독재정치의 전형적인 방식인 형식적인 투표를 꼬집는 용어다.</p>
      <div className={styles.annotation_wrap}>
        <p>무조건 찬성하기 때문에 영어로는 ‘예스 맨’이라고도 한다.</p>
      </div>
      <p>&nbsp;</p>
      <p>행정실장이 인쇄된 용지의 첫 페이지에 있는 숫자들을 하나하나 읽는 동안 독도 씨는 며칠 전 정보 검색을 통해 알아보았던 예·결산소위원회에 대해 떠올려 보았다.</p>
      <p>&nbsp;</p>
      <p>이 소위원회는 진부도교육청의 조례에 의해, 진부도의 학교에서는 의무적으로 구성하게 되어 있다.</p>
      <p>조례에서 그렇게 정한 이유는 첫째, 예산과 결산의 심의는 짧은 시간에 이루어지기 어렵기 때문에, 운영위원회에서 심의하기 전에 미리 소위원회에서 충분한 시간을 가지고 심사하여, 본 회의에서 충실한 심의가 이루어지도록 하기 위함이다.</p>
      <p>둘째, 학교 예산의 대부분은 학생들을 위해 사용되는 바, 예산을 운용하는데 있어서 학생들의 의견을 적극적으로 반영하는 것이 필요하므로, 예·결산의 심의에 학생대표의 참여가 권장되는데, 학생은 운영위원이 될 수 없으므로 소위원회에 소속되어 심사에 참여할 수 있게 하기 위함이다.</p>
      <p>셋째, 운영위원의 전문성을 확보하기 어려울 경우, 외부 전문가를 소위원회에 참여할 수 있게 하여 심의의 질을 높이기 위함이다.</p>
      <div className={styles.annotation_wrap}>
        <p>운영위원회는 소위원회의 사전 심사를 바탕으로 본 회의에서 심의를 한다.</p>
        <p>심사, 심의, 의결의 차이를 구분하는 것도 앞으로 독도 씨가 겪게 될 주요 이슈 중의 하나이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>또한 소위원회 활동에 무리가 없도록, 운영위원회 심의에 필요한 기간도 넉넉하게 보장한다.</p>
      <p>진부도교육청의 ‘학교회계 예산편성 기본지침’을 보면, 예산안은 회계연도 개시일(매년 3월 1일)의 30일 전까지 운영위원회에 제출하게 되어 있고, 결산안도 4월 30일 이전에 학교운영위원회에 제출하여, 5월 31일까지 심의하게 되어 있다.</p>
      <p>즉, 예·결산의 심의에 최소 1개월 이상의 기간이 주어지는 것이다.</p>
      <p>특히 결산의 경우는 학교회계의 출납폐쇄가 3월 20일에 이루어지므로, 운영위원회가 (3월 하순에) 구성되고 4월 초에 첫 회의를 개최하면 (이때 결산안을 상정하게 되면) 약 2개월의 심의 기간을 확보할 수 있다.</p>
      <p>&nbsp;</p>
      <p className={styles.black}>독도 씨는 결산서를 보면서, <span className={styles.quo_mini}>‘어렵다. 심의를 위해 뭘 봐야하는지를 모르겠어. 인터넷을 찾아봐도 정보가 없던데……. 아무래도 예·결산소위원회에 참여해서 외부 전문가를 위촉하자고 하고 좀 배워야겠다.’</span>라고 생각을 했다.</p>
      <p>&nbsp;</p>
      <p>잠시 후 행정실장의 설명이 끝나자, 위원장이 말했다.</p>
      <p className={styles.quo}>“네, 설명 잘 들었습니다. 위원님들 궁금하신 사항이나 의견 있으시면 말씀해 주십시오.”</p>
      <p>&nbsp;</p>
      <p className={styles.quo}>“결산은 예·결산소위원회에서 먼저 심사하고 본 회의에서 그 내용을 듣고 심의하는 거죠?”</p>
      <p>이번에도 독도 씨가 가장 먼저 질문을 했고, 대답은 행정실장이 했다.</p>
      <p>&nbsp;</p>
      <p className={styles.quo}>“아니오. 예·결산소위원회를 구성할 예정이지만, 학교운영위원회에서 결산 심의와 관련한 업무를 위임하지 않았기 때문에, 소위원회에서 심사하지 않고 바로 본 회의에서 심의합니다.”</p>
      <p className={styles.quo}>“네? 업무를 위임하지 않았다고요? 그건 무슨 뜻인가요?”</p>
      <p className={styles.quo}>“말 그대로입니다. 운영위원회에서 소위원회로 결산 심사를 해달라고 위임을 해야 소위원회에서 심사를 하고 결과보고서를 운영위원회에 제출합니다. 그러면 그 결과보고서를 토대로 운영위원회에서 심의를 합니다. 그런데 운영위원회에서 위임하지 않고 직접 심의하기로 되어 있어서 소위원회 심사는 생략된 겁니다.”</p>
      <p>행정실장의 답변은 명료했으나 좀 딱딱한 느낌을 주었다.</p>
      <p>&nbsp;</p>
      <p className={styles.quo}>“무조건 소위원회를 거치는 것으로 알았는데, 그런 게 아니가보군요.”</p>
      <p className={styles.quo}>“네. 그건 운영위원회에서 결정하기 나름입니다. 작년 운영위원회에서 위원님들이 위임하지 않기로 결정했습니다.”</p>
      <p className={styles.quo}>“음……. 예·결산소위원회를 구성하라고 조례에 명시한 것은, 그만큼 예산과 결산의 심의가 중요하고, 또 시간이 많이 걸리는 일이라서 소위원회를 거치라는 뜻이라고 알고 있습니다. 위임을 하지 않아도 된다는 것도, 실제로 작년 위원들이 위임하지 않았다는 것도 잘 이해가 가지 않습니다.”</p>
      <p>독도 씨는 뭔가 석연치 않다는 표정을 지었다.</p>
      <p>&nbsp;</p>
      <p>이때 그동안 침묵을 지키던 김봉식 교감이 말했다.</p>
      <p className={styles.quo}>“규정이 그렇습니다. 본교 「예·결산소위원회 규정」에 의하면 ‘위임한 경우에 심사한다.’고 되어 있습니다. 위임하지 않아도 됩니다. 조례에도 반드시 소위원회의 심사를 거쳐야한다는 문구는 없습니다.”</p>
      <p className={styles.quo}>“알겠습니다. 그럼 결산 심의하기 전에 위임 여부를 먼저 결정해야겠네요?”</p>
      <p>독도 씨의 계속 이어지는 질문에 다시 행정실장이 대답했다.</p>
      <p className={styles.quo}>“이미, 작년 위원님들이 예산과 결산 업무를 위임하지 않기로 결정했으므로, 작년도 결산안은 위임하지 않습니다.”</p>
      <p>&nbsp;</p>
      <p className={styles.quo_mini}>‘어라? 작년 결정을 따른다고?’ <span className={styles.black}>순간적으로 독도 씨는 </span>‘이건 잘못되었다.’ <span className={styles.black}>고 생각했다.</span></p>
      <p>&nbsp;</p>
      <p className={styles.quo}>“아니죠. 작년 위원들의 임기는 이미 끝났고, 작년도 결산안이지만 올해 심의하는 것이니까 올해 위원들의 의견을 들어야지요.”</p>
      <p>&nbsp;</p>
      <p>독도 씨가 행정실장의 발언에 반론을 제기하였는데, 5년차를 비롯하여 2년차, 3년차 학부모위원들은 아마도 ‘이런 일’을 처음 겪는 것 같았다.</p>
      <p>그동안 교장, 교감과 행정실장에 의해 잘 운영되어 왔던 학교운영위원회인데, 웬 초보 운영위원이 나타나서는 회의의 시작부터 지금까지 계속해서 문제 제기를 하고 있지 않은가!</p>
      <p>그들의 표정에는 불편한 심기가 그대로 노출되어 있었다.</p>
      <p>&nbsp;</p>
      <p className={styles.quo}>“제 생각에도, 작년에 예산과 결산 모두 위임하지 않기로 했으니까, 작년 결산안도 위임하지 않는 것이 맞다 봅니다. 여러분들 생각은 어떤가요?”</p>
      <p>윤미옥 위원장이 행정실장의 의견에 맞장구를 치자, 다른 학부모위원들이 답을 했다.</p>
      <p className={styles.quo}>“저도 그렇다 생각합니다.”</p>
      <p>이런 유형의 논쟁은 다수결로 결정하는 것이 아니라 규정의 해석에 따라야 하는 것인데, 위원장은 표결로 결정할 모양이었다.</p>
      <p>&nbsp;</p>
      <p className={styles.quo_mini}>‘어차피 지금 위임 여부를 결정해도 모두 위임하지 않을 테니, 더 따질 필요가 없겠구나.’</p>
      <p>독도 씨는 이번 결산안과 관련해서는 더 이상 예·결산소위원회 이야기를 하지 않기로 했다.</p>
      <p>&nbsp;</p>
      <p className={styles.quo}>“자, 다른 질의 사항은 없습니까?”</p>
      <p className={styles.quo}>“없습니다.”</p>
      <p className={styles.quo}>“그럼 ‘2014학년도 학교회계 세입세출결산서(안)’은 원안대로 가결되었음을 선포합니다.”</p>
      <p>탕! 탕! 탕!</p>
      <p>&nbsp;</p>
      <p>단 하나의 질문도 없이 결산 심의가 끝났다!</p>
      <p>&nbsp;</p>
      <p>독도 씨는, 열심히 검색해보고 공부한 결과, 결산 심의는 학교운영위원회의 심의 업무 중에서 가장 중요한 것의 하나라고 알게 되었는데, 질문 하나 없이 이렇게 허무하게 심의가 끝난 것에 매우 큰 충격을 받았다.</p>
      <p className={styles.quo_mini}>‘나는 그렇다 치고, 다른 사람들도 아무 것도 모르는 것일까? 아니면 질문이 하나도 없어도 이상하지 않을 정도로 완전한 것일까?’</p>
      <p>&nbsp;</p>
      <p className={styles.quo}>“네 번째 안건으로 ‘예·결산소위원회 구성(안)’을 상정하겠습니다.”</p>
      <p>&nbsp;</p>
      <p>위원장의 의사 진행 발언에 이어, 담당자인 행정실장의 설명이 다시 시작되었다.</p>
      <p className={styles.quo}>“네, 진부도교육청 「학교운영위원회 설치‧운영 조례」에 의거, 예·결산소위원회를 구성해야 합니다. 구성은 본교 「예·결산소위원회 규정」에 따라 5명으로 하고, 운영위원회에서 의결로 결정합니다. 운영위원, 교직원, 학부모, 학생 대표, 외부 전문가 중에서 5명을 위촉하되, 학부모위원이 반드시 1명 이상 포함되어야 합니다.”</p>
      <p>&nbsp;</p>
      <p className={styles.quo}>“제가 하겠습니다.”</p>
      <p>이번에는 독도 씨가 먼저 손을 들었다.</p>
      <p className={styles.quo}>“음. 저도 할게요.”</p>
      <p>다른 10여 개의 위원회에 위촉되어 있어서 학교급식소위원회 참여는 곤란하다던 윤미옥 위원장이 예·결산소위원회에는 참여하겠다고 했다. 왜 그랬을까?</p>
      <p>&nbsp;</p>
      <p className={styles.quo}>“교직원 중에서는 어떤 분을 위촉하는 것이 좋을까요?”</p>
      <p>윤미옥 위원장의 질문에 김봉식 교감이 빠르게 대답했다.</p>
      <p className={styles.quo}>“아무래도 제가 하는 것이 낫겠지요? 다른 선생님들은 모두 바쁘니까 덜 바쁜 제가 하는 것이 좋겠네요.”</p>
      <p>&nbsp;</p>
      <p className={styles.quo}>“외부 전문가가 한 분 참여했으면 좋겠습니다. 학생 대표도요.”</p>
      <p>독도 씨가 의견을 제시하자, 지금까지 발언이 없었던 최형식 지역위원이 나섰다.</p>
      <p className={styles.quo}>“아무래도 은행 업무를 봐 왔던 제가 다른 분들보다는 전문적이라 생각됩니다. 전문가가 꼭 외부인이어야 하는 것은 아니죠?”</p>
      <p className={styles.quo}>“그렇죠. 잘 됐네요. 최형식 위원님이 하시면 딱 이네요. 그럼 전교 학생회장까지 포함해서 5명으로 결정하면 될 것 같습니다. 여러분들의 의견은 어떠신가요?”</p>
      <p>위원장이 묻자 위원들이 대답했다.</p>
      <p className={styles.quo}>“찬성입니다.”</p>
      <p>&nbsp;</p>
      <p>이어서 행정실장이 심사 업무 위임 여부를 물었다.</p>
      <p className={styles.quo}>“네, 그럼 구성은 됐고, 좀 전에 말했듯이 앞으로 심의하게 될 올해 예산과 결산에 대해, 소위원회에 심사 업무를 위임할 것인지 결정해야 합니다.”</p>
      <p>그러자 위원장이 좌중을 향해 물었다.</p>
      <p className={styles.quo}>“여러 위원님들의 생각은 어떤가요?”</p>
      <p>&nbsp;</p>
      <p>이번에는 김봉식 교감이 먼저 말을 꺼냈다.</p>
      <p className={styles.quo}>“소위원회에서 심사를 하고 본 회의에서 심의를 해도, 본 회의에서 바로 심의를 하는 것과 아무 차이가 없습니다. 괜히 소위원회를 열게 되면 소위원회 위원들 시간만 뺏는 것입니다. 여기 위원님들, 1년에 몇 차례 되지 않는 운영위원회도 바쁘셔서 전부 출석하기 어려워하십니다. 학교에 이런 저런 위원회가 너무 많아서 위원회 구성이 어렵기에 교사들이나 학부모님들 한 사람이 여러 위원회에 참여하는 경우가 많은데, 보통 시간 뺏기는 일이 아닙니다.”</p>
      <p>교감의 말이 계속 이어졌다.</p>
      <p className={styles.quo}>“이렇게 시간을 뺏기는 것은 학부모님들께도 괴로운 일이겠지만, 교사들에게도 여간 힘든 일이 아닙니다. 불필요한 회의는 가급적 줄이는 게 좋지 않을까요?”</p>
      <p>&nbsp;</p>
      <p>독도 씨가 말했다.</p>
      <p className={styles.quo}>“어째서 불필요한 회의라는 것인가요? 아까도 말씀드렸지만, 예·결산 심의는 중요하고 어렵기 때문에 특별히 소위원회 구성을 의무로 하는 것이라고 들었습니다. 저는 솔직히 결산서를 볼 줄 몰라서 질문을 하고 싶어도 할 수가 없었습니다. 그런데 다른 위원님들은 어떤가요? 오늘 보니까 결산서 내용에 대해 질문을 하나도 하지 않고 결산 심의가 끝났습니다. 여러분들은 결산서의 내용을 잘 아십니까?”</p>
      <p>&nbsp;</p>
      <p>그러자 3년차 학부모위원인 안미자 위원이 날카로운 목소리로 대답했다.</p>
      <p className={styles.quo}>“말씀이 참 불편하게 들립니다. 저도 솔직히 잘 몰라요. 하지만 뭐 학교에서 알아서 잘 했겠지요. 전 선생님들을 믿습니다. 그동안 아무 문제가 없었어요.”</p>
      <p>하지만 독도 씨도 물러서지 않았다.</p>
      <p className={styles.quo}>“문제가 없었으리라고 저도 믿고 싶습니다. 하지만 문제가 있는데 발견하지 못했을 수도 있습니다. 선생님들도 사람인데 완벽할 수는 없겠지요. 아무리 믿는다고 해도 우리가 운영위원이라면 우리 업무인 심의를 충실하게 해야지요. 본 회의에서 할 수 있다면 저도 심사 업무를 위임할 필요가 없다고 생각합니다. 그러나 제 생각엔 자료도 부족한 것 같고, 전문 지식도 부족하므로 꼼꼼하게 살펴보려면 시간이 많이 필요할 것 같습니다. 오늘처럼 본 회의에서 심의하는 것은 무리라고 생각됩니다.”</p>
      <p>&nbsp;</p>
      <p>독도 씨에게는, 오늘 처음 보는 이 사람들이 모두 벽이라 느껴졌지만, 이 이슈만큼은 물러서면 안 될 것 같다고 생각했다.</p>
      <p>그런데…….</p>
      <p>&nbsp;</p>
      <p className={styles.quo}>“이런 말씀 드려서 죄송합니다만, 제가 보기엔 결산서의 내용이 부실합니다.”</p>
      <p>느닷없이 누군가 발언을 시작했다.</p>
      <p>독도 씨의 왼쪽에서 들려오는 목소리의 주인공은 최형식 위원이었다.</p>
      <p className={styles.quo}>“예산액과 결산액의 차액만 표시되어 있고 차액이 발생된 이유에 대해서는 설명하는 자료가 없습니다. 물론 사용된 금액이 본래 예산의 항목에 맞게 사용되었는지를 확인할 수 있는 세부 내역도 표시되어 있지 않습니다. 이렇게 결산하면 안 됩니다. 솔직히 너무 부실해서 질문하지 않았습니다.”</p>
      <p>&nbsp;</p>
      <p>의외였다. 지역위원인 최형식 위원이 비판적인 발언을 하였다.</p>
      <p>교장과 교감은 물론이고, 독도 씨와 최형식 위원을 제외한 모든 사람들의 표정이 딱딱하게 굳어졌다.</p>
      <p>최형식 위원은 은행의 지점장이다.</p>
      <p>예·결산에 관한 지식이 없는 위원들이, 숫자(돈)를 관리하는 일에 전문적인 사람의 말에 토를 달기는 어려웠다.</p>
      <p>독도 씨가 아무리 그럴싸한 근거를 들이밀어도 <span className={styles.quo_mini}>'학교를 믿고, 교장을 믿는다.'</span>는 사람들을 설득하는 것은 불가능에 가깝다.</p>
      <p>하지만 전문가의 한마디에는 별다른 근거를 요하지 않았다.</p>
      <p>&nbsp;</p>
      <p className={styles.quo}>“제가 보기엔, 부위원장님 말씀대로 소위원회에서 심사를 하는 것이 좋겠습니다. 꼼꼼하게 검토하려면 몇 분 정도의 시간으로는 어림도 없습니다. 혼자서 검토할만한 양도 아니고요. 추가 자료를 요청하거나 질의가 필요할 수 있어서 학교에 모여서 해야지요. 마침 저도 소위원회에 참여하기로 했으니 잘 해보겠습니다.”</p>
      <p>최형식 위원이 쐐기를 박는 한마디를 던졌고, 반대 의견이 더 나오지 않자 떨떠름한 표정의 위원장이 말했다.</p>
      <p className={styles.quo}>“다른 의견 있으십니까?”</p>
      <p>다른 의견은 없었다.</p>
      <p className={styles.quo}>“그럼, 다수의 위원님들이 위임을 원하시므로 위임하도록 하겠습니다.”</p>
      <p>그렇게 ‘예·결산소위원회 구성(안)’은, 5명의 소위원회 위원을 위촉하고 심사 업무를 위임하는 것으로 결정되었다.</p>
      <p>&nbsp;</p>
      <p>앞서 자기소개 때 밝힌 바 있듯이, 최형식 위원도 운영위원을 처음 하는 것이라 했다.</p>
      <p>그는 보편적인 학교에서의 결산 심의가 ‘왜 엉터리로 이루어져야만 하는지’ 몰랐기 때문에, 잘 해보고 싶은 마음에 제대로(?) 된 발언을 했던 것이었다.</p>
      <p>그러나 교장의 편에 서라고 교장이 선출한 지역위원인데, 잘 해보고 싶은 마음을 가져서는 안 되는 것이었다.</p>
      <p>만일 그가 경험이 많았더라면 이날처럼 교장의 심기를 불편하게 하는 말은 하지 않았을 것이다.</p>
      <p>아마도 그랬다면 다음 해에도 교장은 그를 지역위원으로 추천하였을 것이다,</p>
      <div className={styles.annotation_wrap}>
        <p>물론 그러지 않았기 때문에 2016년에 교장은 최형식 위원을 추천하지 않았다.</p>
        <p>그런데 재밌게도, 독도 씨가 그를 지역위원으로 추천하였고, 투표를 통해 선출된 최형식 위원은 한비중학교의 지역위원으로 1년 더 활동하였다.</p>
      </div>
      <p>&nbsp;</p>
      <p>훗날 여러 이야기를 통해, 이날의 일은 최형식 위원의 정치적 선택이 아닌 순수한 합리적 선택에 따른 우발적인 행동임이 밝혀졌다.</p>
      <p>그런데 이날의 일은, 독도 씨에게는 캄캄한 어둠 속에 희미한 빛을 내는 한 마리의 작은 반딧불이와 같았다.</p>
      <p>이날 이후 계속해서 바다 위 고립된 섬과 같이 힘들었던 독도 씨에게는, 끝까지 포기하지 않고 어둠을 헤쳐 나갈 수 있게 하는 용기와, 새벽을 기다릴 수 있게 하는 인내를 품게 해준 소중한 희망의 씨앗이었다.</p>
      <p className={styles.bold}>자신만의 탐욕을 추구하는 잘못된 이기심을 버릴 수 있다면, 대부분의 사람들은 거수기가 아닌 운영위원으로서 합리적인 판단을 할 수 있을 것이라는, 그런 희망이었다.</p>
    </div >
  )
}

export default Year2015_05