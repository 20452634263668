import React from 'react'
import ss from './MainText.module.css'

const Year2015_29 = () => {
  return (
    <div className={ss.wrap}>
      <h1 className={ss.title}>29. 학교 홈페이지 운영의 문제.</h1>
      <p className={ss.now_on_anvil}></p>
    </div>
  )
}

export default Year2015_29