import React from 'react'
import ss from './MainText.module.css'

const Year2015_14 = () => {
  return (
    <div className={ss.wrap}>
      <h1 className={ss.title}>14. 교육지원청의 전화(1).</h1>
      <div className={ss.talk}>
        <p className={ss.t1}>교장 선생님, 부종교육지원청 조진일 팀장입니다.</p>
        <p className={ss.t2}>아, 조 팀장님. 오랜만입니다.</p>
        <p className={ss.t2}>웬일이세요?</p>
        <p className={ss.t1}>그간 잘 지내셨죠?</p>
        <p className={ss.t1}>아직 4월인데 벌써 날씨가 좀 더워진 것 같아요.</p>
        <p className={ss.t2}>그러게요.</p>
        <p className={ss.t2}>봄이 점점 짧아지는 것 같아서 아쉽네요.</p>
        <p className={ss.t1}>학교에는 별일 없죠?</p>
        <p className={ss.t2}>그럼요. 별일 없습니다.</p>
        <p className={ss.t2}>왜요? 무슨 민원이라도 접수되었나요?</p>
        <p className={ss.t1}>귀신이시네. 하하하.</p>
        <p className={ss.t2}>아이고, 정말 민원이에요?</p>
        <p className={ss.t2}>무슨 민원인데요?</p>
        <p className={ss.t2}>꽤 오랫동안 조용했는데.</p>
        <p className={ss.t1}>나독도 씨라고 학부모 운영위원인 분, 아시죠?</p>
        <p className={ss.t2}>나독도 씨라…….</p>
        <p className={ss.t2}>아! 네. 알죠. 이번에 운영위원 선출된 분인데,</p>
        <p className={ss.t2}>그런데 그 분이 왜요?</p>
        <p className={ss.t1}>도교육청에 민원을 접수하셨어요.</p>
        <p className={ss.t1}>지역위원 선출 절차에 관해 문의를 했더라고요.</p>
        <p className={ss.t2}>네? 그래요?</p>
        <p className={ss.t2}>뭐라고 문의했는데요?</p>
        <p className={ss.t1}>교장선생님, 지역위원을 안내 없이 혼자 추천하셨어요?</p>
        <p className={ss.t1}>그거 위법 아니냐고 문의했습니다.</p>
        <p className={ss.t2}>앗! 그래요?</p>
        <p className={ss.t2}>안 그래도 지난번에 물어보더라고요.</p>
        <p className={ss.t2}>좀 찜찜하긴 했어요.</p>
        <p className={ss.t2}>그냥 넘어갈 줄 알았더니 기어이…….</p>
        <p className={ss.t1}>아니, 그러시면 안 되는데 왜 그러셨어요?</p>
        <p className={ss.t1}>이거 작은 일 아니에요.</p>
        <p className={ss.t1}>절차 잘 아시잖아요?</p>
        <p className={ss.t2}>아니, 그게……, 우리 학부모들이 다들 협조적이니까,</p>
        <p className={ss.t2}>작년에도 그랬고, 그래서 별 생각 없이 했는데,</p>
        <p className={ss.t2}>올해 갑자기 그 분이 들어와서 따지네요.</p>
        <p className={ss.t2}>아니 참 이상한 사람이네. 그걸 왜 교육청에…….</p>
        <p className={ss.t1}>아이고, 대개는 모르고 넘어가지만,</p>
        <p className={ss.t1}>따지는 사람이 가끔 있어요.</p>
        <p className={ss.t1}>그래서 저희가 조심하라고 몇 번이나 말씀 드렸는데,</p>
        <p className={ss.t1}>아 답답하시네, 어쩌시려고.</p>
        <p className={ss.t2}>어휴, 생각도 못했네요.</p>
        <p className={ss.t2}>일이 이렇게 될 줄은.</p>
        <p className={ss.t2}>도교육청에서는 뭐라고 하던가요?</p>
        <p className={ss.t1}>그 분이 다행히 학교를 밝히지 않고 문의를 했어요.</p>
        <p className={ss.t1}>그래서 답변만 하고 별다른 조치를 취할 필요가 없었대요.</p>
        <p className={ss.t1}>학교를 밝혔다면, 조사해야하니까 다들 곤란해지는 거죠.</p>
        <p className={ss.t1}>그나마 다행이에요.</p>
        <p className={ss.t2}>아, 그렇군요. 다행이네요.</p>
        <p className={ss.t2}>그럼 그것으로 끝인가요?</p>
        <p className={ss.t1}>아니죠.</p>
        <p className={ss.t1}>그 분이 만일 학교를 밝히고 다시 민원을 넣으면 문제가 되죠.</p>
        <p className={ss.t1}>어느 학교라는 것을 알면서도 조사하지 않을 수는 없으니까요.</p>
        <p className={ss.t1}>그래서 도교육청에서 저한테 전화를 한거에요.</p>
        <p className={ss.t1}>잘 막아보라고.</p>
        <p className={ss.t1}>이제는 제가 막아야 하는 일이 된 거죠.</p>
        <p className={ss.t1}>어떻게 될지 모르겠는데……, 일단 잘 설득을 해봐야죠.</p>
        <p className={ss.t1}>그냥 넘어가면 다행인데, 안 그러면 이거 문제 됩니다.</p>
        <p className={ss.t2}>아이고, 그러네요.</p>
        <p className={ss.t2}>여기서 끝낼 수 있게 잘 설득해야겠네요.</p>
        <p className={ss.t2}>죄송합니다.</p>
        <p className={ss.t2}>조 팀장님이 잘 좀 해결해 주세요.</p>
        <p className={ss.t1}>글쎄요. 장담할 순 없지만 노력은 해야죠.</p>
        <p className={ss.t1}>좀 있다가 제가 그분한테 전화를 할 예정인데,</p>
        <p className={ss.t1}>교장선생님이 아셔야겠다 싶어서 먼저 전화 드린 겁니다.</p>
        <p className={ss.t2}>네. 감사합니다. 알려 주셔서.</p>
        <p className={ss.t2}>잘 해결되었으면 좋겠네요.</p>
        <p className={ss.t2}>그런데 학교를 안 밝혔다면서요?</p>
        <p className={ss.t2}>우리 학교인건 어떻게 아셨대요?</p>
        <p className={ss.t1}>그건……, 접수 지역하고 민원인 이름이 뜨잖아요.</p>
        <p className={ss.t1}>도교육청에서 부종시 학부모란 걸 안거죠.</p>
        <p className={ss.t1}>그래서 부랴부랴 저한테 연락한거에요.</p>
        <p className={ss.t1}>저는 이번에 각 학교별로 운영위원 선출된 분들 이름 다 대조해봤고요.</p>
        <p className={ss.t1}>그래서 한비중학교라는 걸 알게 된 거죠.</p>
        <p className={ss.t2}>아. 역시 다 아는 수가 있군요.</p>
        <p className={ss.t1}>뭐……, 경우에 따라서는 그렇죠.</p>
        <p className={ss.t1}>어쨌든 그런 일이 있었다는 것 알고 계시고요.</p>
        <p className={ss.t1}>제가 잘 무마해볼 테지만, 혹시 문제가 될 수도 있으니 각오하시고요.</p>
        <p className={ss.t1}>어찌되든 앞으로 절차는 꼭 잘 지키시고요.</p>
        <p className={ss.t2}>네. 네. 그렇게 하겠습니다.</p>
        <p className={ss.t2}>꼭 좀 잘 해결해 주세요.</p>
        <p className={ss.t1}>그리고 혹시 그 분이 원하면 면담 주선할게요.</p>
        <p className={ss.t1}>교장선생님이 잘못 인정하고 사과하시면 좋을 것 같아요.</p>
        <p className={ss.t1}>무조건 실수라고 하셔야 해요.</p>
        <p className={ss.t1}>앞으로는 절차대로 잘하겠다고 말씀하시면 됩니다.</p>
        <p className={ss.t2}>네. 물론 그래야지요.</p>
        <p className={ss.t2}>그렇게 해주세요.</p>
        <p className={ss.t1}>그래요.</p>
        <p className={ss.t1}>그럼 수고하시고요.</p>
        <p className={ss.t1}>조만간 한번 찾아뵙겠습니다.</p>
        <p className={ss.t2}>네. 언제든지 환영입니다.</p>
        <p className={ss.t2}>차 한 잔 하러 꼭 오세요.</p>
        <p className={ss.t2}>저는 조 팀장님만 믿겠습니다.</p>
        <p className={ss.t2}>감사합니다.</p>
        <p className={ss.t1}>네. 감사합니다.</p>
      </div>
      <p>&nbsp;</p>
      <p>진부도교육청 산하 부종시교육지원청의 대외협력팀 조진일 팀장의 업무 중에는 각 학교의 학교운영위원회 관련 업무를 지원하는 일이 포함되어 있다.</p>
      <p>학부모 운영위원의 연수를 기획하고 주관하는 일을 비롯하여, 운영위원회 관련 현안에 대한 규정의 해석과 지원, 각종 문제 해결 등을 한다.</p>
      <p>따라서 교육지원청 내에서는 운영위원회 관련 규정(법령)에 대해 가장 잘 아는 위치에 있으며, 부종시 내 100여개에 이르는 초·중·고등학교 운영위원회의 동향을 정기적으로 파악하여 수집하고 있다.</p>
      <p>미리 밝혀두지만, 그는 학교운영위원회가 어떤 역할을 해야 하는지, 또 현재의 모습은 어떠한지, 무엇이 문제인지 나름 합리적인 기준으로 판단하는 사람이다.</p>
      <p>누군가 운영위원회의 문제점을 말하면, 그는 심각한 표정을 지으며 곧잘 동의하는 편이다.</p>
      <p>무조건 아니라고 발뺌하거나 모르쇠로 일관하는 다른 교육공무원들에 비하면 훨씬 낫다고 할 수 있다.</p>
      <p>하지만 그도 공무원의 한계를 벗어나지 못하기에, 이를 개혁하기 위한 적극적인 노력은 하지 않는다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“조 팀장님, 운영위원회 연수 내용을 확 바꿉시다. 저희가 커리큘럼 만들어 드릴게요.”</p>
      <p className={ss.quo}>“아시잖아요. 불가능하다는 거. 저는 힘없는 사람이에요. 어차피 안 되는 거 옷까지 벗을 수는 없습니다.”</p>
      <p>훗날 그는 독도 씨를 비롯한 부종교육포럼의 회원들과 소통을 자주 하였는데, 운영위원 연수가 이대로는 안 된다는 것을 동감하면서도, 본인 업무인 운영위원 연수 내용을 본인 마음대로 바꿀 수는 없다고 말했다.</p>
      <p>&nbsp;</p>
      <p>사실, 딱 한 번 시도한 적은 있었다.</p>
      <p className={ss.quo}>“이번 연수에 살짝, 진짜 살짝, 학교장과 학교운영위원회가 독립적인 기구라는 말의 의미를 설명하고, 학교 운영과 관련하여 학부모가 안건을 발의할 수 있다는 내용 딱 하나 집어넣었는데도, 그날 저녁부터 이튿날까지 저 엄청 시달렸어요. 사방에 눈과 귀가 있잖아요. 어떻게 그렇게 빨리……. 어휴, 교장선생님들한테서 항의 전화가 걸려오고……. 너무 힘듭니다. 일단 이정도로 만족합시다. 이것만 해도 정말 크게 한 발 나간 겁니다.”</p>
      <p>그 작은 시도가 처음이자 마지막 시도였는데, 이와 관련한 이야기는 자세한 설명이 필요한 관계로 나중에 시간 순서에 맞춰 하겠다.</p>
      <p>하지만 눈치가 빠른 사람이라면, 이미 중요한 사실을 깨달았을 것이다.</p>
      <p>교육지원청에서 실시하는 운영위원 연수가 끝나면, 그 내용이 지역 내 학교장들의 귀에 들어간다는 사실을 말이다.</p>
      <p>그리고 운영위원 연수가 왜 형식적일 수밖에 없는지, 누가 형식적이기를 원하는지도 말이다.</p>
    </div>
  )
}

export default Year2015_14