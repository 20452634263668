import React from 'react'
import ss from './MainText.module.css'

const Year2015_21 = () => {
  return (
    <div className={ss.wrap}>
      <h1 className={ss.title}>21. 칼럼 - 원탁의 기사.</h1>
      <p>2015년 하반기부터 독도 씨는 교육시민단체에서 활동하였는데, 그 당시 지역신문이나 단체 홈페이지 등에 가끔 교육(학교)을 주제로 한 칼럼(평론)을 기고하였다.</p>
      <p>다음은 독도 씨가 기고했던 몇 개의 칼럼 중 하나인 ‘원탁의 기사’라는 칼럼인데, 앞 장 말미에서 언급했던 ‘회의실 내의 자리 배치’와 관련이 있는 이야기라서 소개해보려 한다.</p>
      <p>&nbsp;</p>
      <p className={ss.fbody}>제목 : 원탁의 기사(#학교자치 #학교운영위원회 #학교민주주의)</p>
      <p className={ss.fbody}>작성자 : 나독도</p>
      <p className={ss.fbody}>작성일 : 2019년 1월 8일</p>
      <p>&nbsp;</p>
      <p>교육계에서는 언제부터인가 학교민주주의에 대한 논의(정의, 적용 방식, 활성화 방법 등)가 활발하게 진행되어 오고 있다.</p>
      <p>그런데 오래도록 논의되고 있지만 진전은 별로 없다.(제대로 적용되지 않고 있다.)</p>
      <p>왜 그럴까?</p>
      <p>답은 아주 단순하다.</p>
      <p>교육의 주체인 학생, 교사, 학부모 모두 학교민주주의에 대한 이해가 부족하기 때문이다.</p>
      <p>&nbsp;</p>
      <p>교사와 학부모는 민주주의를 배우지 못해서 잘 모른다.</p>
      <p>그렇다보니 학생에게 제대로 가르쳐 줄 수가 없다.</p>
      <p>당연하게도 학생은 민주주의를 제대로 배울 수 없으니 잘 이해하지 못한다.</p>
      <p>민주주의를 제대로 이해하지 못한 학생이 성장하여, 민주주의를 잘 모르는 교사와 학부모가 되는 악순환이 계속 진행되고 있다.</p>
      <p>아무리 민주주의를 하자고 외치고, 정책을 만들고, 규정을 손질해도, 이해가 안 되는 것을 실천하기는 어렵다.</p>
      <p>따라서 우리(사회)는 학교장을 포함한 교사에게 먼저 민주주의를 가르쳐야 하고, 이어서 학부모에게도 민주주의를 가르쳐야 한다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“무슨 소리냐? 민주주의가 뭔지 모르는 사람도 있나? 게다가 교사가 민주주의를 모른다고? 과장이고 선동이다.”</p>
      <p>이렇게 말하는 사람도 있을 것이다.</p>
      <p>어쩌면 이 글을 읽는 여러분의 생각일지도 모르겠다.</p>
      <p>&nbsp;</p>
      <p>하지만, 사실이다.</p>
      <p>단어의 사전적 의미를 아는 것과 실체(개념)를 이해하는 것은 다르다.</p>
      <p>&nbsp;</p>
      <p>조금만 관심을 가지고 학교 현장을 둘러보면, 학교 안에 민주주의가 없다는 것을 쉽게 확인할 수 있다.</p>
      <p>민주주의는 사회 구성원이 주권을 행사하는 정치제도이다.</p>
      <p>주권이란 조직(국가, 사회)의 의사를 최종적으로 결정하는 권력이다.</p>
      <p>만일 학교 안에 민주주의가 있다면, 학교 구성원들이 학교의 의사 결정에 참여하고 있어야 한다.</p>
      <p>당신은 학교의 의사 결정(교육과정의 결정, 규정의 결정, 예산의 결정 등)에 얼마나 참여하고 있는가?</p>
      <p>전혀 참여하지 못하고 있을 것이다.</p>
      <p>물론 당신이 바쁘거나 관심이 없어서 그런 것이 아니다.</p>
      <p>당신은 참여할 수 있다는 것도 모르고, 참여하는 방법도 몰라서 못한 것이다.</p>
      <p>이처럼 학교 구성원이 학교의 의사 결정에 참여하지 못하고 있으므로, 학교 안에 민주주의는 없다고 단언할 수 있다.</p>
      <p>그리고 대부분의 학교 구성원이, 이런 사실(학교 안에 민주주의가 없다는 사실)을 인지하지 못하고 있다.</p>
      <p>민주주의를 잘 모르니까 민주주의가 없다는 것을 인지하지 못하는 것이다.</p>
      <p>그러므로 학생, 교사, 학부모 모두 학교민주주의에 대한 이해가 부족하다고 판단할 수 있다.</p>
      <div className={ss.annotation_wrap}>
        <p>좀 더 직설적으로 표현하자면, 민주주의의 이해가 부족한 정도가 아니라 아예 모른다고 말할 수 있다.</p>
        <p>&nbsp;</p>
        <p>그런데 학교 안에 민주주의는 왜 필요할까?</p>
        <p>&nbsp;</p>
        <p>민주주의는 우리 사회의 정치 체제(정체성)이다.</p>
        <p>사회의 정치 체제를 가르치는 것은 사회의 존속과 유지에 반드시 필요한 일이므로, 이는 공교육의 가장 중요한 교육과정에 해당된다.</p>
        <p>즉, <span className={ss.bold}>학교에서의 민주주의 교육은 가장 기본적이고 가장 필수적인 교육과정이다.</span></p>
        <p>그리고 민주주의를 가르치려면, 당연히 학교의 운영 체제가 민주주의이어야 한다.</p>
        <p>그러므로 학교민주주의는 반드시 필요하다.</p>
        <p>&nbsp;</p>
        <p>공교육은 이미 1995년부터 학교민주주의(학교자치)를 제도화하여 시행해왔다.</p>
        <p>그로부터 벌써 한 세대가 지났으므로, 학교민주주의는 이미 정착되었어야 한다.</p>
        <p>하지만 정착은커녕 아직도 논의만 열심히 하고 있다.</p>
        <p>한 세대가 지나도록 민주주의를 이해하지 못했기 때문이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>한 가지만 더 예를 들어보겠다.</p>
      <p>당신은 학교운영위원회가 무엇을 하는 기구인지 아는가?</p>
      <p>&nbsp;</p>
      <p>대부분의 사람은 학교운영위원회가 무슨 일을 하는지 잘 모른다.</p>
      <p>단어의 사전적 의미 수준인, <span className={ss.quo}>“심의하는 기구 아니야?”</span> 정도로 아는 사람은 간혹 있다.</p>
      <p>하지만 그들도 학교운영위원회가 무엇을 어떻게 심의하는지는 모른다.</p>
      <p>일부 교사를 제외한 대부분의 학교 구성원은 학교운영위원회가 무슨 일을 어떻게 하는지 모르기 때문에, 학교운영위원회에 아무 관심이 없다.</p>
      <div className={ss.annotation_wrap}>
        <p>사실상 없는 것과 마찬가지로 학교 운영에 영향을 주지 못하기 때문에 더욱 관심을 끌 수 없다.</p>
      </div>
      <p>아무 관심이 없으니까 회의가 언제 개최되었는지, 무엇을 심의하였는지, 결과가 어떻게 나왔는지 모른다.</p>
      <p>&nbsp;</p>
      <p>비유하자면, 거의 모든 국민이, 국회의 역할이 무엇인지, 어떤 방식으로 운영되는지, 국회의원들이 이번 임시국회에서 무엇을 하였는지, 국정감사는 어떻게 하였고, 예산은 어떻게 심의하였는지 모르는 것과 같다.</p>
      <div className={ss.annotation_wrap}>
        <p>또한 학교운영위원회가 거수기로서만 작동하는 것은, 국회가 정권의 하수인이 되어 독재에 협조하는 것과 같다.</p>
        <p>대통령이 이 나라를 잘 지도할 수 있도록 국회의원들이 보필하고 찬양하는 것이 당연하다는 말과, 학교장이 학교를 잘 이끌어나갈 수 있도록 운영위원들이 최선을 다해 돕는(안건을 날치기하는) 것이 당연하다는 말은 완전히 같다.</p>
      </div>
      <p>&nbsp;</p>
      <p>현실 사회의 국민은, 국회의원이 뭐라고 말하든 관심이 없을 수는 있어도, 국회에서 하는 일이 무엇인지 정도는 안다.</p>
      <p>국민이 그것조차 모른다면 민주주의 국가라고 할 수 없다.</p>
      <p>그런데 학교 구성원은 학교운영위원회가 무슨 일을 하는지 모른다.</p>
      <p>그러므로 민주주의 학교라고 할 수 없다.</p>
      <p>&nbsp;</p>
      <p>한편, 국회에서 벌어지는 일에 관심을 가지는 국민의 수와 민주주의의 수준은 대체로 비례한다고 할 수 있다.</p>
      <p>우리 사회의 민주주의 수준은 어느 정도일까?</p>
      <p>아주 높은 점수를 받기는 어렵겠지만, 그렇다고 정치 후진국 소리를 들을 수준은 아닐 것이다.</p>
      <p>그런데 학교에서 학교운영위원회가 어떻게 돌아가고 있는지 관심을 가지는 학교 구성원의 수는 거의 제로(無)에 가깝다.</p>
      <p>그러니까 학교의 민주주의 수준은 그냥 낮은 정도가 아니라 거의 바닥이라고 보면 된다.</p>
      <p>각 시·도교육청에서 주기적으로 발표하는 학교민주주의 지수를 보면 매우 높다고 나오는데, 실소를 금치 못할 일이다.</p>
      <div className={ss.annotation_wrap}>
        <p>우리 주변의 몇 몇 독재 국가들도 자신들의 민주주의 수준은 아주 높다고 주장한다.</p>
        <p>하지만 우리는 그들 국가의 그런 주장(자랑)을 귀담아 듣지 않는다.</p>
        <p>현실과 한참 동떨어진 억지 주장이니까.</p>
        <p>시·도교육청의 발표를 보고 누군가 이를 비웃는다 해도 나는 이해한다.</p>
        <p>비싼 세금을 들여 내 놓은 발표 결과가 무가치한 억지 결론이니까.</p>
        <p>물론 나는 이런 현실이 매우 아프고 슬프다.</p>
      </div>
      <p>&nbsp;</p>
      <p>학교 구성원이 학교자치(학교민주주의)의 핵심 기구인 학교운영위원회가 무엇인지 모른다는 것은, 국민이 국회가 무엇인지 모른다는 것과 같으므로, <span className={ss.quo_mini}>‘학교민주주의를 이해하지 못하고 있다.’</span>고 말할 수 있다.</p>
      <p>&nbsp;</p>
      <p>어린 아이에게 어른의 옷을 입혀 놓으면 맞지도 않고 보기에도 어색하고 여러모로 불편한 것처럼, 민주주의를 모르는 학교 구성원에게 민주주의 시스템을 안겨만 주고 제대로 교육시키지 않으니, 이를 실천하는 것이 매우 불편한 것이다.</p>
      <p>그리고 그 불편함이 계속 누적되니까, 학교에는 많은 문제가 발생할 수밖에 없다.</p>
      <p>오늘날 학교의 문제, 공교육의 위기는, <span className={ss.quo_mini}>‘학교 운영 방식은 민주주의(학교자치)로 규정해 놓았으나, 실제 운영하는 사람은 민주주의를 이해하지 못하기 때문에 발생했다.’</span>고 말할 수 있다.</p>
      <div className={ss.annotation_wrap}>
        <p>민주주의가 오염(변질)되면 독재가 된다.</p>
        <p>학교민주주의(학교자치)가 엉터리로 운영되면, 어떻게 될까?</p>
      </div>
      <p>&nbsp;</p>
      <p>학교의 많은 문제가 구체적으로 무엇이고, 학교민주주의(학교자치)가 어떻게 엉망인지, 왜 그로인해 문제가 발생하는지, 처음 듣는 사람이라면 아마 잘 모를 것이다.</p>
      <p>계속 이야기해왔던 내용이고 앞으로도 이야기할 내용이므로, 궁금하신 분들은 다른 글을 추가로 읽어보시길 권한다.</p>
      <p>&nbsp;</p>
      <p>서론이 길었는데, 서론에서 말하려는 바는, 학교민주주의에 대한 이해가 부족하기 때문에 아무리 논의를 많이 하고 갖은 정책을 도입해도 학교민주주의(학교자치)가 제대로 자리 잡지 못하고 있다는 것이다.(학교자치는 1995년에 시작된 현재 공교육의 운영 방식이다.)</p>
      <p>&nbsp;</p>
      <p>그리고 오늘의 본론은, 학교민주주의의 이해가 부족하여 발생된 현상인 “회의실의 자리 배치”에 대한 이야기이다.</p>
      <p>우리는, 학교에서 회의실의 자리 배치를 어떻게 하는지 살펴보고, 이런 배치는 학교민주주의의 이해 부족에서 발생된 현상이라는 것을 살펴볼 것이다.</p>
      <p>&nbsp;</p>
      <p>그러기 위해서 먼저, <span className={ss.bold}>권리</span>에 대한 이야기를 해보자.</p>
      <div className={ss.annotation_wrap}>
        <p>민주주의를 이해하는데 도움이 되는 이야기이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>권리(right)는 사람(개인) 또는 집단이 ‘당연히 주장하고 요구할 수 있는 힘이나 자격’을 뜻한다.</p>
      <p>권리는 사회 속에서 부여되는 힘이다.</p>
      <div className={ss.annotation_wrap}>
        <p>오직 한 사람의 인간만 있다면, 누군가에게 요구할 수 있는 권리는 존재하지 않는다.</p>
      </div>
      <p>&nbsp;</p>
      <p>다시 말해서 권리는, 사회가 규정으로 정한 힘이다.</p>
      <p>순수한 자연에서 발생한 물리적인 현상이 아니라, 인간의 생존과 행복(욕구)에 필요한 개인의 요구(또는 몫)를 인정한(합의한) 사회의 약속이다.</p>
      <div className={ss.annotation_wrap}>
        <p>우리 사회가 규정한(주권자의 합의로 약속한) 권리 중에서 가장 기본적인 권리는 인권이다.</p>
        <p>인권은 보편적인 인간이 가지는 권리로서, 사람답게 살 수 있는 권리이다.</p>
        <p>사람은 누구나, 나의 존엄과 가치를 훼손하지 말라고 타인에게 요구할 수 있고, 인권을 침해하는 일이 발생하지 않도록 사회는 규정을 만들어 지키고 있다.</p>
      </div>
      <p>&nbsp;</p>
      <p>또한 권리는 자신의 몫(이익)을 주장하고 요구할 수 있는 힘이기도 하지만, 편의상 그 몫 자체를 권리라고 말하기도 한다.</p>
      <p>&nbsp;</p>
      <p>하나의 이익을 놓고 두 사람 이상이 서로의 권리(몫)를 주장하는 일이 발생할 때, 그 비율을 정한 것이 곧 사회의 약속인 법이다.</p>
      <div className={ss.annotation_wrap}>
        <p>권리 분배(비율을 정하는 것)의 예를 들어 보자.</p>
        <p>도로를 이용하고자 하는 차(운전자)가 4대(4명)이고, 이 4대가 십자형 교차로의 4방향에서 동시에 교차로로 진입하여 직진하고자 하는 경우, 각 운전자에게 도로를 이용할 수 있는 무제한의 권리가 있다면, 이들은 저마다의 권리를 주장하며 먼저 지나가려 할 것이다.</p>
        <p>만일 아무런 질서가 없다면(권리의 제한이 없다면), 충돌 사고가 발생할 위험이 매우 높을 것이다.</p>
        <p>사고의 위험은 건강권 및 재산권의 침해 위험에 해당된다.</p>
        <p>사회(국가)는 이를(권리의 침해 위험을) 두고 볼 수 없으므로, 우선 차선(중앙선)을 그어 양방향으로 통행이 가능하게 하고, 교차로에 신호등을 배치하여 일정한 순간에 일정 방향에서만 차가 지나갈 수 있도록 권리를 분배(제한)한다.</p>
        <p>4명에게 통행 방향 및 통행 시간에 따른 주행 권리를 분배하여 모두가 도로를 안전하게 이용할 수 있게 하였는데, 이렇게 도로에서의 권리 분배를 약속(규정)으로 정한 것이 바로 도로교통법이다.</p>
        <p>이외에도 국민의 교육받을 권리를 분배한 교육기본법, 소비자와 사업자 사이의 권리를 분배한 소비자기본법 등 2019년 기준으로 약 1,500개 가까운 법률이 권리 분배를 위해 정해져 있다.</p>
        <p>물론 이 중에서 가장 중요한 법은, 모든 국민의 주권을 포함하여 여러 기본적인 권리를 분배한 법인데, 이 법이 바로 헌법이다.</p>
      </div>
      <p>&nbsp;</p>
      <p className={ss.bold}>사회의 권리에는 총량이 존재한다.</p>
      <p>누군가 권리를 가지고 싶다면(경쟁하고자 한다면), 다른 누군가는 권리를 양보해야 한다.</p>
      <div className={ss.annotation_wrap}>
        <p>어느 날 밤, 어떤 버스 정류장에 마지막 버스가 도착했다.</p>
        <p>버스의 탑승 정원은 40명이었고, 이미 40명이 모두 탑승한 상태였으나, 정류장에는 20명의 손님이 더 있었다.</p>
        <p>이 버스는 막차였기 때문에, 기사는 먼저 탑승한 40명의 손님들에게 양해를 구하고 정류장의 20명을 정원 외로 태웠다.</p>
        <p>이 버스의 경우, 버스를 이용할 수 있는 탑승 권리는 40개로 정해져 있다.</p>
        <p>손님을 60명 태웠다고 해도 그 권리가 60개가 되지는 않는다.</p>
        <p>먼저 탑승한 40명은 정원 외 탑승으로 인해 어느 정도의 불편함을 감수해야 한다.</p>
        <p>1개의 권리를 온전하게 누릴 수 없다.</p>
        <p>즉, 40개의 권리를 60명이 나누어 가진 것이지 권리가 60개가 된 것이 아니다.</p>
        <p>이처럼 권리를 쪼개서 여럿이 나누어 가질 수는 있지만, 권리의 전체 수량은 변하지 않는다.</p>
        <p>그런데 만일 같은 종류의 버스 한 대를 해당 노선의 막차 시간에 추가로 편성하여, 이 버스에 20명을 태운다면, 권리는 모두 80개(40개×2대)가 된다.</p>
        <p>이때, 특별 편성된 버스의 40개 권리는 20명이 나누어 가지게 된다.</p>
        <p>이 경우는 권리가 새롭게 생성된 것이다.</p>
        <p>이처럼 사회 환경의 변화에 따라 새로운 권리가 생기기도 하고, 있던 권리가 사라질 수도 있지만, 변화가 없는 일정한 조건에서의 ‘권리의 전체 수량’은 일정하다.</p>
        <p>그러므로 사회의 권리에는 총량이 존재한다고 할 수 있다.</p>
      </div>
      <p>&nbsp;</p>
      <p>권리를 하나의 커다란 빵에 비유하자면, 100명이 빵을 먹고 싶을 때, 똑같은 크기로 100개의 조각을 내어 한 조각씩 나누어 줄 수도 있고, 빵을 만든 사람에게 절반을 떼어주고 나머지를 공평하게 분배할 수도 있으며, 운반하거나 조각을 내는 수고를 한 사람에게 기여도에 따라 조금 크게 조각을 내어 줄 수도 있을 것이다.</p>
      <p>이처럼 권리를 나누는(비율을 정하는) 방법은 여러 가지가 있을 수 있다.</p>
      <p>또한 방법을 결정하는 방식(누가 분배 비율을 정할 것인가)도 여러 가지가 있을 수 있다.</p>
      <p>물리적으로 힘센 사람이 빵을 차지한 다음 마음대로 나눌 수도 있고, 무리 중에 지도자로 선출된 사람이 임의로(자신의 판단에 따라) 나눌 수도 있으며, 빵을 나누는 비율을 사회적 합의에 의해 결정하고 그 결정에 따라 나눌 수도 있다.</p>
      <p>빵(권리)을 ‘어떤 비율로 나눌 것인가’를 결정하는 것이 자본주의, 혹은 사회주의 같은 사상(또는 정책)에 해당되고, ‘누가 나눌 것인가’를 결정하는 것이 전제주의 혹은 민주주의와 같은 정치제도에 해당된다.</p>
      <div className={ss.annotation_wrap}>
        <p>현재 우리 사회는 민주주의 사회다.</p>
        <p>최상위 질서(규정)인 헌법을 결정하는(개정하는) 사람이 국민이므로, 권리를 분배하는 주체(빵을 나누는 사람)가 국민인 민주주의 사회다.</p>
        <p>권리를 어떤 비율로 나눌 것인가는 권리의 종류와 각 상황에 따라 다른데, 우리 사회의 기본 원칙은 개인의 재산(소유)권을 인정하고 보호하는 자본주의를 따르고 있다.</p>
        <p>다만, 개인의 생존권, 인권, 행복추구권 등을 보호하기 위해 일부 사회주의 정책(철도·수도·전기 등의 공공운영, 최저임금제, 국민건강보험, 기초연금, 기초생활보장제도 등)도 시행하고 있다.</p>
        <p>정리하자면, 우리 사회의 권리를 나누는 방법은, 정치 시스템으로는 민주주의, 경제 시스템으로는 자본주의를 따르고 있다.</p>
      </div>
      <p>&nbsp;</p>
      <p>인간은 사회적 동물이다.</p>
      <p>사회를 이루어 살아가는 인간에게는 반드시 질서가 필요하다.</p>
      <p>일반적으로 사회(집단)는 인간(개인)에게 사회의 존속을 위한 권리를 주장하고, 인간은 사회에게 자신의 생존에 필요한 권리를 주장한다.</p>
      <p>또한 개인과 개인 사이에서도 수많은 권리의 주장(이해관계의 충돌)이 발생한다.</p>
      <p>질서(법)는 이러한 권리를 어떻게 분배할 것인가를 정하는 것이다,</p>
      <p>그렇다면 어떻게 정하는 것이 좋은가?</p>
      <p>사회와 개인과의 권리의 비율, 개인과 개인과의 권리의 비율은 얼마나 어떤 방식으로 정하는 것이 좋은가?</p>
      <p>&nbsp;</p>
      <p>이 질문에 대해 답하기 전에, 다른 질문을 하나 해보자.</p>
      <p>권리의 분배량을 정하는 것과, 결정 주체(분배할 사람)를 정하는 것 중에서 더 중요한 것은 무엇일까?</p>
      <p>&nbsp;</p>
      <p>다시 빵의 예시로 돌아가서 살펴보자.</p>
      <p>당신이라면 빵을 나누는 비율을 결정할 때 당신의 의견이 필요하다고 생각하는가, 아니면 누군가가 그냥 주는 대로 받아도 괜찮다고 생각하는가?</p>
      <p>아마 많이만 주면 누가 줘도 상관없다고 생각할지도 모르겠다.</p>
      <p>하지만 사람들은 중요한 빵(권리)일수록 많이 가지려 하기 때문에, 만일 ‘나누는 사람’에게 임의로 나눌 수 있는 권한을 준다면, 그냥 ‘기다리는 당신’에게는 합리적인 몫이 돌아올 가능성이 매우 낮아지게 된다.</p>
      <div className={ss.annotation_wrap}>
        <p>“대통령이, 시장이, 학교장이, 우리의 영웅이, 우리의 지도자가 알아서 잘 해줄 것이므로, 나는 그를 믿고 그의 처분을 따른다.”는 말은 위험하다.</p>
        <p>이는 엘리트주의를 인정하는 말이고, 독재를 용인하는 말이다.</p>
        <p>그가 아무리 지혜롭고 공정하다고 한들 모든 사람을 만족시킬 수는 없다.</p>
        <p>늘 다수의 불만족한 사람이 발생할 수밖에 없는데, 빵의 분배에 대한 의견을 낼 수 없다면 그 불만을 인내하기 어려우며, 권력이 독점되면 그 불만을 해소하기 어려워진다.(이의 제기를 해도 받아들여지지 않는다. 이의 제기의 권리마저 빼앗기기 때문이다.)</p>
        <p>게다가 지도자가 탐욕에 굴복하는 순간, 빵은 모두 사라지고 만다.</p>
        <p>알다시피, 완벽한 사람은 없다.</p>
        <p>더군다나 당신의 대통령은, 당신의 학교장은, 생각보다 지혜롭지도 공정하지도 않다.</p>
        <p className={ss.bold}>좋은 권력자를 만날 확률은 매우 적다.</p>
        <p className={ss.bold}>하지만 당신이 ‘권리를 나누는 사람’이 되면(정치에 참여하면), 좋은 권력자를 만날 확률을 계산할 필요가 없다.</p>
      </div>
      <p>&nbsp;</p>
      <p>위와 같은 상황에서 많이 차지하고 싶다면, 당신은 ‘나누는 사람’이 되어야 한다.</p>
      <p>그런데 만일, ‘나누는 사람’에게 임의로 나눌 수 있는 권한이 있다면, 모든 사람이 ‘나누는 사람’이 되고 싶어 할 것이므로 다툼이 일어날 것이다.</p>
      <p>이때 힘의 균형이 맞는다면 사람들은 분배에 대한 합의를 할 것이고, 힘의 균형이 무너진다면 힘이 센 사람 혹은 무리가 빵을 차지하게 될 것이다.(많은 사람이 참여할수록 힘의 균형은 잘 지켜진다.)</p>
      <p>결국 우리는, 권리의 분배량을 정하는 것보다 결정 주체(분배할 사람)를 정하는 것이 더 중요하다는 것을 알 수 있다.</p>
      <div className={ss.annotation_wrap}>
        <p>정치 시스템이 경제 시스템(정책)보다 상위의 개념이다.</p>
        <p>좋은 정책은 나의 권리를 더 많이 보장해 준다.</p>
        <p>좋은 정책을 기대하려면, 먼저 좋은 정치제도를 가져야 한다.</p>
      </div>
      <p>&nbsp;</p>
      <p>이제 그 앞의 질문으로 돌아가 보자.</p>
      <p>질서(법)는 권리를 어떻게 분배할 것인가를 정하는 것인데, 과연 어떻게 정하는 것이 좋은가?</p>
      <p>구체적인 권리의 분배량은 상황에 따라 다를 것이므로, 분배량을 정하는 사람(들)이 합리적으로 정하면(합의하면) 된다.</p>
      <p>보다 중요한 것은, 바로 위에서 말했듯이 누가 ‘나누는 사람’이 되느냐이다.</p>
      <p>힘센 사람? 현명한 사람? 빵을 운반한 사람? 빵을 자르는 사람?</p>
      <p>아주 특수한 경우에는 답이 달라질 수도 있겠지만, 보편적으로는 ‘몫을 받을 사람’이 ‘나누는 사람’이 되어야 한다.</p>
      <p>그래야만 모두가 만족할 수 있는 확률이 높아진다.</p>
      <div className={ss.annotation_wrap}>
        <p>아주 특수한 경우는 민주주의 사회에서도 발생할 수 있다.</p>
        <p>이를테면, 전쟁이나 국가비상사태로 계엄 상황이 되는 사례이다.</p>
        <p>이때 개인의 권리는 극히 제한되고, 그 줄어든 권리를 국가가 가져가서 행사하게 된다.</p>
        <p>이는 빵(권리)을 빼앗으려는 외부의 세력(또는 재난·재해)에 대항하기 위하여, 계엄사령관이 빵(권리)의 분배를 임의로 시행하는 것이, 빵 주인(주권자)의 이익에 부합되는 경우이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>권리를 앞에 두고, 이해 당사자들이 서로 의견을 내어 합의하게 되면, 권리는 순조롭게 분배될 수 있다.</p>
      <p>만일, 당신의 의견이 받아들여졌다면, 혹은 당신이 어떤 의견에 동의했다면, 당신은 그 의견에 따른 분배에 만족할 것이다.</p>
      <p>물론 사람이 많아질수록, 모두가 만족할 수 있는 분배 비율을 찾기는 점점 어려워진다.</p>
      <div className={ss.annotation_wrap}>
        <p>신(神)도 모든 사람을 만족시킬 수는 없다.</p>
      </div>
      <p>그러나 모든 사람이 합의할 수 있는 비율을 찾을 수는 있다.</p>
      <div className={ss.annotation_wrap}>
        <p>최종적으로 표결(투표)이라는 합의 절차가 있기 때문이다.</p>
      </div>
      <p>올바른 절차(민주적 절차)를 거친다면, 때론 어떤 합의가 내게 불만족스러울 수는 있겠지만, 평균적인 시민의 권리는 공정하게 분배되고 있다고 확신할 수 있으므로, 결과에 승복할 수 있을 것이다.</p>
      <div className={ss.annotation_wrap}>
        <p>위에서 좋은 정책을 기대하려면, 좋은 정치제도를 가져야 한다고 말했다.</p>
        <p>권리의 분배에 만족하려면, ‘기다리는 사람’이 아니라 ‘나누는 사람’이 되어야 한다.</p>
        <p>즉, 좋은 정치제도란 시민이 참여하는 정치제도이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>여기까지의 내용을 정리해보자.</p>
      <p>사회의 권리에는 총량이 존재한다.</p>
      <p>일정한 조건에서 권리의 수량은 일정하므로, 적정 비율로 분배해야 한다.</p>
      <p>권리를 분배하는 것은 법(질서, 규정, 약속)에 의한다.</p>
      <p>누가 분배할 것인가를 정하는 것이 정치제도인데, 민주주의는 ‘권리를 받을 사람(시민)’이 ‘권리를 분배하는 사람(주권자)’이 되는 시스템이므로, 가장 만족도가 높은 방식이다.</p>
      <p>민주주의는, 사람이 많아질수록 합리적인 비율을 찾기 어렵고, 합의를 위한 과정도 어렵지만, 올바른 절차를 통해 합의를 이루어내면 시민(주권자)의 평균적인 만족도가 높아진다는 장점이 있다.</p>
      <p>여기서의 올바른 절차(민주적 절차)란 시민(주권자)이 참여하는(의견을 내는) 절차이다.</p>
      <p>정부와 국회는 정책 결정(법령의 제·개정)을 위해 여론을 듣고, 정보를 모으고, 분석하고, 토론해야 하며, 이 과정을 오류 없이 시민에게 전달해야 한다.(왜곡·조작된 통계나 논리적 오류가 없어야 한다.)</p>
      <p>또한 시민은 이를 감시·확인하고 의견(피드백)을 전달해야 한다.</p>
      <p>얼마나 많은 시민들이 ‘감시, 확인, 의견 전달’에 참여하느냐에 따라 정책(권리 분배)의 질이 달라진다.</p>
      <p>&nbsp;</p>
      <p>정리하자면, 사회 구성원의 생존과 행복추구를 위해서는 여러 권리를 보호(분배)받아야 하는데, 이러한 권리가 합리적으로 분배되기 위해서는, 사회 구성원이 권리의 분배과정(법의 제·개정, 정책의 결정, 예산의 감시 등)에 참여해야 한다.</p>
      <p>&nbsp;</p>
      <p>이것이 민주주의의 개념이고, 학교민주주의란 학교에서의 민주주의를 말하는 것이므로, ‘학교에서의 규정의 제·개정, 교육과정의 결정, 학교회계의 감시’에 권리의 주체(학생, 교사, 학부모)가 참여(민주적 절차를 통해 결정)하는 것이 바로 학교민주주의이다.</p>
      <p>&nbsp;</p>
      <p>그리고 학교민주주의를 강조하는 가장 중요한 이유는, 우리 사회가 민주주의 사회이므로 학교에서부터 이를 배워야 하기 때문이다.</p>
      <p>민주주의를 배우는 것은 공교육에 있어서 가장 중요하고 가장 기본적인 교육과정이다.</p>
      <p className={ss.bold}>학교에서 배우지 못하면 어디에서 배울 수 있는가?</p>
      <p className={ss.bold}>그런 곳은 없다.</p>
      <p>&nbsp;</p>
      <p>권리와 민주주의(학교민주주의)에 대한 개략적인 설명을 마쳤다.</p>
      <p>이제 학교에서 “회의실의 자리 배치”를 어떻게 하는지 살펴보자.</p>
      <p>&nbsp;</p>
      <p>간단하다.</p>
      <p>&nbsp;</p>
      <p>대부분의 회의실에는, 직사각형의 탁자(책상)와 여러 개의 의자가 놓여 있다.</p>
      <p>직사각형의 짧은 변이자 문에서 먼 쪽의 변이, 관습 상 소위 상석이라고 여기는 자리다.</p>
      <p>학교운영위원회가 열리면, 학교장은 상석에 앉는다.</p>
      <p>학교장의 양쪽 옆(좌우측)으로 학부모위원, 지역위원, 교원위원들이 나란히 앉는다.</p>
      <p>그리고 회의를 주재하는 운영위원장은 주로 학교장의 맞은편에 앉는다.</p>
      <div className={ss.annotation_wrap}>
        <p>탁자의 모양이 타원이나 정사각형일 수도 있고, 운영위원장이나 학교장의 위치가 위의 설명과 다를 수도 있지만, 대부분의 학교에서 상석을 구분하고 그 자리에 학교장이 앉는다.</p>
        <p>그렇지 않은 학교가 있기는 하겠지만, 찾기가 쉽지 않다.</p>
      </div>
      <p>&nbsp;</p>
      <p className={ss.quo}>“별스럽다. 그게 뭐라고 따지나? 어찌되었든 관습적으로 상석은 존재하는 것이고, 누군가 앉아야 하는데, 학교장이 앉는 것이 가장 자연스러운 것이 아닌가?”</p>
      <p>&nbsp;</p>
      <p>그런가?</p>
      <p>그게 자연스러운가?</p>
      <p>&nbsp;</p>
      <p>상석과 말석을 구분하는 예절은 위계질서가 있는 관계에서 필요한 예절이다.</p>
      <p>요즘은 일상적인 인간관계에서의 위계질서를 불편하게 생각하는 사람들이 많기 때문에, 이러한 구분을 상식이라고 여기지 않는 추세이다.</p>
      <p>하지만 위계질서가 굳게 자리 잡혀 있는 조직에서는 이러한 예절이 기본 상식이다.</p>
      <div className={ss.annotation_wrap}>
        <p>흔히 이러한 조직을 경직된 조직이라고 한다.</p>
        <p>대표적인 곳이라면 공무원 조직(행정기관)을 들 수 있다.</p>
        <p>이 칼럼이 작성된 후인 2023년의 일인데, 아예 법령으로 상석 등을 구분했던 교정공무원 예절 규정과 소방공무원 예정 규정이 폐지되었다.</p>
        <p>그 이유는 간명했다.</p>
        <p>바로 경직된 조직 문화를 지양하고 수평적 조직 문화를 강조하기 위함이었다.</p>
      </div>
      <p>&nbsp;</p>
      <p>교장실에 가도 같은 자리배치를 볼 수 있다.</p>
      <p>아예 접객 테이블을 없앤 파격적인 교장실도 가끔 언론에 나오지만, 아직 대부분의 교장실에서 교장이 상석에 앉고 양 옆으로 학부모들이 앉아 있는 모습을 쉽게 볼 수 있다.</p>
      <p>학교는 생각보다 위계질서(권위주의적 사고방식)를 강조하는 조직이다.</p>
      <p>&nbsp;</p>
      <p>위계질서를 강조하는 조직의 업무체계는 상명하복이 기본이다.</p>
      <p>수평적 소통을 필요로 하지 않는다.</p>
      <p>그러므로 교장실이나 회의실의 자리배치를 보면, 학교장과 교사의 관계를 짐작해볼 수 있다.</p>
      <p>그리고 겉으로는 아니라고 하겠지만, 자리배치를 그렇게 하도록 지시한 학교장은, 자신과 학부모의 관계에도 위계가 있다고 생각한다.</p>
      <p>&nbsp;</p>
      <p>그런데!</p>
      <p>우리나라 공교육(학교)의 운영 방식은 학교자치(학교민주주의)이다.</p>
      <p>자치의 주체는 학교 구성원 모두이다.</p>
      <p>학교민주주의를 대표하는 법정 기구는 학교운영위원회이고, 학교운영위원회는 학교장의 하부 조직이 아닌 독립 기구이다.</p>
      <p>또한 법정 기구는 아니지만, 학교자치의 3대 축인 학부모회, 교사협의회, 학생회 역시 학교장의 하부 조직으로 취급되지 않는다.</p>
      <div className={ss.annotation_wrap}>
        <p>3개 ‘회’는 법정 기구가 아니라서, 단위 학교의 규정에 의해 만들어질 수도 있고 없앨 수도 있다.</p>
        <p>또한 학교장과 독립적일 수도 있고, 하부 조직일 수도 있다.</p>
        <p>예컨대 학생회칙을 학교장이 만드는 학교라면 학생회는 학교장의 하부 조직이 된다.</p>
        <p>당연하지만, 이런 규정은 반민주적인 규정이다.</p>
        <p>이런 규정을 바로 잡으려면 학교운영위원회가 정상 작동되어야 한다.</p>
        <p>안타깝지만, 대부분의 학교가, 우리 사회의 눈치를 보느라 유명무실한 수준으로 3개 ‘회’의 독립성을 인정해주는 척만 할 뿐, 실제로는 학교자치를 거부하고 있다.</p>
        <p>가끔 학생회, 학부모회, 교사협의회를 법정 기구로 하자는 의견이나 학교운영위원회에 학생위원을 참여시키자는 의견이 나올 때마다, 극렬하게 반대하는 대형 교사 단체가 있다.</p>
        <p>그들의 논리를 한마디로 요약하면, <span className={ss.fbody}>‘아직 준비가 되어 있지 않다.’</span>라고 할 수 있다.</p>
        <p>물론 준비를 방해하면서 그렇게 말하는 것은 일종의 순환 논증 오류이다.</p>
        <p className={ss.bold}>그 단체는 교장 독재를 좋아하고 학교민주주의를 싫어하는 것 같다고 추정된다.</p>
      </div>
      <p>&nbsp;</p>
      <p>학교 구성원과 학교장은 수평적인 관계이다.</p>
      <p>학교자치는 수평적인 소통을 기본으로 작동한다.</p>
      <p>&nbsp;</p>
      <p>학교자치(수평적 소통)와 위계질서(상명하복)는 상극인 단어이다.</p>
      <p>&nbsp;</p>
      <p>회의실의 자리배치는 구시대(학교자치 시행 이전)의 위계질서와 권위주의를 반영하고 있다.</p>
      <p>하지만 학교운영위원회가 열리는 회의실은, 학교자치의 핵심 공간이다.</p>
      <p>소통의 상징인 공간이다.</p>
      <p>이 공간의 속성(상호 소통)과 이 공간의 자리배치(명령과 복종)는 서로 부딪히고 있다.</p>
      <p>자, 이래도 상석을 구분하고 학교장이 상석에 앉는 것이 자연스러운 일인가?</p>
      <p>&nbsp;</p>
      <p>그렇게 자리배치를 지시한(혹은 전임 교장이 배치한 형태를 방치한) 학교장도, 그에 대해 이의를 제기하지 않은 운영위원들도, 그리고 그게 무슨 대수냐고 혀를 찬 사람들도 모두 <span className={ss.bold}>학교민주주의에 대한 이해가 부족</span>하다고 판단된다.</p>
      <p>&nbsp;</p>
      <p>자리 배치는 단순한 사안이 아니다.</p>
      <p>위계질서가 드러나는 자리 배치는 수평적이고 자유로운 의견 개진을 방해한다.</p>
      <p>자리 배치를 신경 쓰지 않는 사람도 있지만, 많은 사람들은 자리 배치 하나만으로도 자기검열을 시작한다.</p>
      <p>&nbsp;</p>
      <p>아주 유명한 이야기를 하나 해볼까 한다.</p>
      <p>1136년 영국에서 완성된 “브리타니아 왕들의 연대기”라는 책에는, 유명한 아서왕의 전설이 서술되어 있는데, 이 이야기에 ‘원탁’이 등장한다.</p>
      <p>아서왕과 12명의 기사(원탁의 기사)들은 출신성분이나 지위고하를 막론하고 상좌(상석) 없이 원탁에 둘러 앉아 회의를 한다.</p>
      <p>여기서의 원탁은, 통치자의 특권(절대 왕권)을 인정하지 않고, 참가한 모두에게 정치권력(권리)이 평등하게 주어진다는 것을 상징한다.</p>
      <div className={ss.annotation_wrap}>
        <p>우리나라에서도 가끔 어디에선가 ‘원탁회의를 진행한다.’는 이야기가 나올 때가 있다.</p>
        <p>바로 민주적 의견 수렴, 민주적 의사 결정을 하겠다는 뜻이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>1215년 영국에서는, 국왕의 실정에 반발한 귀족들에 의해, 왕의 권한을 제한하고 국민의 자유와 권리를 보장한 문서인 마그나카르타가 제정되었다.</p>
      <p>이 마그나카르타는 훗날(17세기) 명예혁명의 기초가 된 문서이며, 오늘날 근대 헌법의 토대가 된, 세계사에서 빼놓을 수 없는 기념비적인 문서이다.</p>
      <p>마그나카르타의 의의는 여러 가지가 있는데, 여기서 주목할 것은 왕의 절대 권력을 법으로 제한하였고, 공동체 논의 기구인 의회가 시작되었다는 것이다.</p>
      <p>비록 그 영향력이 제한적이었다고는 하지만, 어딘가 원탁의 기사 이야기를 닮지 않았는가!</p>
      <p>아무래도 원탁의 기사가 당대의 문학에 등장했었다는 것을 감안하면, 당시의 영국 사회에는 어느 정도 수평적 권력에 대한 인식이 있었다고 볼 수 있다.</p>
      <p>&nbsp;</p>
      <p>수평적 권력이라는 관점에서 보면, 원탁은 민주주의를 상징하고 있다.</p>
      <p>반면에 회의실의 자리 배치는 지위의 상하를 구분한 계급사회의 상징이다.</p>
      <p>12세기의 중세 영국인들은 원탁을 상상하고 문학에 표현했는데, 21세기의 우리나라 학교는 그런 상상을 하지 못한다.</p>
      <p>부끄럽다.</p>
      <p>&nbsp;</p>
      <p>학교자치를 하라고 만들어 놓은 학교운영위원회가 제대로 동작하지 않는 데에는, 우리 의식 깊숙이 존재하는 비민주적이고 평등하지 못한 사고, 즉 학교장을 “학교 운영의 파트너이자 대의 집행권자, 그리고 한사람의 운영위원”이 아닌, 우리의 “제왕적 통치자”로 여기는 권위주의적 사고가 있기 때문이다.</p>
      <p>그리고 그러한 사고가 있는 한, 학교민주주의는 요원할 것이다.</p>
      <p>&nbsp;</p>
      <p>학교민주주의가 제대로 자리 잡지 않는 이상, 아무리 ‘혁신교육’과 같은 미사여구를 가져다 붙여도, 제대로 된 교육의 혁신은 이루어지지 않을 것이며, 공교육은 서서히 무너져갈 것이다.</p>
      <p>그렇게 학교로부터의 민주주의를 배우지 못한 아이들과, 자치단위에서의 민주주의를 실행할 줄 모르는 학부모들로 구성된 사회는, 지금의 정치 수준을 벗어나기 어려울 것이며, 최악의 자살률과 최악의 출산율에서 헤어 나오지 못하고 결국 참담한 소멸의 길로 나아갈 것이다.</p>
      <p>&nbsp;</p>
      <p>악담도, 과장도 아니다.</p>
      <p>이쪽이든 저쪽이든, 아무리 높은 지지율로 지지를 해도 공약은 지켜지지 않고 적폐는 그대로이며, 촛불을 들어도 민주주의는 후퇴하고, 여전히 아이들은 공무원이 되기 위해 문제집을 외울 것이며, 그렇게 합격한 공무원들은 영혼을 심장 한 구석에 봉인해 놓고 무능하고 복지부동하는 관료로 늙어갈 것이다.</p>
      <p>때로는 모질고 독한 사람들이 있어서 어쩌다 한 발 전진할 때도 있겠지만, 결국 부의 양극화는 돌이킬 수 없는 수준으로 심화될 것이고, 머지않은 미래에 모든 생산 단위에서 인간이 배제되는 시기가 오면, 그때 우리 사회의 인권이 어떤 수준이 될지는 미루어 짐작할 수 있을 것이다.</p>
      <p>&nbsp;</p>
      <p>우리는 지금,</p>
      <p className={ss.bold}>아이들에게 민주주의를 가르쳐야 한다.</p>
      <p>&nbsp;</p>
      <p>직면해 있고, 또 앞으로 다가올 무수히 많은 문제들을 해결하기 위해서, 통치자에게 기대지 말고 스스로 결정(자치)해야 한다.</p>
      <p>&nbsp;</p>
      <p className={ss.bold}>지금 학교에서, 원탁에 앉아야 한다.</p>
    </div>
  )
}

export default Year2015_21