import React from 'react'
import styles from './MainText.module.css'

const Prologue14 = () => {
  return (
    <div className={styles.wrap}>
      <h1 className={styles.title}>14. 세뇌의 이유.</h1>
      <p>대한민국에서 학교(공교육)의 운영 방식은 학교자치이다.</p>
      <p>학교자치는 학교 규정, 학교회계(예산), 교육과정 편성, 학사일정, 교과서 선정, 급식 운영, 학교건물의 관리 등 학교 운영과 관련한 대부분의 결정을 교육부나 교육청이 아닌 단위 학교에서 정한다.</p>
      <p>그러므로 국가 교육과정이나 법률에서 정한 특별한 경우를 제외하고, 교육부나 교육청은 학교 운영에 대해 이래라 저래라 간섭할 수 없다.</p>
      <div className={styles.annotation_wrap}>
        <p>학교의 교복에 대해서도 간섭할 수 없다.</p>
        <p>교복을 입을 것인지 말 것인지, 어떤 형태로 입을 것인지 등은 학교 구성원이 결정한다.</p>
        <p>학교 구성원의 고유 권한이므로, 대통령이 개입할 수 없다.</p>
        <p>청와대 청원게시판을 이용할 정도로 똑똑한 학생들인데도, 청와대에 청원해도 아무 소용이 없다는 사실, 교복은 자신들이 결정에 참여해야 하는 사항이라는 기본적인 사실을 모른다.</p>
        <p>학교는 도대체 뭘 가르치는 것인가?</p>
        <p>아이들이 그렇게 벌떼같이 아우성치는 데도, “얘들아, 교복은 학교에서 우리들이 함께 결정하는 것이란다.”라는 그 말 한마디를 가르쳐주지 않는다.</p>
      </div>
      <p>&nbsp;</p>
      <p>단위 학교의 대표 운영자는 학교장이고, 학교장은 학교 운영에 관한 대부분의 것을 승인하는 결재권자이다.</p>
      <p>만일 학교장을 견제할 수 있는 장치가 없다면, 단위 학교의 학교장은 학교 운영의 전권을 쥐는 절대 권력자가 될 것이다.(특정인에게 절대 권력을 쥐어 준다는 것은, 그 사람에게 독재를 허용한다는 것을 뜻한다.)</p>
      <p>그래서 대한민국의 학교자치 시스템에서는, 학교장을 견제하기 위해서 학교운영위원회를 두어 학교 운영에 관한 사항을 심의 받도록 하고 있다.(「초·중등교육법」 제32조 등)</p>
      <p>그러나 책의 본문에서 후술하겠지만, 대부분의 학교운영위원회는 학교장의 거수기일 뿐 정상적으로 작동하지 않는다.</p>
      <div className={styles.annotation_wrap}>
        <p>학교장은, 결재권을 가짐으로써 집행의 최종 승인에 따른 책임을 진다.</p>
        <p>하지만 현행 법령에서 학교운영위원회를 두도록 하였으므로, 완전한 결정권이 주어지는 것은 아니다.</p>
        <p>학교운영위원회는 의결기구가 아닌 심의기구지만, 학교장은 그 심의결과를 최대한 존중해야 하며, 심의결과와 다르게 시행할 경우 학교운영위원회와 교육청에 보고하여야 한다.</p>
        <p>만일 정당한 사유 없이 심의결과와 다르게 시행할 경우, 교육청의 시정명령을 받게 된다.</p>
        <p>그러므로 학교운영위원회의 심의 결과가 학교장의 의지에 반하게 나올 경우, 학교장으로서는 매우 곤란한 지경에 처할 수 있다.</p>
        <p>따라서 학교장은 자신의 의지대로 학교를 운영하기 위해서(완전한 결정권을 확보하기 위해서) 학교운영위원회를 거수기로 만들고 싶어 한다.</p>
        <p>물론 이와 같은 희망은 전국의 모든 학교에서 손쉽게 현실화 된다.</p>
      </div>
      <p>&nbsp;</p>
      <div className={styles.annotation_wrap}>
        <p>거수기는 손을 드는 기계를 뜻한다.</p>
        <p>아무 의견도 내세우지 않고 시키는 대로 손을 드는 사람이 거수기이다.</p>
        <p>무조건 찬성만 한다고 해서 영어로는 'yes man'이라고도 한다.</p>
        <p>학교운영위원회의 안건은 대부분 학교장이 제출한다.(다른 운영위원도 안건을 발의할 수 있는데, 이와 같은 사실을 학부모위원은 모른다. 학부모에게 관련 규정을 알려주지 않기 때문인데, 그 이유는 학생들에게 규정개정 절차를 가르쳐주지 않는 이유와 같다.)</p>
        <p>제출한 안건은 별다른 의견 없이 바로 가결된다.</p>
        <p>거수기 위원들은 학교의 운영에 관한 주요 안건이 10개라고 해도, 안건의 설명시간을 다 포함해서 1시간 안에 처리할 수 있는 기적을 보여준다.</p>
        <p>물론 수십 페이지, 수백 개의 항목이 적힌 예산안과 결산안도 수분 내에 가결된다.</p>
        <p>논의 없이 표결로 처리하는 행위를 날치기라 하는데, 일부가 아닌 전체가 날치기로만 운영되는 곳이 학교운영위원회이다.</p>
        <p>그리고 이에 대해 이의를 제기하는 사람은 없다.</p>
        <p>만일 당신이 학교운영위원이 되어, 회의에서 무엇인가를 진지하게 논의하려고 하면, 학교장은 “회의 후 회식자리를 예약해 놓았다.”고 이야기를 할 것이다.</p>
        <p>당신은, 예약시간에 늦게 가기를 원치 않는 다른 거수기들의 눈총을 받게 되므로 결국 질문을 포기하게 될 것이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>법적 기구는 아니지만, 흔히 학교자치를 말할 때 교사협의회, 학부모회, 학생회를 3대 축으로 꼽는데, 이 모든 기구들도 현실에서는 학교장의 하위 기구로 작동한다.</p>
      <p>교사의 경우 대표적으로는 인사권을 통해 통제한다.(나는 이것을 <span className={styles.bold}>주권의 독점</span>이라고 표현한다.)</p>
      <p>학부모의 경우, 학교운영위원이나 학부모회 임원을 대상으로 하는 학부모 교육을 통제함으로써, ‘모든 학부모가 학교자치와 학교행정을 모르는 상태’를 30년째 유지시키고 있다.(나는 이것을 <span className={styles.bold}>주권의 은폐</span>라고 표현한다.)</p>
      <div className={styles.annotation_wrap}>
        <p>1995년 5.31 교육개혁을 통해 공급자 중심에서 수요자 중심으로 교육체제가 변환되고, 단위학교 수준의 교육자치가 시작되었다.</p>
        <p>학교자치의 핵심기구인 학교운영위원회는 1995년 하반기에 시범학교에서 처음 운영 되었고, 1996년부터 정식 도입되었다.</p>
        <p>1995년부터 계산하면 2024년은 30년이 되는 해이다.</p>
      </div>
      <p>학생자치는 어떠한가?</p>
      <p>학생회의 운영에 필요한 ‘제대로 된’ 예산을 배정하는가?</p>
      <p>학생들에게 관련 규정의 개정 절차를 가르치고 행동하게끔 교육하는가?</p>
      <p>학생들에게 민주적 절차를 가르치는가?</p>
      <p>아니다.</p>
      <p>학생들은 갖가지의 학교 규정에 의해 통제 당하기만 한다.(나는 이것을 <span className={styles.bold}>주권의 배제</span>라고 표현한다.)</p>
      <p>&nbsp;</p>
      <p>왜 학교의 자치기구들은 죄다 정상적으로 작동되지 않는가?</p>
      <p>왜 모든 국민들은 학교자치가 무엇인지, 자치기구가 해야 할 일이 무엇인지, 자신들의 올바른 권리행사를 어떻게 해야 하는지, 권리행사의 의미가 무엇인지를 알지 못하는가?</p>
      <p> 왜 우리들은 아무것도 모르게끔 30년째 세뇌당하고 있는 것일까?</p>
      <p>&nbsp;</p>
      <p>그 이유는,</p>
      <p>학교가 민주주의를 구축하는 이유는,</p>
      <p>단 하나다.</p>
      <p>시스템은 민주주의지만 주권을 행사하지 못하게 하는,</p>
      <p>정보를 독점하고 숨기고 가르치지 않음으로써 유지되는,</p>
      <p>바로 <span className={styles.bold}>학교장의 독재를 위한 것</span>이다.</p>
      <p>&nbsp;</p>
      <p>그렇다.</p>
      <p className={styles.bold}>학교는 독재를 構築한다.</p>
    </div>
  )
}

export default Prologue14