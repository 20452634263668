import React from 'react'
import ss from './MainText.module.css'

const Anvil = () => {
  return (
    <div className={ss.wrap}>
      <h1 className={ss.title}>28. 칼럼 - 조선시대의 학부모에게.</h1>
      <p>앞 장의 말미에서, 우리 사회 시민의 평균적인 민주주의 이해 수준과 학교에서 만나는 구성원(교사, 학부모)의 민주주의 이해 수준이 달라 보인다는 독도 씨의 의견이 있었다.</p>
      <p>노파심에서 말하지만, 여기서의 구성원은 학교 운영에 참여하는 구성원을 말한다.</p>
      <div className={ss.annotation_wrap}>
        <p>전체 학교 구성원의 수준과 시민의 수준은 대동소이할 것이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>여기에서 짐작할 수 있는 것이 하나 있다.</p>
      <p>바로 민주주의에 대한 이해가 높은 사람들이 학교 운영에 참여하지 못하고 있다는 것이다.</p>
      <p>극단적으로 말하자면, 학교(운영에 참여하는 사람들 중)에는 민주주의를 이해하는 사람이 없다는 뜻인데, 앞서도 계속 말했지만, 학교 구성원으로서 <span className={ss.quo}>“내가 왜 민주주의를 모르냐?”</span>고 항변을 하는 사람이 있다면, 자신이 속해 있는 학교를 좀 냉정하게 바라보자.</p>
      <p>당신의 학교는 민주적으로 운영되고 있는가?</p>
      <p>형식적으로만 그렇지 않은가?</p>
      <p>당신이 열심히 실천했다면 그렇게 운영되고 있을까?</p>
      <p>당신이 이론적으로 잘 안다고 해도 <span className={ss.bold}>실천하지 않으면 모르는 것과 다를 바 없다.</span></p>
      <p>그러므로 학교에 민주주의를 이해하는 사람은 없다.</p>
      <p>물론 평균적으로 그렇다는 이야기이고, 아주 없는 것은 아니다.</p>
      <p>몇몇 교사, 그리고 몇몇 학부모는 학교민주주의를 구현하기 위해서 갖은 애를 쓰고 있다.</p>
      <p>너무 소수라서 이기지 못하는 싸움을 하고 있는 것일 뿐이다.</p>
      <p>&nbsp;</p>
      <p>학교에는 왜 민주주의를 이해하는(실천하는) 사람이 없을까?</p>
      <p>&nbsp;</p>
      <p>1. 어떤 사람은 민주주의를 혐오한다.</p>
      <p>2. 어떤 사람은 민주주의를 모른다.</p>
      <p>3. 어떤 사람은 민주주의를 알지만 실천의 필요성을 못 느낀다.</p>
      <p>4. 어떤 사람은 민주주의를 적극적으로 실천하고 싶지만, 그렇지 못한 환경에 굴복한다.</p>
      <p>5. 아주 극소수의 사람만이 민주주의를 실천하려고 노력하고 있다.</p>
      <div className={ss.annotation_wrap}>
        <p>민주주의를 실천하기 위해 애쓰는 사람은, 학교당 한 명을 찾아보기도 어려운 실정이니, 평균적으로는 없다고 말해도 된다.</p>
      </div>
      <p>&nbsp;</p>
      <p>학교의 운영에 참여하는 학교 구성원은, 위의 다섯 가지 유형으로 나뉠 수 있다.</p>
      <p>그런데 어쩌면 당신은, 5번 유형이 극소수라는 독도 씨의 분류에 동의하지 않을 지도 모른다.</p>
      <p>그리고 만일 5번 유형이 적다는 것에는 동의한다고 해도, 1번이나 2번보다는 3번과 4번의 유형이 훨씬 더 많을 것이라 생각할 것이다.</p>
      <p>이론으로나마 공교육에서 민주주의를 가르치고 있고, 우리 사회의 정치와 주류 문화가 민주주의를 지향하고 있기 때문이다.</p>
      <p>하지만 놀랍게도 1번과 2번 유형의 사람이 압도적으로 많다.</p>
      <p>&nbsp;</p>
      <p>1번 유형의 사람은, 학교란 단지 상급 교육기관으로 진학하기 위한 수단이고, 그래서 교과 성적이 가장 중요하다고 생각하기 때문에, 민주주의를 중요하게 생각하지 않을뿐더러 오히려 자녀의 학업에 방해된다며 혐오한다.</p>
      <div className={ss.annotation_wrap}>
        <p>다른 이유로 민주주의를 싫어하는 사람도 있으나, 대부분의 1번 유형은 ‘진학을 위한 학교’와 ‘학교민주주의’는 공존할 수 없다고 생각하는 성적지상주의자이다.</p>
      </div>
      <p>자녀가 학생회장이 되기를 바라는 이유는 단지 학교생활기록부에 기록되기 위한 것이므로, 일단 학생회장이 된 뒤에는, 예결산소위원회 따위에 불려가서 학업에 하등 도움이 안 되는 학교 예산안 심사에 시간을 빼앗길 수 없다고 생각한다.</p>
      <p>학생 자치회 대의원회를 소집하는 일은 귀찮고 쓸데없는 일이며, 학생회 예산도 담당교사가 알아서 사용하는 것이 옳다고 생각한다.</p>
      <p>왜 대토론회 따위를 열어서 휴대폰 사용 규정과 같은 것을 개정하니 마니하면서 면학 분위기를 흐리냐고 성토한다.</p>
      <p>학원 갈 시간도 빠듯하므로, 자치 활동을 한다고 방과 후에 학교에 남아 시간을 보내는 것을 용납하지 않는다.</p>
      <p>이런 사람들은, 우리 사회의 민주주의는 필요하다고 말하지만, 학교에서의 민주주의는 불필요한 일이라며 혐오한다.</p>
      <div className={ss.annotation_wrap}>
        <p>경우에 따라 사회의 민주주의도 불필요하다고 말할 수 있는 사람들이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>2번 유형은 가장 많이 존재하는 유형이다.</p>
      <p>1번 유형도 사실상 민주주의를 모르는 유형이라고 할 수 있는데, 민주주의를 모르지만 혐오하지는 않는 사람을 별개로 하여 2번 유형으로 놓았다.</p>
      <p>이들은 현재 학교가 민주적으로 운영되고 있다고 믿는다.</p>
      <div className={ss.annotation_wrap}>
        <p>민주주의를 모르기 때문에, 교육청과 학교에서 그렇다고 이야기하면 그런 줄 안다.</p>
      </div>
      <p>혹시 당신도 당신의 학교가 민주적으로 운영되고 있다고 믿고 있다면, 당신이 생각하는 민주주의가 무엇인지 다시 한번 고민해 보기 바란다.</p>
      <p>학부모회 임원과 학교운영위원을 민주적 방식으로 선출하고, 학교운영위원회가 열리고, 학생자치회가 존재하고, 학부모회가 운영되고 있기 때문에 학교민주주의 지수가 높다고 생각한다면 큰 오산이다.</p>
      <p>민주주의가 무엇이며, 왜 지금의 학교민주주의가 엉터리인지는, 본문 곳곳에서 구체적인 사례를 들어 설명하였으니 참고하도록 하자.</p>
      <div className={ss.annotation_wrap}>
        <p>민주주의 체제는 표현과 언론의 자유, 정보의 자유, 결사 및 집회의 자유를 존중하고 보호해야 하며, 법치주의와 공정한 선거로 운영되며, 모든 사회 구성원의 권리를 동등하게 보장해야 한다.</p>
        <p>북한이나 중국, 러시아 같은 국가는 스스로 민주주의 국가라고 이야기하지만, 형식적인 선거 절차만 운영할 뿐이고, 언론이나 정보가 통제되는 등 민주주의 체제에서 필요한 많은 것들이 보장되지 않는다.</p>
        <p>이들 국가를 민주주의 국가라 할 수 있는가?</p>
        <p>학교도 똑같다.</p>
        <p>임원·위원을 투표로 선출한다고 해서, 그것만으로 민주주의라고 할 수는 없다.</p>
        <p>정보는 제한되고 언로는 닫혀있으며, 주권자의 의견은 형식적으로만 묻고 담당자의 제안과 교장의 결재로만 운영되는 시스템을 학교민주주의라고 말하려면, 위의 나라들도 민주주의 국가라고 인정해야 한다.</p>
      </div>
      <p>&nbsp;</p>
      <p>그런데 2번 유형의 사람은 왜 민주주의를 모르는 것일까?</p>
      <p>사실, 생각해 볼 필요도 없는, 너무나 당연한 현상이다.</p>
      <p>앞서도 계속 말했지만, 교사도 민주주의를 모르기 때문에 학생에게 민주주의를 제대로 가르칠 수 없다.</p>
      <p>제대로 가르치지 못하면 제대로 배울 수 없다.</p>
      <p>많은 사람이 민주주의를 제대로 배우지 못한 채 어른이 되었고, 그런 어른에게서 제대로 배우지 못하는 학생이 다시 어른이 되는 악순환이 이어지고 있다.</p>
      <p>그러므로 먼저 교사를 대상으로 민주주의를 다시 가르쳐야 한다.</p>
      <p>쉽지는 않겠지만 말이다.</p>
      <div className={ss.annotation_wrap}>
        <p>그리고 2번 유형은 잠재적으로 언제든지 1번 유형이 될 수 있다.</p>
        <p>그러니 학교민주주의로 가는 길은 멀고도 험한 길일 수밖에 없다.</p>
      </div>
      <p>&nbsp;</p>
      <p>한편, 3번과 4번 유형의 사람이 생활 속에서 민주주의를 실천하는 것과 그렇지 않는 것은, ‘민주주의 행동 스위치’를 켜고 끄는 것과 같다.</p>
      <p>3번 유형의 사람은 기본적으로는 스위치가 꺼져 있지만, 민주주의가 작동하는 환경에 속해 있을 때는 자연스럽게 스위치를 켜게 된다.</p>
      <p>반면에 4번 유형의 사람은 기본적으로는 스위치가 켜져 있지만, 이를 억압하는 환경을 만나거나 실행이 어려운 환경을 만나면 스위치를 끄게 된다.</p>
      <p>3번이든 4번이든 스위치가 꺼져있는 동안에는, 중립이라는 변명으로 민주주의를 외면하거나, 반대 체제에 동조하게 된다.</p>
      <p>&nbsp;</p>
      <p>학교에는 1번과 2번 유형의 구성원이 가장 많으며, 형식적인 민주주의를 계속 유지하려는 세력(공교육의 공급자)이 30년째 자신들의 세상을 구축하고 자신들의 왕국을 지배하고 있어서, 그나마 적은 수인 3번과 4번 유형 사람의 스위치도 꺼져 있다.</p>
      <p>그러므로 슬프게도, 학교에는 민주주의가 존재하지 않는다.</p>
      <p>&nbsp;</p>
      <p>우리나라는 민주공화국이고, 공교육은 사회가 민주주의 시스템을 유지할 수 있도록 국민을 교육해야 하므로, 1번 유형의 사람을 계도하고, 2번 유형의 사람에게 민주주의를 가르치고, 3번 유형의 사람이 스위치를 켤 수 있도록 학교민주주의를 활성화해야 하며, 4번 유형 사람의 스위치가 꺼지지 않도록 학교민주주의를 감시하고 지원해야 한다.</p>
      <p>하지만 공교육은 그러한 일(계도하고, 가르치고, 학교민주주의를 활성화하고, 감시·지원하는 일)을 하지 않고 있으며, 학교 구성원들은 아직도 아주 <span className={ss.bold}>오래된(교육자치 시대 이전의) 가치관을 가르치고 배우는 악순환</span>을 계속하고 있다.</p>
      <div className={ss.annotation_wrap}>
        <p>여기서 말하는 구시대의 가치관이란, 수직적인 권력 구조와 전제적인 운영 체제를 기반 질서로 하는 민주주의 이전 시대의 정치의식을 의미한다.</p>
      </div>
      <p>&nbsp;</p>
      <p>미래의 세대들이 올바른 교육을 받고 자라서, 보다 살기 좋은 사회를 만들 수 있게 하려면, 지금 우리는 공교육이 자신의 역할을 제대로 못하고 있는 현실을 지켜보고만 있어서는 안 된다.</p>
      <p>모두가 각성하여(스위치를 켜서) 구시대의 가치관을 수정하고, 학교민주주의가 학교에 올바르게 자리 잡고 뿌리내릴 수 있도록 해야 한다.</p>
      <p>&nbsp;</p>
      <p>독도 씨는 위와 같은 바람으로 한 편의 칼럼을 기고한 적이 있는데, ‘조선시대의 학부모에게’라는 제목의 칼럼이다.</p>
      <p>현대 사회에 맞지 않는 오래된 관습에 젖어 있는 학부모에게, 우리 아이들의 미래를 위해서 각성을 요구하는 내용이다.</p>
      <p>보통의 학부모가 학교의 운영 방식을 바라보는 보편적인 시선이 어떠한지, 어떤 관점으로 바라보아야 좋을지 생각해보자는 의미에서 해당 칼럼을 소개해보려 한다.</p>
      <p>&nbsp;</p>
      <p className={ss.fbody}>제목 : 조선시대의 학부모에게(#학교자치 #학교운영위원회 #학교민주주의)</p>
      <p className={ss.fbody}>작성자 : 나독도</p>
      <p className={ss.fbody}>작성일 : 2018년 7월 13일</p>
      <p>&nbsp;</p>
      <p>예전에, 그러니까 내가 어렸던 시절에, 어디서나 쉽게 들을 수 있었던 말 중에 이런 말이 있었다.</p>
      <p className={ss.quo}>“나라(나라님)가 하는 일에 최선을 다해 협조해야(따라야) 한다. 그게 우리나라가 잘되고, 내가 잘되는 길이다.”</p>
      <p>&nbsp;</p>
      <p>어린 시절의 나는 이 말의 참뜻을 잘 몰랐고, 그저 어른들이 하는 말이니까 옳은 말이라 생각했다.</p>
      <p>그러나 시간이 흘러, 지금의 나는 그 말이 의미하는 바를 잘 안다.</p>
      <p>&nbsp;</p>
      <p>고대에서 조선시대에 이르는 정치체제에서는 통치자인 왕이 국가의 주권자였다.</p>
      <p>국민에게는 주권이 없었으므로, 나라의 일은 모두 왕의 뜻대로 결정했다.</p>
      <div className={ss.annotation_wrap}>
        <p>법전을 편찬하여 법치를 하였으며, 국왕을 견제하는 신료의 힘이 강할 때는 왕권이 약화되기도 하였으나, 법을 고치거나 신료의 뜻(제안)을 거부할 수 있는 절대적인 힘이 왕에게 있는 전제군주제였다.</p>
      </div>
      <p>통치자의 결정이 국민에게 이득이 될 수도 있고 해가 될 수도 있는데, 국민들은 결정할 권한이 없으므로 그저 따르는 수밖에는 없었다.</p>
      <p>&nbsp;</p>
      <p>하지만 이미 내가 태어나기도 전부터, 우리 사회는 민주주의 사회가 되었다.</p>
      <p>민주주의 사회에서의 주권은 국민에게 있고, 통치자(대통령)는 국민(주권자)의 뜻(이익)에 부합되도록, 국민의 의견을 물어 나라의 일(정책)을 결정한다.</p>
      <div className={ss.annotation_wrap}>
        <p>통치자를 국민이 결정(임명)하며, 통치자가 국민주권을 무시하고 자신의 뜻대로 통치할 경우 임기를 보장받지 못하고 탄핵될 수도 있다.</p>
      </div>
      <p>&nbsp;</p>
      <p>민주주의 사회에서의 국가의 정책은 사회적 합의에 의해 만들어져서 시행된다.</p>
      <p>이때, 민주적 의견 수렴과 민주적 의사결정에 의해 정책이 만들어지고 시행되어야, 그것이 내 생각과 다르다고 해도 협조할(따를) 수 있다.</p>
      <p>그것이 민의를 왜곡하고 특정 집단의 이익을 추구하는 일이라면, 협조하는 것이 아니라 잘못을 지적하고 바로잡아야 하는 것이 주권자의 올바른 행동(애국)이다.</p>
      <p>그것이 민주적인 절차(의견 수렴과 의사결정)를 무시하고 만들어지지 않도록, 견제하고 감시하는 것이 바로 사회 구성원(주권자)의 권리이자 책임이다.</p>
      <p>&nbsp;</p>
      <p>그러니까 나라의 일에 무조건 협조해야 한다는 말은, 전제정치 시대인 조선시대에나 어울리는 말이고, 민주정치 시대인 현재의 우리나라에는 맞지 않는 말이다.</p>
      <p>또한, 주권자가 아닌 통치자가 주권자의 뜻에 반하는 정책을 만들어 시행하더라도 무조건 협조해야 한다는 위의 말은, 주권자의 권리와 책임을 버리고 통치자에게 주권을 이양하겠다는 말이며, 민주주의를 버리고 전제주의로 가자는 말과 같다.</p>
      <div className={ss.annotation_wrap}>
        <p>국가의 헌법을 뒤엎어 버리자는, 반체제적인 말이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>참으로 무서운 말이다.</p>
      <p>&nbsp;</p>
      <p>그리고 그 당시에는, 그러한 가치관이 사회 전반에 깊게 자리 잡고 있었다.</p>
      <p>그래서 학창 시절에 정부에 대한 비판을 할 때면 쉽게 들을 수 있었던 말이 위의 그 말이었다.</p>
      <p>나는 그러한 말을 어르신 뿐 아니라 동년배 학우들로부터도 많이 들었다.</p>
      <p>열린사회의 적은, 기득권과 그 기득권에 붙어 이득을 얻고자 하는 기회주의자뿐만 아니라, 정작 피해자이면서도 기득권 세력에 순응하는 다수의 구성원일 수도 있다는 점에, 나는 절망하고는 했다.</p>
      <p>아마도 오랜 과거로부터, 부모와 선생님에게서 배워 계속해서 전해져 내려오는, 시대착오적인 가치관(구습) 때문이리라.</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“나는 초중고 학교에서부터 민주주의를 가르치지 않으면, <span className={ss.bold}>올바른 애국자는 계속 소수</span>일 것이라 생각한다.”</p>
      <p>&nbsp;</p>
      <p>조선과 같은 전제군주제 국가에서나 통용될, 나라와 나라님에 무조건 협조해야 한다는 저 무서운 말은, 국가 시스템이 민주주의로 바뀐 지금에도 망령처럼 우리 사회의 곳곳에서 어슬렁거리고 있다.</p>
      <p>특히 민주주의를 가르쳐야할 우리 공교육 내부에 아주 견고하게 자리 잡고 있다.</p>
      <p>&nbsp;</p>
      <p>무슨 소리냐고?</p>
      <p>&nbsp;</p>
      <p>학교운영위원회는, 학교 운영의 권한이 단위 학교로 내려온 5·31 교육개혁의 일환으로, 단위 학교의 교육과정과 교육행정을 견제·감시하기 위해 만들어진 심의기구이다.</p>
      <p>명목상 학교 운영의 결정권은 학교장에게 있으나, 주요 결정사항은 반드시 학교운영위원회의 심의를 거쳐야 하고, 학교장이 학교운영위원회의 심의 결과를 이행하지 않아도 되는 경우는 매우 제한적이기 때문에, 학교운영위원회는 매우 강력한 학교장의 견제 기구이다.</p>
      <p>또한 학교장과는 독립적인 기구이며, 학부모와 교사 등 학교 구성원의 대의기구이다.</p>
      <p>그러므로 학교운영위원회는, 주권자(학교 구성원)의 권리를 보장하고 동시에 견제와 감시의 기능(책임)을 가진, <span className={ss.bold}>학교민주주의의 핵심기구</span>라 할 수 있다.</p>
      <p>&nbsp;</p>
      <p>따라서 학교운영위원회를 의무적으로 구성하게 되어 있는 학교 운영 시스템은, 전제정치가 아닌 민주정치 시스템이므로, 위에서 말한 것처럼 통치자(학교장)에게 ‘무조건 협조’(주권자의 권리와 책임을 버리고 통치자에게 주권을 이양하는 행위)를 해서는 안 된다.</p>
      <p>그런데 그 대의기구의 절반가량을 차지하는 학부모들이 바로 그 망령에 사로 잡혀 있다.</p>
      <p className={ss.quo}>“우리가 한마음으로 학교(장)에 협조해야 한다. 그게 우리 학교가 빛나는 길이고, 아이들이 공부를 잘하게 되는 길이고, 그래서 우리 아이가 행복해지는 길이다.”</p>
      <p>절망에 또 절망을 불러일으키는 말이다.</p>
      <p>나라님에 협조해야 한다는 말과 무엇이 다른가?</p>
      <div className={ss.annotation_wrap}>
        <p>통치자가 훌륭하게 통치해주기를 기대하며 자치(스스로 통치)를 포기하는 행위를, 독도 씨는 <span className={ss.bold}>누군가의 선의에 기대는 것</span>이라 표현한다.</p>
        <p>그것은, 자신의 참여가 가장 중요하다는 사실을 망각하고, 훌륭한 대통령이 정답이고 좋은 교장이 부임하는 것이 행운이라고 믿는 마음이다.</p>
        <p>누군가의 선의에 기댄다는 것은, 정치적으로는 주권을 포기하는 것이며, 경제적으로는 계약서를 쓰지 않는 거래 혹은 담보가 없는 대출과 같고, 생태계로 치자면 포식자의 처분을 기다리는 피식자의 운명과도 같다.</p>
        <p>이는 민주주의를 포기하고 전제주의를 바라는 행위이며, 내 운명(주권)을 타인에게 맡기는 어리석은 행위이다.</p>
        <p>모든 사람을 만족시킬 수 있는, 선의로 충만한 훌륭한 대통령이 있을 수 있는가?</p>
        <p>마찬가지다.</p>
        <p>좋은 교장은 없다.</p>
      </div>
      <p>&nbsp;</p>
      <p>거의 모든 학부모들이 저 말을 당연한 것으로 받아들이고 있다.</p>
      <p>의사결정을 위한 민주적 협의체를 구성하기는커녕, 견제와 감시는커녕, 어떻게 하면 협조가 잘될까 고민하는 것을 자랑으로 여긴다.</p>
      <p>&nbsp;</p>
      <p>학교장의 의사에 반하는 의견을 제시하는 사람은 극렬한 비난을 받게 된다.</p>
      <p>학교장이나 교사가 아닌 학부모로부터 말이다.</p>
      <p>참으로 무섭고 두려운 현실이다.</p>
      <p>&nbsp;</p>
      <p>견제와 감시가 이루어지지 않는 사회가 얼마나 쉽게 부패하고 병드는 지는 새삼 말하지 않아도 알 것이다.</p>
      <p>학교도 마찬가지다.</p>
      <p>크고 작은 많은 문제(어려움)를 개선해야 하는데, 문제를 덮고 덮다가 큰 사고가 발생한다.</p>
      <p>분명 불행을 예방할 수 있음에도 불구하고, 그래야만 함에도 불구하고, 그 망령에 사로 잡혀 교육을 망치고 있다.</p>
      <p>&nbsp;</p>
      <p>학교장에게 협조만 하는데 학교자치가 될 리가 없다.</p>
      <p>학교자치가 이루어지지 않는 학교에서 아이들이 민주주의를 제대로 배울 수 있을까?</p>
      <p>문제를 제기하고, 의견을 수렴하고, 해결책을 논의하고, 안건을 협의·합의하며 문제를 해결해가는 과정을 전혀 배우지 못한다.</p>
      <p>능동적이고 주체적인 삶도, 자기결정권도, 합의에 의한 의사결정 방법도, 자치도, 민주주의도 어느 것 하나 배울 수가 없다.</p>
      <p>&nbsp;</p>
      <p>그렇게 해서 어른이 되면, 어떤 사회가 펼쳐질까?</p>
      <p>인권에 대한 인식은 부족하고, 배금주의와 갑질이 만연하고, 부정과 부패로 얼룩지고, 철학과 영혼이 없는 공무원이 양산되고, 성실하게 사는 사람이 손해 보며, 법을 거래하고, 권력에 따라 대접이 달라지는, 그래서 절망과 분노가 만연한, 늘 공격과 혐오의 대상이 필요한 그런 사회가 되는 것이다.</p>
      <p>&nbsp;</p>
      <p>학교장의 눈치를 보는 것이 당연한 교사.</p>
      <p>학교장에 협조하는 것을 미덕으로 아는 학부모.</p>
      <p>그래서 시키는 것에 따르기만 해야 하는 학생.</p>
      <p>교육의 3주체가 모두 이지경인데 국가의 미래가 아름다울 수 있을까?</p>
      <p>&nbsp;</p>
      <p>질문의 답은 너무 빤하다.</p>
      <p>지금 우리가 목도하고 있는 현실이 그렇다.</p>
      <p>&nbsp;</p>
      <p>당신들, 학교에 무조건 협조하는 것이 내 아이를 위한 것이라 믿는 사람들.</p>
      <p>그렇게 키운 아이도 똑같이 자란다.</p>
      <p>조선시대의 가치관을 배워, 현대 사회의 민주주의를 모르는 어른으로 자란다.</p>
      <p>매번 목소리 큰 기득권이 이기는 세상이 되는, 공정하지 못한, 합리적이지 못한, 인권이 존중되지 않는 사회에서 살게 될 것이다.</p>
      <p>&nbsp;</p>
      <p>당신이나 당신의 아이가 약자일 가능성이 더 크다는 사실을 잊지 말라.</p>
      <p>포식자와 피식자의 관계로 이루어진 사회라면, 포식자의 수보다는 피식자의 수가 훨씬 많을 테니까.</p>
      <p>먹이 피라미드에서 당신의 아이가 상위에 위치할 것이라는 기대와 희망은 헛되다.</p>
      <p>그런 헛된 기대에 자원을 쏟아 붓는 사회는 행복할 수 없다.</p>
      <p>&nbsp;</p>
      <p>그러니 제발, 그 망령에서 벗어나자.</p>
      <p>&nbsp;</p>
      <p>민주주의 사회에서의 견제와 감시는 훼방이 아니다.</p>
      <p className={ss.bold}>견제와 감시는 내 몸 병들지 말라고 살펴보는 것과 같다.</p>
      <p>아이들의 건강을 깐깐하게 살펴보듯이, 아이들의 학교를 깐깐하게 살펴보자.</p>
      <p>혹시 좀 시끄러워질까 걱정하는 사람이 있다면, 아직 익숙하지 않아서 그런 것이고 거쳐야할 과정이라고 받아들이자.</p>
      <p>&nbsp;</p>
      <p>우리 교육의 문제점, 우리 학교의 문제점의 근본적인 원인(책임)은 바로 당신, <span className={ss.bold}>조선시대에 살고 있는 학부모</span>에게 있다는 사실을 깨닫기 바란다.</p>
      <p>&nbsp;</p>
      <p>교육의 주체는 학교장이 아니다.</p>
      <p>교육의 주체는 학생과 학부모와 교사다.</p>
      <p className={ss.bold}>당신은 교육의 주체이지 협조자가 아니다.</p>
      <p>&nbsp;</p>
      <p>위대한 한 명의 스승이 교육을 하는 시대는 이미 오래전에 저물었다.</p>
      <p>당신이, 우리 모두가, 아이의 스승이 되어야 한다.</p>
      <p>그래야 우리 아이들 모두가 행복해 질 수 있다.</p>
      <p className={ss.bold}>우리는 조선시대에 살고 있는 것이 아니다.</p>
      <p>&nbsp;</p>
      <p>끝.</p>
      <p>&nbsp;</p>
      <p>위 칼럼은 부종교육포럼 인터넷 게시판에 실렸는데, 업로드 후 몇 개의 댓글이 달렸다.</p>
      <p>&nbsp;</p>
      <p>익명의 회원이 남긴 첫 번째 댓글은, <span className={ss.quo}>“교사도 학부모도 민주주의를 모르고 교장의 권위에 복종할 때, 그래서 학교자치가 제대로 작동되지 않을 때, 모든 걸 맘대로 할 수 있는 교장은 행복할까요? 혼자만 잘하면(교육철학만 훌륭하면) 된다고 생각하는 교장은 바람직한 교육자일까요?”</span>라는 글이었다.</p>
      <p>이 댓글에는 여러 대답(댓글)이 달렸는데, 그렇지 않다는 의견 쪽이 좀 더 우세했다.</p>
      <p>그중에서, “교육철학이 훌륭하고 학생들에게 헌신적이지만 학교자치를 등한시하는 교장은, <span className={ss.bold}>좋은 선생님이 될 수는 있지만 좋은 교장이 될 수는 없다.</span>”는 댓글이, 가장 좋은 반응을 얻었다.</p>
      <p>학교자치가 작동하지 않는 학교 운영 시스템은 독재 시스템이고, 아무리 좋은 선생님일지라도 독재하는 교장이라면 옳지 않다는 것이 해당 댓글의 논지였다.</p>
      <p>&nbsp;</p>
      <p>한편, 여러 댓글 중 학교운영위원회에서 혼자 애쓰는 한 학부모가 외로움을 호소하며 남긴 댓글이 있었는데, 독도 씨는 <span className={ss.quo}>“다른 학부모로부터 욕을 먹으면서도 견제와 감시를 포기하지 않는, 외로운 학부모에게 경의를 보냅니다. 외로운 싸움이지만 포기하지 않는 당신들이 있어서, 우리 교육의 미래에 조금이나마 희망을 가져봅니다.”</span>라고 격려의 말을 남겼다.</p>
      <p>이에 김만근 씨가, <span className={ss.quo}>“우리 아이들의 건강한 교육과 성장을 위해, 외롭지 않는 다수의 어른들이 싸우는 그 날이 오기를 바랍니다. 브라보!”</span>라는 댓글을 남겼다.</p>
      <p>&nbsp;</p>
      <p>독도 씨는 자신이 쓴 칼럼을 다시 읽어보면서, 소위 <span className={ss.bold}>엄마의 마음</span>이라는 클리셰를 떠올렸다.</p>
      <p>많은 사람들에게서 회자되는 단어로서, ‘자녀에게 헌신하는 모성애’를 학교 교육에 투영해서 표현한 단어인데, 교장의 뜻에 따르고 봉사활동에 참여하는 것이 자녀를 위한 길이라 여기며 자녀와 관련이 있는 안건에만 관심을 가지는 자들이 입에 달고 다니는 단어이기도 하다.</p>
      <p>많은 사람들이 이를 긍정적인 표현으로 생각하지만, 독도 씨는 이를 부정적으로 본다.</p>
      <p>위 칼럼에서 말했듯이, 통치자에게 권력을 이양함으로써 형식은 민주주의 시스템이지만 내용은 전제주의 시스템으로 운영되도록, 스스로 주권을 포기한 자들이 그러한 행태에 자긍심을 가지고 내뱉는 단어이기 때문이다.</p>
      <p className={ss.bold}>모성애는 위대하다.</p>
      <p className={ss.bold}>그러나 독재에 부역함으로써 자녀의 이득을 추구하는 엄마의 마음은 추하다.</p>
      <p>이 둘을 구분하지 못하는 것도 우리 사회의 어두운 면이라 할 것이다.</p>
    </div>
  )
}

export default Anvil