import { jwtDecode } from "jwt-decode"

//게시글 전체를 넘겨 받음.
const isMeInPost = (post) => {
    if (localStorage.getItem('token') && jwtDecode(localStorage.getItem('token')).userId === post?.poster?._id) {
        return true
    } else if (localStorage.getItem('token') && jwtDecode(localStorage.getItem('token')).admin === true) {
        return true
    } else {
        return false
    }
}
export default isMeInPost