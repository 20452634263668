import React from 'react'
import styles from './MainText.module.css'

const Year2015_02 = () => {
  return (
    <div className={styles.wrap}>
      <h1 className={styles.title}>2. 가정통신문.</h1>
      <p>2015년 3월, 독도 씨가 아들이 가져온 가정통신문 속에서 ‘학교운영위원회’라는 단어를 발견한 것은, 예전처럼 그냥 지나쳐 버릴 수도 있었던 단지 가벼운 우연이었을 뿐이었지만, 호기심에 이끌려 검색창에 이 단어를 타이핑 해본 것은, 일생일대의 사건이 시작된, 돌이킬 수 없는 걸음의 출발이었다.</p>
      <p>“학교운영위원회는 학교 운영의 주요 사안 결정에 참여하는 것이다.”라거나, “민주성·투명성·공정성을 기반으로 창의적인 교육을 실현할 수 있는 환경을 제공한다.”는 검색 결과를 통해 독도 씨가 처음 접한 학교운영위원회의 이미지는 매우 강렬하였고 꽤나 매력적이었다.</p>
      <p className={styles.quo_mini}>‘아니, 학교 운영은 교장이 마음대로 하는 것이 아니었어? 학부모가 학교에 가서 의견을 내고 의사 결정에 참여할 수 있다고? 진짜? 우리나라 학교가 그랬어? 언제부터?’</p>
      <p>&nbsp;</p>
      <p>놀라운 일이었다.</p>
      <p>독도 씨가 청소년 시절 다녔던 80년대의 초·중·고등학교를 떠올려보면 상상도 할 수 없는 일이었다.</p>
      <p>그 당시 부모님들이 학교에 가는 일은, 입학식이나 졸업식 같은 특별한 행사가 아니고서는 자녀가 사고를 일으켜 수습하러 가게 되거나, 잘 봐달라고 선생님에게 돈 봉투(촌지)를 주러 가는 일 외에는 없었기 때문이었다.</p>
      <p>&nbsp;</p>
      <p className={styles.quo_mini}>‘그것 참 매력적인데? 가고 싶은 학교를 마음대로 선택할 수도 없고, 가르치는 선생님을 선택할 수도 없고, 그밖에 뭐 하나 선택할 수 있는 게 없어서, 아무리 싫고 마음에 안 드는 일이 있어도 소위 복불복이라 여기고 받아들여야만 하는 게 학교라고 생각했는데, ‘교육과정 결정’이나 ‘교과서 선정’, ‘체험학습 장소 선정’ 등 학교 운영에 관련한 주요 사안을 결정할 때 학부모가 의견을 내고 그 결정에 참여할 수 있다고? 와! 좋은데? 도대체 이런 시스템이 있다는 것을 왜 몰랐지?’</p>
      <p>&nbsp;</p>
      <p>다만, 구체적으로 무슨 일을 어떻게 하는 지에 대한 정보는 찾기가 어려웠다.</p>
      <p>법령에서 정의한 법적 기구이고, 하는 일은 아무리 봐도 중요한 일인데, 학교나 교육청에서 만든 (다 똑같은) 간단한 학교운영위원회 소개 페이지만 검색되고, 실제 학교운영위원이 자신의 경험을 토대로 작성한 글(업무내용, 주의사항, 보람 있었던 일, 개선이 필요한 점 등의 후기)은 이상하리만큼 찾기가 어려웠다.</p>
      <p>&nbsp;</p>
      <p className={styles.quo_mini}>‘뭐 이렇게 정보가 없어?’</p>
      <p>1995년에 처음 시작되었으니 이미 20년이 지난 시점이고, 전국의 초·중·고등학교가 1만2천여 개여서 학교운영위원의 수도 최소 6만 명 이상일 테니, 연인원으로 백만 명 이상의 사람들이 학교운영위원을 했다는 것인데 어찌 이리 정보가 없을까?</p>
      <p>&nbsp;</p>
      <p className={styles.quo_mini}>‘일단 부딪혀보자.’</p>
      <p>&nbsp;</p>
      <p>백문이 불여일견이라고 했다.</p>
      <p>독도 씨는 일단 학교운영위원회의 학부모위원에 지원하기로 했다.</p>
    </div>
  )
}

export default Year2015_02