import React from 'react'
import styles from './MainText.module.css'

const Year2015_10 = () => {
  return (
    <div className={styles.wrap}>
      <h1 className={styles.title}>10. 우리가 관심을 가져야할 것.</h1>
      <p>지역위원 선출의 문제(임춘옥 교장의 위법 행위)를 설명하기 위해 상당히 많은 내용을 이야기하였다.</p>
      <p>그런데 이렇게 자세하게 설명을 한 이유는, <span className={styles.quo}>“앞으로 규정된 절차대로 공정하게 지역위원을 선출하는지 잘 감시하여, 위법 행위가 발생하는 것을 예방하자.”</span>는 말을 하기 위한 것이 아니다.</p>
      <p>물론 그러한 예방 행위도 필요한 일이지만, <span className={styles.quo}>“우리가 진짜 관심을 가져야할 것은 위법 행위 자체가 아니라, 위법 행위를 해서라도 운영위원회를 거수기로 만들어 학교를 자신의 뜻대로 운영하고자하는 학교장이 있다는 것이다.”</span>라는 말을 하기 위함이다.</p>
      <p>&nbsp;</p>
      <p>누구나 자기 뜻대로 정치를 하고 싶을 것이므로, 그런 모습을 이해할 수 있다는 사람들이 꽤 있다.</p>
      <p className={styles.quo}>“정치란 원래 그런 것이야.”<span className={styles.black}>라면서.</span></p>
      <p>그런데 틀렸다.</p>
      <p>민주주의에서 정치(운영)란, 토론을 통해 나와 뜻이 다른 상대방을 설득하여 내 뜻을 관철시키는 수준의 합의에 이르거나, 어느 정도 양보를 통해 상호 만족할 수 있는 수준의 합의에 이르거나, 끝내 합의하지 못하면 어쩔 수 없이 표 대결을 통해 결정을 내리는 것이지, 정적을 제거함으로써 토론과 협의를 생략하고 내 뜻만을 관철시키려 하는 것이 아니다.</p>
      <p>그와 같은, ‘형식은 민주주의지만 내용은 독단인 정치’를 무엇이라고 하는가?</p>
      <p>바로 독재라고 한다.</p>
      <p>&nbsp;</p>
      <p>대부분의 교장(혹은 교사)들은 학교가(학교장이) 독재정치를 하고 있다고 말하면, 지나치다면서 기분 나쁘다고 하겠지만, 에둘러 표현할 이유가 없다.</p>
      <p>너무 명백한 사실이기 때문이다.</p>
      <p>그리고 그것은, 그것이 교육이기 때문에 아주 큰 문제가 된다.</p>
      <p className={styles.bold}>학생들은 민주주의를 배우는 것이 아니라 독재를 배우며 자라고 있다.</p>
      <p>&nbsp;</p>
      <p>그리고 (1995년 이후로) 학교를 졸업한 대부분의 국민들도 학교에서 그렇게 배워왔다.</p>
      <p>물론 그 이전(학교민주주의가 없던 시절)에 학교를 다녔던 사람들은 더더욱 민주주의를 제대로 배우지 못했다.</p>
      <p>그 결과는 매우 자명하다.</p>
      <p>우리 사회를 보고 질문을 해보자.</p>
      <p>&nbsp;</p>
      <p className={styles.bold}>지금 우리는 과연 민주주의를 잘 이해하고 있는 것일까?</p>
    </div >
  )
}

export default Year2015_10