import React from 'react'
import ss from './MainText.module.css'

const Year2015_15 = () => {
  return (
    <div className={ss.wrap}>
      <h1 className={ss.title}>15. 교육지원청의 전화(2).</h1>
      <div className={ss.talk}>
        <p className={ss.t1}>여보세요?</p>
        <p className={ss.t1}>나독도님이신가요?</p>
        <p className={ss.t2}>네. 그런데요.</p>
        <p className={ss.t1}>네, 위원님. 안녕하세요?</p>
        <p className={ss.t1}>저는 부종교육지원청 대외협력팀의 조진일 팀장이라고 합니다.</p>
        <p className={ss.t2}>아, 네……. 안녕하세요?</p>
        <p className={ss.t1}>전화 드린 것은 다름이 아니고.</p>
        <p className={ss.t1}>지난주에 국민신문고로 학교운영위원회 관련 문의를 주셨죠?</p>
        <p className={ss.t2}>네. 맞습니다.</p>
        <p className={ss.t1}>이미 도교육청 답변을 받으셨죠?</p>
        <p className={ss.t2}>네. 그렇습니다.</p>
        <p className={ss.t1}>답변을 드리긴 했는데,</p>
        <p className={ss.t1}>아무래도 직접 설명을 드리는 것이 도움이 될 것 같다고,</p>
        <p className={ss.t1}>도교육청에서 저한테 설명 부탁을 했네요.</p>
        <p className={ss.t1}>저는 부종교육지원청에서 운영위원회 담당 업무를 맡고 있습니다.</p>
        <p className={ss.t2}>아, 그렇군요.</p>
        <p className={ss.t1}>지역위원 추천 관련해서 안내를 못 받으셨다고 하셨죠?</p>
        <p className={ss.t1}>당연히 안내를 받고 추천하실 수 있어야 합니다.</p>
        <p className={ss.t1}>교장선생님이 실수를 하신 것 같더라고요.</p>
        <p className={ss.t2}>네. 교육청에서도 위법이라고 하더라고요.</p>
        <p className={ss.t1}>네, 맞습니다.</p>
        <p className={ss.t1}>그래서 위원님 기분이 많이 언짢으셨을 것 같아요.</p>
        <p className={ss.t1}>그러면 안 되는데 가끔 실수하시는 교장선생님이 있으세요.</p>
        <p className={ss.t1}>고의는 아닐 겁니다.</p>
        <p className={ss.t1}>학기 초라 무척 바쁘다보니 그랬나 봐요.</p>
        <p className={ss.t1}>다른 분들도 그런 실수를 가끔 하거든요.</p>
        <p className={ss.t1}>그래서 저도 여러 교장선생님들한테 자주 말씀 드립니다.</p>
        <p className={ss.t1}>아무리 바빠도 여러 번 체크하시라고요.</p>
        <p className={ss.t1}>그래도 사람이다 보니 실수가 발생하네요.</p>
        <p className={ss.t2}>아, 네……. 뭐, 그럴 수도 있겠죠.</p>
        <p className={ss.t1}>그래서 혹시 추천을 못하셔서 많이 불쾌 하셨나요?</p>
        <p className={ss.t2}>그렇다기보다는, 처음이라 잘 몰라서요.</p>
        <p className={ss.t2}>제대로 하는 건지 아닌지도 모르겠고,</p>
        <p className={ss.t2}>시원하게 설명해주는 자료도 없고,</p>
        <p className={ss.t2}>그래서 교육청에 물어본 거죠.</p>
        <p className={ss.t1}>그래요. 사실 운영위원회가 좀 복잡하고 어렵고 그래요.</p>
        <p className={ss.t1}>알고 보면 별 것 아닌데, 처음에는 생소해서 그래요.</p>
        <p className={ss.t2}>네. 좀 혼란스러워요.</p>
        <p className={ss.t2}>사실 운영위원회 회의도 생각과는 많이 다르고.</p>
        <p className={ss.t1}>그래요? 회의가 어땠는데요?</p>
        <p className={ss.t2}>아니, 뭐……. 저는 좀 치열하게 토의할 줄 알았는데,</p>
        <p className={ss.t2}>생각보다 설렁설렁 하더라고요.</p>
        <p className={ss.t2}>경험이 있는 분들이면 뭔가 다를 줄 알았는데,</p>
        <p className={ss.t2}>오히려 더 모르는 것 같기도 하고…….</p>
        <p className={ss.t1}>흠. 그러셨구나.</p>
        <p className={ss.t1}>여러 가지로 불편하셨구나.</p>
        <p className={ss.t2}>그 표현이 맞는 것 같아요.</p>
        <p className={ss.t2}>마음이 좀 불편했어요.</p>
        <p className={ss.t1}>적응하시면 차차 나아지실 겁니다.</p>
        <p className={ss.t1}>혹시 의문이 있거나 하시면 저한테 전화주세요.</p>
        <p className={ss.t1}>제가 성심성의껏 알려드리겠습니다.</p>
        <p className={ss.t1}>번호 저장해 두시고요.</p>
        <p className={ss.t1}>명함은 문자로 한 장 보내드리겠습니다.</p>
        <p className={ss.t2}>아, 네. 고맙습니다.</p>
        <p className={ss.t1}>그리고…….</p>
        <p className={ss.t1}>지역위원 선출 건은 더 문제 삼지는 않으실 거죠?</p>
        <p className={ss.t1}>제가 임춘옥 교장선생님께는 단단히 일러두었습니다.</p>
        <p className={ss.t1}>앞으로 실수하지 마시라고요.</p>
        <p className={ss.t2}>그래요?</p>
        <p className={ss.t2}>아! 근데 제가 학교 이름은 말 안했는데?</p>
        <p className={ss.t2}>그러고 보니, 어떻게 아셨어요?</p>
        <p className={ss.t1}>어떻게 알게 되었습니다. 하하하.</p>
        <p className={ss.t1}>그냥 모른 척 해주시면 감사하겠습니다.</p>
        <p className={ss.t1}>대신 제가 우리 위원님은 특별히 잘 모시겠습니다.</p>
        <p className={ss.t1}>언제든지 궁금한 게 있으시면 저한테 전화 주십시오.</p>
        <p className={ss.t2}>네…….</p>
        <p className={ss.t2}>저도 사실 정보보호 같은 것 딱히 믿지는 않습니다.</p>
        <p className={ss.t2}>말 안 해도 다 알거라고 예상은 했어요.</p>
        <p className={ss.t2}>그나저나, 교장선생님은 뭐라 하시던가요?</p>
        <p className={ss.t2}>기분 나쁘다고 하시던가요?</p>
        <p className={ss.t1}>아니에요.</p>
        <p className={ss.t1}>실수를 인정하셨지요.</p>
        <p className={ss.t1}>죄송하다고도 하셨고요.</p>
        <p className={ss.t1}>아무 때나 시간 괜찮으시면 저랑 같이 한번 방문하시겠어요?</p>
        <p className={ss.t1}>차 한 잔 하시면서 오해도 풀고.</p>
        <p className={ss.t2}>오해는 무슨…….</p>
        <p className={ss.t2}>그냥 궁금해서 그랬어요.</p>
        <p className={ss.t2}>이제 절차를 확실히 알았으니 됐어요.</p>
        <p className={ss.t1}>그럼 더 문제 삼지 않으시는 거죠?</p>
        <p className={ss.t2}>네. 그럴게요.</p>
        <p className={ss.t1}>저랑 약속하셨습니다!</p>
        <p className={ss.t2}>네. 뭐…….</p>
        <p className={ss.t1}>감사합니다.</p>
        <p className={ss.t1}>그리고 앞으로 무슨 문제 있으면요,</p>
        <p className={ss.t1}>국민신문고 사용하지 마시고 저한테 전화주세요.</p>
        <p className={ss.t1}>웬만한 건 제가 다 해결해 드리겠습니다.</p>
        <p className={ss.t1}>국민신문고에 민원 넣는 것보다 훨씬 빠르게,</p>
        <p className={ss.t1}>그리고 깔끔하게 처리할 수 있습니다.</p>
        <p className={ss.t1}>부탁드릴게요.</p>
        <p className={ss.t2}>예. 웬만하면 그렇게 하겠습니다.</p>
        <p className={ss.t1}>감사합니다.</p>
        <p className={ss.t1}>이야기가 잘 돼서 기쁩니다.</p>
        <p className={ss.t2}>네. 저도 감사합니다.</p>
      </div>
      <p>&nbsp;</p>
      <p className={ss.quo_mini}>‘이야. 무슨 일인지 모르겠네. 교육지원청 팀장이라는 사람이 깍듯하게 전화를 다 하고. 그나저나 ‘모 학교’라고 했는데, 다 아네? 교장 모르게 물어보려고 한 건데 교장도 알았단 말이지? 좀 곤란할 수도 있겠네. 안 그래도 회의 때 별로 기분 좋아 보이지 않던데, 이제 나한테 감정이 좋지 않겠네. 흐흐흐.’</p>
      <p>&nbsp;</p>
      <p>앞서도 말했지만, 당시의 독도 씨는 지금보다 아는 것이 훨씬 적었다.</p>
      <p>그래서 교육청 답변을 받았고, 또 교육지원청 팀장의 전화도 받았으며, 학교장의 <span className={ss.quo_mini}>‘다시 실수하지 않겠다.’</span>는 말을 전해 들었기 때문에, 그런대로 만족할 수 있었다.</p>
      <p>&nbsp;</p>
      <p>독도 씨는 이제 다음 단계로 한 발 나아가야한다고 생각했다.</p>
      <p>위에서 다 설명하지는 못했지만(차차 설명하겠지만), 불편한 마음을 정리하기 위해 짚어 낸 문제점들은 이제 거의 다 또렷이 보였다.</p>
      <p>독도 씨는 앞으로 이 문제점들을 하나하나 해결하기 위해 노력하기로 마음먹었다.</p>
      <p>&nbsp;</p>
      <p>그리고 그것들과는 별개로, 당장 해결해야할 일이 하나 있다는 것을 생각해내었다.</p>
      <p>그것은 가장 무력하게 넘어가야했던 결산 심의와 관련한 일이었다.</p>
      <p>여러 날을 곰곰이 생각하고, 정보를 얻기 위해 백방으로 노력한 결과, 결산 심의에 필요한 자료(특히 세부 지출 내역)를 학교장에게 요구할 수 있다는 것을 알게 되었고, 그런 자료들을 모아 놓고 보면 학교 예·결산의 흐름을 알 수 있으리라는 판단을 내렸다.</p>
      <p>그러니까, 어디에서 얼마를 무엇을 위해 사용했는지 구체적으로 기록된 서류를 본다면, 무엇을 심의할지 감을 잡을 수 있을 것이라는 판단이었다.</p>
      <p>&nbsp;</p>
      <p>심호흡을 한 번 한 뒤에, 독도 씨는 교장실로 전화를 걸었다.</p>
    </div>
  )
}

export default Year2015_15