import React from 'react'
import styles from './School3.module.css'
import { useNavigate } from 'react-router-dom'
import img_ready from '../../img/School3.jsx/onReady.png'
import img_anvil from '../../img/School3.jsx/onAnvil.png'

const School3 = () => {
  const navigate = useNavigate()

  return (
    <div className={styles.wrap}>
      {/* <p className='go_back' onClick={()=>navigate(-1)}>← 이전 페이지로</p> */}
      <div className={styles.warning}>
        <p>※ 공지사항</p>
        <p>학교 3.0의 본문에 등장하는 이름(인물·지역·학교)과 사건은 모두 허구이며, 창작의 산물입니다.</p>
        <p>모든 이야기는 글쓴이의 경험을 토대로 하였으나, 구체적인 내용은 상상으로 꾸민 것입니다.</p>
        <p>글쓴이는 우리 시대, 우리 사회의 교육이 나가야할 바를 이야기하고 있으나, 그것은 글쓴이의 주관입니다.</p>
        <p>다른 견해나 반론, 혹은 잘못된 사실 등이 있다면 커뮤니티를 통해 말씀해 주십시오. 언제든 환영입니다.</p>
      </div>


      <div className={styles.pc_main}>
        <div className={styles.pc_prologue} onClick={() => navigate('/school3.0/prologue')} />
        <div className={styles.pc_epilogue} />
        <div className={styles.book2015} onClick={() => navigate('/school3.0/2015')}>
          <p className={styles.pc_book_title}>2015</p><img className={styles.pc_book_ready} src={img_ready} />
        </div>
        <div className={styles.book2016}><p className={styles.pc_book_title}>2016</p><img className={styles.pc_book_ready} src={img_anvil} /></div>
        <div className={styles.book2017}><p className={styles.pc_book_title}>2017</p><img className={styles.pc_book_ready} src={img_anvil} /></div>
        <div className={styles.book2018}><p className={styles.pc_book_title}>2018</p><img className={styles.pc_book_ready} src={img_anvil} /></div>
        <div className={styles.book2019}><p className={styles.pc_book_title}>2019</p><img className={styles.pc_book_ready} src={img_anvil} /></div>
        <div className={styles.book2020}><p className={styles.pc_book_title}>2020</p><img className={styles.pc_book_ready} src={img_anvil} /></div>
      </div>

      <div className={styles.only_mobile}>
        {/* <div className={styles.mobile_temp} onClick={() => navigate('/school3.0/prologue')}>들어가는 말</div>
        <div className={styles.mobile_temp} onClick={() => navigate('/school3.0/2015')}>2015</div>
        <div className={`${styles.mobile_temp} ${styles.mobile_disable}`}>2016</div>
        <div className={`${styles.mobile_temp} ${styles.mobile_disable}`}>2017</div>
        <div className={`${styles.mobile_temp} ${styles.mobile_disable}`}>2018</div>
        <div className={`${styles.mobile_temp} ${styles.mobile_disable}`}>2019</div>
        <div className={`${styles.mobile_temp} ${styles.mobile_disable}`}>2020</div>
        <div className={`${styles.mobile_temp} ${styles.mobile_disable}`}>맺는 말</div> */}
        <div className={styles.mobile_main}>
          <div className={styles.mobile_prologue} onClick={() => navigate('/school3.0/prologue')} />
          <div className={styles.mobile_epilogue} />
          <div className={styles.mobile_book2015} onClick={() => navigate('/school3.0/2015')}>
            <p className={styles.mobile_book_title}>2015</p><img className={styles.mobile_book_ready} src={img_ready} />
          </div>
          <div className={styles.mobile_book2016}><p className={styles.mobile_book_title}>2016</p><img className={styles.mobile_book_ready} src={img_anvil} /></div>
          <div className={styles.mobile_book2017}><p className={styles.mobile_book_title}>2017</p><img className={styles.mobile_book_ready} src={img_anvil} /></div>
          <div className={styles.mobile_book2018}><p className={styles.mobile_book_title}>2018</p><img className={styles.mobile_book_ready} src={img_anvil} /></div>
          <div className={styles.mobile_book2019}><p className={styles.mobile_book_title}>2019</p><img className={styles.mobile_book_ready} src={img_anvil} /></div>
          <div className={styles.mobile_book2020}><p className={styles.mobile_book_title}>2020</p><img className={styles.mobile_book_ready} src={img_anvil} /></div>
        </div>
      </div>



    </div>
  )
}

export default School3