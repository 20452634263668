import React from 'react'
import styles from './MainText.module.css'

const Prologue04 = () => {
  return (
    <div className={styles.wrap}>
      <h1 className={styles.title}>4. 규정은 누가 만들었을까?</h1>
      <p>예전에는 깨닫지 못했지만, 최근의 나는 이런 규정의 공지(가르침)에서 좀 이상한 점이 있다는 것을 발견했다.</p>
      <p>나는 그것이 정말 이상하다고 생각한다.</p>
      <p>하지만 이상한 점을 이야기하기 전에 다른 질문을 먼저 해보자.</p>
      <p>&nbsp;</p>
      <p>“도대체 이 규정은 누가 만들었을까?”</p>
      <p>&nbsp;</p>
      <p>당신은 이런 질문을 한 적이 있는가?</p>
      <p>아마도 당신은 이런 질문에는 관심이 없었을 것이다.</p>
      <p>만일 당신이 이런 질문에 대해 관심을 가졌다면, 오늘날 우리 사회의 모습은 지금과는 아주 많이 달라져 있었을 것이다.</p>
      <p>그렇기 때문에 우리 사회의 여러 가지 비극의 원인을 당신에게서 찾는다면, 당신의 무관심이 원인이라고 말할 수도 있다.</p>
      <p>하지만 나는 비극의 원인이 당신에게 있다고 말하지 않겠다.</p>
      <p>당신이 관심을 가지지 않도록 만든 어떤 시스템이 존재할 수도 있으니까.</p>
      <p>예를 들자면 당신의 부모님이 그랬다.</p>
      <p>“선생님 말씀을 잘 들어야 한다. 선생님이 시킨 대로만 하면 된다.”</p>
      <p>그래서 당신은 규정을 지키는 것에만 관심이 있었을 뿐이었다.</p>
      <p>&nbsp;</p>
      <p>다시 한 번 묻자.</p>
      <p>학교의 규정은 누가 만드는 것일까?</p>
      <p>아마 가장 많은 대답은 ‘교장선생님’일 것이다.</p>
      <p> 그밖에 ‘학생주임선생님’이나 ‘교육청’이라고 말하는 사람도 있을 것이다.</p>
      <p>&nbsp;</p>
      <p>그런데 다 틀렸다.</p>
      <p>&nbsp;</p>
      <p>답을 찾기 위해서 우리는 규정의 전문(全文)을 들여다보아야 한다.</p>
      <p>규정의 전문을 보면, 대개 마지막 장에 ‘규정의 개정’에 대한 조항이 나온다.</p>
      <p>개정이 가능한 규정이라면, <span className={styles.bold}>최종 개정한 사람이 지금의 규정을 만든 사람</span>이고, <span className={styles.bold}>개정할 수 있는 권한을 가진 사람이 규정을 만드는 사람</span>이다.</p>
      <p>&nbsp;</p>
      <p>그렇다면 이제, 학교의 규정을 개정할 수 있는 사람이 누구인지 살펴보면 된다.</p>
      <p>&nbsp;</p>
      <p className={styles.bold}>규정을 개정할 수 있는 사람은 누구인가?</p>
      <p></p>
      <p></p>
      <p></p>
    </div>
  )
}

export default Prologue04