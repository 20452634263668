const getCharacterScore = (char) => {
    if (/[가-힣]/.test(char)) {
        return 2; // 한글 한 글자당 2점
    } else if (/[a-zA-Z0-9]/.test(char)) {
        return 1; // 영문 및 숫자 한 글자당 1점
    } else {
        return 0; // 특수문자 등은 0점
    }
};

const validateNickname = (nickName) => {
    let totalScore = 0;

    for (const char of nickName) {
        totalScore += getCharacterScore(char);
    }

    if (totalScore > 16) {
        return false
    } else {
        return true
    }
};

export default validateNickname;