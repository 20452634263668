import React from 'react'
import styles from './MainText.module.css'

const Prologue10 = () => {
  return (
    <div className={styles.wrap}>
      <h1 className={styles.title}>10. 있는데 없다.</h1>
      <p>자, 어떤가?</p>
      <p>비록 최종 결정권은 없지만, 그래도 목소리를 낼 수 있고 부모님과 선생님을 설득할 수 있는, 불완전하나마 최소한의 권리는 학생에게 있지 않은가!</p>
      <p>그래서 많은 교육전문가들이 현행 시스템으로도 충분히 주인의 대우가 가능하다고 주장하는 것이다.</p>
      <p>&nbsp;</p>
      <p>하지만 나는 “선의에 기댄다는 것은 사실상 없는 것과 마찬가지다.”라고 했다.</p>
      <p>&nbsp;</p>
      <p>이 문제를 다른 관점에서 질문해보자.</p>
      <p>당신은 위의 사례에서 뭔가 이상하다는 것을 느끼지 못했는가?</p>
      <p>나는 당신이 위 사례에서 상당한 이질감을 느꼈을 것이라 생각한다.</p>
      <p>&nbsp;</p>
      <p>그렇다.</p>
      <p>위와 같은 개정 사례를 당신은 본 적이 없다!</p>
      <div className={styles.annotation_wrap}>
        <p>물론 아주 없지는 않다.</p>
        <p>위와 같은 과정으로 규정을 개정하는 학교가 있기는 있다.</p>
        <p>학생들이 능동적으로 개정안을 발의하기도 하고, 학생들이 자신들의 행사(체육대회, 축제 등)도 주관하는 ‘희귀한’ 학교가 전국에 몇 군데 있다.</p>
        <p>또한 초기 혁신학교에서도 위와 같은 개정 사례를 실천하는 경우가 상당수 있었다.</p>
        <p>그러나 단발성으로 그치지 않고 정착되어 이어지는 경우는 그중에서도 소수이며, 전체 학교의 수를 생각해보면 없다고 해도 과언이 아니다.</p>
      </div>
      <p>&nbsp;</p>
      <p>다시 한 번 묻자.</p>
      <p>당신이 처음 학교에서 학교생활 관련 규정을 들을 때, 선생님은 당신에게 규정을 어떻게 바꿀 수 있는지 이야기해 주었는가?</p>
      <p>당신은 규정의 전문이 적힌 문서를 본 적이 있는가?</p>
      <p>1년 내내 교실의 앞쪽 혹은 뒤쪽 어딘가에 붙여져 있던 수많은 게시물 중에서, 해서는 안 되는 금지된 일의 목록이 적힌 규정의 일부를 보았을지언정, 규정의 전문을 본 적이 있는가?</p>
      <p>좀 더 구체적으로, 규정의 마지막 장에 존재하는 <span className={styles.bold}>규정을 바꾸는 절차에 대해 당신의 담임선생님으로부터 배운 적이 있는가?</span></p>
      <div className={styles.annotation_wrap}>
        <p>학생이 개정안을 발의할 수 있는 경우는, 아주 특별한 학교장을 만나지 못하면 발생하지 않는다.</p>
        <p>현재의 시스템은 학생의 주권을 보장하지 않는 불완전한 시스템이고, 그나마 최소한의 권리도 선의에 기대야하기 때문에 없는 것과 같다.</p>
        <p>더 이상 학생이 주인이라는 말은 하지 말아야한다.</p>
      </div>
      <p>&nbsp;</p>
      <p>이상하다.</p>
      <p className={styles.bold}>왜 규정의 개정 절차를 가르쳐주지 않을까?</p>
      <p>왜 제한적인 권리마저 박탈하려고 할까?</p>
      <p>&nbsp;</p>
      <p>아무도 말해주지 않는다.</p>
      <p>그래서 정말 이상한데도, 이상한 줄 모른다.</p>
      <p>사람들은 여전히, 학교는 잘 가르치고 있다고 믿는다.</p>

    </div>
  )
}

export default Prologue10