import React from 'react'
import styles from './MainText.module.css'

const Prologue11 = () => {
  return (
    <div className={styles.wrap}>
      <h1 className={styles.title}>11. 배우지 못한 절차.</h1>
      <p>대한민국의 공교육은 주권의 행사 방법을 가르쳐야 한다.</p>
      <p>주권의 행사는 투표소에 가서 한 표를 행사하는 것이 전부가 아니다.</p>
      <p>최소한 위 사례에서 본 규정의 개정 절차 정도는 배워야 주권이 필요하고, 소중하고, 중요하다는 것을 깨달을 수 있지 않겠는가?</p>
      <div className={styles.annotation_wrap}>
        <p>민주주의에서 표를 행사(주권을 행사)한다는 것은 무엇인가를 결정(합의)하기 위한 것이다.</p>
        <p>‘한 표를 행사’한다고 하면 대부분의 사람들은 대표자를 선출하는 선거만을 떠올리는데, 이것 역시 사회적 합의의 일부이다.</p>
        <p>보편적으로 사회적 합의(어떤 결정)를 완성하기 위한 과정은, 동아리와 같은 작은 소모임부터 학교와 같은 큰 공동체(학교자치), 주민자치가 이루어지는 마을과 그 마을을 포함하는 도시(지방자치), 그리고 국가 차원에서 모두 같다.</p>
        <p>무엇을 결정해야할 필요가 발생하면 의견(여론)을 듣고, 정보(데이터)를 모으고, 학습(연구, 분석 등)하고, 토론하여 합의하는 과정을 거친다.</p>
        <p>나를 대신하고 우리를 대표할 사람을 뽑는 과정도 근본적으로는 같다.</p>
        <p>출마자의 철학, 능력, 공약 등을 검증하고 선택하는 과정은, 정책을 결정하는 과정이나 학교운영위원회에서 심의하는 과정과 다를 바가 없다.</p>
        <p>시민들은 자신이 속한 조직에서 직접 결정에 참여할 수도 있고, 대표자를 선출하여 결정하게 할 수도 있다.</p>
        <p>자신이 직접 결정에 참여할 때에도, 대표자가 결정하는 과정에 함께 참여할 때에도, 사회적 합의를 완성하기 위한 과정을 합리적(민주적)으로 거처야 하고, 이를 감시·견제할 수 있어야 올바른 주권 행사라 할 수 있다.</p>
        <p>그러므로 합의의 마지막 단계인 투표(다수결의 원칙)만 배우는 것으로는, 민주주의를 배웠다고 말할 수 없다.</p>
      </div>
      <p>&nbsp;</p>
      <p>초‧중‧고교 내내 불편함을 강제하는 규정을 억지로 지켜야한다는 것만 배우고, 나의 불편함을 개선하기 위한 ‘규정을 바꾸는 절차’에 대해서는 배우지 않는다.</p>
      <p>사회라는 조직의 유지를 위한 약속(규정, 법)을 능동적으로 만들고 바꾸는 방법을 배우지 않기에, <span className={styles.bold}>바꾸는 것은 권력자의 선의가 아니면 불가능한 것이라 여기며 살고, 불편하면 개선하려는 것이 아니라 일단 피하고, 안 되면 청탁하고, 그래도 안 되면 위반하고, 그러고 나서 은폐하는 것을, 살아가는 방법(요령)이라 생각한다.</span></p>
      <p>그래서 질서를 잘 지키는 사람은 호구가 되고, 피하고 청탁하고 위반하고 은폐 잘하는 사람은 유능한 사람이 된다.</p>
      <p>&nbsp;</p>
      <p>민주주의를 배우는 학급자치회 시간은, 나의 관심과 상관없는 주제의 토론이 이어지므로 지루하기만 하다.</p>
      <p>그래서 민주주의는 재미없고 쓸모가 없다.</p>
      <p>정작 우리의 관심을 필요로 하고, 우리의 삶에 영향을 끼치는 정책은, <span className={styles.bold}>여론이 형성되고, 의견을 수렴하여 분석하고, 자료(정보)를 모아서 연구(학습)하고, 논의하고, 그것을 발의하여 토론하고, 협의하고 합의하여 제정·개정되는 절차를 학교에서 배우지 못했기에, </span>국민들의 삶에서 괴리되어 있다.</p>
      <p className={styles.bold}>나는 모르는 일이고, 누군가 하는 일이 되어 있다.</p>
      <p className={styles.bold}>학교에서 배우지 못한 절차를 도대체 어디에서 배울 수 있을까?</p>
      <p>&nbsp;</p>
      <p>대한민국은 민주공화국이다.</p>
      <p>대한민국의 국민은 법(규정)의 제정·개정에 직·간접적으로 참여할 수 있다.</p>
      <p>학교는 규정 개정 절차에 학생이 참여할 수 있도록 함으로써 이를 가르쳐야 한다.</p>
      <p>&nbsp;</p>
      <p>학교에는 규정이 있고, 규정의 전문에는 규정의 개정 절차가 명시되어 있다.</p>
      <p>하지만 지켜야할 의무만 가르치고 개정할 수 있는 권리에 대해서는 알려 주지 않는다.</p>
      <p>학교에서 주권의 행사 방법을 배우지 못하기 때문에, 공교육을 받은 국민은 투표할 줄은 알지만 민주주의는 모른다.</p>
      <p>&nbsp;</p>
      <p className={styles.bold}>있는 것을 일부러 숨겨놓고 가르치지 않는 것은, 그것에 반함이다.</p>
      <p>&nbsp;</p>
      <p className={styles.bold}>학교는 민주주의를 驅逐한다.</p>
    </div>
  )
}

export default Prologue11