import React from 'react'
import ss from './MainText.module.css'

const Year2015_30 = () => {
  return (
    <div className={ss.wrap}>
      <h1 className={ss.title}>30. 예산 심의, 그리고 혼자가 아니다.</h1>
      <p className={ss.now_on_anvil}></p>
    </div>
  )
}

export default Year2015_30