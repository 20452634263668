import { jwtDecode } from "jwt-decode"

const isAdmin = () => {
    if (localStorage.getItem('token') && jwtDecode(localStorage.getItem('token')).admin === true) {
        return true
    } else {
        return false
    }
}

export default isAdmin