import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import styles from './AppLayout.module.css'
import { hamburger, hamburger_close, mypage_icon } from '../../svg/icon_svg'
import { jwtDecode } from 'jwt-decode'

const AppLayout = () => {

    const navigate = useNavigate()
    const location = useLocation()

    const [showNavbar, setShowNavbar] = useState(true)
    const [lastScrollY, setLastScrollY] = useState(0)

    const [showMenu, setShowMenu] = useState(false)
    const [showMypage, setShowMypage] = useState(false)
    const [isLogin, setIsLogin] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false)

    const [showArrow, setShowArrow] = useState(true);


    useEffect(() => {
        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [lastScrollY])

    useEffect(() => {
        setShowMenu(false)
        setShowMypage(false)
        window.scrollTo({
            top: 0
        })
    }, [location.pathname])

    useEffect(() => {
        if (!localStorage.getItem('token')) {
            return
        } else {
            setIsLogin(true)
            if (jwtDecode(localStorage.getItem('token')).admin === true) {
                setIsAdmin(true)
            }
        }
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            setShowArrow(prevShowArrow => !prevShowArrow);
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    const handleScroll = () => {
        const currentScrollY = window.scrollY;
        if (currentScrollY > lastScrollY) {
            setShowNavbar(false)
        } else {
            setShowNavbar(true)
        }
        setLastScrollY(currentScrollY)
    }

    const clickOpenMenu = () => {
        if (showMenu) {
            setShowMenu(false)
        } else {
            if (showMypage) {
                setShowMypage(false)
            }
            setShowMenu(true)
        }
    }
    const clickOpenMypage = () => {
        if (showMypage) {
            setShowMypage(false)
        } else {
            if (showMenu) {
                setShowMenu(false)
            }
            setShowMypage(true)
        }
    }
    const handleLogout = () => {
        localStorage.removeItem('token')
        window.location.reload()
    }

    const moveSchool3 = () => {
        navigate('/school3.0')
        setShowMenu(false)
        sessionStorage.removeItem('prologue')
        sessionStorage.removeItem('2015')
    }
    const moveDokdo = () => {
        if (location.pathname === '/dokdo_column') {
            window.location.reload()
        }
        navigate('/dokdo_column')
        setShowMenu(false)
    }
    const moveCommunity = () => {
        if (location.pathname === '/community') {
            window.location.reload()
        }
        navigate('/community')
        setShowMenu(false)
    }

    return (
        <div style={{ overflowX: 'hidden' }}>
            {/* <div className={`${styles.navbar_wrap} ${showNavbar ? styles.visible : styles.hidden}`}> */}
            <div className={styles.navbar_wrap}>
                <div className={styles.navbar}>
                    <div className={showMenu ? styles.menu_icon_close : styles.menu_icon} onClick={() => clickOpenMenu()} style={{ padding: '5px' }}>
                        {showMenu ? hamburger_close : hamburger}
                    </div>
                    <div className={styles.navbar_box1} style={{ width: location.pathname !== '/' && '440px' }} >
                        <img className={styles.top_logo} src={`${process.env.PUBLIC_URL}/logo.png`} onClick={() => navigate('/')} />
                        <p className={styles.headline} onClick={() => moveSchool3()}>학교 3.0</p>
                        <p className={styles.headline} onClick={() => moveDokdo()}>독도 칼럼</p>
                        <p className={styles.headline} onClick={() => moveCommunity()}>커뮤니티</p>
                        {location.pathname !== '/' && (
                            <p className={styles.headline} style={{ width: '50px' }} onClick={() => navigate(-1)}>{showArrow ? '←' : '이전'}</p>
                        )}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {isLogin ? (
                            <>
                                {isAdmin && (
                                    <p className={styles.headline} style={{ marginRight: '30px' }} onClick={() => navigate('/admin-page')}>운영자</p>
                                )}
                                <p className={styles.headline} style={{ marginRight: '30px' }}>내 정보</p>
                                <p className={styles.headline} onClick={() => handleLogout()}>로그아웃</p>
                                <div className={styles.mypage_icon} onClick={() => clickOpenMypage()} style={{ padding: '5px' }}>{mypage_icon}</div>
                            </>
                        ) : (
                            <>
                                <p className={styles.headline} style={{ marginRight: '30px' }} onClick={() => navigate('/login')}>로그인</p>
                                <p className={`btn-blue ${styles.signup_blue_btn}`} onClick={() => navigate('/signup')}>회원가입</p>
                                <p className={`btn-blue ${styles.login_blue_btn}`} onClick={() => navigate('/login')}>로그인</p>
                            </>
                        )}

                    </div>
                </div>
                {showMenu && (
                    <div className={styles.menu}>
                        <p className={styles.menu_list} onClick={() => moveSchool3()}>학교 3.0</p>
                        <p className={styles.menu_list} onClick={() => moveDokdo()}>독도 칼럼</p>
                        <p className={styles.menu_list} onClick={() => moveCommunity()}>커뮤니티</p>
                    </div>
                )}
                {showMypage && (
                    <div className={styles.menu}>
                        <p className={`${styles.menu_list} ${styles.mypage_lineup}`}>내 정보</p>
                        <p className={`${styles.menu_list} ${styles.mypage_lineup}`} onClick={() => handleLogout()}>로그아웃</p>
                    </div>
                )}
            </div >
            <div style={{ marginBottom: '67px' }} />
            <Outlet />
        </div >
    )
}

export default AppLayout