import React from 'react'
import styles from './MainText.module.css'

const Prologue02 = () => {
    return (
        <div className={styles.wrap}>
            <h1 className={styles.title}>2. 학교의 역할은 무엇인가?</h1>
            <p>공교육이란, 법(「교육기본법」, 「초·중등교육법」 등)에 의해 국가가 주관하는 교육을 말한다.</p>
            <p>「교육기본법」에서 국가는 유아교육, 초·중등교육(초·중·고등학교), 고등교육(대학교 등)을 위한 학교를 두고, 전 국민을 대상으로 하는 평생교육시설을 법률로 정한다고 명시하고 있다.</p>
            <p>그러므로 넓은 의미에서 공교육의 범위는 유아교육, 초등교육, 중등교육, 고등교육, 평생교육 모두를 포함한다고 할 것이다.</p>
            <p>그러나 일반적으로 통용되는 공교육이라 함은, 국가가 주관하는 교육 중에서도 「초·중등교육법」에 따른 초·중·고등학교의 교육을 말한다.</p>
            <p>따라서 이 책에서의 학교는 ‘초·중·고등학교’이고, 공교육은 ‘학교’ 또는 ‘학교의 교육과정’이라고 정의한다.</p>
            <p>&nbsp;</p>
            <p>공교육은 공익을 목적으로 한다.</p>
            <p>그렇다면 교육에 있어서 공공의 이익은 무엇일까?</p>
            <p>시대에 따라, 정치체제에 따라, 생각하는 사람의 관점에 따라 다양한 정의와 주장이 있겠지만, 교육에 있어서의 공익은 현 사회의 유지(사회체제의 존속 또는 사회질서의 유지)라고 보는 것이 가장 합당하다.</p>
            <p>좀 더 구체적으로 말하자면, 국민에게 보편적인 교육을 제공함으로써 국민의 사회화, 즉 ‘공동체 속에서 살아가기 위한 가치관, 지식 등을 배워 사회의 일원이 되는 것’을 돕고, 국민들이 안정적으로 사회화됨으로써 사회가 유지(존속)되도록 하는 것이 공교육의 목적이다.</p>
            <p>그리고 사회를 안정적으로 유지하는 것은, 사회의 주인인 국민이 정한 헌법의 가치를 계속해서 지켜 나가는 것을 의미한다.</p>
            <p>&nbsp;</p>
            <p>따라서 대한민국 학교의 교육과정이 공공의 이익을 지향하고 있고, 학교가 교육과정에 따라 학생들을 잘 가르치고 있다면, 우리 사회는 헌법의 가치를 지키면서 안정적으로 유지되고 있어야 한다.</p>
            <p>그런데 과연 그런가?</p>
            <p>헌법의 가치를 제대로 지키고 있는가?</p>
            <p>사회는 안정적으로 잘 유지되고 있는가?</p>
            <p>&nbsp;</p>
            <p className={styles.bold}>우리 사회가 잘 유지되고 있는지 아닌지는 도대체 어떻게 확인할 수 있을까?</p>
        </div>
    )
}

export default Prologue02