import React, { useEffect, useMemo, useRef, useState } from 'react'
import ReactQuill, { Quill } from "react-quill";
import ImageResize from "quill-image-resize-module-react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import styles from './WritePost.module.css'
import api from '../../util/api';
import { jwtDecode } from 'jwt-decode';
import MagicUrl from 'quill-magic-url'
import baseURL from '../../data/baseURL';
import QuillImageDropAndPaste from 'quill-image-drop-and-paste'
import 'react-quill/dist/quill.snow.css';
import '../../QuillCustom.css';
import isAdmin from '../../util/isAdmin';
import isMeInPost from '../../util/isMeInPost';

Quill.register("modules/imageResize", ImageResize);
Quill.register('modules/magicUrl', MagicUrl)
Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste)
window.Quill = Quill;

const Font = Quill.import("formats/font");
const Size = Quill.import("formats/size");
// Font.whitelist = ['body', "dotum", "gulim", "batang", "NanumGothic", "malgun", 'hambt', 'gung'];
Font.whitelist = ['normal', "head", "body"];
Size.whitelist = ['normal', "8", "10", "12", "14", "18", "22", "26", "30", "36", "48"];
Quill.register(Size, true);
Quill.register(Font, true);




const WritePost = () => {

    const navigate = useNavigate()
    const quillRef = useRef(null);
    const [searchParams] = useSearchParams()
    const modifyPostId = searchParams.get('q')
    const originalPostId = searchParams.get('o')
    const query_special = searchParams.get('special')

    const [title, setTitle] = useState('')
    const [content, setContent] = useState('')
    const [secret, setSecret] = useState(false)
    const [imgArray, setImgArray] = useState([])
    const [head, setHead] = useState('')


    useEffect(() => {
        if (!localStorage.getItem('token')) {
            navigate('/login')
            return
        }
        if (query_special === 'dokdo') {
            if (isAdmin() === false) {
                navigate('/')
                return
            }
        }
        if (originalPostId) {
            setTitle('Re : ')
        }
        if (modifyPostId) {
            fetchModifyPost()
        }
    }, [])

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault()
            event.returnValue = ''
            return ''
        }

        window.addEventListener('beforeunload', handleBeforeUnload)

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [])


    const fetchModifyPost = async () => {
        const response = await api.get(`/fetch-post?postId=${modifyPostId}`)
        if (response.data.message === 'no') {
            navigate('/not-found')
        } else if (response.data.message === 'ok') {
            const data = response.data.post
            if (isMeInPost(data) === false) {
                navigate('/not-found')
                return
            }
            setTitle(data.title)
            setContent(data.content)
            setSecret(data.secret)
            setImgArray(data.imgArray)
            setHead(data.head)
        }
    }


    const imageHandler = async () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.addEventListener('change', async () => {
            const file = input.files[0];
            const formData = new FormData()
            formData.append('img', file)
            try {
                const res = await api.post('/write-post-image', formData)
                const data = await res.data

                const imgUrl = `${baseURL}/uploads/${data.filename}`;
                const editor = quillRef.current.getEditor();
                const range = editor.getSelection();
                editor.insertEmbed(range.index, 'image', imgUrl);
                editor.setSelection(range.index + 1);

            } catch (error) {
            }
        });
    };
    const imageDropHandler = async (imageDataUrl, type, imageData) => {
        try {
            const file = imageData.toFile()

            const formData = new FormData()
            formData.append('img', file)

            const res = await api.post('/write-post-image', formData)
            const data = await res.data

            const imgUrl = `${baseURL}/uploads/${data.filename}`;
            const editor = quillRef.current.getEditor();
            const range = editor.getSelection();
            editor.insertEmbed(range.index, 'image', imgUrl);
            editor.setSelection(range.index + 1);
        } catch (err) {

        }
    }


    const quillModule = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ size: Size.whitelist }],
                    [{ font: Font.whitelist }],
                    [{ 'align': [] }],
                    ['bold', 'italic', 'underline'],
                    ['link'],
                    [{ 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466', 'custom-color'] }, { 'background': [] }],
                    ['image', 'video'],
                ],
                handlers: { image: imageHandler },
            },
            clipboard: {
                matchVisual: false,
            },
            imageResize: {
                parchment: Quill.import("parchment"),
                modules: ["Resize", "DisplaySize"],
            },
            magicUrl: true,
            imageDropAndPaste: {
                handler: imageDropHandler,
            },
            // keyboard: {
            //    bindings: {
            //       // 백스페이스 또는 delete 키를 누를 때 이미지 삭제 핸들링 연결
            //       handleImageDelete: {
            //          key: [Quill.modules.keyboard.keys.BACKSPACE, Quill.modules.keyboard.keys.DELETE],
            //          handler: handleImageDelete,
            //       },
            //       // 다른 키 바인딩...
            //    },
            // },
        }),
        [],
    )

    const formats = [
        'size',
        'font',
        'align',
        'bold',
        'italic',
        'underline',
        'link',
        'color',
        'background',
        'image',
        'video',
    ]


    //이미지의 fileName만 가져도 될 것 같긴 한데, 적절히 수정
    const handleContentChange = (value) => {
        setContent(value)

        // 이미지 태그의 src 속성에서 파일명을 추출하는 정규식
        const imgSrcRegex = /<img\s+src="http:\/\/(?:\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}|[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*)(?::\d{1,5})?\/uploads\/([^"]+)"/g;


        // 정규식으로 이미지 파일명을 추출하여 배열에 저장
        const matches = [...value.matchAll(imgSrcRegex)];
        const imgFileNames = matches.map(match => match[1]);

        // imgArray 상태를 업데이트합니다.
        setImgArray(imgFileNames);
    }

    const handleSubmit = async () => {
        if (!localStorage.getItem('token')) {
            alert('로그인 정보가 없습니다. 로그인 후 다시 시도해 주세요.')
            return
        }
        if (!title) {
            alert('제목을 입력해 주세요.')
            return
        }
        if (!content) {
            alert('내용을 입력해 주세요')
            return
        }
        try {
            const response = await api.post('/write-post', {
                title: title,
                content: content,
                secret: secret,
                userId: jwtDecode(localStorage.getItem('token')).userId,
                original: originalPostId,
                modifyPostId: modifyPostId,
                imgArray: imgArray,
                head: head ? head : '선택 안 함',
                category: (isAdmin() === true && query_special === 'dokdo') ? 'dokdo' : 'community'
            })
            if (response.data.message === 'success') {
                navigate(`/read_post?postId=${response.data.postId}`)
                // if (query_special === 'dokdo') {
                //     navigate('/dokdo_column')
                // } else {
                //     navigate('/community')
                // }
            }
        } catch (err) {
        }
    }



    return (
        <div className={styles.wrap}>
            <div style={{ display: 'flex', width: '100%', marginBottom: '10px' }}>
                <div className={styles.explain_input_title}>제목</div>
                {query_special === 'dokdo' ? (
                    <input type='text' className={styles.select_head} placeholder='말머리 입력' value={head} onChange={(e) => setHead(e.target.value)} />
                ) : (
                    <select className={styles.select_head} value={head} onChange={(e) => setHead(e.target.value)}>
                        <option value=''>말머리 선택</option>
                        <option value='선택 안 함'>선택 안 함</option>
                        <option value='토론'>토론</option>
                        <option value='질문/답변'>질문/답변</option>
                    </select>
                )}
                <input type='text' className={styles.input_title} value={title} onChange={(e) => setTitle(e.target.value)} />
            </div>
            <ReactQuill
                value={content} placeholder="내용" modules={quillModule}
                ref={quillRef}
                formats={formats}
                style={{ width: '100%', height: '420px', position: 'relative' }}
                onChange={(content) => {
                    handleContentChange(content);
                }}
            />
            <div style={{ marginTop: '70px', display: 'flex', alignItems: 'center' }}>
                <input className={styles.input_secret} type='checkbox' id='secret' checked={secret} onChange={(e) => setSecret(e.target.checked)} />
                <label htmlFor='secret'>비밀글</label>
            </div>
            <div style={{ display: 'flex', marginTop: '20px' }}>
                <p className='btn-blue btn-blue-reverse' style={{ marginRight: '30px' }} onClick={() => navigate(-1)}>취소</p>
                <p className='btn-blue btn-blue' onClick={() => handleSubmit()}>저장</p>
            </div>
        </div>
    )
}

export default WritePost