import React from 'react'
import styles from './MainText.module.css'

const Prologue05 = () => {
  return (
    <div className={styles.wrap}>
      <h1 className={styles.title}>5. 대한민국은 민주공화국이다.</h1>
      <p>공교육의 목적을 다시 상기 해보자.</p>
      <p>다양한 지식을 얻고, 학교(사회) 구성원과 소통하고, 자신의 능력을 계발하고, 각종 규범과 사회의 가치관을 익히는 등 사람이 ‘사회’ 속에서 살아가는데 필요한 여러 행위를 가르치고 배우고 깨닫게 되는 과정이 공교육이다.</p>
      <p>이러한 공교육의 교육과정은 이 사회의 유지를 목적으로 한다.</p>
      <p>&nbsp;</p>
      <p>사회의 유지를 좀 더 구체적으로 말하면 국가(대한민국)의 유지가 된다.</p>
      <p>공교육의 목적을 ‘국가를 유지하기 위한 교육과정을 통해 미래 세대를 양성하는 것’이라고 보면, 학교는 국가의 존립 기반이라고 할 수 있다.</p>
      <p>그렇다면 공교육이 지향해야할 교육과정의 핵심은 무엇일까?</p>
      <p>&nbsp;</p>
      <p>당연히, 가장 중요한 것은 사회(국가)의 정체성을 가르치는 것이다.</p>
      <p>우리 사회의 정체성은 헌법에 있다.</p>
      <div className={styles.annotation_wrap}>
        <p>사회의 정체성을 규정하는 것이 헌법이므로, ‘헌법의 유지’나 ‘사회의 유지’나 ‘국가의 유지’나 모두 같은 말이라 하겠다.</p>
      </div>
      <p>&nbsp;</p>
      <p>헌법은 국가의 최상위 법이다.</p>
      <p>법은 국가를 구성하고 질서를 유지하기 위한 규정(주권자가 만든 약속)이다.</p>
      <div className={styles.annotation_wrap}>
        <p>주권(主權)이란 ‘가장 주요한 권리’이며, ‘국가의 의사를 최종적으로 결정하는 권력’이다.</p>
        <p>주권자(主權者)란 주권을 가진 사람이다.</p>
        <p>주권자는 곧 주인(主人)이다.</p>
      </div>
      <p>대한민국 헌법 제1조 제1항은 “대한민국은 민주공화국이다.”라고 되어 있다.</p>
      <p>그리고 제2항은 “대한민국의 주권은 국민에게 있고, 모든 권력은 국민으로부터 나온다.”라고 되어 있다.</p>
      <div className={styles.annotation_wrap}>
        <p>민주공화국은 민주주의와 공화주의가 합쳐진 단어다.</p>
        <p>두 정치체제는 고대의 그리스(민주정치)와 로마(공화정치)에서 시작되었는데, 민주주의는 시민에 의한 직접 통치 체제이고, 공화주의는 시민의 대표를 선출하여 통치하는 체제이다.</p>
        <p>보통 직접민주주의와 대의민주주의라고 구분하여 말하는데, 시민이 주권을 가진다는 측면에서는 양자가 동일하다.</p>
        <p>내용(정치철학)으로 구분하면, 공화주의는 공동체 전체의 이익을 지향하고, 민주주의는 개인(다수의 개인)의 권리를 지향한다는 차이가 있다.</p>
        <p>공화주의는 개인의 권리를 제한할 위험이 있고, 민주주의는 다수에 의한 소수의 억압과 공동체 약화의 위험이 있다.</p>
        <p>어느 체제가 더 이상적인가에 대한 사상적 논쟁은 아직 진행 중이다.</p>
        <p>우리나라의 헌법은 두 체제를 병용(민주공화국)하여 정체성을 규정하고 있다.</p>
        <p>형식적으로는 직접민주주의(국민 투표)와 대의민주주의(선출된 대표자에 의한 행정 및 입법 활동)를 병용하고 있고, 내용면에서도 민주주의 성격의 정책과 공화주의 성격의 정책이 섞여 있다.</p>
      </div>
      <p>&nbsp;</p>
      <p>학교는 공교육이 시행되는 기관이고, 공교육은 사회(국가)를 유지하기 위함이고, 사회를 유지하기 위해서는 사회의 정체성을 가르쳐야 하고, 우리 사회가 민주공화국이라는 명제에서 도출되는 명백한 결론은, <span className={styles.bold}>“학교는 사회를 유지하기 위해 민주주의를 가르쳐야 한다.”</span>이다.</p>
      <div className={styles.annotation_wrap}>
        <p>민주주의와 공화주의를 구분한다면 서로 다른 개념이므로, “민주주의와 공화주의를 함께 가르쳐야 한다.”라고 말해야겠지만, 여기에서는 정치철학을 주로 다루는 것이 아니라, 정책이 형성되기까지의 사회적 합의 과정(민주적 절차)이나 교육자치(민주적 운영방식)를 주로 다루기 때문에, 시민이 주권을 가진다는 뜻의 민주주의(넓은 의미에서 공화주의를 포함하는 개념)를 국가 정체성으로 보고, 학교는 민주주의를 가르쳐야 한다고 주장하는 것이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>이것이야말로 공교육이 지향해야할 교육과정의 핵심이고, 학교의 가장 중요한 역할이다.</p>
      <p>&nbsp;</p>
      <p>그런데, 애써 가르치지 않아도 이미 민주공화국인데, ‘민주주의를 가르치는 것’이 뭐 그리 중요한 일이냐고 반문하는 사람이 의외로 많이 있다.</p>
      <p>&nbsp;</p>
      <p>그럼 이제 앞의 질문으로 돌아 가보자.</p>
      <p>&nbsp;</p>
      <p className={styles.bold}>규정을 만드는 사람은 누구인가?</p>
    </div>
  )
}

export default Prologue05