import React from 'react'
import styles from './MainText.module.css'

const Year2015_08 = () => {
  return (
    <div className={styles.wrap}>
      <h1 className={styles.title}>8. 학교장의 위법 행위.</h1>
      <p>첫 회의를 갔다 온 다음날, 독도 씨는 온 종일 마음이 불편했다.</p>
      <p>무엇이 문제였을까? 무엇이 그렇게 독도 씨를 불편하게 했던 것일까?</p>
      <p>&nbsp;</p>
      <p>첫 번째로 신경이 거슬렸던 것은 지역위원 선출의 문제였다.</p>
      <p>당시의 독도 씨는 아는 것보다 모르는 것이 더 많은 상태였기에, 무언가 이상했지만 무엇이 이상한지를 도무지 알 수 없었다.</p>
      <p>게다가 학교운영위원회 관련하여 도움이 될 만한 정보는 꽁꽁 감추어져 있어서(지금도 그렇지만 당시에는 더욱 심했기 때문에), 문제를 파악하는데 필요한 정보를 얻을 방법이 없었다.</p>
      <p>결국 독도 씨는 교육청에 문의하기로 마음먹었다.</p>
      <p>그는 국민신문고에 접속한 후 한비중학교 지역위원 선출 상황을 설명하고, 그와 같은 상황에는 문제가 없는지 문의하는 내용의 민원을 작성하여 접수하였다.</p>
      <p>&nbsp;</p>
      <p>독도 씨가 작성한 문의의 내용과 교육청의 답변에 대해서는 잠시 뒤에 살펴보기로 하고, 지금부터 우리는 지역위원 선출에 대해 자세히 알아보기로 하자.</p>
      <p>도대체 무엇이 문제인지 이번 기회에 정확하게 짚고 넘어가보자.</p>
      <p>&nbsp;</p>
      <p>우선 지역위원에 대한 이해를 돕기 위해, 학교운영위원회의 정수와 구성, 그리고 지역위원의 자격과 그 선출 절차를 알아보자.</p>
      <p>기본적인 내용은 「초·중등교육법 시행령」 및 각 시·도교육청의 조례 등에 의해 규정되어 있다.(법령에서는 범위만 정하고 구체적인 숫자는 단위 학교의 운영위원회 규정에서 정하기도 한다.)</p>
      <p>&nbsp;</p>
      <p>하나, 학교운영위원회 위원의 정수는 상기 시행령에서 5명~15명으로 정하였다.</p>
      <p>학생수가 200명 미만인 학교는 5~8명, 200명~999명인 학교는 9~12명, 1000명 이상인 학교는 13~15명의 운영위원을 둘 수 있다.</p>
      <p>한비중학교의 경우 학생수가 1000명이 살짝 안 되기 때문에 9~12명 사이에서 정할 수 있는데, 한비중학교는 「학교운영위원회 규정」에서 12명으로 정하였다.</p>
      <p>&nbsp;</p>
      <p>둘, 운영위원의 구성도 상기 시행령에서 정하였는데, 정수 내에서 학부모위원 40~50%, 교원위원 30~40%, 지역위원 10~30%로 구성하게끔 되어 있다.</p>
      <p>따라서 어떻게 구성하여도 학부모위원 또는 교원위원 단독으로 과반을 확보할 수 없다.</p>
      <p>대개는 지역위원을 포함해야 과반 확보가 가능하기 때문에, 학부모와 교장의 뜻이 달라서 표 대결을 할 경우 지역위원이 캐스팅보트(결정권)를 쥐게 된다.</p>
      <div className={styles.annotation_wrap}>
        <p>단, 학부모위원이 50%일 경우에는 교원위원과 지역위원의 표를 모두 합해도 과반이 될 수 없다.</p>
        <p>이런 점을 고려해서 운영위원의 정수를 홀수로 정하는 학교가 있다.</p>
        <p>운영위원의 정수가 홀수일 경우, 어떻게 정해도 학부모위원은 무조건 50% 미만이 된다.</p>
        <p>이런 조건은 학교장이 좋아할만한 조건이다. 왜 그런지는 곧 알게 된다.</p>
      </div>
      <p>&nbsp;</p>
      <p>그래서 드문 경우이긴 하지만 간혹 존재하는 흥미진진한(!) 학교의 운영위원회는, 지역위원이 ‘누구의 추천으로 들어왔느냐(누구의 편이 되느냐)’가 학교 운영 정책의 결정에 있어서 매우 중요한 포인트가 되기도 한다.</p>
      <p>한비중학교의 경우, 총원이 12명이고 학부모위원 6명(50%), 교원 위원 4명(33%), 지역위원 2명(17%)으로 구성되어 있으므로 적법하다.</p>
      <p>&nbsp;</p>
      <p>셋, 상기 시행령을 보면, 학부모위원은 당해 학교에 재학 중인 학생의 학부모(부모, 후견인 등의 보호자)여야 하고, 교원위원은 당해 학교에 근무하는 교사여야 한다는 조건이 있다.</p>
      <p>그리고 지역위원은 ‘당해 학교가 소재하는 지역을 생활근거지로 하는 자로서 예산·회계·감사·법률 전문가, 교육행정 공무원, 사업자, 학교 동문, 기타 학교 운영에 이바지하고자 하는 자’로 한다고 그 조건이 명시되어 있다.</p>
      <p>&nbsp;</p>
      <p>학부모위원이나 교원위원이 될 수 있는 자는 그 학교의 학부모와 교사라는 범위가 뚜렷한 자격 조건이 있는데, 지역위원의 경우는 해석의 여지가 상당해서, 가끔 자격 여부를 놓고 논란이 발생하기도 한다.</p>
      <p>‘그 학교가 소재하는 지역을 생활근거지로 하는 자’라고만 규정하고서는 그 소재지를 어디까지 인정할 것인지에 대한 제한규정이 없기 때문에, 학교가 소재하고 있는 읍·면·동까지만 볼 것인지, 좀 더 확장해서 시·군·구까지 볼 것인지 정할 방법이 없다.</p>
      <p>심지어 진부도교육청은 ‘학교운영위원회 업무편람’에서, 타 광역시·도에 살고 있어도 회의에 참석할 수 있다면 지역위원이 될 수 있다고 말하고 있다.</p>
      <p>사실상 지역 제한은 없는 것과 마찬가지다.</p>
      <p>또한 전문가, 공무원, 사업자, 동문 등이 아니어도 ‘학교 운영에 이바지하고자 하는 자’라면 지역위원이 될 수 있는데, ‘학교 운영에 이바지하고자 하는 자’로 인정할 수 있는 객관적인 기준이 없으므로, 당사자가 주장하기만 하면 누구나 그런 사람이 될 수 있다.</p>
      <p>따라서 사실 상 지역위원의 자격에는 제한이 없다고 볼 수 있다.(단, ‘국가공무원법’에 따른 결격 사유 제한은 적용된다. 물론 이것은 학부모위원이나 교원위원에게도 마찬가지다.)</p>
      <p>&nbsp;</p>
      <p>넷, 상기 시행령에 따른 지역위원 선출 절차를 살펴보면 다음과 같다.</p>
      <p>학년 초 새 학기가 시작되면, 가정통신문 등을 통해 학부모위원 선출 공고가 학부모들에게 전달된다.</p>
      <p>뜻이 있는 학부모는 학부모위원에 지원하게 되고, 지원자가 정수(선출 인원)와 같으면 무투표 당선이고, 정수를 초과하면 학부모 전체 투표(학부모 총회)를 거쳐 당선자를 가린다.</p>
      <p>마찬가지로 교사들도 교원위원 지원절차를 거치는데, 정수를 초과하면 교직원 전체회의에서 무기명투표를 통해 교원위원을 선출한다.</p>
      <p>이때 학교장은 당연직 위원으로 무조건 선출된다.</p>
      <p>만일 교원위원의 정수가 4명이라면, 학교장은 무조건 포함되어야 하므로 학교장을 제외한 교사 중에서 3명을 선출한다.</p>
      <p>&nbsp;</p>
      <p>이와 같이 학부모위원과 교원위원이 확정되면, 이들 위원 중 누구라도 제한 없이 지역위원 후보를 추천할 수 있다.</p>
      <p>추천된 사람이 정수 이내면 무투표 당선이 되고, 정수를 초과하면 학부모위원과 교원위원이 무기명투표를 하여 지역위원 당선자를 결정한다.</p>
      <p>여기까지가 지역위원 선출을 위한 법령상의 절차이다.</p>
      <p>&nbsp;</p>
      <p>그런데 위 절차는 법령에서 정한 기본적인(학교마다 공통적인) 절차다.</p>
      <p>각 학교에서는, 구체적인 세부 절차를 따로 정해야 한다.</p>
      <p>예를 들자면, 추천 일정과 투표 일정, 투표 장소, 투표 방식 등이 그것이다.</p>
      <p>일반적으로 학부모위원이나 교원위원 선출을 위한 세부 절차는 각각 선출을 주관하는 선출관리위원회(이하 선관위)에서 선관위원들이 정한다.</p>
      <div className={styles.annotation_wrap}>
        <p>혹은 구체적인 세부 절차를 아예 학교 규정으로 정해 놓을 수도 있다.</p>
        <p>이 경우 선관위는 세부 절차를 따로 정하지 않고, 학교 규정에서 정한 세부 절차대로 선거를 관리하면 된다.</p>
        <p>하지만 이 정도로 꼼꼼하게 규정을 만드는 학교는 매우 드물 것이다.</p>
        <p>참고로, 진부도교육청의 「학교운영위원회 설치·운영 조례」에는 ‘위원의 선출 절차 등에 관하여 필요한 사항은 해당학교 운영위원회 규정으로 정한다.’라는 것과, ‘학부모위원과 교원위원 선출을 위해 각각의 선출관리위원회를 구성하라.’는 내용이 명시되어 있다.</p>
      </div>
      <p>&nbsp;</p>
      <p>그러나 지역위원 선출의 경우, 학부모위원과 교원위원을 합한 수(4명~13명)가 많지 않아서 별도의 선관위를 구성하지 않고 직접 모여서 정하는 경우가 대부분이다.</p>
      <div className={styles.annotation_wrap}>
        <p>번거롭기는 하지만, 별도의 선관위를 구성해도 된다.</p>
        <p>하지만 학년 초의 일정은 매우 빠듯하다. 가급적 간편하게 직접 모여서 정하는 것이 좋다.</p>
      </div>
      <p>&nbsp;</p>
      <div className={styles.annotation_wrap}>
        <p>그런데 가끔 세부 절차는 학교장이 정하는 것이 아니냐고 묻는 사람들이 있다.</p>
        <p>아무래도 민주적 절차에 익숙하지 않아서 나오는 질문일 것이다.</p>
        <p>규정 혹은 규정에 없는 절차 따위를 정하는 사람은 주권자이다.(주권자는 스스로(직접) 규정을 정할 수도 있고, 대의 기구를 통해 간접적으로 규정을 정할 수도 있다. 예를 들자면 헌법은 주권자인 시민이 직접 정하는 규정이고, 법은 국회를 통해 간접적으로 정하는 규정이다.)</p>
        <p>지역위원을 선출하는(추천하고 투표하는) 권리는 학부모위원과 교원위원에게 있다.</p>
        <p>따라서 별도의 법령이나 학교 규정으로 정하지 않았다면, 지역위원 선출 절차는 학부모위원과 교원위원이 논의하여 정해야 한다.</p>
        <p>학교장과 운영위원회는 서로 독립적인 기구이다.</p>
        <p>학교장은 한 사람의 운영위원으로서 선출에 참여할 수는 있지만, 학교장으로서 선출에 개입해서는 안 된다.</p>
        <p>만일 학교장이나 행정실에서 세부 절차를 결정한다면, 이는 명백한 월권행위이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>정리하자면, 지역위원은 학부모위원과 교원위원이 추천하고 투표로 선출하되, 구체적인 세부 절차(일정, 방식 등)는 학교운영위원회 규정으로 정하거나, 선관위에서 정하거나, 선출의 권한을 가진(선출 주체인) 학부모위원과 교원위원이 모여서 직접 정해야 한다.</p>
      <div className={styles.annotation_wrap}>
        <p>부연 설명이 길어지지만, 여기서 하나만 더 짚고 넘어가자.</p>
        <p>지역위원 선출을 위해 학부모위원과 교원위원이 모여서 세부 절차를 논의해야 하는 경우, 이 모임을 주관하는 책임자는 누구냐는 것이다.</p>
        <p>상기 시행령은 ‘이 모임’을 주관하는 주체를 별도로 명시하고 있지 않다.</p>
        <p>하지만 「초·중등교육법」을 보면, ‘학교에 학교운영위원회를 구성·운영하여야 한다.’고 되어 있으므로, 학교운영위원회 구성·운영의 책임이 ‘학교’에게 있음을 알 수 있다.</p>
        <p>학교의 책임자는 학교장이므로, 학교운영위원회 구성에 필요한 모든 절차의 책임자이자, ‘이 모임’을 주관하는 주체는 바로 학교장이다.</p>
        <p>즉 학부모위원과 교원위원이 선출되면, 모임을 주관하고 그들에게 후속 절차를 안내하는 것은 학교장의 업무이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>참고로, 결정해야할 세부 절차 중에서 가장 중요한 것은 투표 방식의 결정이다.</p>
      <p>2인 이상을 선출할 때, 한 번만 투표 하여 순위대로 선출할 것인지 아니면 한 번에 한 명씩 뽑는 방식으로 여러 번 투표 할 것인지 결정할 수 있다.</p>
      <p>또한 1명에게만 기표하는 단기명 방식으로 할 것인지, 여러 명에게 기표하는 연기명 방식으로 할 것인지도 결정할 수 있다.</p>
      <p>투표 결과 과반이 안 되어도 다득표자면 당선자로 선출 할 것인지, 과반 득표가 아닐 경우 결선 투표를 통해 당선자를 선출 할 것인지를 결정할 수 있으며, 동점자일 경우의 당선 처리 기준 등을 결정할 수 있다.</p>
      <p>투표 방식에 따라 당락이 달라질 수 있기 때문에, 투표 방식은 상당히 중요한 이슈가 되며, 이를 놓고 대립이 일어나는 경우도 종종 발생한다.(독도 씨도 이후 5년 동안 5번의 투표 중에 3번이나 투표 방식의 결정을 놓고 학교장과 대립하는 경험을 하게 된다.)</p>
      <div className={styles.annotation_wrap}>
        <p>진부도교육청의 「학교운영위원회 설치·운영 조례」에는, 운영위원장 및 부위원장 선출 투표 시 동점이면 연장자를 당선자로 한다는 조항이 있다.</p>
        <p>이는 「공직선거법」의 국회의원, 지방자치단체장 등 선출 시 동점자 처리 기준과 같다.</p>
        <p>이 때문에 지역위원 선출 시에도 동점일 경우 연장자를 당선자로 결정하는 것이 당연하다고 생각하는 학교가 많다.</p>
        <p>그러나 지역위원 선출 시에 동점자를 처리하는 기준은 법령에서 규정하고 있지 않다.</p>
        <p>따라서 어떤 기준으로 선출하는 것이 좋을 지는 각 학교에서 자율적으로 결정할 수 있다.</p>
        <p>독도 씨 개인적으로는, 연장자 우대를 좋아하지 않는다. 21세기에 장유유서(長幼有序)라니! 아름답지도 않을뿐더러 이는 헌법의 평등권에 위배되는 명백한 차별이다. 다른 기준을 정할 수 없다면 차라리 추첨을 하는 것이 공정하다고 생각한다.</p>
      </div>
      <p>&nbsp;</p>
      <p>여기까지 지역위원의 자격과 선출 절차를 알아보았는데, 독도 씨가 이상하다고 생각했던 부분은 바로 한비중학교의 지역위원 선출 절차였다.</p>
      <p>&nbsp;</p>
      <p>당시 독도 씨가 진부도교육청에 전화했을 때 받은 답변은, <span className={styles.quo_mini}>‘학교 행정실에서 학부모위원과 교원위원 모두에게 지역위원 추천서를 나눠주면서 언제까지 추천하라고 안내를 해준다.’</span>였다.</p>
      <p>그런데 사실 이는 일종의 편법이다.</p>
      <p>민주적인 절차대로라면 선출 권한을 가진 학부모위원과 교원위원이 직접 일정과 투표 절차를 정해야 한다.</p>
      <p>그러나 학년 초의 빠듯한 일정상, 행정 편의를 위해, 학교 행정실에서 추천 마감일을 정하고 추천서를 나누어 주는 경우가 많다.</p>
      <p>추천 일정은 누가 정하여도 거의 차이가 없기 때문에, 이에 대해 운영위원들이 특별히 이의를 제기할 필요가 없으니, 보통은 문제가 되지 않는다.</p>
      <p>(물론 정석대로 학부모위원과 교원위원이 모여서 직접 일정을 정하는 학교도 있다.)</p>
      <p>&nbsp;</p>
      <p>어쨌든 진부도교육청의 설명대로만 진행되었어도 괜찮은데, 한비중학교는 절차 논의를 위한 모임(회의)을 열지 않았고, 행정실에서 안내하지도 않음으로써, 운영위원들의 지역위원 추천 절차를 아예 생략하였다.</p>
      <p>그러고 나서 학교장이 2명의 지역위원을 단독으로 추천하여 무투표로 당선시켰다.</p>
      <div className={styles.annotation_wrap}>
        <p>굳이 비유하자면, 국회의원을 선출하는 선거에서, 대통령이 중앙선거관리위원회를 조종하여 후보등록 공고를 하지 않아서, 다른 국민은 입후보 하지 못 하고, 대통령이 몰래 후보등록 일정과 방법을 알려준 사람만 단독 입후보 하여 당선된 것과 같다.</p>
        <p>이렇게 하면, 선출이 아니라 임명이다.</p>
        <p>당연하게도, 그렇게 당선 된 사람은 국민을 위해 일하지 않고, 대통령의 뜻에만 충성하는 꼭두각시가 될 것이다.</p>
        <p><span className={styles.fbody}>“아무도 입후보하지 않을 것 같아서 후보등록 공고를 하지 말라고 했다. 다만 내 주변의 인재들에게는 알려줬는데, 고맙게도 그들이 입후보 해줬다.”</span>고 말해도 국민들이 가만히 있으면, 그 나라의 정치(민주주의) 수준은 낮은 정도가 아니라 아예 없는 수준이라고 할 수 있다.</p>
        <p>마찬가지로 <span className={styles.fbody}>“위원님들께 안내를 드려도 보통은 추천을 하지 않기 때문에, 그냥 제가 혼자 추천했습니다.”</span>라고 말해도 문제가 무엇인지 모른다면, 그 학교의 민주주의 수준도 0점에 가깝다고 할 수 있다.</p>
      </div>
      <p>&nbsp;</p>
      <p>임춘옥 교장이 2명을 추천하였지만, 이는 추천을 통한 선출이 아니다.</p>
      <p>학교장 지명으로 선발한 것과 같다.</p>
      <p>당연히 위법 행위이다.</p>
      <p>그리고 이러한 위법 행위가 드러날 경우, 위법의 결과로 구성된 운영위원회는 무효가 되므로 지역위원을 재선출 하여야 한다.</p>
      <p>또한 이미 끝난 1회 운영위원회 회의도 무효 처리되므로 심의한 안건도 모두 무효가 된다.</p>
      <p>이렇게 되면 운영위원회를 재구성 한 후 모든 안건을 재심의 하여야 한다.(심의 후 시행까지 완료한 안건의 경우, 심의가 무효가 되면 심의 없이 시행된 꼴이 되어, 이것 또한 위법 행위가 된다.)</p>
      <p>그러므로 위법 행위를 저지른 임춘옥 교장이 그 책임을 묻는 교육청으로부터 징계를 받을 가능성은 매우 높다고 할 수 있다.</p>
      <p>독도 씨의 질문에, 교장과 교감이 당황했던 것은 다 이유가 있었다.</p>
      <p>&nbsp;</p>
      <p>참고로, 이러한 위법 사례는 그리 희귀한 사례가 아니다.</p>
      <p>여러 학교에서 가끔 발생하고 있다.</p>
      <p>하지만 운영위원이면서도 법령이며 학교 규정을 전혀 모르는 사람들이 대부분이라서, 이와 같은 위법 행위가 발생해도 모르고 넘어가는 경우가 많다.</p>
      <div className={styles.annotation_wrap}>
        <p>운영위원이라면, 학교 홈페이지에 접속하여 학교의 주요 규정 정도는 반드시 내려 받고 읽어 봐야 한다.</p>
      </div>
      <p>게다가 누군가 이를 발견하고 문제 삼아 민원을 제기할 경우, 그 파장이 크기 때문에(명백한 위법 행위이고, 수습이 어려운 경우가 많다), 교육청에서 적극적으로 민원인에게 취하를 요청하여 대부분 무마시킨다.</p>
      <p>그러므로 가끔 발생하지만, 인지하지 못하거나 무마시키기 때문에, 거의 알려지지 않는다.</p>
      <div className={styles.annotation_wrap}>
        <p>취하 요청을 하면서 <span className={styles.fbody}>‘수습이 어렵다.’</span>고 말하는 것은 비겁한 변명이다.</p>
        <p>수습이 어려워질수록 위법 행위에 대한 책임이 커지는 것이기에, 교장에 대한 징계 수위가 올라간다는 것이 문제이지 수습이 불가능한 것이 아니기 때문이다.</p>
        <p>교육청은, 제 식구를 감싸려고 민원 취하를 요청하면서, 민원인에게는 <span className={styles.fbody}>‘파장이 커서 학생들에게 피해가 간다.’</span>는 논리를 내민다.</p>
        <p>가장 만만한 볼모를 핑계로 삼아 자기 식구를 보호한다.</p>
        <p className={styles.bold}>팔은 안으로 굽는다.</p>
        <p>학생들에게 공정과 정의를 가르쳐야 하는 교육계조차도 이 명제에서 자유롭지 못하다.</p>
        <p>통탄할 일이다.</p>
        <p>가르침(공정과 정의)을 실천하지 못하면 가르침에 대한 신뢰는 추락한다.</p>
        <p>학교에서 배운 것을 신뢰하지 못하는 사회는 안전하지 못하다.</p>
        <p>공정과 정의를 무시함으로 인해 일어나는 인재(人災)는 끊이지 않고 반복하여 발생한다.</p>
        <p>무엇이 진짜 피해인가?</p>
        <p className={styles.bold}>‘학교가 어수선해지는 것’과 ‘공정과 정의를 신뢰하지 못하는 사회’ 중에서, 학생들에게 더 큰 피해를 주는 것은 어떤 것인가?</p>
      </div>
      <p>&nbsp;</p>
      <p className={styles.quo}>“뭐 그럴 수도 있지. 그게 그렇게 잘못된 일인가? 어차피 다른 사람들이 잘 추천하지 않으니까 교장선생님이 알아서 추천한거잖아. 그리고 다 ‘학교 운영에 이바지할 사람’이고, 누가 되든 큰 차이가 없잖아?”</p>
      <p>아마 당시 한비중학교 운영위원들에게 개인 의견을 물었다면 모두 이렇게 말했을 것이다.</p>
      <p>그리고 어쩌면, 그때나 지금이나 전국 학교의 수만 명의 운영위원들도 같은 말을 할 것이다.</p>
      <p>법령 위반 사안이지만 별 일 아니라고 생각하는, 이것이 어떤 의미를 가지는지 잘 모르는 사람들이 상당히 많다.</p>
      <p>그렇다면 이제, 지역위원이 어떤 의미를 가지는지, 왜 한비중학교의 임춘옥 교장을 비롯하여 많은 교장들이 위와 같은 위법 행위를 하는지 알아보자.</p>
    </div>
  )
}

export default Year2015_08