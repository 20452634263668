import React from 'react'
import styles from './MainText.module.css'

const Prologue13 = () => {
  return (
    <div className={styles.wrap}>
      <h1 className={styles.title}>13. 전제주의거나 독재거나.</h1>
      <p>민주주의는 시민(국민)이 사회의 주권을 행사하는 정치체제다.</p>
      <p>&nbsp;</p>
      <p>민주주의의 상대 개념으로는 전제주의가 있다.</p>
      <p>전제주의는 권력자(왕과 같은 개인이나 귀족과 같은 특정세력)가 주권(법을 제정하고 개정하는 권리)을 가진다.</p>
      <p>예컨대 조선시대가 이에 해당된다.</p>
      <p>&nbsp;</p>
      <p>민주주의의 또 다른 상대 개념으로 독재정치가 있는데, 독재(dictatorship)는 민주주의거나 민주주의처럼 대중의 지지를 요하는 정치체제에서 권력을 획득한 권력자가, 주권자의 실질적인 주권 행사를 못하게 막고 권력을 독점하는 변질된(오염된) 민주주의를 뜻한다.</p>
      <p>&nbsp;</p>
      <p>전제군주인 왕은 법을 결정하는 주권자인데, 독재자는 시민이 주권자인 체제에서 그 주권을 무력화하고 권력을 행사하므로, 전제정치나 독재정치나 겉으로 보이는 모습은 크게 다를 바 없으나 엄밀하게는 서로 구분되는 정치체제이다.</p>
      <div className={styles.annotation_wrap}>
        <p>독재는 전제정치와 달리, 헌법 등의 사회 규정은 민주주의 혹은 민주주의와 유사한 형태로 구성되어 있다.</p>
        <p>군사적 힘에 의해서 권력을 쟁취하거나, 대중의 지지에 의해 권력을 쟁취하거나, 법(예를 들면 계엄)에 의해 권력이 집중되는 형태 등 다양한 모습의 독재가 존재하는데, 이를 유지하는 방법은 크게 두 가지가 있다.</p>
        <p>군대 등의 물리력으로 법의 제정·개정을 힘으로 강제하고 사법부 또한 장악하여 법을 초월하는 통치를 하는 물리적 독재가 있고, <span className={styles.bold}>정보의 독점·은폐와 여론의 장악·조작 등을 통해 주권자의 눈과 귀를 가리고 부패한 현실을 왜곡·세뇌하여 장기 집권을 이어가는 세뇌를 이용한 독재</span>가 있다.</p>
      </div>
      <p>&nbsp;</p>
      <p>대한민국은 민주공화국이고, 공교육(학교)은 민주주의를 가르쳐야 한다.</p>
      <p>하지만 공교육(학교)은 민주주의를 가르치기는커녕 민주주의를 구축하고 있다.</p>
      <p>따라서 당신은, 학교의 운영방식이 전제정치 혹은 독재정치에 가까울 것이라 추측해볼 수 있을 것이다.</p>
      <p>&nbsp;</p>
      <p>이제, 왜 학교가 민주주의를 구축하는지, 그 이유를 살펴보자.</p>
    </div>
  )
}

export default Prologue13