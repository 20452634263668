import React from 'react'
import ss from './MainText.module.css'

const Year2015_12 = () => {
  return (
    <div className={ss.wrap}>
      <h1 className={ss.title}>12. 다른 문제들(2)</h1>
      <p>세 번째, 회의 공개의 문제.</p>
      <p>의견 수렴의 문제나, 회의 공개의 문제는 사실 독도 씨가 회의에 참석하기 전에는 인지하지 못했던 문제였다.</p>
      <p>법률 전문가가 아닌 일반인 독도 씨에게 있어서 법, 시행령, 조례, 학교의 규정 같은 딱딱한 내용의 문서는 쉽게 읽혀지는 것이 아니었다.</p>
      <p>당장 관심 있었던 운영위원회의 기능(심의 대상)이나 위원의 자격·구성·절차·임기·의무 등에 대한 내용을 자세히 살펴보는 것만 해도 쉽지 않은 일이어서 다른 부분까지 세세하게 살펴보지는 못했다.</p>
      <p>그러나 첫 회의를 경험한 후, 운영위원회의 모습이 생각했던 것과 많이 달랐기에, 여러 가지 의문점들이 생겼고, 그래서 유일하게 얻을 수 있는 정보인 법령과 학교 규정을 다시 한번 꼼꼼히 살펴볼 수밖에 없었는데, 회의를 한 번 경험해 보았기 때문인지 그전에는 잘 안 보였던 여러 가지 문제들이 이때에는 쉽게 눈에 띄었다.(역시 경험만큼 훌륭한 가르침은 없다.)</p>
      <p>&nbsp;</p>
      <p>진부도교육청의 「학교운영위원회 설치·운영 조례」를 보면, 운영위원회가 회의를 소집할 때는 일시와 안건 등을 가정통신문이나 학교 홈페이지 등을 통해서 알리라고 되어 있다.</p>
      <div className={ss.annotation_wrap}>
        <p>「초‧중등 교육법 시행령」에도 같은 내용이 있는데, 2016년 10월에 개정된 내용으로 지금은 시행되고 있지만, 2015년에 독도 씨가 관련 규정을 살펴볼 때에는 존재하지 않았다.</p>
      </div>
      <p>또한 한비중학교 「학교운영위원회 규정」에도 “회의 개최 시에는 가정통신문, 학교게시판, 학교 홈페이지 등에 개최일자, 안건 등을 알림으로써 학생, 학부모, 교직원 등이 회의에 참관할 수 있도록 하여야 한다.”고 명시되어 있다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo_mini}>‘운영위원회는 학교 운영에 관한 안건을 심의하는 것이고, 학생과 학부모, 교직원의 의견을 반영하는 회의인데 이를 비밀로 해서는 안 되겠지. 당연히 회의는 공개가 원칙인거야.’</p>
      <p>이렇게 생각한 독도 씨는 법령과 학교 규정에 있는 관련 조항을 세 가지로 요약하여 정리해 보았다.</p>
      <p>하나, 회의 전에 일자와 안건을 공개(공지)한다.</p>
      <p>둘, 회의도 (원하는 사람은 참관할 수 있도록) 공개한다.</p>
      <p>셋, 회의록을 작성하여 공개한다.</p>
      <p>(다만 개인 정보 보호, 공정성 확보, 교육 또는 교권 보호 등의 필요가 있을 경우, 회의는 비공개로 할 수 있고, 회의록도 공개하지 않을 수 있다.)</p>
      <p>&nbsp;</p>
      <p>그런데 학교 홈페이지를 보면, 지난 회의록은 공개가 되어 있지만, 회의 일정을 공개한 게시물은 존재 하지 않았고, 지난 회의록 어디에도 운영위원, 교감, 간사, 안건 설명자 외의 사람(학생, 학부모, 교직원 등)이 참관한 기록이나 참석하여 발언한 기록은 없었다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo_mini}>‘학기 초의 나처럼 운영위원이 뭔지 모르는 사람이 태반일 텐데, 참관하라고 알려줘도 그게 뭐냐고 되물을 판에……, 언제 회의를 하는지 공개하지 않겠다는 것은 아예 참관이 불가능한 조건을 만들겠다는 거네. 참관이 싫다는 건데……, 하긴, 그런 수준의 회의를 누가 참관한다는 것이 진심 쪽팔린 일이긴 하겠다. 지난 회의록을 봐도 질문 하나 없이 원안 가결된 안건이 수두룩하네.’</p>
      <p>&nbsp;</p>
      <p>훗날 독도 씨는 한비중학교의 운영위원장이 되어 운영위원회를 정석대로 운영하려고 노력하였는데, 그 과정에서 번번이 부딪히던, 당시 가장 강력한 교장의 방패였던 김봉식 교감의 훼방에 많은 어려움을 겪었다.</p>
      <p>그래서 결국, <span className={ss.quo_mini}>‘이 방법이라도 해야겠다.’</span>면서 담판을 짓기 위해 <span className={ss.quo}>“저하고 술 한 잔 하시죠?”</span>라는 승부수를 던졌고, 흔쾌히 응한 교감과 조금 부드러운 분위기에서 긴 대화를 나누었다.</p>
      <p>두 사람이 당시 주거니 받거니 취하도록 마시면서 나눴던 여러 이야기 중에 회의 공개에 관한 이야기도 있었기에 잠시 소개하고자 한다.(2016년도 가을의 일이었다.)</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“진부도교육청 조례에, 운영위원회 회의 소집 시 학교 홈페이지에 일시와 안건을 공개하라고 되어 있는데 왜 안하는 겁니까? 이거 작년에 제가 지적한 이야기인데, 그 뒤로 더 문제 삼지 않았더니 고쳐지지가 않네요. 계속 이러실 겁니까?”</p>
      <div className={ss.annotation_wrap}>
        <p>당시 독도 씨는 회의 공개의 책임자가 학교장(홈페이지 운영자)이라고 잘못 생각하고 있었는데, 임춘옥 교장이나 김봉식 교감도 학교장의 책임인 것처럼 말했다.</p>
        <p>왜 그런지는 위에서 설명한 바와 같다.</p>
      </div>
      <p className={ss.quo}>“허허, 그랬나요? 그 부분은 신경을 못 써서……, 한번 체크를 해봐야겠네요.”</p>
      <p>얼큰하게 붉어진 얼굴의 김봉식 교감은 실실 웃으면서 이야기를 했다.</p>
      <p className={ss.quo}>“우리 학교 규정에도 나와 있는 이야기에요. 그러므로 학교 규정 위반! 조례 위반! 고친다고 하고 안 고쳤으니 신뢰 위반!”</p>
      <p>독도 씨는 라임을 살려 대화를 이어나갔다.</p>
      <p className={ss.quo}>“아이고 위원장님. 또 왜이러실까. 우리 딱딱하게 말하지 않기로 해놓고.”</p>
      <p>말은 그렇게 했지만, 두 사람의 분위기는 가볍고 부드러웠다.</p>
      <p className={ss.quo}>“잘 지키자는 말이죠. 그리고 규정을 떠나서, 회의 한다고 알리고 와서 보라고 해야, 관심이 있고 궁금한 사람들이 와서 보죠. 그게 정상 아닌가요?”</p>
      <p className={ss.quo}>“에이, 그런 사람은 없어요. 제가 교직 생활이 몇 년인데, 단 한 명도 못 봤어요.”</p>
      <p>단언이었다. 김봉식 교감은 가끔 확실하지 않은 것을 확실하다고 단언하여 실수하는 경우가 있는데, 자신의 경험에 대해서는 더욱 확신을 가지고 단언하고는 했다.</p>
      <p className={ss.quo}>“다른 학교에서도 알리지 않았으니까 그랬겠죠. 저와 같이 활동하는 부종교육포럼 회원들 중에는 실제 참관해봤다는 사람이 있습니다. 저도 나중에 운영위원을 하지 않을 때 그럴 계획이고요.”</p>
      <p>독도 씨의 말이 약간 빨라지면서 힘이 들어가기 시작했다. 그러자 바로 김봉식 교감은 힘을 빼자는 뜻으로 소주잔을 들어 독도 씨에게 건배를 청했다.</p>
      <p className={ss.quo}>“그러지 마세요. 우리 사장님 힘들어요. 이제 좀 쉽게 갑시다. 그 부종교육포럼인가 하는 단체도 소문이 안 좋아요. 다른 회사 사장님들도 불만이 많다고 합디다.”</p>
      <p>김봉식 교감은 종종 학교를 회사로, 교장을 사장으로 표현했다.</p>
      <p>소주 한 잔씩을 털어 넣고, 독도 씨는 교감의 잔에 술을 따르면서 물었다.</p>
      <p className={ss.quo}>“아니 근데, 제가 진짜 궁금해서 그런데요. 왜 힘든 건데요?”</p>
      <p>이번에는 교감이 독도 씨의 잔에 술을 따르면서 대답했다.</p>
      <p className={ss.quo}>“거참, 위원장님도 누가 우리 집에 들어와서 가족회의 하는 거 지켜보면 부담스럽지 않겠어요?”</p>
      <p className={ss.quo}>“에? 그게 무슨 소리에요? 운영위원이 가족이고 학부모가 남이에요? 참관하는 게 부담스러울 수 있다는 생각은 들지만, 그 정도로 배타적일 필요가 있나요? 학교의 구성원들은 다 한 가족이에요. 운영위원은 그 학부모들의 대표고요.”</p>
      <p className={ss.quo}>“아니에요. 남보다 더 해요. 아직 젊고 경험이 적어서 잘 모르실거에요. 학부모들이 얼마나 극성인지. 모르면 그냥 넘어갈 일도 알면 한마디 하려고 어찌나 애를 쓰는지. 잘 알지도 못하면서 말이에요. 어휴, 그냥 맘대로 하라고 하면 회사 문 닫아야 해요. 아예 안돌아 갑니다.”</p>
      <p>김봉식 교감의 말은 평상 시 같으면 나오지 않았을 속마음이었다. 그의 학부모 비하 발언은 편협했고, 듣는 순간 독도 씨는 기가 막혀서 잠시 자신의 귀를 의심했다.</p>
      <p className={ss.quo}>“아, 정말……. 무슨 말씀을 하시려는 건지 알겠는데요. 그건 일단 지나치게 일반화한 것이고요. 일부 사람들이 자치 운영 절차를 잘 모르거나 토론 문화에 익숙하지 않아서 매끄럽지 못한 경우가 있다는 것은 저도 인정합니다. 모르면 그럴 수 있잖아요. 그런데 그렇다고 아예 학교자치를 하지 않겠다고 하는 것이 말이 됩니까? 그럴수록 자꾸 개방해서 익숙해지게 해야지요. 혼란이 있다고 해도 금방 잡힙니다. 금방 안 잡힌다고 해도 우리는 개방해야 하고요. 왜 자꾸 쌍팔년도 식으로 운영하려고 하십니까? 이제 알파고의 시대라고요. 제발 교문 좀 걸어 잠그려고 하지 마세요!”</p>
      <p>&nbsp;</p>
      <p>대화는 오랫동안 계속 이어졌지만, 이날의 나머지 대화는 나중에 기술하도록 하고, 일단 여기까지만 살펴보자면, 교감의 말은 위의 의견 수렴의 문제와 관련해서 교장이 한 말과 다를 바가 없다.</p>
      <p>공개(공지)를 하지 않는 이유를 묻자 <span className={ss.quo_mini}>‘신경 쓰지 못 했다.’</span>라고 대답을 하였고, 이후 <span className={ss.quo_mini}>‘공지해도 참관하러 오는 사람은 없다.’</span>라고 말했는데, 이는 <span className={ss.quo_mini}>‘공지해도 참관하러 오는 사람이 없으니 공지를 하든 말든 차이가 없다. 그래서 신경 쓰지 않게 된다.’</span>는 것으로 연결하여 이해해도 무리가 없을 것이다.</p>
      <p>물론 그동안 신경 쓰지 않았어도 아무도 문제 삼지 않았다는 뜻이기도 하다.</p>
      <p>&nbsp;</p>
      <p>이에 대한 독도 씨의 생각은 명확했다.</p>
      <p className={ss.quo_mini}>‘진부도교육청 조례와 한비중학교 규정에서 정한 것이므로 당연히 지켜야 하는 것이 맞고, 규정을 떠나서라도, 대의 기구의 회의는 공개가 원칙이므로, 사람들이 참관하러 오든 안 오든 결과에 관계없이 공지해야 하는 것이 옳다.’</p>
      <p>&nbsp;</p>
      <p>그리고 독도 씨는 <span className={ss.quo_mini}>‘참관해봤다는 사람이 있습니다. 저도 그럴 계획이고요.’</span>라고 했는데, 이는 참관하러 오는 사람이 없다는 김봉식 교감의 단언에 대한 명확한 반박 사례이다.(실제로 독도 씨는 한비중학교 운영위원이 아니었던 2018년에 운영위원회 참관 신청을 한 적이 있다)</p>
      <div className={ss.annotation_wrap}>
        <p>김봉식 교감의 ‘참관하러 오는 사람이 없다.’와 앞서 임춘옥 교장의 ‘아무도 읽지 않는다.’는 발언은, 비록 독도 씨 등의 반박 사례가 존재하지만 사실상 드문 사례라는 점에서, 일반화의 오류라고 비판하기 어렵다.</p>
        <p>다만, 이는 애초에 ‘없기를 바라는’ 듯 적극적으로 공지하지 않고, 참관이나 참여를 유도하지 않고, 운영위원회 운영에 필요한 학부모 연수를 형식적으로 하고(어용기구화 유지), 운영위원회의 존재나 심의 안건을 노출시키지 않음으로써 학부모가 인지할 수 없게 만드는 등 독단적 운영을 유지하기 위한 여러 행위들에서 기인한 결과이므로(고의거나 미필적 고의거나), <span className={ss.bold}>인과 전도의 오류</span>라고 비판할 수 있다.</p>
      </div>
      <p>덧붙여, 김봉식 교감은 <span className={ss.quo_mini}>‘누군가 지켜보는 것이 부담스럽다.’</span>는 속마음을 드러냈는데, 이는 학교운영위원회가 거수기로 구성되었기에 날치기로 처리되는 회의의 수준이 공개가 불가할 정도로 부끄럽다는 것이고, 의견 수렴이나 의사소통이 없는 밀실행정에 대한 비판을 감내하기 어렵다는 것을 의미한다.</p>
      <p>게다가 회의가 공개되다 보면 독도 씨와 같은 질 나쁜(?) 학부모들이 늘어나는 최악의(!) 사태가 발생할 수 있고, 사람들이 학교운영위원회를 잘 알게 되면 더 이상의 독단적 운영을 이어갈 수 없게 된다는 것도 부담스러운 요인이 된다.</p>
      <p>결국 그러한 부담스러움 때문에 운영위원이 아닌 학부모를 ‘남’(외부인)이라 표현한 것인데, 학교는 교사의 직장이고, 직장에서 직원들이 하는 일을 외부인이 이래라 저래라 하는 것이 싫다는 것을 솔직하게 드러낸 표현이라 할 수 있다.</p>
      <div className={ss.annotation_wrap}>
        <p>학교는 교사의 직장이지만, 교사의 직장인 것만은 아니다.</p>
        <p>이런 정체성의 차이를 모르는 관리자가 있다는 것도 우리 교육의 불행이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>그러니까 김봉식 교감은 공교육이 지향하는 운영 방식(학교자치)을 완전히 부정하고 있다.</p>
      <p>이처럼 겉으로는 학교자치를 존중하는 척 하지만 속마음은 깡그리 부정하는 교사가 어디 한둘이겠냐 만은, 혹시 이런 교사일수록 관리자로 진급하기 쉬운 것은 아닌지 냉정하게 점검해볼 필요가 있다고 하겠다.</p>
      <div className={ss.annotation_wrap}>
        <p>부종시교육지원청의 학교행정지원과 대외협력팀 조진일 팀장은 다음과 같은 의견을 피력한 바 있다.</p>
        <p className={ss.fbody}>“운영위원회 회의를 공개하는 것은 필요한 일이지만, 참관하는 사람이 너무 많아 장내가 소란스러워지거나, 불필요한 발언을 남발하여 회의 시간이 지체되거나, 기타 불상사가 발생하면 회의 진행이 곤란해질 수 있기 때문에, 학부모회 임원이나 학생회 임원 정도로 참관을 제한하는 것이 필요할 수 있다.”</p>
        <p>하지만 참관 승인, 장내 정돈, 발언 기회 부여 등은 모두 회의를 주관하는 운영위원장의 역할이므로, 운영위원장의 합리적 판단이 필요한 것이지 자격 제한이 필요한 것이 아니다.(참관 자격을 제한하는 것은 공개 원칙에 위배된다.)</p>
        <p>또한 이런 점을 잘 생각해보면, 참관으로 인해 누군가에게 부담이 발생할 수 있다면, 그 사람은 바로 회의를 주관하는 운영위원장이지, 회의 진행과 관계없는 교장과 교감이 아니라는 것을 알 수 있다.</p>
        <p className={ss.bold}>정상적인 상황이라면, 학부모의 운영위원회 참관에 학교장이 부담을 가질 이유는 없다.</p>
      </div>
      <p>&nbsp;</p>
      <p>참고로, 그날 밤에 두 사람은 만취해서, 서로 앞으로 잘 협력하자며 어깨동무까지 하고 술집을 나섰지만, 결국 운영위원 임기 마지막까지도 두 사람의 가치관의 간극은 좁혀지지 않았고 대립은 계속되었다.</p>
      <p>여담이지만, 이날 이후 김봉식 교감은 <span className={ss.quo}>“교사는 모두 대졸자이다. 평균 학력이 높은 우월한 집단이다.”</span>라는 학력주의 발언이나, <span className={ss.quo}>“여편네들 치맛바람이 문제다.”</span>라는 여성·학부모 비하 발언을 독도 씨에게 했는데, 독도 씨 입장에서는 그와 가까워지고 싶어도 가까워질 수 없게 만드는, 용납이 어려운 발언들이었다.</p>
      <p>아무래도 그는, 조금 친해진 사람에게는 본심을 숨길 수 없었던 ‘솔직한 선생님’이라고 생각된다. 물론 학력주의 가치관이나 여성과 학부모를 비하하는 속마음을 가진 교사가 더 있을 것이라고는 생각하지 않는다.</p>
      <p>믿거나 말거나.</p>
    </div>
  )
}

export default Year2015_12