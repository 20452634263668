import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styles from './School3_read.module.css'
import Prologue01 from './component/Prologue01'
import Prologue02 from './component/Prologue02'
import Prologue03 from './component/Prologue03'
import Prologue04 from './component/Prologue04'
import Prologue05 from './component/Prologue05'
import Prologue06 from './component/Prologue06'
import Prologue07 from './component/Prologue07'
import Prologue08 from './component/Prologue08'
import Prologue09 from './component/Prologue09'
import Prologue10 from './component/Prologue10'
import Prologue11 from './component/Prologue11'
import Prologue12 from './component/Prologue12'
import Prologue13 from './component/Prologue13'
import Prologue14 from './component/Prologue14'
import Prologue15 from './component/Prologue15'
import Prologue16 from './component/Prologue16'
import Year2015_01 from './component/Year2015_01'
import Year2015_02 from './component/Year2015_02'
import Year2015_03 from './component/Year2015_03'
import Year2015_04 from './component/Year2015_04'
import Year2015_05 from './component/Year2015_05'
import Year2015_06 from './component/Year2015_06'
import Year2015_07 from './component/Year2015_07'
import Year2015_08 from './component/Year2015_08'
import Year2015_09 from './component/Year2015_09'
import Year2015_10 from './component/Year2015_10'
import Year2015_11 from './component/Year2015_11'
import Year2015_12 from './component/Year2015_12'
import Year2015_13 from './component/Year2015_13'
import Year2015_14 from './component/Year2015_14'
import Year2015_15 from './component/Year2015_15'
import Year2015_16 from './component/Year2015_16'
import Year2015_17 from './component/Year2015_17'
import Year2015_18 from './component/Year2015_18'
import Year2015_19 from './component/Year2015_19'
import Year2015_20 from './component/Year2015_20'
import Year2015_21 from './component/Year2015_21'
import Year2015_22 from './component/Year2015_22'
import Year2015_23 from './component/Year2015_23'
import Year2015_24 from './component/Year2015_24'
import Year2015_25 from './component/Year2015_25'
import Year2015_26 from './component/Year2015_26'
import Year2015_27 from './component/Year2015_27'
import Year2015_28 from './component/Year2015_28'
import Year2015_29 from './component/Year2015_29'
import Year2015_30 from './component/Year2015_30'
import api from '../../util/api'
import Anvil from './component/Anvil'

const School3_read = () => {

    const { id } = useParams()
    const [parts, setParts] = useState([])
    const [viewLeftRight, setViewLeftRight] = useState(false)
    const [showMobileMenu, setShowMobileMenu] = useState(false)

    const [lastRequestTime, setLastRequestTime] = useState(0);

    const navigate = useNavigate()

    useEffect(() => {
        setParts(id.split('_'))
    }, [id])
    useEffect(() => {
        if (parts[1] === 'all' || id === 'anvil') {
            setViewLeftRight(false)
        } else {
            setViewLeftRight(true)
        }
    }, [parts])
    useEffect(() => {
        if (parts[1] !== 'all') {
            if (parts[0] === 'prologue' && parseInt(parts[1]) < 11) {
                sessionStorage.setItem('prologue', '1')
            } else if (parts[0] === 'prologue' && parseInt(parts[1]) >= 11) {
                sessionStorage.setItem('prologue', '2')
            }
            if (parts[0] === '2015' && parseInt(parts[1]) < 11) {
                sessionStorage.setItem('2015', '1')
            } else if (parts[0] === '2015' && parseInt(parts[1]) >= 11) {
                sessionStorage.setItem('2015', '2')
            }
        }
    }, [parts])

    useEffect(() => {
        if (Date.now() - lastRequestTime >= 10000) {
            fetchViewCount()
        }
    }, [id])

    const fetchViewCount = async () => {
        try {
            if (id === 'anvil') {
                return
            }
            const response = api.get(`/school3-view-count?id=${id}`)
            setLastRequestTime(Date.now())
        } catch (err) {
        }
    };

    const remoteControl = (bt) => {
        switch (bt) {
            case 'center':
                if (parts[0] === 'anvil') {
                    navigate('/school3.0')
                } else {
                    navigate(`/school3.0/${parts[0]}`)
                }
            case 'top':
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                })
                break;
            case 'right':
                if (parts[0] === 'prologue') {
                    if (parts[1] === '16') {
                        alert('마지막 장입니다.')
                        return
                    }
                    const page = parseInt(parts[1])
                    navigate(`/read/prologue_${page + 1}`)
                    return
                }
                if (parts[0] === '2015') {
                    if (parts[1] === '30') {
                        alert('마지막 장입니다.')
                        return
                    }
                    const page = parseInt(parts[1])
                    navigate(`/read/2015_${page + 1}`)
                    return
                }
                break;
            case 'bottom':
                window.scrollTo({
                    top: document.body.scrollHeight,
                    behavior: 'smooth'
                })
                break;
            case 'left':
                if (parts[0] === 'prologue') {
                    if (parts[1] === '1') {
                        alert('첫 번째 장입니다.')
                        return
                    }
                    const page = parseInt(parts[1])
                    navigate(`/read/prologue_${page - 1}`)
                    return
                }
                if (parts[0] === '2015') {
                    if (parts[1] === '1') {
                        alert('첫 번째 장입니다.')
                        return
                    }
                    const page = parseInt(parts[1])
                    navigate(`/read/2015_${page - 1}`)
                    return
                }
                break;
        }
    }


    const exportMainText = () => {
        switch (id) {
            case 'anvil':
                return <Anvil />
            case 'prologue_all':
                return <>
                    <Prologue01 />
                    <Prologue02 />
                    <Prologue03 />
                    <Prologue04 />
                    <Prologue05 />
                    <Prologue06 />
                    <Prologue07 />
                    <Prologue08 />
                    <Prologue09 />
                    <Prologue10 />
                    <Prologue11 />
                    <Prologue12 />
                    <Prologue13 />
                    <Prologue14 />
                    <Prologue15 />
                    <Prologue16 />
                </>
            case 'prologue_1':
                return <Prologue01 />
            case 'prologue_2':
                return <Prologue02 />
            case 'prologue_3':
                return <Prologue03 />
            case 'prologue_4':
                return <Prologue04 />
            case 'prologue_5':
                return <Prologue05 />
            case 'prologue_6':
                return <Prologue06 />
            case 'prologue_7':
                return <Prologue07 />
            case 'prologue_8':
                return <Prologue08 />
            case 'prologue_9':
                return <Prologue09 />
            case 'prologue_10':
                return <Prologue10 />
            case 'prologue_11':
                return <Prologue11 />
            case 'prologue_12':
                return <Prologue12 />
            case 'prologue_13':
                return <Prologue13 />
            case 'prologue_14':
                return <Prologue14 />
            case 'prologue_15':
                return <Prologue15 />
            case 'prologue_16':
                return <Prologue16 />
            case '2015_all':
                return <>
                    <Year2015_01 />
                    <Year2015_02 />
                    <Year2015_03 />
                    <Year2015_04 />
                    <Year2015_05 />
                    <Year2015_06 />
                    <Year2015_07 />
                    <Year2015_08 />
                    <Year2015_09 />
                    <Year2015_10 />
                    <Year2015_11 />
                    <Year2015_12 />
                    <Year2015_13 />
                    <Year2015_14 />
                    <Year2015_15 />
                    <Year2015_16 />
                    <Year2015_17 />
                    <Year2015_18 />
                    <Year2015_19 />
                    <Year2015_20 />
                    <Year2015_21 />
                    <Year2015_22 />
                    <Year2015_23 />
                    <Year2015_24 />
                    <Year2015_25 />
                    <Year2015_26 />
                    <Year2015_27 />
                    <Year2015_28 />
                    {/* <Year2015_29 />
                    <Year2015_30 /> */}
                </>
            case '2015_1':
                return <Year2015_01 />
            case '2015_2':
                return <Year2015_02 />
            case '2015_3':
                return <Year2015_03 />
            case '2015_4':
                return <Year2015_04 />
            case '2015_5':
                return <Year2015_05 />
            case '2015_6':
                return <Year2015_06 />
            case '2015_7':
                return <Year2015_07 />
            case '2015_8':
                return <Year2015_08 />
            case '2015_9':
                return <Year2015_09 />
            case '2015_10':
                return <Year2015_10 />
            case '2015_11':
                return <Year2015_11 />
            case '2015_12':
                return <Year2015_12 />
            case '2015_13':
                return <Year2015_13 />
            case '2015_14':
                return <Year2015_14 />
            case '2015_15':
                return <Year2015_15 />
            case '2015_16':
                return <Year2015_16 />
            case '2015_17':
                return <Year2015_17 />
            case '2015_18':
                return <Year2015_18 />
            case '2015_19':
                return <Year2015_19 />
            case '2015_20':
                return <Year2015_20 />
            case '2015_21':
                return <Year2015_21 />
            case '2015_22':
                return <Year2015_22 />
            case '2015_23':
                return <Year2015_23 />
            case '2015_24':
                return <Year2015_24 />
            case '2015_25':
                return <Year2015_25 />
            case '2015_26':
                return <Year2015_26 />
            case '2015_27':
                return <Year2015_27 />
            case '2015_28':
                return <Year2015_28 />
            case '2015_29':
                return <Year2015_29 />
            case '2015_30':
                return <Year2015_30 />
            default:
                return
        }
    }

    return (
        <div>
            <div onClick={() => setShowMobileMenu(!showMobileMenu)}>
                {exportMainText()}
            </div>
            <div className={styles.remote_wrap}>
                <div className={styles.remote_center} onClick={() => remoteControl('center')}></div>
                <div className={styles.remote_top} onClick={() => remoteControl('top')}>위로</div>
                {viewLeftRight && (
                    <div className={styles.remote_right} onClick={() => remoteControl('right')}>다음글</div>
                )}
                <div className={styles.remote_bottom} onClick={() => remoteControl('bottom')}>아래로</div>
                {viewLeftRight && (
                    <div className={styles.remote_left} onClick={() => remoteControl('left')}>이전글</div>
                )}
            </div>
            <div className={styles.bottom_button_wrap}>
                {viewLeftRight && (
                    <p onClick={() => remoteControl('left')}>이전글</p>
                )}
                < p onClick={() => remoteControl('center')} style={{ margin: '0 30px' }}>목록</p>
                {viewLeftRight && (
                    <p onClick={() => remoteControl('right')}>다음글</p>
                )}
            </div>
            {showMobileMenu && (
                <div className={styles.mobile_menu_wrap}>

                    {viewLeftRight ? (
                        <div className={`${styles.mobile_btn} ${styles.mobile_btn_left}`} onClick={() => remoteControl('left')}></div>
                    ) : (
                        <div />
                    )}
                    <div className={`${styles.mobile_btn} ${styles.mobile_btn_top}`} onClick={() => remoteControl('top')} />
                    <div className={styles.hamburger} onClick={() => remoteControl('center')}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <div className={`${styles.mobile_btn} ${styles.mobile_btn_bottom}`} onClick={() => remoteControl('bottom')} />
                    {viewLeftRight ? (
                        <div className={`${styles.mobile_btn} ${styles.mobile_btn_right}`} onClick={() => remoteControl('right')}></div>
                    ) : (
                        <div />
                    )}

                </div>
            )}

        </div >
    )
}

export default School3_read