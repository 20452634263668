import React from 'react'
import styles from './MainText.module.css'

const Year2015_03 = () => {
  return (
    <div className={styles.wrap}>
      <h1 className={styles.title}>3. 무투표 당선.</h1>
      <p>독도 씨는 가정통신문에 적혀 있는 대로, 학교행정실에 방문하여 학부모위원에 입후보한다는 지원서를 작성하였고, 6명을 선출하는 학부모위원에 6명이 지원하여 투표 없이 당선이 되었다는 통지를 받았다.</p>
      <p>6명 선출하는데 딱 6명이 지원했다는 것이 좀 신기한 일이라 생각되었지만, 어쨌든 당선된 독도 씨는 기분이 좋았다.</p>
      <p>&nbsp;</p>
      <p>그리고 얼마 뒤, 첫 운영위원회 날짜를 통보 받았고, 그 일주일 전에 총 10개의 안건이 담긴 두툼한 서류 봉투를 아들 편에 전달 받았다.</p>
      <p>&nbsp;</p>
      <p>독도 씨는 인터넷을 통해 검색할 수 있는 학교운영위원회 관련한 정보의 양과 질이 매우 빈약하다는 사실에 적잖게 놀랐지만, 그래도 최선을 다해서 학교운영위원회의 기능(심의 사항), 그리고 학교운영위원의 역할에 대해 공부했다.</p>
      <p>먼저 「초·중등교육법」과 「초·중등교육법 시행령」, 그리고 진부도(독도 씨가 살고 있는 부종시가 속한 광역지방자치단체)교육청의 「학교운영위원회 설치·운영 조례」를 찾아보고 해당 법령에 명시된 여러 가지 규정과 절차부터 차근차근 익혔다.</p>
      <p>그리고 그 결과, 첫 회의가 시작되기도 전에 ‘무언가 이상한 것’을 발견하게 되었다.</p>
    </div>
  )
}

export default Year2015_03