import React from 'react'
import ss from './MainText.module.css'

const Year2015_27 = () => {
  return (
    <div className={ss.wrap}>
      <h1 className={ss.title}>27. 급식 논쟁(3).</h1>
      <p>공무원의 고질적인 병폐 중 하나로, 민원인의 질문에 엉뚱한 답변을 하는 것이 있다.</p>
      <p>질문에 대해 올바른 대답을 하는 것이 그들의 의무인데, 대답하기가(진실을 말하기가) 곤란한 질문을 받을 경우, 거짓말은 할 수 없으니 이도 저도 아닌 대답을 하는 경우가 있다.</p>
      <div className={ss.annotation_wrap}>
        <p>거짓말이 들통 나면 징계를 피할 수 없고, 민형사상의 책임을 져야하며, 불명예스럽게 퇴임할 수도 있다.</p>
        <p>따라서 대놓고 거짓말을 하는 경우도 간혹 있기는 하지만, 대개의 경우 거짓말은 하지 않는다.</p>
      </div>
      <p>&nbsp;</p>
      <p>그렇다면 대답하기 곤란한 질문이란 무엇일까?</p>
      <p>민원인의 질문 사유 중에는, 단순하게 궁금한 것을 묻는 것도 있지만, ① 정책이나 행정 절차 등에 의문을 제기 하거나, ② 공무원의 행위에 불만이 있어서 문제를 제기하거나, ③ 다른 민간인의 행위에 불만(분쟁)이 있어서 공정한 판단을 묻는 등 곤란함의 원인이 되는 유형이 몇 가지 있다.</p>
      <p>물론 이 유형들이 항상 곤란함과 연결되지는 않는다.</p>
      <p>대개는 별 문제없이 답변할 수 있는데, 간혹 곤란한 경우가 발생한다.</p>
      <p>이를 테면 ‘문제가 있는 정책이나 행정 절차를 계속 고수해야 할 때’, 그에 관한 질문을 받는 경우가 그렇다.</p>
      <p>이 문제를 시인하면, 해당 조직(기관)이 곤란에 처하게 되므로 답변이 쉽지 않다.</p>
      <div className={ss.annotation_wrap}>
        <p>문제를 시인하면, 개선하려는 노력을 해야 하는데, 생각하기 싫고 책임지기 싫은 습성이 있는 자들이 부지기수라서 문제를 시인하려 하지 않는다.</p>
        <p>혹은 정치적인 이유 등으로 문제 상황을 계속해서 유지하려는 불합리한 경우도 있다.</p>
      </div>
      <p>또 다른 경우로서, 공무원이나 민간인의 어떤 행위에 대한 문제 제기를 받았는데, ‘공무원이 불공정 행위를 했거나 혹은 민간인과 부당하게 연루되었을 때’가 그러하다.</p>
      <p>이와 관련된 부당함을 시인하면, 해당(자신 혹은 동료) 공무원이 곤란에 처하게 되므로, 역시 답변이 쉽지 않다.</p>
      <div className={ss.annotation_wrap}>
        <p>가장 흔한 예로는 부당한 행정처리(인·허가 등)를 들 수 있다.</p>
        <p>허가를 내 줄 수 없는 상황에서 허가를 내 주는 행위는 비일비재하고, 이것이 발각되어도 최소한의 이행강제금만 부과하여 면죄부를 부여하는 행태는 오래전부터 만연되어 있다.</p>
        <p>그밖에 공사장 소음이 너무 커서 신고를 했는데, 신기하게도 공무원이 소음을 측정하러 오면 공사장의 장비가 가동을 멈춘다거나, 건물의 주차장을 다른 용도로 사용하여 신고를 했는데 신기하게도 공무원이 확인하러 나오면 그때는(그때만) 주차장으로 사용하는 현상도 상식처럼 흔하게 발생한다.</p>
        <p>심지어 독도 씨의 경우, 장애인 편의시설의 경사로가 잘못되었다고 신고를 했는데, 측정을 엉터리로 해놓고 문제없다는 답변을 받은 적도 있다.</p>
        <p>이러한 사례들의 경우, 아무리 치밀하게 증거를 만들어 민원을 제기해도 제대로 된 답변을 받기 어려울 수 있다.</p>
      </div>
      <p>이처럼, 올바른 대답을 하자니 곤란에 처하게 되는 상황이 있을 수 있는데, 그러한 상황을 은폐하거나 회피하기 위해 엉뚱한 답변을 하는 것이다.</p>
      <div className={ss.annotation_wrap}>
        <p>올바른 대답을 하여 해당 조직이나 해당 공무원이 곤란해지면, 답변을 한 공무원도 어려움을 겪는다.</p>
        <p>동업자 정신이 부족하다고 지탄받게 되고, 내부의 적으로 취급받게 된다.</p>
        <p>그래서 그러한 비난이 두려우므로 민원에 엉터리로 대처하게 되는 경우도 있다.</p>
        <p>물론 적극적으로 서로의 잘못을 덮어 주려는 부덕한 공무원(유유상종)도 있고, 진실을 외면함으로써 이득을 취하려는 기회주의자도 있다.</p>
      </div>
      <p>&nbsp;</p>
      <p>이러한 행동은 민원인(질문자)에게 극심한 스트레스를 안기는(심할 경우 우울증에 걸리기도 한다) 매우 비열한 행동이며, 공무원의 봉사·성실·정직 등의 ‘원칙과 의무’를 규정한 헌법, 국가공무원법, 공직자윤리법 등에 반하는 행동(불법)이다.</p>
      <p>이러한 비양심적이고 무책임한 행동을 하는 공무원에게는 강력한 징계 조치를 내려야 마땅한데, 팔이 안으로 굽기 때문에, 민원인이 이의를 제기해서 감사를 하여도 대부분은 제대로 밝혀지지도 않을뿐더러, 일부 밝혀진다고 한들 별다른 징계를 받지 않는다.</p>
      <div className={ss.annotation_wrap}>
        <p>분노한 민원인이 대응할 수단은 마땅치 않다.</p>
        <p>계속 질문을 하면 반복민원으로 처리하여 아예 답변을 하지 않고, 부당한 조치에 대해 감사를 요청해도 시간만 끌다가 흐지부지 되기 마련이고, 그렇다고 수사를 의뢰하거나 소송을 제기하기에는 얻는 이득에 비해 지불해야하는 대가가 크기 때문에 결국 포기하는 일이 다반사이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>그러다보니 이런 일이 만연하게 되고, 일부 공무원들은 그러한 불성실한 대응을 대수롭지 않게 여긴다.(전부가 아니라서 일부인 것이지, 일부가 소수라는 뜻은 아니다.)</p>
      <p><span className={ss.quo_mini}>‘세상일이 다 그런 거지. 아니꼬우면 공무원을 하든가.’</span>라는 식이다.</p>
      <p>안타깝게도 우리 사회는, 이런 부분에서는 크게 낙후된 사회이다.</p>
      <div className={ss.annotation_wrap}>
        <p>가끔 TV를 통해 국정감사나 청문회 등을 시청하다보면, 그러한 수준의 답변을 하는 답변자를 쉽게 만날 수 있다.</p>
        <p>수차례 끈질기게 질문을 해도, 절대 올바른 대답을 하지 않는다.</p>
        <p>바보가 된 것처럼, 모른다고 하거나 엉뚱한 대답을 한다.</p>
        <p>이는, 지켜보는 국민에게 극심한 스트레스를 안겨주는 행동인데, 그들은 뻔뻔하게도 당당하게 고개를 쳐들고 말을 한다.</p>
        <p>참으로 후안무치한 자들이 아닐 수 없다.</p>
        <p>그리고 그런 수준의 후안무치한 자들은, 교육청에도 많다.</p>
        <p>앞으로 다양한 사례에서 그러한 자들을 만나 볼 수 있을 것이다.</p>
        <p>&nbsp;</p>
        <p>참고로, 이런 자들이 고위 관료 혹은 선출직 공무원으로 재직하고 있는 사회를 민주주의가 정착된 사회라고 볼 수는 없다.</p>
        <p>그리고 이러한 자들을 교육시킨 곳이 바로 공교육이다.</p>
        <p>물론 아무리 올바른 교육을 한다고 하여도 모든 사람이 선량한 공무원이 될 수는 없을 것이다.</p>
        <p>그러나 불량한 자들을 걸러내고, 억제하고, 선량한 방향으로 계도하는 시스템을 작동시킬 수 있는 사회적 역량(철학, 문화)을 기르는 것은 공교육의 책임이다.</p>
        <p>지금의 학교 운영 시스템을 보면, 지금 사회의 낙후된 수준이 딱 그에 걸맞은 정도라는 것을 확실하게 깨달을 수 있다.</p>
      </div>
      <p>&nbsp;</p>
      <p>한편, 사회의 모순(병폐)은 교육에서부터 시작되므로, 교육기관인 학교를 관리하는(관할하는) 교육청의 공무원부터 그러한 병폐에 찌들어 있을 것이라고, 우리는 능히 짐작할 수 있다.</p>
      <div className={ss.annotation_wrap}>
        <p>그러한 자들이 관할하는 학교가 깨끗할 리 만무하고, 그러한 학교에서 교육받는 학생들이 사회로 진출하는 것이므로, 우리 사회의 공공기관의 수준을 근거로 역으로 추론하면 이러한 짐작이 타당하다고 할 수 있다.</p>
      </div>
      <p>이러한 짐작이 사실임을 확인할 수 있는 몇 가지 사례가 있는데, 독도 씨가 [별표3]의 해석에 관한 질문을 했을 때 교육청으로부터 받은 답변이 그러했다.</p>
      <p>&nbsp;</p>
      <p>학교운영위원회를 마치고 집으로 돌아온 독도 씨는, 며칠 뒤 국민신문고를 통해 진부도교육청에 다음과 같은 문의를 남겼다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo_mini}>안녕하십니까?</p>
      <p className={ss.quo_mini}>본인은 진부도 부종시 한비중학교 학부모운영위원입니다.</p>
      <p className={ss.quo_mini}>학교급식의 영양관리기준에 관해 문의합니다.</p>
      <p className={ss.quo_mini}>「학교급식법 시행규칙」의 [별표3]에 따르면, 중학교 남학생의 공급 기준량은 800㎉이고, 여학생의 공급 기준량은 667㎉입니다.</p>
      <p className={ss.quo_mini}>그런데 본교 영양교사의 말에 따르면, “(800×남학생인원+667×여학생인원)÷전체인원”으로 영양량을 계산한 후 ±10% 내에서 공급하기만 하면, 남녀학생에게 균등한 양을 배식해도 아무 문제가 없다고 합니다.</p>
      <p className={ss.quo_mini}>우리 학교는 그렇게 계산한 공급 기준량이 732.4㎉이고, 실제 공급량은 707㎉(공급 기준량의 96.5%)이며, 남녀학생에게 동일한 양을 배식하고 있습니다.</p>
      <p className={ss.quo_mini}>영양교사는 이와 같이 공급하는 것이 적법하다고 말합니다.</p>
      <p className={ss.quo_mini}>그러나 본인은, 현재 남녀학생에게 같은 양(남학생도 707㎉, 여학생도 707㎉)을 배식하고 있으므로, 남학생의 경우 공급 기준량의 88%만 공급받고 있어서 위법이라고 판단합니다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo_mini}>본인은, [별표3]의 남학생과 여학생의 공급 기준량이 다르므로, 남학생과 여학생에게 다르게 적용된 평균 열량대로 각각 다르게 공급해야한다고(남학생과 여학생의 배식량을 달리해야 한다고) 주장하고 있으나, 영양교사는 [별표3]의 남학생과 여학생의 공급 기준량이 다른 것은, 전체 총 공급량을 결정하기 위한 지표일 뿐이고, 남녀학생에게 배식량을 달리하라는 뜻이 아니라고 주장하고 있습니다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo_mini}>질문입니다.</p>
      <p className={ss.quo_mini}>[별표3]의 내용에 대한 교육청의 해석을 요청합니다.</p>
      <p className={ss.quo_mini}>[별표3]의 내용이, 남학생과 여학생에게 각각 다른 양을 공급하라는 것인지, 총 공급량만 준수하면 같은 양을 공급해도 괜찮다는 것인지 확인하여 주시기 바랍니다.</p>
      <p>&nbsp;</p>
      <p>그리고 며칠 뒤 독도 씨는 한 통의 전화를 받았다.</p>
      <p>&nbsp;</p>
      <div className={ss.talk}>
        <p className={ss.t1}>여보세요?</p>
        <p className={ss.t2}>나독도님이신가요?</p>
        <p className={ss.t1}>네. 그런데요?</p>
        <p className={ss.t2}>안녕하세요?</p>
        <p className={ss.t2}>저는 진부도교육청 교육급식과 이장옥입니다.</p>
        <p className={ss.t2}>국민신문고로 학교급식 관련 질문을 주셨죠?</p>
        <p className={ss.t1}>아! 네. 맞습니다.</p>
        <p className={ss.t2}>제가 국민신문고로 답변을 할 텐데요.</p>
        <p className={ss.t2}>그전에 좀 이해를 돕고자 전화를 드렸습니다.</p>
        <p className={ss.t1}>아, 네.</p>
        <p className={ss.t2}>지금 학교에서 남학생과 여학생에게 같은 양을 주고 있죠?</p>
        <p className={ss.t2}>그것 때문에 문의 주신 거고요.</p>
        <p className={ss.t1}>네. 그렇습니다.</p>
        <p className={ss.t2}>그게 사실 원칙적으로는,</p>
        <p className={ss.t2}>모든 학생에게 각각 맞는 양을 줘야 하거든요.</p>
        <p className={ss.t2}>그런데 그렇게 주기가 참 어렵죠.</p>
        <p className={ss.t2}>모든 학생들의 적정량을 파악하기도 어렵고,</p>
        <p className={ss.t2}>많은 인원에게 배식을 하려면,</p>
        <p className={ss.t2}>학생마다 양을 다르게 배식할 수도 없기 때문이에요.</p>
        <p className={ss.t1}>그렇죠.</p>
        <p className={ss.t2}>집에서 먹을 때나, 도시락을 싸와서 먹는 것처럼,</p>
        <p className={ss.t2}>각자의 양에 맞춰 주기는 어렵습니다.</p>
        <p className={ss.t2}>집단 급식의 한계죠.</p>
        <p className={ss.t1}>알고 있습니다.</p>
        <p className={ss.t2}>하지만 학생들의 평균 적정 섭취량은 정할 수 있어요.</p>
        <p className={ss.t2}>그걸 국가가 정하고 있는 거지요.</p>
        <p className={ss.t1}>그렇겠죠.</p>
        <p className={ss.t1}>먹는 양이 각자 달라도 평균은 비교적 정확하게 구할 수 있죠.</p>
        <p className={ss.t1}>우리나라 학생들의 평균 키와 체중은 알고 있으니까요.</p>
        <p className={ss.t2}>네. 그렇습니다.</p>
        <p className={ss.t2}>그래서 평균 적정 섭취량으로 공급하자는 겁니다.</p>
        <p className={ss.t2}>개인별로 정확하게 주기는 어려우니까,</p>
        <p className={ss.t2}>평균이라도 맞춰서 주자는 의미입니다.</p>
        <p className={ss.t1}>네. 그렇겠죠.</p>
        <p className={ss.t2}>그래서 각 학교는 [별표3]의 기준량에 맞춰서 공급하는 겁니다.</p>
        <p className={ss.t2}>하지만 배식을 어떻게 해야 한다고는 정해진 바가 없습니다.</p>
        <p className={ss.t2}>배식 방법은 학교에서 자율적으로 정합니다.</p>
        <p className={ss.t1}>그런데 남학생과 여학생은 기준량이 다르잖아요.</p>
        <p className={ss.t1}>그러니까 다르게 배식해야 되는 것 아닙니까?</p>
        <p className={ss.t2}>그렇게 볼 수도 있습니다만,</p>
        <p className={ss.t2}>아까도 말했듯이 한꺼번에 많은 학생을 먹여야하다 보니,</p>
        <p className={ss.t2}>아무래도 같은 양을 주는 정량 배식이 효율적입니다.</p>
        <p className={ss.t2}>진부도 내 학교들은 대부분 그렇게 주고 있습니다.</p>
        <p className={ss.t2}>배식량을 달리해서는,</p>
        <p className={ss.t2}>생각처럼 원활하게 운영하기 쉽지 않아요.</p>
        <p className={ss.t2}>그런 고충을 좀 이해해 주셨으면 합니다.</p>
        <p className={ss.t1}>이해하라고요?</p>
        <p className={ss.t2}>네. 학교급식의 한계를 좀 이해해주셨으면 해요.</p>
        <p className={ss.t1}>아니, 저는 [별표3]에 대한 교육청의 해석을 물어본 것이지,</p>
        <p className={ss.t1}>학교급식의 한계를 물어본 것이 아닙니다.</p>
        <p className={ss.t1}>남학생과 여학생에게 배식량을 달리 하는 것이 원칙이지요?</p>
        <p className={ss.t2}>그게……,</p>
        <p className={ss.t2}>총량은 확실히 정해져있고,</p>
        <p className={ss.t2}>배식량은 학교에서 자율적으로 조정하는 것이라서,</p>
        <p className={ss.t2}>남녀학생에게 달리 주면 좋겠지만,</p>
        <p className={ss.t2}>사정상 그렇게 못하더라도 총량만 지키면,</p>
        <p className={ss.t2}>교육청에서도 개입하기 어렵습니다.</p>
        <p className={ss.t1}>아니요.</p>
        <p className={ss.t1}>내용을 확실하게 말씀해달라고요.</p>
        <p className={ss.t1}>남녀학생의 공급량이 다르므로,</p>
        <p className={ss.t1}>배식량이 달라야한다는 제 해석이 맞는지 묻고 있습니다.</p>
        <p className={ss.t1}>교육청에서 개입해달라는 게 아니에요.</p>
        <p className={ss.t2}>그런 내용은 제가 답을 드리기 어렵고요.</p>
        <p className={ss.t2}>다만 학교급식의 한계를 이해해달라고 말씀드리는 겁니다.</p>
        <p className={ss.t1}>아니, 이것보세요. 주무관님.</p>
        <p className={ss.t2}>주무관이라니요! 장학사입니다.</p>
        <p className={ss.t2}>호칭을 정확하게 해주세요.</p>
        <p className={ss.t1}>…….</p>
        <p className={ss.t1}>네. 장학사님. 그러지요.</p>
        <p className={ss.t1}>다시 질문할게요.</p>
        <p className={ss.t1}>[별표3]은 배식량을 달리하라는 뜻이 아닙니까?</p>
        <p className={ss.t2}>아까도 말씀드렸지만,</p>
        <p className={ss.t2}>배식을 어떻게 해야 한다고는 정해진 바가 없습니다.</p>
        <p className={ss.t2}>배식 방법은 학교에서 자율적으로 정할 수 있으니까…….</p>
        <p className={ss.t1}>잠깐만요.</p>
        <p className={ss.t1}>‘맞다, 아니다.’ 한 말씀만 해주세요.</p>
        <p className={ss.t1}>부연설명은 안 하셔도 돼요.</p>
        <p className={ss.t2}>그렇게 대답해드릴 수가 없습니다.</p>
        <p className={ss.t1}>왜죠?</p>
        <p className={ss.t2}>아니 그럼 선생님께서는,</p>
        <p className={ss.t2}>어떻게 하길 바라시나요?</p>
        <p className={ss.t2}>남학생과 여학생을 구분해서 배식하길 원하시나요?</p>
        <p className={ss.t2}>그건 문제가 많습니다.</p>
        <p className={ss.t2}>제가 한비중학교 영양선생님과도 통화를 했습니다.</p>
        <p className={ss.t2}>이미 학교운영위원회에서 논의를 했다더군요.</p>
        <p className={ss.t2}>여러 가지 문제가 있어서 어렵다는 것도 다 아실 텐데요.</p>
        <p className={ss.t1}>어려운 것은 다른 문제고요.</p>
        <p className={ss.t1}>일단, 법령 해석을 먼저 옳게 하려는 거예요.</p>
        <p className={ss.t1}>그러고 나서 법령에 맞게 할 수 있는 방법을 찾아야죠.</p>
        <p className={ss.t2}>그게 해석이 참 곤란한 부분입니다.</p>
        <p className={ss.t2}>실제 정책을 시행하는 입장에서는,</p>
        <p className={ss.t2}>현실을 감안하지 않을 수가 없어요.</p>
        <p className={ss.t1}>하…….</p>
        <p className={ss.t1}>그러니까 ‘맞다, 아니다.’를 대답할 수 없다는 거죠?</p>
        <p className={ss.t2}>선생님의 문제 제기는 이해합니다.</p>
        <p className={ss.t2}>교육청에서도 개선이 필요한 부분이라 생각합니다.</p>
        <p className={ss.t2}>마침 자율배식에 대해 연구가 현재 진행 중에 있습니다.</p>
        <p className={ss.t2}>몇 몇 학교에서 시범사업을 시작하고 있어요.</p>
        <p className={ss.t2}>자율배식의 성과가 좋으면,</p>
        <p className={ss.t2}>교육청에서 대대적으로 전환사업을 할 겁니다.</p>
        <p className={ss.t2}>그러면 선생님께서 제기한 문제도 해결이 될 겁니다.</p>
        <p className={ss.t2}>하지만 지금은 일단 개선이 어렵습니다.</p>
        <p className={ss.t1}>됐습니다.</p>
        <p className={ss.t1}>더 이상 동문서답하지 않으셔도 됩니다.</p>
        <p className={ss.t1}>그냥 국민신문고로 답변 주세요.</p>
        <p className={ss.t2}>알겠습니다.</p>
        <p className={ss.t2}>만족스럽지는 않으셨겠지만 아무쪼록 많은 이해를 부탁드립니다.</p>
        <p className={ss.t2}>국민신문고 답변은 기한 내 처리해 드리겠습니다.</p>
        <p className={ss.t2}>감사합니다.</p>
        <p className={ss.t1}>네. 고맙습니다.</p>
      </div>
      <p>&nbsp;</p>
      <p>사실은 한 시간에 가까운 긴 대화였지만, 자꾸만 반복되는 내용이 너무 많아서 핵심만 추려서 서술했다.</p>
      <p>진부도교육청 이장옥 장학사와의 통화와 관련하여, 독도 씨가 아직도 기억하고 있는 것은 세 가지이다.</p>
      <p>첫 번째로는 답변을 회피했다는 것이다.</p>
      <p>독도 씨의 짐작으로는, [별표3]의 내용을 독도 씨의 해석대로 이해하고 있으나, 이를 인정하게 되면, 법령을 준수하지 않는 학교가 부지기수로 많게 된다는 문제가 발생하고, 이를 시정하려면 또 다른 문제가 발생하므로 차마 인정할 수 없어서 자꾸 논점을 흐려가며 동문서답을 한 것이다.</p>
      <div className={ss.annotation_wrap}>
        <p>앞선 토론에서 보았듯이, 양을 달리하는 정량 배식도 완전한 답이 되지 않는다.</p>
        <p>결국은 자율배식을 해야 하는데, 준비(매뉴얼 등)가 부족한 상황에서 자율배식을 전면적으로 시행하는 것은 매우 어려운 일이다.</p>
        <p>이러한 점은 독도 씨도 이해를 한다.</p>
        <p>다만, 문제를 인정하고 해결하려는 노력을해야 보다 빠르고 올바르게 답을 찾을 수 있는데, 해결이 어렵다고 해서 문제를 인정하지 않는 것은 잘못이라는 점을 지적하는 것이다.</p>
      </div>
      <p>두 번째는 호칭에 민감했다는 것이다.</p>
      <p>뭐, 그럴 수도 있다.</p>
      <p>직급을 낮춰 부르면 기분 나쁠 수도 있다.</p>
      <p>그런데 그게 그렇게 민원인에게 화를 낼 일인지는 모르겠다.</p>
      <p>당시만 해도 독도 씨는 교육청에서 일하는 사람의 직급에 대해 잘 알지 못했다.</p>
      <p>상대해 본 경험이 없었으니까.</p>
      <p>고의로 그런 것도 아닌데 왜 그렇게 화를 냈을까?</p>
      <p>학교와 마찬가지로 교육청에도 권위주의가 만연해 있다고 들었는데, 아마도 그 탓이겠지.</p>
      <div className={ss.annotation_wrap}>
        <p>독도 씨의 지인 중에 교육감의 측근인 사람이 있었는데, 그 사람은 가끔 <span className={ss.fbody}>“부종교육지원청 교육장 정도는 내 말 한마디면 바로 날려 버릴 수 있어.”</span>라고 말하고는 했다.</p>
        <p>이런 불합리하고 불공정한 낙하산 인사가 판을 치는 곳이라면, 권위주의가 조직을 장악하고 있다고 해도 이상할 것이 하나도 없다.</p>
      </div>
      <p>세 번째는 진부도교육청의 자율배식 시범사업이 그 무렵 시작되었다는 것이다.</p>
      <p>듣기로는 시작부터 바로 좋은 성과를 보여주어서, 이후 꾸준히 확대되고 있다고는 한다.</p>
      <p>다만, 10여 년이 지난 지금도 여전히 진부도 학교의 10% 수준에 머물고 있는 것을 보면, 교육청에서 열심히 시행하고 있지는 않은 듯하다.</p>
      <p>&nbsp;</p>
      <p>며칠 뒤, 국민신문고를 통해 받은 진부도교육청의 답변은 예상을 벗어나지 않았다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo_mini}>안녕하십니까? 경기도교육청 교육급식과 이장옥입니다.</p>
      <p className={ss.quo_mini}>귀하께서 질의하신 내용에 대한 검토사항은 다음과 같습니다.</p>
      <p className={ss.quo_mini}>1. 학교급식은 학교 구성원 전체에게 제공하는 단체급식의 특수성을 고려하여 「학교급식법」에 영양관리기준을 마련하여 관리되고 있으며, 학교 구성원의 성별, 학년(연령) 등을 고려한 구성인원비율에 의해 영양관리 기준량을 산출하고 평균 영양량으로 관리를 하고 있습니다.</p>
      <p className={ss.quo_mini}>2. 영양관리 기준량 준수범위는 에너지의 ±10%로 하되, 탄수화물 : 단백질 : 지방의 에너지 비율이 각각 55～70% : 7～20% : 15～30%가 되도록 하고 있습니다.</p>
      <p className={ss.quo_mini}>3.귀하는 ‘남학생, 여학생의 공급 기준량이 다르므로, 배식량이 달라야 한다.’라고 하셨는데, 남학생과 여학생의 차이뿐만 아니라, 개인의 건강상태, 연령, 성장정도, 건강상태에 따라서도 영양권장량은 다르기에, 배식량 조절, 자율배식대 운영, 영양·식생활교육을 통해 최대한 개인의 영양 권장량에 따른 섭취를 할 수 있도록 노력하고 있습니다.</p>
      <p className={ss.quo_mini}>따라서 귀하께서 질의하신 내용의 영양관리기준은 학교급식법에 적합함을 말씀드립니다.</p>
      <p>&nbsp;</p>
      <p>1번과 2번의 내용은 특별할 것이 없고, 3번의 내용은 남학생과 여학생 뿐 아니라 개인의 권장량이 모두 다르므로, 배식량을 조절하거나, 자율배식대를 운영함으로써 권장량이 공급될 수 있도록 노력하고 있다는 뜻이다.</p>
      <p>언뜻 들으면 맞는 말 같은데, 자세히 들여다보면 논점일탈과 거짓말이 복합되어 있음을 알 수 있다.</p>
      <p>그러니까 남학생과 여학생의 차이가 질문의 핵심(논점)인데, 개인의 권장량이 모두 다르다는 논리로 문제를 회피하고 있으며, 배식량 조절이나 자율배식대 운영이 제대로 이루어지고 있다면, 적법하다는 답변이 거짓이 아니겠지만, 이를 형식적으로 운영할 경우 권장량이 제대로 공급될 수 없으므로, 실질적인 운영이 담보되지 않는 이상 권장량이 공급되고 있다는 논리는 참이 될 수 없다.</p>
      <p>그러니까 <span className={ss.quo_mini}>‘같은 양을 정량 배식하는 것이 잘못이 아니냐?’</span>는 독도 씨의 질문에 대해, <span className={ss.quo_mini}>‘같은 양을 정량 배식하면 잘못이고, 양을 달리주면 잘못이 아니다.’</span>라고 답하지 않고, <span className={ss.quo_mini}>‘양을 달리주려는 노력을 하고 있으므로 잘못이 아니다.’</span>라고 답한 것이다.</p>
      <p>즉, <span className={ss.quo_mini}>‘같은 양을 주는 것은 잘못이다.’</span>라는 답변이 빠진 것이다.</p>
      <p>이래서는 명확한 답변이 되지 않는다.</p>
      <p>&nbsp;</p>
      <p>독도 씨는 진부도교육청의 답변에 만족할 수가 없었다.</p>
      <p>납득할 수 있는 대답이 필요했다.</p>
      <p>그래서 결국 다시 민원을 신청하였는데, 교육청의 답변이 문제의 핵심을 벗어났음을 지적하고, [별표3]의 해석에 대한 정확한 답변을 재차 요구하였다.</p>
      <p>&nbsp;</p>
      <p>그러자 다시 담당 장학사로부터 전화가 왔고, 또 다시 긴 시간의 설득과 항의가 이어졌다.</p>
      <p>물론 내용은 앞서의 것과 다를 것이 하나도 없었다.</p>
      <p>그냥 답변하면 될 텐데, 왜 자꾸 전화를 해서 이해해달라고 하는 것일까?</p>
      <p>어쨌거나, 통화로는 상호 아무 소득이 없었다.</p>
      <p>이장옥 장학사는 독도 씨가 요구한 질문의 답변을 정확하게 해주지 않았고, 독도 씨는 그래서 아무 것도 납득할 수 없었다.</p>
      <p>그러고 난 뒤, 도착한 답변의 내용은 이전의 것과 완전히 똑같았다.</p>
      <p>답답했다.</p>
      <p>여기서 한 번 더 질문을 해봤자 나아질 것이 없다고 판단되었다.</p>
      <p>&nbsp;</p>
      <p>그래서 독도 씨는 이번에는 [별표3]에 대해 법제처의 유권해석을 요청하는 민원을 신청했다.</p>
      <div className={ss.annotation_wrap}>
        <p>민원인이 법령해석이 필요할 때는 먼저 해당기관(교육부)의 법령해석을 받아야 한다.</p>
        <p>이 해석이 민원인의 생각과 다를 경우, 교육부(장관)에 요청하여 법제처의 법령해석을 받을 수 있다.</p>
        <p>그런데 당시 독도 씨는 교육부의 법령해석을 받지 않고 바로 ‘법제처에 법령해석을 요청하는 민원’을 신청하였는데, 이는 처음 겪는 일이다보니 절차를 정확히 알지 못한 탓이었다.</p>
      </div>
      <p>&nbsp;</p>
      <p>그런데 이 민원이, 독도 씨의 기피신청(진부도교육청 교육급식과를 기피하는 신청)에도 불구하고, 다시 진부도교육청 교육급식과로 접수가 되었다.</p>
      <p>그리고 이장옥 장학사는, 분명히 다른 민원(법제처에 유권해석을 요청하는 민원)임에도 불구하고 이를 중복민원으로 처리하여 기존 답변으로 갈음하고 그대로 종결시켰다.</p>
      <div className={ss.annotation_wrap}>
        <p>민원 처리부서의 불공정·불친절 답변이 예상될 경우, 민원인은 기피신청을 할 수 있다.</p>
        <p>다만, 기피신청이 반드시 받아들여지는 것은 아니다.</p>
      </div>
      <p>&nbsp;</p>
      <p>독도 씨는 똑같은 내용의 민원을 다시 작성하여, 다시 기피신청을 하고, 이번에는 자동으로 처리되지 않도록 교육부로 직접 지정하여 민원을 넣었다.</p>
      <p>&nbsp;</p>
      <p>그러고 나서, 며칠 뒤 독도 씨는 교육부 담당자로부터 전화를 받았다.</p>
      <p>그런데 그 내용이 좀 의외였다.</p>
      <div className={ss.annotation_wrap}>
        <p>공무원이 민원인에게 전화를 하는 경우는 종종 있다.</p>
        <p>민원의 내용을 잘 이해하지 못해서 묻기 위한 경우, 또는 글로서는 설명(답변)이 부족하다고 판단하여 추가 설명을 할 경우 등 그 이유는 다양하다.</p>
        <p>그 중에는, 민감한 내용의 민원이라서 답변이 곤란할 때, 민원인에게 전화를 걸어 분위기를 파악해보고 가능하면 취하를 요청하거나, 부족할 수밖에 없는 답변에 대해 이해를 요청하기 위한 경우도 있다.</p>
        <p>지난 10여 년간 독도 씨가 받은 전화는 모두 ‘답변이 곤란한 민원’에 대한 취하 요청 혹은 이해 요청이었는데, 단 한 번의 예외가 바로 당시 교육부의 전화였다.</p>
      </div>
      <p>&nbsp;</p>
      <p>거두절미하고 본론만 살펴보자.</p>
      <p>&nbsp;</p>
      <div className={ss.talk}>
        <p className={ss.t2}>선생님의 질문 내용이 제가 잘 이해가 안 되서 그러는데요.</p>
        <p className={ss.t2}>남학생과 여학생의 배식량이 달라야 하는 것 아니냐고 하신건가요?</p>
        <p className={ss.t1}>네. 맞습니다.</p>
        <p className={ss.t2}>그러면 혹시 그 학교에서 남학생과 여학생에게 같은 양을 준다는 건가요?</p>
        <p className={ss.t1}>네. 그렇습니다.</p>
        <p className={ss.t2}>아니 왜요?</p>
        <p className={ss.t1}>네? 왜라뇨?</p>
        <p className={ss.t2}>남학생과 여학생이 먹는 양이 다르잖아요?</p>
        <p className={ss.t2}>그런데 그 학교는 같은 양을 줘요?</p>
        <p className={ss.t1}>네…….</p>
        <p className={ss.t1}>우리 학교만 그런 게 아니라,</p>
        <p className={ss.t1}>대부분의 학교가 그렇다고 알고 있거든요.</p>
        <p className={ss.t1}>진부도교육청 장학사도 그렇게 말했고요.</p>
        <p className={ss.t1}>그런데 혹시 그런 사실을 모르고 계셨나요?</p>
        <p className={ss.t2}>아, 네……. 몰랐습니다.</p>
        <p className={ss.t2}>저는 당연히 다르게 주는 것으로 알고 있습니다.</p>
        <p className={ss.t2}>그래야 되는 것 아닌가요?</p>
        <p className={ss.t1}>아니, 그걸 왜 저한테 물으세요?</p>
        <p className={ss.t1}>제가 물어봐야할 질문인거 같은데…….</p>
        <p className={ss.t2}>아, 그런가요?</p>
        <p className={ss.t2}>그럼 제가 좀 더 확인해 보고 답변 드리겠습니다.</p>
      </div>
      <p>&nbsp;</p>
      <p>그러니까 이 전화는 민원인의 질문이 무슨 뜻인지 이해를 못해서 확인하려고 건 전화였다.</p>
      <p>즉, 너무 당연한 물음이라서 혹시 질문에 다른 뜻이 있는지 궁금했다는 것이다.</p>
      <div className={ss.annotation_wrap}>
        <p>또한 교육부의 해석을 받지 않은 상태이기 때문에 법제처에 해석을 요청할 수는 없으니, 교육부에서 먼저 해석을 해주겠다는 취지의 전화였다.</p>
        <p>참고로, 민원인의 판단과 교육부의 해석이 같을 경우, 법제처에 해석 요청을 할 수 없다.</p>
      </div>
      <p>&nbsp;</p>
      <p>지금까지의 전개와는 너무나 다른 의외의 상황이었다.</p>
      <p>교육부 담당자는 너무나 당연하다는 듯이, 남학생과 여학생의 배식량이 다른 것 아니냐고 오히려 반문을 하였다.</p>
      <p>독도 씨는 전화를 끊고 나서도 한 동안 정신이 멍했다.</p>
      <p className={ss.quo_mini}>‘아니, 내가 지금 무슨 소리를 들은 거지? 그동안 너무 비정상이 정상을 대신하고 있어서, 정상을 정상이라고 말하는 사람을 보니까 오히려 생소하고 정신을 못 차리겠네.’</p>
      <p>우연숙 영양교사와 김봉식 교감과 학부모운영위원들에 맞서 너무 힘들고 어렵게 논쟁을 벌였고, 진부도교육청 이장옥 장학사의 형편없는 답변에 진절머리가 나던 차에, <span className={ss.quo_mini}>‘무슨 소리냐? 당신 말이 당연한데 그걸 왜 질문하나?’</span>라는 식의 전화를 받았으니 한편으로는 일종의 성취감이 들었지만, 다른 한편으로는 허탈한 느낌이 들었다.</p>
      <p className={ss.quo_mini}>‘이렇게 당연한 것을 가지고 그렇게 힘들게 싸워야 했다니…….’</p>
      <p>또한 이런 생각도 들었다.</p>
      <p className={ss.quo_mini}>‘그나저나 교육부는 어디 다른 나라에서 근무하나? 어찌 이렇게 교육 현장을 모르나? 이해가 안가네. 이것도 심각한 문제아냐? 도대체, 왜?’</p>
      <p>학교급식 주관부서인 교육부는 「학교급식법 시행규칙」을 만들어 놓았지만, 현장에서 이를 제대로 지키지 않는다는 사실을 전혀 모르고 있었다.</p>
      <p>이는 기본적인 점검도 하지 않는다는 뜻이다.</p>
      <p>&nbsp;</p>
      <p>며칠 뒤 교육부로부터 받은 답변의 내용은 다음과 같았다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo_mini}>평소 자라나는 학생들의 건전한 심신발달과 식생활 개선 등 <span className={ss.bold}>교육의 일환으로 실시하는 학교급식</span>에 대한 관심과 협조에 감사드립니다.</p>
      <p className={ss.quo_mini}>‘학교별 영양관리기준’은 학교에 배치된 영양전문가(영양교사, 영양사)가 「학교급식법」 제11조 및 같은 법 시행규칙 제5조 ‘학교급식의 영양관리기준’에 따라 연령별, 성별 등을 고려한 단위학교에서의 적합한 영양관리기준을 정하고 이를 학교운영위원회 심의를 거쳐 학교장이 결정토록 하고 있습니다.</p>
      <p className={ss.quo_mini}>※ 학교급식의 영양관리기준 : 붙임자료 참조</p>
      <p className={ss.quo_mini}>따라서 남녀공학인 경우는 남학생과 여학생의 영양관리기준이 달라 <span className={ss.bold}>이를 각각 정하고 동 기준에 충족되도록 음식량 등을 조정하여 제공</span>하고 있습니다.</p>
      <p className={ss.quo_mini}>한편, NEIS(교육행정정보시스템)의 학교급식시스템 등의 ‘평균영양권장량’은 연령별, 성별 등을 종합적으로 고려한 학교별 ‘영양량 평균치’를 단순 의미하는 것이지, <span className={ss.bold}>남학생과 여학생에게 동일한 영양량을 제공해야 한다는 것이 아님</span>을 알려드립니다.</p>
      <p>&nbsp;</p>
      <p>굵은 글씨로 강조한 부분이 바로 독도 씨가 주목했던 부분이다.</p>
      <p>첫째, 학교급식은 교육의 일환이다.</p>
      <div className={ss.annotation_wrap}>
        <p>이 명제는 우리나라 공교육의 급식에 대한 정의다.</p>
        <p>단순히 밥을 먹이고 끝나는 것이 아니라 주요 교육과정의 하나이므로, 교육 목적의 달성을 도외시하고 행정편의만 생각해서 쉬운 길로 가려해서는 안 된다.</p>
      </div>
      <p>둘째, 남학생과 여학생의 영양관리기준이 다르므로 양을 달리 주고 있다.(달리 줘야 한다.)</p>
      <div className={ss.annotation_wrap}>
        <p>답변 내용을 보아서는, 진부도교육청을 포함하여 몇몇 교육청에 사실 확인을 위해 문의해 봤을 것으로 추정된다.</p>
        <p>아마도 해당 교육청에서는 ‘자율배식대를 운영하고 있으므로, 달리 주고 있다’고 팩트지만 진실이 아닌 답변을 했을 것이다.</p>
        <p>물론 제대로 운영하고 있는 지의 여부는 단위학교의 책임으로 떠넘기고 말이다.</p>
      </div>
      <p>셋째, 나이스(NEIS)에서 계산한 공급량은 학교의 평균만 계산해서 알려주는 것이며, 이를 기준삼아 남학생과 여학생에게 동일하게 제공하라는 것이 아니다.</p>
      <p>&nbsp;</p>
      <p>그러니까 독도 씨는, 남학생과 여학생의 배식량을 각각 다르게 관리하여야 한다는, [별표3]을 만든 주무관청(교육부)의 유권해석을 받은 것이다.</p>
      <p>결국 독도 씨의 주장이 옳았음이 확인되었다.</p>
      <div className={ss.annotation_wrap}>
        <p>해석에 이견이 있기 어려운 명확한 내용을 영양교사가 잘못 해석하는 일은 있을 수 있다고 해도, 교육청의 담당 장학사가 그러한 것은 문제라고 판단한다.</p>
        <p>민원을 담당하는 교육청의 장학사가 법령의 내용을 이해하는 수준이 부족하다면, 부족한대로 문제이며, 만일 알면서도 똑바로 답변하지 않았다면 이는 더 큰 문제이다.</p>
        <p>독도 씨는, 이런 상식 수준의 내용 확인을 위해서, 교육청에서 안 되어서 교육부에까지 질의해야 한다는 것이, 국민의 한사람으로서 부끄러운 일이라 생각한다.</p>
      </div>
      <p>&nbsp;</p>
      <p>그런데 이는 한비중학교 학교운영위원회에서 급식량 문제를 제기한 뒤로 4개월이나 지난 뒤의 일이었다.</p>
      <p>문제를 해결한 것도 아니고, 고작 문제임을 확인하는 데에 왜 이렇게 오랜 시간이 걸려야 했을까?</p>
      <p>&nbsp;</p>
      <p>첫 번째 원인은 한비중학교의 상식적이지 않은 법 해석 때문이다.</p>
      <p>여러 가지 이유로 남녀학생에게 같은 양을 배식할 수밖에 없었다면, 그것을 해결하기 위한 방법을 찾으면 될 터인데, 같은 양을 배식하는 것이 적법하다는 ‘상식을 벗어난 주장’을 고집했기 때문이다.</p>
      <p>이는 <span className={ss.bold}>문제를 덮는 것이 문제를 쉽게 해결할 수 있는 방법</span>이라는 잘못된 믿음을 가지고 있기 때문인데, 이러한 잘못된 믿음은 많은 사람들이 <span className={ss.bold}>하지 않아도 될 힘든 싸움을 겪게 만드는 악습</span>이다.</p>
      <div className={ss.annotation_wrap}>
        <p>폐쇄적인 사회(조직)일수록 이러한 악습이 깊고 넓게 뿌리내려 있는데, 교육계(학교)는 어느 곳보다 폐쇄적인 사회이다.</p>
        <p>그러다보니 이러한 악습이 만연되어 있어도 이상할 것이 없다.</p>
        <p>게다가 폐쇄적이기 때문에 외부에 잘 드러나지도 않는다.</p>
      </div>
      <p>&nbsp;</p>
      <p>두 번째 원인은 앞서 이야기한 ‘곤란한 민원에 엉뚱한 답변’을 하는 정직하지 못한 교육청 공무원의 태도 때문이다.</p>
      <p>일반적으로, 답변이 곤란한 민원을 제기할 경우, 정답을 얻기 위해서 수개월이 걸리는 것은 매우 흔한 일이며, 그나마 이는 행복한 결론인 편이다.</p>
      <p>수년이 걸려야 겨우 해결이 되는 경우도 있고, 끝까지 해결이 되지 않는 경우도 상당 수 존재한다.</p>
      <div className={ss.annotation_wrap}>
        <p>교육기관을 포함하여 정부기관 어느 곳에나 불공정의 뿌리는 깊고 넓게 자리 잡고 있다.</p>
        <p>너무 크고 오래 고착되어 쉽게 손댈 수 없을 정도이다.</p>
        <p>많은 사람들이 사회의 공정함에 갈증을 느끼지만, 공정한 사회를 구현하기 위해 가야할 길은 멀고도 험하다.</p>
        <p>공정함은 반드시 시스템으로 보장되어야 한다.</p>
        <p>누군가의 선의에 기대야하는 공정함은 신뢰할 수도 지속될 수도 없다.</p>
        <p>그리고 잘 갖추어진 시스템을 기본 전제로 하되, 끊임없이 이를 감시하고 보완할 수 있는 사회의 문화(철학)도 반드시 필요하다.</p>
        <p>즉, 과학적이고 논리적인 토론 문화, 다수의 인정을 얻을 수 있는 합리적 논의, 소수 의견 존중, 공생과 배려, 인권 존중, 책임의 이행과 권리의 보장 등 사회의 민주적 역량을 높여야 한다.</p>
        <p>그러므로 교육(학교민주주의)이 바로 서지 않으면, 그 길은 어쩌면 가지 못할 길이 될지도 모른다.</p>
      </div>
      <p>&nbsp;</p>
      <p>어쨌거나, 독도 씨는 4개월이라는 긴 시간을 싸워 겨우 법 해석을 받았는데, 교육부의 법 해석을 받았음에도 불구하고, 문제가 해결되지는 않았다.</p>
      <p>이는 독도 씨가 더 이상 급식량의 부족을 문제 삼지 않았기 때문인데, 그 이유는 다음과 같았다.</p>
      <p>&nbsp;</p>
      <p>첫째, 이미 4개월이라는 시간이 흘러 어느덧 11월이 되었는데, 학년말이 되어 가면서 관련 이슈가 점차 약해졌다.</p>
      <p>불만이 있었던 일부 학부모들도 점차 이 문제에 둔감하게 되었다.</p>
      <div className={ss.annotation_wrap}>
        <p>특별한 경우가 아니면 학교 내의 문제는 대개 학년말로 가면서 점차 관심이 낮아진다.</p>
        <p>그리고 새로운 학년이 시작되면 작년의 문제는 묻히고 잊히는 경우가 많다.</p>
        <p>학교는 매년 새로운 신입생이 들어오고 졸업생이 떠나간다.</p>
        <p>그러다보니 어떤 문제에 대한 관심이나 그 문제의 해결을 위한 노력이 해를 넘어 지속되기가 어렵다.</p>
        <p>어쩌다 만들어지는 좋은 문화는 단절되기 쉽고, 계속 존재하던 악습은 고쳐지지 않는다.</p>
        <p>그런 이유로, 어떤 이슈는 똑같은 내용이지만 매년 새로운 이슈로서 반복하여 제기된다.</p>
        <p>학교운영위원회가 올바르게 작동하지 않는 문제도 30년째 반복하여 제기되는 이슈의 하나이다.</p>
      </div>
      <p>둘째, 그동안 자율배식대에 밥 외에 김치가 추가되고, 배식구에서도 주찬이 아닌 경우에 한해서 더 달라는 학생에게 반찬을 조금 추가해주는 등의 변화가 있었다.</p>
      <p>이러한 변화로 인해 양 부족이 미약한 수준이나마 개선되면서, 독도 씨의 시선이 조금 누그러졌다.</p>
      <p>셋째, 꽤 많은 시간이 흐르면서 학생들이 적응하기도 하였다.</p>
      <p>아침밥을 좀 더 많이 먹거나, 따로 간식을 먹는 등 여러 방식으로 대응하는 학생이 많이 늘었다.</p>
      <p>그러다보니 양에 대한 불만의 목소리가 이전보다는 다소 줄었다.</p>
      <p>넷째, 그동안 학부모운영위원을 중심으로 몇몇 학부모 사이에서 독도 씨에 대한 부정적인 소문(악의적 평가)이 돌았기 때문에, 그에 따른 큰 심리적 고통이 독도 씨에게 있었다.</p>
      <p>다섯째, 애초에 이 문제에 적극적으로 대응하려는 사람은 독도 씨가 유일했기 때문에, 혼자 힘으로 장기간 계속해서 문제를 제기하기에는 벅찼다.</p>
      <p>&nbsp;</p>
      <p>이런 몇 가지 요인들이 복합적으로 작용하여, 독도 씨는 교육부의 해석을 임춘옥 교장에게 전달하고 나서는 더 이상 급식 문제에 대해 거론하지 않았다.</p>
      <p className={ss.quo_mini}>‘급식 문제는……, 여기까지만 하자. 그래도 조금의 변화가 있었고, 교육부 해석을 전달했으니 학교에서도 좀 더 노력하겠지. 여러 가지로 쉽지가 않네. 생각보다 더 힘드네.’</p>
      <div className={ss.annotation_wrap}>
        <p>정량 배식은 가장 쉬운 배식 방법이다.</p>
        <p>학교급식을 준비하는 급식실의 업무는 매우 고되고(육체노동의 강도도 높고, 안전, 위생, 영양, 환경, 질서, 교육, 만족도 등 신경써야할 부분이 많으며, 전교생을 상대로 하는 만큼 정신적인 노동의 강도도 상당히 높다), 주변의 요구와 시선과 평가가 많아서 스트레스가 상당하기 때문에, 쉬운 배식 방법을 선택했다고 해서 학교급식을 비판하는 것은 어쩌면 가혹한 일일 수도 있다.</p>
        <p>그러나 그렇다고 해서 정량 배식만 고집하면, 실정법을 위반하고, 청소년의 성장에 부정적인 영향을 끼치며, 학교급식의 만족도를 떨어뜨리고, 환경을 오염시키고, 교육급식에 반하게 된다.</p>
        <p>그러므로 우리는 합리적인 방법을 찾기 위해 고민해야 한다.</p>
        <p>실무적으로 어려운 문제라며 해결 노력을 하지 않으려는 영양교사와, 내 아이의 문제가 아니라며 관심을 가지지 않는 학부모와, 현상유지에 만족하기 때문에 문제 제기에 거부반응을 보이는 학교장 모두 그러해서는 안 된다.</p>
        <p>당신들의 이기심으로 피해를 입는 것은 결국 학생들이고, 우리 사회의 미래이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>그러니까 격렬한 논쟁이 있었고, 그로 인해 약간의 변화는 발생했지만, 근본적인 문제는 해결되지 않은 것이다.</p>
      <p>아쉽지만 경험이 부족했던 당시의 독도 씨로서는 그것이 최선이었다.</p>
      <p>&nbsp;</p>
      <p>하지만 이후로도 독도 씨는 학교급식에 대해 깊은 관심을 가졌다.</p>
      <p>&nbsp;</p>
      <p>학교급식의 문제는, 위의 한비중학교에서 벌어진 양에 대한 논쟁 외에도 많다.</p>
      <p>부실한 급식의 질, 부정한 운영, 급식 실무 종사자들의 근무환경, 잔반 문제 등 심각한 문제들이 많다.</p>
      <p>가장 심각한 문제는, 학교급식을 수동적으로 받아들이는 학생들의 급식에 대한 인식(교육급식의 부재)과 학부모의 낮은 관심이다.</p>
      <div className={ss.annotation_wrap}>
        <p>학생과 학부모가 학교 운영의 참여에 수동적인 것은 어제 오늘의 일이 아니다.</p>
        <p>공교육이 시작된 이래로 긴 시간 공급자와 수요자의 관계가 일방적이었으므로, 사람들의 의식(사회 전반의 의식)이 그에 길들여져 있기 때문이다.</p>
        <p>30년 전부터 수요자와 공급자가 상호 협력을 요하는 운영 체계(학교자치)로 바뀌었지만, 이에 대한 교육이 전혀 이루어지지 않고 있기 때문에 사람들의 의식은 여전히 낡은 체계에 머물러 있다.</p>
        <p>교육 전반이 그러하므로, 급식에서의 현상이 특별한 것은 아니다.</p>
        <p>그나마 다른 교육과정보다는 참여와 관심이 높은 편이지만, 턱없이 부족한 것이 현실이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>이러한 문제들의 해결을 위해서는 정책적 고민도 필요하지만, <span className={ss.bold}>무엇보다도 학교민주주의가 정착되는 것이 중요하다.</span></p>
      <p>올바른 급식 교육을 위해서는 학교 구성원들의 능동적인 참여와 올바른 지원과 감시가 필요한데, 이를 위해서는 <span className={ss.bold}>자치 운영 역량이 제고되어야 하기 때문</span>이다.</p>
      <p>&nbsp;</p>
      <p>이렇게, 배가 고프다는 아들의 목소리를 듣고 시작된 독도 씨의 문제 제기와 그로 인한 논쟁은 길었다.</p>
      <p>비록 문제는 해결되지 않았지만, 독도 씨는 정보를 모으고 분석하고 논쟁을 벌이면서 공교육의 문제에 한 걸음 더 접근하게 되었다.</p>
      <p>그리고 독도 씨는, 학교운영위원회에 처음 관심을 가졌던 그해 가을에, 본격적으로 학교와 공교육에 대해 깊이 고민하기 시작하였다.</p>
      <p>&nbsp;</p>
      <p>그가 학교에 다녔던 70년대 말과 80년대는 군사독재 시절이었고, 학교를 포함한 사회의 시스템은 민주적이지 않았다.</p>
      <p>그러나 많은 시민들이 피와 눈물을 흘리며 싸운 덕에, 21세기에 이른 지금 우리 사회에는 민주주의 시스템이 어느 정도 자리를 잡게 되었다.</p>
      <p>그리고 학교 역시 30년 전인 1995년부터 민주주의 시스템(분권, 자치)이 도입되었다.</p>
      <p>&nbsp;</p>
      <p>그런데 학교의 민주주의 시스템에는 심각한 문제가 존재한다.</p>
      <p>형식만 민주주의이고 내용은 그렇지 않다는, 그래서 사실상 민주주의라 볼 수 없다는 절망적인 문제가 존재한다.(형식만 민주주의인 정치 형태에는 대표적으로 독재가 있다.)</p>
      <p>심지어 대부분의 사람들이, 학교가 비민주적이라는 사실조차 알지 못한다.</p>
      <p>근본적인 원인은 아주 명확하다.</p>
      <p>학교를 운영하는 사람들, 즉 학교 구성원들의 의식수준이 민주적이지 않기 때문이다.</p>
      <p>바뀐 시스템으로 30년째 운영되고 있지만, 30년 전의 의식수준을 크게 벗어나지는 못하고 있기 때문이다.</p>
      <p>독도 씨는 이점에 충격을 받았다.</p>
      <p>어째서 학교가 이럴 수가 있을까?</p>
      <p>분명 우리 사회의 시민들은, 비록 이상적이라고 할 수는 없지만 그래도 꽤 민주적이라고 보여 지는데, 학교에서 만나는 구성원(교직원, 학부모 등)들의 의식수준은 민주주의를 전혀 모르는 사람들과 크게 다르지 않으니 말이다.</p>
      <p>&nbsp;</p>
      <p>어쩌면 많은 시민들이, 생각보다도 아직 민주주의를 잘 모르는 것이 아닐까?</p>
      <p>아니면 학교라는 특수한 환경은 민주주의와는 맞지 않는 것인가?</p>
      <p>조금 더, 독도 씨에게는 정보와 분석이 필요했다.</p>
      <p>그래서 독도 씨는 보다 깊은 관심을 가지고 학교와 교육에 대해 고민하기 시작했다.</p>
    </div>
  )
}

export default Year2015_27