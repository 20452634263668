import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import styles from './ReadPost.module.css'
import api from '../../util/api'
import Spinner from '../../common/Spinner/Spinner'
import authSecretPost from '../../util/authSecretPost'
import convertPostCategory from '../../util/convertPostCategory'
import isMeInPost from '../../util/isMeInPost'
import img_lock from '../../img/Community/icon_lock.png'
import img_unlock from '../../img/Community/icon_unlock.png'
import img_heart_yes from '../../img/Community/heart_yes.png'
import img_heart_no from '../../img/Community/heart_no.png'
import img_comment from '../../img/Community/icon_comment.png'
import { jwtDecode } from 'jwt-decode'
import { convertDate3 } from '../../util/convertDate'
import itemPerPage from '../../data/itemPerPage'
import img_enter from '../../img/Community/icon_enter.png'

const ReadPost = () => {

    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const postId = searchParams.get('postId')

    const [data, setData] = useState({})
    const [secretOk, setSecretOk] = useState(false)

    const [comments, setComments] = useState([])
    const [inputComment, setInputComment] = useState('')
    const [commentSecret, setCommentSecret] = useState(false)

    const [isModifyComment, setIsModifyComment] = useState('none')
    const [inputModifyComment, setInputModifyComment] = useState('')
    const [modifyCommentSecret, setModifyCommentSecret] = useState(false)

    const [isReplyComment, setIsReplyComment] = useState('none')
    const [inputReplyComment, setInputReplyComment] = useState('')
    const [replyCommentSecret, setReplyCommentSecret] = useState(false)


    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        if (data) {
            if (data.secret === true) {
                if (authSecretPost(data) === true) {
                    setSecretOk(true)
                } else {
                    navigate('/community')
                }
            } else {
                setSecretOk(true)
            }
        }
    }, [data])

    const fetchData = async () => {
        try {
            const response = await api.get(`/fetch-post?postId=${postId}`)
            if (response.data.message === 'ok') {
                let tempData = response.data.post

                const commentsData = tempData.comments.filter(item => item.status === 'normal')
                let temp = {}
                commentsData.forEach((item, idx) => {
                    if (item.original) {
                        if (temp.hasOwnProperty(item.original)) {
                            temp[item.original].push(item)
                        } else {
                            temp[item.original] = []
                            temp[item.original].push(item)
                        }
                    }
                })

                const doesntHaveOriginal = commentsData.filter((item, idx) => !item.original)
                let result = []
                for (const post of doesntHaveOriginal) {
                    result.push(post)
                    if (temp[post._id]) {
                        for (const under of temp[post._id]) {
                            result.push(under)
                        }
                    }
                }
                tempData.comments = result
                setData(tempData)
            } else {
                navigate('/community')
            }
        } catch (err) {

        }
    }


    const handleClickDeletePost = async () => {
        if (isMeInPost(data) === false) {
            return
        }
        const result = window.confirm('삭제된 글은 복구가 불가능합니다. 삭제하시겠습니까?')
        if (result) {
            const response = await api.get(`/delete-post?postId=${data._id}`)
            if (response.data.message === 'ok') {
                if (data.category === 'dokdo') {
                    navigate('/dokdo_column')
                } else {
                    navigate('/community')
                }
            }
        }
    }

    const handleClickLike = async () => {
        if (!localStorage.getItem('token')) {
            navigate('/login')
        } else {
            const response = await api.get(`/post-like?userId=${jwtDecode(localStorage.getItem('token')).userId}&postId=${data._id}`)
            if (response.data.message === 'ok') {
                setData(prevData => ({
                    ...prevData,
                    like: response.data.newLike
                }))
            }
        }
    }

    const isAlreadyLike = (array) => {
        if (!localStorage.getItem('token')) {
            return false
        } else if (array.includes(jwtDecode(localStorage.getItem('token')).userId)) {
            return true
        } else {
            return false
        }
    }

    const handleReplyPost = () => {
        if (data.original) {
            navigate(`/write?o=${data.original}`)
        } else {
            navigate(`/write?o=${data._id}`)
        }
    }

    const handleCommentSubmit = async () => {
        if (!localStorage.getItem('token')) {
            alert('로그인이 필요합니다.')
            return
        }
        try {
            const response = await api.post('/write-comment', {
                userId: jwtDecode(localStorage.getItem('token')).userId,
                postId: data._id,
                content: inputComment,
                secret: commentSecret,
            })
            if (response.data.message === 'ok') {
                setInputComment('')
                setCommentSecret(false)
                fetchData()
            }
        } catch (err) {

        }
    }

    const handleLikeComment = async (comment) => {
        if (!localStorage.getItem('token')) {
            navigate('/login')
            return
        }
        try {
            const response = await api.post('/comment-like', { userId: jwtDecode(localStorage.getItem('token')).userId, commentId: comment._id })
            if (response.data.message === 'ok') {
                fetchData()
            }
        } catch (err) {

        }
    }

    const handleDeleteComment = async (comment) => {
        if (isMeInPost(comment) === false) {
            return
        }
        const result = window.confirm('삭제된 글은 복구가 불가능합니다. 삭제하시겠습니까?')
        if (result) {
            const response = await api.get(`/delete-comment?commentId=${comment._id}`)
            if (response.data.message === 'ok') {
                fetchData()
            }
        }
    }

    const movePrevAndNext = async (direction) => {
        try {
            const response = await api.get(`/get-community?category=${data.category}`)

            const postsData = response.data
            let temp = {}
            postsData.forEach((item, idx) => {
                if (item.original) {
                    if (temp.hasOwnProperty(item.original)) {
                        temp[item.original].push(item)
                    } else {
                        temp[item.original] = []
                        temp[item.original].push(item)
                    }
                }
            })

            const doesntHaveOriginal = postsData.filter((item, idx) => !item.original)
            let result = []
            for (const post of doesntHaveOriginal) {
                result.push(post)
                if (temp[post._id]) {
                    const reversedUnder = temp[post._id].slice().reverse()
                    for (const under of reversedUnder) {
                        result.push(under)
                    }
                }
            }
            const final = result.filter(item => item.status === 'normal')
            const index = final.findIndex(item => item._id === data._id)
            const indexToList = result.findIndex(item => item._id === data._id)
            if (direction === 'prev') {
                if (index === 0) {
                    alert('맨 앞 게시글입니다.')
                } else {
                    window.location.href = `/read_post?postId=${final[index - 1]._id}`
                }
            } else if (direction === 'next') {
                if (index === final.length - 1) {
                    alert('마지막 게시글입니다.')
                } else {
                    window.location.href = `/read_post?postId=${final[index + 1]._id}`
                }
            } else if (direction === 'list') {
                let line = data.category
                if (data.category === 'dokdo') {
                    line = 'dokdo_column'
                }
                navigate(`/${line}?page=${Math.ceil((indexToList + 1) / itemPerPage)}`)
            }
        } catch (err) {

        }
    }

    const handleClickModifyComment = (comment) => {
        setIsReplyComment('none')
        setModifyCommentSecret(comment.secret)
        setInputModifyComment(comment.content)
        setIsModifyComment(comment._id)
    }

    const handleCancelModifyComment = () => {
        setInputModifyComment('')
        setIsModifyComment('none')
    }

    const handleModifyCommentSubmit = async () => {
        try {
            const response = await api.post('/modify-comment', {
                commentId: isModifyComment,
                secret: modifyCommentSecret,
                content: inputModifyComment,
            })
            if (response.data.message === 'ok') {
                fetchData()
                handleCancelModifyComment()
            }
        } catch (err) {

        }
    }

    const handleClickReplyComment = (comment) => {
        setIsModifyComment('none')
        setReplyCommentSecret(comment.secret)
        setInputReplyComment('')
        setIsReplyComment(comment._id)
    }
    const handleCancelReplyComment = () => {
        setInputReplyComment('')
        setIsReplyComment('none')
    }

    const handleReplyCommentSubmit = async (comment) => {
        if (!localStorage.getItem('token')) {
            navigate('/login')
            return
        }
        try {
            const response = await api.post('/reply-comment', {
                userId: jwtDecode(localStorage.getItem('token')).userId,
                postId: data._id,
                original: comment.original ? comment.original : isReplyComment,
                content: inputReplyComment,
                secret: replyCommentSecret,
                originalPoster: comment?.poster?._id
            })
            if (response.data.message === 'ok') {
                fetchData()
                handleCancelReplyComment()
            }
        } catch (err) {

        }
    }








    if (!data.title || !secretOk) {
        return (
            <div className={styles.wrap}>
                <Spinner />
            </div>
        )
    }

    return (
        <div className={styles.wrap}>
            <div className={styles.main_wrap}>
                <div className={styles.category_wrap}>
                    <h1 className={styles.category}>{convertPostCategory(data.category)}</h1>
                    <div className={styles.modify_btn_wrap}>
                        {localStorage.getItem('token') && (data.category === 'community' || jwtDecode(localStorage.getItem('token')).admin === true)
                            && (
                                <p className={styles.btn_blue} onClick={() => handleReplyPost()}>답글</p>
                            )}
                        {isMeInPost(data) && (
                            <>
                                <p className={styles.btn_blue} onClick={() => navigate(`/write?q=${data._id}`)}>수정</p>
                                <p className={styles.btn_blue} onClick={() => handleClickDeletePost()}>삭제</p>
                            </>
                        )}
                    </div>

                </div>
                <div className={styles.title_wrap}>
                    <div>
                        {data.secret === true && <img className={styles.img_lock} src={img_lock} />}
                        {data.head !== '선택 안 함' && <p className={styles.title_head}>[{data.head}]</p>}
                    </div>
                    <h1>{data.title}</h1>
                </div>
                <div className={styles.post_info_wrap}>
                    <div>
                        <p style={{ color: data.poster?.nickname ? 'black' : 'gray' }}>{data.poster?.nickname ? data.poster.nickname : '(알 수 없음)'}</p>
                        <div className={styles.mini_line_column} />
                        <p>{convertDate3(data.date)}</p>
                    </div>
                </div>
                <div className={styles.post_info_wrap} style={{position:'relative',bottom: '8px'}}>
                    <p className={styles.view_count}>조회수 {data.view && data.view.length}</p>
                    <div className={styles.button_wrap_mobile}>
                        <p className={styles.btn_blue} onClick={() => movePrevAndNext('prev')}>이전</p>
                        <p className={styles.btn_blue} onClick={() => movePrevAndNext('list')}>목록</p>
                        <p className={styles.btn_blue} onClick={() => movePrevAndNext('next')}>다음</p>
                    </div>
                </div>

                <div className={`${styles.danger} ${styles.content_area}`} dangerouslySetInnerHTML={{ __html: data.content }} style={{ overflow: "auto" }} />

                <div className={styles.like_and_view_wrap}>
                    <div onClick={() => handleClickLike()}>
                        <img src={isAlreadyLike(data.like) ? img_heart_yes : img_heart_no} />
                        <p>좋아요</p>
                        <span>{data.like?.length}</span>
                    </div>
                    <div>
                        <img src={img_comment} />
                        <p>댓글</p>
                        <span>{data.comments?.length}</span>
                    </div>
                </div>
                <div className={styles.comment_wrap}>
                    <h1>댓글</h1>
                    <div className={styles.comment_area}>
                        {data.comments?.map((item, idx) => (
                            <div key={idx} style={{ display: 'flex', alignItems: 'flex-start' }} onClick={() => console.log(item)}>
                                {item.original && (
                                    <img src={img_enter} style={{ width: '20px', marginRight: '5px' }} />
                                )}
                                <div style={{ width: '100%' }}>
                                    <div className={styles.comment_top_area}>
                                        <h3 style={{ color: item?.poster?.nickname ? 'black' : 'gray' }}>
                                            {item?.poster?.nickname ? item.poster.nickname : '(알 수 없음)'}
                                        </h3>
                                        <p style={{ marginRight: '5px' }}>{convertDate3(item.date)}</p>
                                        {isMeInPost(item) === true && isModifyComment !== item._id && (
                                            <>
                                                <p onClick={() => handleClickModifyComment(item)}>수정</p>
                                                <p onClick={() => handleDeleteComment(item)}>삭제</p>
                                            </>
                                        )}
                                        {isModifyComment === item._id && (
                                            <>
                                                <p onClick={() => handleModifyCommentSubmit()}>완료</p>
                                                <p onClick={() => handleCancelModifyComment()}>취소</p>
                                                <img src={modifyCommentSecret ? img_lock : img_unlock} onClick={() => setModifyCommentSecret(!modifyCommentSecret)}
                                                    style={{ width: '20px', marginLeft: '5px' }} />
                                            </>
                                        )}
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                        {item.secret && (
                                            <img src={img_lock} style={{ width: '20px', marginRight: '5px' }} />
                                        )}
                                        <p className={styles.comment_content} style={{ color: item.secret && !isMeInPost(item) ? 'red' : 'black' }}>
                                            {item.to && (
                                                <span className={styles.mention_span}>@{item.to?.nickname}</span>
                                            )}
                                            {isModifyComment === item._id ? (
                                                <>
                                                    <textarea className={styles.input_comment} value={inputModifyComment}
                                                        onChange={(e) => setInputModifyComment(e.target.value)} />
                                                </>
                                            ) : (
                                                item.secret && !isMeInPost(item) ? '비밀 댓글입니다.' : item.content
                                            )}

                                        </p>
                                    </div>

                                    <div className={styles.comment_bottom_area}>
                                        <div onClick={() => handleLikeComment(item)}>
                                            <img src={isAlreadyLike(item.like) ? img_heart_yes : img_heart_no} />
                                            <p>{item?.like?.length}</p>
                                        </div>
                                        {isReplyComment === item._id ? (
                                            <p onClick={() => handleCancelReplyComment()}>취소</p>
                                        ) : (
                                            <p onClick={() => handleClickReplyComment(item)}>답글 쓰기</p>
                                        )}

                                    </div>

                                    {isReplyComment === item._id && (
                                        <div style={{ display: 'flex', marginTop: '10px', marginBottom: '-20px' }}>
                                            <img src={img_enter} style={{ width: '25px', height: '25px', marginRight: '10px' }} />
                                            <div style={{ width: '100%' }}>
                                                <textarea className={styles.input_comment} value={inputReplyComment}
                                                    onChange={(e) => setInputReplyComment(e.target.value)} placeholder='댓글을 입력해 주세요.' />
                                                <div className={styles.comment_btn_area}>
                                                    <img src={replyCommentSecret ? img_lock : img_unlock} onClick={() => setReplyCommentSecret(!replyCommentSecret)} />
                                                    <p className={`${styles.btn_blue}`} style={{ width: 'fit-content', height: 'fit-content', marginRight: '0px' }} onClick={() => handleReplyCommentSubmit(item)}>
                                                        등록
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div>
                        ))}
                    </div>
                    <textarea className={styles.input_comment} value={inputComment} onChange={(e) => setInputComment(e.target.value)} placeholder='댓글을 입력해 주세요.' />
                    <div className={styles.comment_btn_area}>
                        <img src={commentSecret ? img_lock : img_unlock} onClick={() => setCommentSecret(!commentSecret)} />
                        <p className={`${styles.btn_blue}`} style={{ width: 'fit-content', height: 'fit-content', marginRight: '0px' }} onClick={() => handleCommentSubmit()}>
                            등록
                        </p>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ReadPost