import React from 'react'
import styles from './MainText.module.css'

const Prologue09 = () => {
  return (
    <div className={styles.wrap}>
      <h1 className={styles.title}>9. 규정개정심의위원회.</h1>
      <p></p>
      <p>학생의 학교생활에 관한 규정은, 학교마다 조금씩 차이는 있지만 대부분 비슷하다.</p>
      <p>통칭하면 학교생활규정이라 할 수 있고, 학생생활규정, 학교생활인권규정, 생활인권규정 등 지역에 따라 혹은 학교에 따라 이름이 다를 수 있다.</p>
      <p>경기도교육청 기준으로 학교생활인권규정의 개정 절차는 다음 예시(가상 사례)와 같다.(학교마다 조금의 차이는 있을 수 있으나 전반적인 내용은 같다.)</p>
      <p>&nbsp;</p>
      <p className={styles.circle_num}></p>
      <p className={styles.circle_num}>① 한국중학교는 여름에도 교복(하복, 긴 바지)을 입고 등교해야 하는 규정이 있는데, 이 학교의 학생들은 날이 더우면 반바지를 입고 등교하고 싶어 했다.</p>
      <p className={styles.circle_num}>② 어느 날 2학년 3반 학급회의에서 체육복 반바지를 입고 등교하고 싶다는 의견이 나왔고, 학생들은 이를 학생회에 건의하기로 의결하였다.</p>
      <p className={styles.circle_num}>③ 2학년 3반 학급회의에서 의결된 내용이 학생회에 전달되었는데, 3학년 1반에서도 이런 의견이 나왔고, 최근 학생들의 불만이 많이 접수되고 있기에, 학생회는 대의원회의를 소집하여 논의한 끝에 전체 학생의 의견을 수렴하기로 결정하였다.</p>
      <p className={styles.circle_num}>④ 전체 학생의 의견을 수렴한 후, 학생회는 학교생활인권규정 개정안(5월에서 9월까지 체육복 반바지 등교 자율 허용)을 작성하였고, 대의원회의 의결을 거쳐 발의하였다.</p>
      <p className={styles.circle_num}>⑤ 학생회가 발의한 개정안은 규정개정심의위원회(이하 위원회)로 전달되었다.(위원회는 학생위원, 학부모위원, 교사위원으로 구성된다.)</p>
      <p className={styles.circle_num}>⑥ 위원회가 소집되었고 해당 개정안에 대해 학부모와 교사의 의견을 수렴하였다.</p>
      <p className={styles.circle_num}>⑦ 수렴된 의견을 놓고 위원회에서 회의를 하여 수정 개정안(기상청 발표 기준, 체감온도 33도 이상일 때 체육복 반바지 등교 자율 허용)을 확정하였다.</p>
      <p className={styles.circle_num}>⑧ 수정 개정안이 학교운영위원회에 상정되었고, 학교운영위원회 심의 결과 수정 개정안이 원안 가결되었다.</p>
      <p className={styles.circle_num}>⑨ 학교장이 새로운 학교생활인권규정을 승인 및 공포하였고 시행되었다.</p>
      <p>&nbsp;</p>
      <p>위와 같은 절차로, 학생은 학교생활과 관련한 규정의 개정에 참여할 수 있다.</p>
      <p>비록 ‘학생이 없는 학교운영위원회’에서 최종 심의를 받아야 하지만, 그 전 단계인 규정개정심의위원회에서는 학부모 및 교사와 동등한 권리를 가지고 참여할 수 있다.</p>
      <p>물론 학부모와 교사를 모두 설득해야 하는 어려움이 있지만, 전략적으로 잘 접근한다면 어느 정도 승산이 있을 것이라 판단한다.</p>
      <p>무엇보다도 ‘학생의 대표기구인 학생회에서 개정안을 발의할 수 있기 때문에, 불편한 규정을 바꾸자는 목소리를 공식적인 절차로 낼 수 있다는 것’이 중요하다.(개정안 발의는 위원회, 학생회, 교사협의회, 학부모회 모두 가능하다.)</p>
      <p>&nbsp;</p>
      <p>쉽지 않는 길이지만, 청와대에 청원을 해야만 할 정도의 어려운 일도 아니다.</p>
      <div className={styles.annotation_wrap}>
        <p>2018년에서 2019년에 걸쳐 청와대 청원게시판에는, 학생들의 교복 관련 청원이 천 건 이상 게시되었다.</p>
        <p>주로 불편한 교복을 개선해달라고(혹은 없애달라고) 호소하는 내용들이었다.</p>
        <p>이에 대해서는 본문에서 따로 자세히 쓰겠지만, 교복을 바꾸는 것은 학교에서 구성원들이 결정하는 일이고, 규정개정심의위원회에 발의하여 안건으로 다룰 수 있는 일이다.</p>
        <p>학생들은 이런 절차를 모르기 때문에 스스로 안건을 발의할 생각을 하지 못하고, 처음에는 규정을 만드는 사람이라 생각한 교장선생님에게 부탁했을 것이다.</p>
        <p>그러나 <span className={styles.bold}>교장선생님은 이런 절차를 알기 때문에, 이런 절차를 행하지 않는다.</span>(이런 절차를 작동시키면 학교 구성원들이 민주주의에 익숙해진다. 학교의 구성원들이 민주주의에 익숙해지면 학교운영위원회도 민주적 절차로 작동하게 된다. 교장선생님이 학교운영위원회의 민주적 작동을 어떻게 생각하는지는 본문을 보면 쉽게 알 수 있다.)</p>
        <p>교장선생님이 들어주지 않으니, 더 높은 사람에게 청해야 한다고 판단하고 청와대에 청원을 넣었을 것이다.</p>
        <p>아마도 학생들은, 교복(규정)을 바꾸는 것은 대통령이 개입해야만 가능한 매우 어려운 일이라 생각했을 것이다.</p>
        <p>이와 같은 청와대 청원 건에서 보여 지는 가장 큰 문제는, <span className={styles.bold}>수평적 자치로 해결해야할 일을 수직적인 힘(위계질서)을 이용하여 해결하려 하는 상명하복 문화와 중앙집권적 사고방식의 고착화</span>에 있다.</p>
        <p>또한 이와 같은 청원 행위를, “어려움이 있으면 청원할 수도 있지.”라고 우리가 쉽게 받아들인다면, 우리에게 있어서 <span className={styles.bold}>규정을 찾아보는 일은 영원히 낯선 일</span>이 되어버린다.</p>
        <p>몇 자만 타이핑하면 관련 규정이 줄줄 검색되는 시대에, 스스로 찾으면 쉽게 해결될 수 있는 민원을 행정기관에 질의하는 것은, 민원인에게도 담당 공무원에게도 모두 많은 시간이 소비되는 비효율적인 일이다.</p>
        <p>행정은 규정에 종속되어 있다.</p>
        <p>시민들이 규정에 익숙해지지 않으면, 민원이 남발되어 발생하는 행정의 낭비가 일상화되는 것을 막을 수 없다.(공공에서의 혁신의 기회가 점점 줄어드는 것이다.)</p>
        <p>&nbsp;</p>
        <p>학생들이, 학교에서 해결할 수 있는 민원을 청와대에 넣는 현상은 절대로 가볍게 볼 문제가 아니다.</p>
        <p>학생들에게 “불편한 규정이 있다면 너희 스스로 개정안을 만들어 오라.”는 말을 해주지 못한다면, 학교가 그러한 교육(연습, 훈련)을 제공해주지 못한다면, 이전의 세대가 그랬듯이, 지금의 학부모 세대가 그러하듯이, 미래의 세대 또한 마찬가지로, 학교자치·학교민주주의는 계속해서 잠들어 있을 것이고, 사회의 유지를 위해 알아야할 중요 정보들은 계속 은폐될 것이며, 무엇보다 중요한 <span className={styles.bold}>민주적 절차는 영원히 배우지 못하게 될 것이다.</span></p>
        <p>사회는, 소수의 기득권에게는 행복한 사회가 되겠지만, 건강하게 유지되지는 못할 것이다.</p>
      </div>
    </div>
  )
}

export default Prologue09