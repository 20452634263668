const is31Day = (month) => {
    switch (month) {
       case 1:
          return true;
       case 2:
          return false;
       case 3:
          return true;
       case 4:
          return false;
       case 5:
          return true;
       case 6:
          return false;
       case 7:
          return true;
       case 8:
          return true;
       case 9:
          return false;
       case 10:
          return true;
       case 11:
          return false;
       case 12:
          return true;
    }
 }

 export default is31Day;