import React from 'react'
import styles from './NotFound.module.css'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {

    const navigate = useNavigate()

  return (
    <div className={styles.wrap}>
        <h1>404 Not Found</h1>
        <h3>잘못된 접근입니다.</h3>
        <p onClick={()=>navigate('/')}>메인 페이지로 가기</p>
    </div>
  )
}

export default NotFound