import React from 'react'
import styles from './MainText.module.css'

const Prologue12 = () => {
  return (
    <div className={styles.wrap}>
      <h1 className={styles.title}>12. 질문의 시작점.</h1>
      <p>당신이, “규정은 누가 만드는 것인가?”라는 질문을 했더라면 규정의 개정에 학생이 참여할 수 있다는 것을 알아냈을 것이다.</p>
      <p>그랬다면 제한적이나마 당신은 불편함을 바꾸기 위해 노력했을 지도 모른다.</p>
      <p>또한 그런 당신이 적당히 많았다면, 오늘날 대부분의 학교는 학생회를 통해 규정 개정안을 발의할 수 있다는 것을 ‘어쩔 수 없이’ 가르쳤을 것이다.</p>
      <p>그로인해 학생의 주권(최종 결정에 참여할 권리) 요구가 높아졌을 것이고, 우리 사회의 민주주의에 대한 이해는 지금보다 훨씬 나아졌을 것이다.</p>
      <p>&nbsp;</p>
      <p>하지만 나는 당신이 질문하지 않은 것에 대해 당신의 책임을 묻지 않는다고 했다.</p>
      <p>당신이 그런 질문을 떠올리지 못하도록 만든 사회의 시스템에 문제가 있다고 했고, 예컨대 당신에게 “선생님 말씀을 무조건 잘 들어야한다.”고 가르친 부모님에게도 책임이 있다고 했다.</p>
      <div className={styles.annotation_wrap}>
        <p>“불편한 것이 있으면 불편하다고 적극적으로 말하라.”고 가르치는 부모는 매우 드물다.</p>
        <p>선생님의 말씀이 아니라, “규정을 확인하고 규정을 지키라.”고 말하는 부모는 더욱 드물다.</p>
        <p>성문법 체계가 갖추어진 나라에서, 규정(성문법)이 있는데 선생님의 해석(불문법)만 따르라고 하니까, 규정을 오인하고 무시함으로써 발생하는 사건·사고가 끊이지 않는 것이다.</p>
      </div>
      <p>그리고 그런 시스템의 정점에는 학교가 있다.</p>
      <p>위에서 말했듯이 학교는 규정의 일부(개정 절차)를 감추고 가르쳐주지 않는다.</p>
      <p>글을 배우기 시작하면서부터 12년 동안 계속해서 의무가 잔뜩 적힌 목록만 배웠기 때문에, 그것을 바꿀 수 있는 권리(규정을 만드는 사람)에 대해서 관심을 가지기는 쉽지 않다.</p>
      <p>&nbsp;</p>
      <p>그렇다면 왜일까?</p>
      <p>왜 학교는 규정의 전문을 알려주지 않는 것일까?</p>
      <div className={styles.annotation_wrap}>
        <p>“규정의 전문을 홈페이지에 공지했으므로 숨긴 것이 아니다.”라는 비겁한 변명은 하지 말자.</p>
        <p>홈페이지의 어느 구석에 있는지 찾기도 어렵고, 조회수를 보면 대부분 한 자릿수이다.</p>
        <p>아무도 방문하지 않도록 불편함을 방치하는 것도 학교의 홈페이지 운영 노하우다.</p>
      </div>
      <p>&nbsp;</p>
      <p>우리는 이제 이것에 대해 의문을 가져야할 필요가 있다.</p>
      <p>그리고 이와 유사한 질문이 이 책의 여러 곳에서 계속 등장할 것이다.</p>
    </div>
  )
}

export default Prologue12