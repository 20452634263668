import React from 'react'
import ss from './MainText.module.css'

const Year2015_17 = () => {
  return (
    <div className={ss.wrap}>
      <h1 className={ss.title}>17. 아들에게.</h1>
      <p>그날 저녁, 독도 씨는 아들을 불렀다.</p>
      <p className={ss.quo}>“밤톨아! 이리 와볼래?”</p>
      <p className={ss.quo}>“예. 왜요?”</p>
      <p>어떻게 말을 꺼내야할지 고민하느라 잠시 뜸을 들인 뒤에, 독도 씨는 이야기를 시작했다.</p>
      <p className={ss.quo}>“너, 학교생활 재밌니?”</p>
      <p className={ss.quo}>“네. 재밌어요. 친구들도 많이 사귀고요.”</p>
      <p className={ss.quo}>“잘됐네. 학교생활은 재밌어야 해. 그게 최고지. 수업은 어때? 지루하고 어렵고 싫고 그렇지는 않아?”</p>
      <p className={ss.quo}>“전혀요. 수업도 나름 재밌어요. 그런데 왜요?”</p>
      <p className={ss.quo}>“음. 아빠가 학교운영위원인 건 저번에 말해줘서 알지?”</p>
      <p className={ss.quo}>“그럼요. 알죠.”</p>
      <p>이미 독도 씨는 아들에게 운영위원회에 대해서도 설명해주었고, 아빠가 운영위원을 하려는 이유(학교 운영에 구성원으로서 참여하려 함)도 설명해주었다.</p>
      <p>밤톨이는 아직 중학교 1학년 어린 나이지만, 아빠의 뜻을 어느 정도는 이해하고 있었다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“그런데 운영위원이란 게, 어쩔 땐 교장선생님의 뜻에 반대할 수도 있거든. 너도 알다시피 아빠가 뭐든 시키는 대로만 하고 막 굽실거리는 그런 사람은 아니잖아. 아니다 싶으면 아니라고 말하는 스타일인 거 알지?”</p>
      <p className={ss.quo}>“네. 알아요.”</p>
      <p className={ss.quo}>“뭐, 너도 나 닮았으니까 잘 알겠지. 그래서 말인데, 아무래도 아빠가 교장선생님하고 많이 부딪힐 것 같아. 그러다보면 교장선생님이나 학교 선생님들이 아빠를 싫어할 수도 있을 거야. 그렇겠지?”</p>
      <p className={ss.quo}>“그렇겠죠.”</p>
      <p className={ss.quo}>“자, 아빠는 선생님들이 아무리 내가 싫고 밉다고 해도 너한테 대놓고 불이익을 주거나 차별 하지는 않을 거라고 생각해. 거의 확실해. 하지만, 그럴 리 없겠지만, 그래도 혹시나 네가 학교생활을 하는데 뭔가 선생님으로부터 차별을 당하거나 억울한 일을 당할 수도 있어. 아빠는 그게 걱정이야.”</p>
      <p>&nbsp;</p>
      <p>독도 씨가 본론을 꺼내자, 밤톨이는 잠시 생각을 하고는 답했다.</p>
      <p className={ss.quo}>“음, 근데 괜찮을 거 같아요. 좀 이상하고 마음에 안 드는 선생님도 있긴 한데, 좋은 선생님도 많더라고요. 그런 선생님들이 그럴 것 같지는 않아요. 그리고 저는 원래 그런 거 잘 신경 안 써요.”</p>
      <p>밤톨이는 원래 선생님이 관심을 가져주고 자상하게 대해주기를 바라는 타입이 아니다.</p>
      <p>친구들과 뛰어 놀고 즐기는 것이 중요할 뿐, ‘선생님의 학생에 대한 관심’에는 관심이 없는 아이다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“그래도 혹시 그런 일이 있을까봐 그래. 그러면 아빠가 너한테 미안하잖아.”</p>
      <p className={ss.quo}>“에이, 그런 사람 있으면 그 사람이 나쁜 거잖아요. 나한테 미안할 필요 없어요. 아빠는 아빠 하고 싶은 대로 하세요. 나는 괜찮아요.”</p>
      <p className={ss.quo}>“그리고 너 어쩌면 앞으로 상도 잘 못 받을지 몰라. 객관적인 점수로 매기는 대회 우수상 같은 것은 네가 잘하면 받을 수 있는데, ‘품행이 바르고 타의 모범이 되는 학생’한테 주는 상처럼 선생님들이 주관으로 결정하는 상은 받기 힘들 수도 있어. 그리고 어떤 선생님은 수행 점수도 박하게 줄지 몰라. 물론 아까도 말했지만 그럴 가능성이 높지는 않은데 혹시 몰라서 그래.”</p>
      <p className={ss.quo}>“에이 뭐, 상 안 받으면 어때요. 그리고 수행 평가도 뭐 착실하게 하면 기본 점수는 다 준다고 하던데요. 걱정하지 마세요. 난 진짜 괜찮아요.”</p>
      <p>밤톨이는 빙그레 웃으면서 걱정하지 말라고 했다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“그래? 고맙다. 그럼 아빠 계속 운영위원 할게.”</p>
      <p className={ss.quo}>“네. 아빠.”</p>
      <p className={ss.quo}>“별일 없겠지만, 혹시라도 무슨 일 생기면, 그때는 숨기지 말고 아빠한테 말해 줘야해. 알겠지? 그러면 아빠가 자세히 알아보고 잘못된 것이 있다면 바로 잡아줄게.”</p>
      <p className={ss.quo}>“그럴게요.”</p>
      <p>&nbsp;</p>
      <p>독도 씨는 앞으로도 거수기로 행동할 생각이 없었다.</p>
      <p>그렇다면 교장이 그를 좋아할 리도 없을 것이다.</p>
      <p>그런데 독도 씨는 앞으로 그와 번번이 부딪히게 될 교장에게, 생각지도 못했던 무기가 있다는 것을 알게 되었다.</p>
      <p>그래서 만일 그의 아들이 혹시나 있을 수 있는 불이익을 두려워한다면, 차라리 운영위원을 그만 두겠다는 생각을 했다.</p>
      <p>하지만 아들은 신경 쓰지 않는다고 했다.</p>
      <p>이제 독도 씨의 마음속에는, 교장의 그 비겁하고 악랄한 무기를 깨뜨려야 한다는 투지가 불타올랐다.</p>
      <p>그리고 그것이야말로 진정으로 아들을 위한 일이라고 생각했다.</p>
    </div>
  )
}

export default Year2015_17