import React from 'react'
import styles from './MainText.module.css'

const Year2015_01 = () => {
    return (
        <div className={styles.wrap}>
            <h1 className={styles.title}>1. 교장실에서</h1>
            <p className={styles.quo}>“교장선생님, 잘 대처하셔야 합니다. 자칫 실수하시면 낭패 볼 수 있습니다.”</p>
            <p className={styles.quo}>“그러게요. 좀 이상한 자가 들어와서는……, 지난번 교육청 민원 건은 정말 아슬아슬 했어요. 큰 일 날 뻔 했지요.”</p>
            <p>웃는 얼굴과 달리 목소리는 다소 긴장한 듯한 교감을 바라보며, 임춘옥 교장은 깊은 한 숨을 내쉬었다.</p>
            <p>&nbsp;</p>
            <p className={styles.quo}>“맞습니다. 지원청 조 팀장 아니었으면 심각할 뻔 했습니다. 우리가 너무 안일했어요. 물론 이번 일은 그때와 다릅니다. 규정대로라면 문제될 부분이 없어요. 그래도 조심해서 나쁠 것은 없지요.”</p>
            <p>김봉식 교감은 조심해야 한다는 것을 재차 강조했다.</p>
            <p>&nbsp;</p>
            <p className={styles.quo}>“뭣도 모르면서 뭔가 대단한 일을 한다고 착각하며 애쓰는 사람들이 가끔 있습니다. 교육에 대해서는 정말 아무 것도 모르면서 말이지요. 그런 학부모들이 여럿 있으면 학교를 운영 하는 게 많이 힘들어져요.”</p>
            <p>교장의 불만 섞인 목소리가 조심스럽게 흘러나왔다.</p>
            <p>&nbsp;</p>
            <p className={styles.quo}>“잘 모를 때 초장에 길을 잘 들여놔야 합니다. 앞으로 제가 신경 써서 살펴보겠습니다.”</p>
            <p>가끔 말실수 때문에 구설에 오르는 일이 있긴 하지만, 김봉식 교감은 든든한 지원군이다.</p>
            <p>이런 교감이 있으면 엉뚱한 학부모를 다루는 것이 한결 쉬워진다.</p>
            <p className={styles.quo}>“그래요. 교감선생님만 믿습니다. 그렇다고 너무 기죽이지는 말고요. 살살 다뤄 주세요. 발끈해서 또 쓸데없는 민원 넣고 그러면 골치 아프니까.”</p>
            <p>학교는 그저 조용한 것이 최고다.</p>
            <p>좋은 일이든 나쁜 일이든 시끄러우면 피곤하다.</p>
            <p>임춘옥 교장은 교장실을 나서는 교감을 배웅하고는 그 사람을 만나기 위해 행정실로 향했다.</p>
        </div>
    )
}

export default Year2015_01