import React from 'react'
import styles from './MainText.module.css'

const Prologue07 = () => {
  return (
    <div className={styles.wrap}>
      <h1 className={styles.title}>7. 학교의 주인은 누구인가?</h1>
      <p>학교에는 아주 많은 규정들이 존재한다.</p>
      <p>학칙을 시작으로 학교생활규정, 학업성적관리규정, 학교운영위원회규정, 학부모회규정, 급식소위원회규정, 예결산소위원회규정, 교복선정위원회규정, 그밖에도 관련자가 아니면 있는지도 모를 많은 규정이 존재한다.</p>
      <p>&nbsp;</p>
      <p>이러한 규정은 각 규정마다 개정의 권한이나 절차가 조금씩 다르다.</p>
      <p>그렇지만 주요 규정 개정의 최종 결정은 대부분 학교운영위원회에서 한다.</p>
      <div className={styles.annotation_wrap}>
        <p>학교운영위원회는 심의기구라는 제한이 있지만, 사실상 최종 결정권자라고 봐도 무방하다.</p>
        <p>이에 대해서는 본문에서 상세하게 설명하겠다.</p>
      </div>
      <p>&nbsp;</p>
      <p>그렇다면 학교운영위원이 주권자이고 학교의 주인일까?</p>
      <p>&nbsp;</p>
      <p>학교운영위원회는 학교장(당연직), 학부모가 선출하는 학부모위원, 교사가 선출하는 교원위원, 그리고 지역위원(학부모위원, 학교장, 교원위원이 모여서 선출한다)으로 구성된다.</p>
      <p>선출직에 의한 대의민주주의 시스템인 것이다.</p>
      <p>따라서 현행 제도 하에서는 학부모와 교사(학교장 포함)가 학교의 주권자이다.</p>
      <p>&nbsp;</p>
      <p>이 시점에서 “아니, 왜 학교의 주인이 학생이 아닌가?”라는 의문이 드는 사람도 있을 것이다.</p>
      <p>아마도 많은 사람들이 “학교는 학생을 교육하는 목적을 가진 기관이고, 학생을 중심에 놓고 운영하며, 학생 없이는 존재할 수도 없는데 어째서 학생이 주인이 될 수 없는가?”라며 목소리를 높일 것이다.</p>
      <p>하지만 이상해도 할 수 없다.</p>
      <p>현행 제도상 학교의 주인은 학생이라고 말할 수 없다.</p>
      <p className={styles.bold}>학생에게는 하고 싶은 것을 스스로 결정할 수 있는 권한이 없다.</p>
      <p>아무리 불편하더라도 누군가 만들어 놓은 규정에 무조건 따라야만 한다.</p>
      <p>그런데도 학생이 학교의 주인이라고 주장한다면, 그건 말장난이고 기만이다.</p>
      <div className={styles.annotation_wrap}>
        <p>양떼목장의 주인이 양이 아니듯, 헬스클럽의 회원이 주인이 아닌 것처럼, 학생이 중심이라고 해서 학생이 학교의 주인이라고 할 수는 없다.</p>
        <p>&nbsp;</p>
        <p>한편, “학부모가 주인이라고?”라며 놀라는 사람도 있을 것이다.</p>
        <p>거의 대부분의 사람들이, 학부모는 학교 교육에 있어서 단순히 참관자(observer)인 것으로만 알고 있다.</p>
        <p>주인이 주인인줄 모르는 것에서부터 온갖 비극은 잉태된다.</p>
        <p>학부모와 관련된 이야기는 본문에서 살펴보자.</p>
      </div>
      <p>&nbsp;</p>
      <p>정리하자면, 학교에 존재하는 대부분의 규정의 개정 권한은 최종적으로 학교운영위원회에 있고, 따라서 규정은 학교운영위원회에서 만든다고 말할 수 있으며, 학교의 주권은 학교운영위원을 선출하는 학부모와 교사(그리고 학교장)에게 있다고 말할 수 있다.</p>
      <p>&nbsp;</p>
      <p>만일 당신이, 학생도 학교의 주인이 되게 하고 싶다면, 우리(당신과 나)는 입법 활동을 통해 「초·중등교육법」을 개정하여 학교운영위원회에 학생위원이 포함되게 하여야 한다.</p>
      <div className={styles.annotation_wrap}>
        <p>학생이 학교운영위원의 역할을 할 수 있느냐에 대한 논란이 있을 수 있다.</p>
        <p>물론 현재의 공교육 수준으로는, 18세 미만의 연령에서 시행하기에 현실적으로 어려움이 있을 수 있다.</p>
        <p>하지만 학교자치가 제대로 작동되고 관련 교육이 효과적으로 시행된다면, 학생위원이 참여하는 것은 전혀 문제가 되지 않는다고 판단한다.</p>
        <p>이미 학생 자치활동으로 체육대회나 학교축제 등의 대규모 행사를 기획하고 시행한 사례가 존재한다.</p>
        <p>학교운영위원회가 그만한 수준 이상의 역량을 필요로 한다고 생각하는가?</p>
        <p>이 책의 본문을 읽어본다면(평균적인 학교운영위원회의 수준을 안다면) 전혀 문제가 없다는 것을 깨닫게 될 것이다.</p>
        <p>만일 표결 없이 의견만 제시하는 수준으로 참여하게 한다면, 당장 15세 이상인 학생의 회의 참석을 의무화해도 문제없다고 생각한다.</p>
        <p>아무리 못해도 현재 거수기 역할만 하는 학부모·교원·지역위원으로 운영되는 것보다 더 못하지는 않을 것이다.</p>
      </div>
    </div >
  )
}

export default Prologue07