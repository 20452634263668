import is31Day from "./is31Day"

const dateProblem = (year, month, day) => {
    if (day === 29 && month === 2 && (year % 4 !== 0)) {
        return true
    }
    if (day === 30 && month === 2) {
        return true
    }
    if (day === 31 && (is31Day(month) === false)) {
        return true
    }
}

export default dateProblem