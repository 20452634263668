import React, { useEffect, useState } from 'react'
import styles from './Adminpage.module.css'
import { jwtDecode } from 'jwt-decode'
import api from '../../util/api'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import Spinner from '../../common/Spinner/Spinner'

const AdminPage = () => {

    const navigate = useNavigate()
    const location = useLocation()

    const [isAuthenticate, setIsAuthenticate] = useState(false)
    const [searchParams] = useSearchParams()
    const tab = searchParams.get('q')

    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const judgeAuthenticate = async () => {
            const judge = await fetchAuthenticate()
            if (judge) {
                setIsAuthenticate(true)
            } else {
                navigate('/')
            }
        }

        judgeAuthenticate()
    }, [localStorage.getItem('token')])

    useEffect(() => {
        if (tab) {
            fetchData()
        }
    }, [tab])

    const fetchAuthenticate = async () => {
        try {
            if (!localStorage.getItem('token')) {
                return false
            }
            const response = await api.post('/is-admin', { userId: jwtDecode(localStorage.getItem('token')).userId })
            if (response.data === true) {
                return true
            } else {
                return false
            }
        } catch (err) {
        }
    }

    const moveTab = (name) => {
        setIsLoading(true)
        if (tab === name) {
            fetchData()
        } else {
            // setIsLoading(true)
            // tab이 바뀌자마자 JSX에서는 조건부 렌더링이 바뀌므로 navigate 전에 isLoading을 바꾸는 게 맞음
            // 새로고침 시에는 isLoading 안 바꾸고 그냥 새로고침만
            navigate(`/admin-page?q=${name}`)
        }
    }

    const fetchData = async () => {
        try {
            const response = await api.get(`/admin-get-data?tab=${tab}`)
            setData(response.data)
            setIsLoading(false)
        } catch (err) {

        }
    }


    if (!isAuthenticate || isLoading || !data) {
        return (
            <div className={styles.wrap}>
                <Spinner />
            </div>
        )
    }


    return (
        <div className={styles.wrap}>
            <div className={styles.menu_wrap} style={{ width: '100%', marginBottom: '50px', display: 'flex' }}>
                <div className={`btn-blue ${tab !== 'user-list' && 'btn-blue-reverse'}`} onClick={() => moveTab('user-list')}>회원 목록</div>
                <div className={`btn-blue ${tab !== 'notice-setting' && 'btn-blue-reverse'}`} onClick={() => moveTab('notice-setting')}>커뮤니티 공지 설정</div>
                <div className={`btn-blue ${tab !== 'view-count' && 'btn-blue-reverse'}`} onClick={() => moveTab('view-count')}>조회수 확인</div>
            </div>
            {tab === 'user-list' && (
                <div>
                    {(data && data.length > 0) && data.map((user, idx) => (
                        <div key={idx} style={{ margin: '10px 0' }}>
                            <p>{user.nickname} / {user.gender === 'male' ? '남성' : '여성'} / {user.birth.year}년 {user.birth.month}월 {user.birth.day}일 생 / {user.email}</p>
                        </div>
                    ))}
                </div>
            )}
            {tab === 'notice-setting' && (
                <div>
                    <h1 style={{ marginTop: '20px' }}>준비 중</h1>
                </div>
            )}
            {tab === 'view-count' && (
                <div className={styles.view_count_wrap}>
                    {(data && data.length > 0) && data?.map((item, idx) => (
                        <div key={idx} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                            <p style={{ width: '130px' }}>{item.title}</p><p style={{ fontWeight: 'bold' }}>{item.view.length}회</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default AdminPage