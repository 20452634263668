import React, { useEffect, useState } from 'react'
import styles from './Community.module.css'
import character from '../../img/char1.png'
import api from '../../util/api'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode'
import img_lock from '../../img/Community/icon_lock.png'
import authSecretPost from '../../util/authSecretPost'
import isAdmin from '../../util/isAdmin'
import convertPostTitleFromStatus from '../../util/convertPostTitleFromStatus'
import Spinner from '../../common/Spinner/Spinner'
import { convertDate } from '../../util/convertDate'
import img_enter from '../../img/Community/icon_enter.png'
import itemPerPage from '../../data/itemPerPage'

const Community = () => {

  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const query_page = searchParams.get('page')

  //data는 추후에 검색 옵션 등의 설정이 필요할 때, 직접 표시될 dataToView를 바꿀 때의 기준이 될 전체 데이터를 담아 둠.
  const [data, setData] = useState([])
  const [dataToView, setDataToView] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)


  const [isLoading, setIsLoading] = useState(true)

  const testArray = [
    '', '', '', '', '', '', '', '', '', '',
    '', '', '', '', '', '', '', '', '', '',
    '', '', '', '', '', '', '', '', '', '',
    '', '', '', '', '', '', '', '', '', '',
    '', '', '', '', '', '', '', '', '', '',
    '', '', '', '', '', '', '', '', '', '',
    '', '', '', '', '', '', '', '', '', '',
    '', '', '', '', '', '', '', '', '', '',
    '', '', '', '', '', '', '', '', '', '',
    '', '', '', '', '', '', '', '', '', '',

    '', '', '', '', '', '', '', '', '', '',
    '', '', '', '', '', '', '', '', '', '',
    '', '', '', '', '', '', '', '', '', '',
  ]

  const table_width = ['10%', '46%', '15%', '13%', '8%', '8%']

  useEffect(() => {
    fetchData()
  }, [location.pathname])
  useEffect(() => {
    if (query_page && isNaN(parseInt(query_page))) {
      navigate(location.pathname)
      return
    }
    if (query_page) {
      setCurrentPage(parseInt(query_page))
    } else {
      setCurrentPage(1)
    }
  }, [query_page])

  useEffect(() => {
    if (dataToView && dataToView.length > 0) {
      setMaxPage(Math.ceil(dataToView.length / itemPerPage))
    } else {
      setMaxPage(1)
    }
  }, [dataToView])

  const fetchData = async () => {
    try {
      let query = '';
      if (location.pathname === '/community') {
        query = 'community'
      } else if (location.pathname === '/dokdo_column') {
        query = 'dokdo'
      }
      const response = await api.get(`/get-community?category=${query}`)

      const postsData = response.data
      let temp = {}
      postsData.forEach((item, idx) => {
        if (item.original) {
          if (temp.hasOwnProperty(item.original)) {
            temp[item.original].push(item)
          } else {
            temp[item.original] = []
            temp[item.original].push(item)
          }
        }
      })

      const doesntHaveOriginal = postsData.filter((item, idx) => !item.original)
      let result = []
      for (const post of doesntHaveOriginal) {
        result.push(post)
        if (temp[post._id]) {
          const reversedUnder = temp[post._id].slice().reverse()
          for (const under of reversedUnder) {
            result.push(under)
          }
        }
      }

      // setData(response.data)
      setDataToView(result)
      setIsLoading(false)
    } catch (err) {

    }
  }

  const handleClickWrite = () => {
    if (!localStorage.getItem('token')) {
      navigate('/login')
    } else if (location.pathname === '/community') {
      navigate('/write')
    } else if (location.pathname === '/dokdo_column') {
      navigate('/write?special=dokdo')
    }
  }
  const handleClickPost = (post) => {
    if (post.status !== 'normal') {
      return
    }
    if (post.secret === true) {
      if (authSecretPost(post) === true) {
        //성공
        handleMoveToPost(post._id)
      } else {
        alert('권한이 없습니다.')
      }
    } else {
      //성공
      handleMoveToPost(post._id)
    }
  }

  const handleMoveToPost = (postId) => {
    navigate(`/read_post?postId=${postId}`)
  }

  const handlePaginationPrevOrNext = (direction) => {
    switch (direction) {
      case 'prev':
        if (currentPage === 1) {
          break;
        } else {
          navigate(`${location.pathname}?page=${currentPage - 1}`)
          break;
        }
      case 'next':
        if (currentPage === maxPage) {
          break;
        } else {
          navigate(`${location.pathname}?page=${currentPage + 1}`)
          break;
        }
      case '1':
        navigate(`${location.pathname}?page=${1}`)
        break;
      case 'max':
        navigate(`${location.pathname}?page=${maxPage}`)
        break;
      default:
        break;
    }
  }




  if (isLoading) {
    return (
      <div className={styles.wrap}>
        <Spinner />
      </div>
    )
  }


  return (
    <div className={styles.wrap}>
      {/* <img src={character} style={{ width: '200px',marginTop:'79px' }} alt='' />
      <h1 style={{ fontSize: '32px', margin: '10px 0' }}>커뮤니티</h1>
      <p style={{ fontSize: '22px' }}>준비 중입니다.</p> */}



      <div className={`center ${styles.headline}`} style={{ width: '100%', marginBottom: '30px', position: 'relative' }}>
        {location.pathname === '/community' && (
          <h1>커뮤니티</h1>
        )}
        {location.pathname === '/dokdo_column' && (
          <h1>독도 칼럼</h1>
        )}
        {(location.pathname === '/community' || (location.pathname === '/dokdo_column' && isAdmin() === true)) && (
          <p className='btn-blue' style={{ position: 'absolute', right: 0 }} onClick={() => handleClickWrite()}>글쓰기</p>
        )}
      </div>
      <div className={styles.table}>
        <div className={styles.table_head}>
          <p style={{ width: table_width[0] }}>&nbsp;</p>
          <p style={{ width: table_width[1] }}>제목</p>
          <p style={{ width: table_width[2] }}>글쓴이</p>
          <p style={{ width: table_width[3] }}>작성일</p>
          <p style={{ width: table_width[4] }}>조회</p>
          <p style={{ width: table_width[5] }}>좋아요</p>
        </div>
        {dataToView && dataToView.filter((item, idx) => ((currentPage - 1) * itemPerPage) < (idx + 1) && (idx + 1) <= (currentPage * itemPerPage)).map((item, idx) => (
          <div key={idx} className={styles.table_data_wrap} onClick={() => handleClickPost(item)} style={{ cursor: item.status !== 'normal' && 'default' }}>
            <div className={styles.table_data_index} style={{ width: table_width[0] }}>
              {item.notice ? (
                <p className='btn-blue btn-blue' style={{ padding: '5px 10px', marginRight: '10px', fontWeight: 'normal' }}>공지{item.notice}</p>
              ) : (
                <p style={{ fontSize: '14px' }}>{item.index}</p>
              )}
            </div>

            <div className={styles.table_body_title} style={{ width: table_width[1] }}>
              {item.secret && (
                <img src={img_lock} style={{ width: '25px', marginRight: '5px' }} />
              )}
              <p className={`ellip ${styles.title_p}`} style={{ color: item.status === 'normal' ? 'black' : 'gray' }}>
                {item.head !== '선택 안 함' && <span className={styles.title_head}>[{item.head}]</span>}
                {item.original && <img src={img_enter} style={{ width: '20px' }} />}
                {convertPostTitleFromStatus(item.title, item.status)}
              </p>
            </div>


            {item.status === 'normal' ? (
              <>
                <p className={styles.table_body} style={{ width: table_width[2], color: item.poster?.nickname ? 'black' : 'gray' }}>{item.poster?.nickname ? item.poster.nickname : '(알 수 없음)'}</p>
                <p className={styles.table_body} style={{ width: table_width[3] }}>{convertDate(item.date)}</p>
                <p className={styles.table_body} style={{ width: table_width[4] }}>{item.view && item.view.length}</p>
                <p className={styles.table_body} style={{ width: table_width[5] }}>{item.like?.length}</p>
              </>
            ) : (
              <>
                <p className={styles.table_body} style={{ width: table_width[2], color: item.poster?.nickname ? 'black' : 'gray' }}>&nbsp;</p>
                <p className={styles.table_body} style={{ width: table_width[3] }}>&nbsp;</p>
                <p className={styles.table_body} style={{ width: table_width[4] }}>&nbsp;</p>
                <p className={styles.table_body} style={{ width: table_width[5] }}>&nbsp;</p>
              </>
            )}

            {/* 모바일 전용 */}
            {item.status === 'normal' ? (
              <div className={styles.table_body_etc_mobile}>
                <p className={styles.table_body_mobile} style={{ width: 'fit-content', color: item.poster?.nickname ? 'black' : 'gray' }}>{item.poster?.nickname ? item.poster.nickname : '(알 수 없음)'}</p>
                <p className={styles.table_body_mobile} style={{ width: 'fit-content' }}>{convertDate(item.date)}</p>
                <p className={styles.table_body_mobile} style={{ width: 'fit-content' }}>조회 {item.view && item.view.length}</p>
                <p className={styles.table_body_mobile} style={{ width: 'fit-content' }}>좋아요 {item.like?.length}</p>
              </div>
            ) : (
              <div className={styles.table_body_etc_mobile}>
                <p className={styles.table_body_mobile} style={{ width: 'fit-content', color: item.poster?.nickname ? 'black' : 'gray' }}>&nbsp;</p>
                <p className={styles.table_body_mobile} style={{ width: 'fit-content' }}>&nbsp;</p>
                <p className={styles.table_body_mobile} style={{ width: 'fit-content' }}>&nbsp;</p>
                <p className={styles.table_body_mobile} style={{ width: 'fit-content' }}>&nbsp;</p>
              </div>
            )}

          </div>
        ))}
        {dataToView && dataToView.length === 0 && (
          <p className={styles.not_yet_post}>아직 작성된 글이 없습니다.</p>
        )}
      </div>





      <div className={styles.pagination_wrap}>
        <p onClick={() => handlePaginationPrevOrNext('1')}>처음</p>
        <p onClick={() => handlePaginationPrevOrNext('prev')}>이전</p>
        <div className={styles.pagination_only_pc}>
          {Array.from({ length: maxPage - (Math.floor((currentPage - 1) / 10) * 10 + 1) <= 10 ? maxPage - (Math.floor((currentPage - 1) / 10) * 10 + 1) + 1 : 10 }, (_, i) => {
            const additional = Math.floor((currentPage - 1) / 10) * 10 + 1
            return (
              <p key={i} className={currentPage === additional + i ? styles.current_page : ''} onClick={() => navigate(`${location.pathname}?page=${additional + i}`)}>{additional + i}</p>
            )
          })}
        </div>
        <div className={styles.pagination_only_mobile}>
          {Array.from({ length: maxPage - (Math.floor((currentPage - 1) / 3) * 3 + 1) <= 3 ? maxPage - (Math.floor((currentPage - 1) / 3) * 3 + 1) + 1 : 3 }, (_, i) => {
            const additional = Math.floor((currentPage - 1) / 3) * 3 + 1
            return (
              <p key={i} className={currentPage === additional + i ? styles.current_page : ''} onClick={() => navigate(`${location.pathname}?page=${additional + i}`)}>{additional + i}</p>
            )
          })}
        </div>
        <p onClick={() => handlePaginationPrevOrNext('next')}>다음</p>
        <p onClick={() => handlePaginationPrevOrNext('max')}>끝</p>
      </div>

    </div>
  )
}

export default Community