//오늘이면 날짜 대신 시간
export const convertDate = (target) => {
    const date = new Date(target);
    const now = new Date();
    if (date.toDateString() === now.toDateString()) {
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        return `${hours}:${minutes}`;
    } else {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}.${month}.${day}`;
    }
}

//오늘이면 날짜 대신 시간을 반환하는 코드를 삭제한 함수. 오늘이어도 날짜로 반환됨
//2002.07.09 형태로 반환
export const convertDate2 = (target) => {
    const date = new Date(target);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}.${month}.${day}`;
}

//날짜 시간 전부 다 표시
export const convertDate3 = (target) => {
    const date = new Date(target);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${year}.${month}.${day} ${hours}:${minutes}`;
}

//년월일로 구분
export const convertDate4 = (target) => {
    const date = new Date(target);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hour = date.getHours();
    const minute = date.getMinutes();
    return `${year}년 ${month}월 ${day}일 ${hour}시 ${minute}분`;
}

// 21:15 등으로 반환
export const convertDate5 = (target) => {
    const date = new Date(target);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
}