import React from 'react'
import ss from './MainText.module.css'

const Year2015_19 = () => {
  return (
    <div className={ss.wrap}>
      <h1 className={ss.title}>19. 학부모가 학교에 가는 이유.</h1>
      <p>다섯 번째, 교원위원의 침묵.</p>
      <p>교원위원들은 회의 시간 내내 침묵을 지켰다.</p>
      <p>물론 아예 아무 말도 안한 것은 아니었다.</p>
      <p>우선 교원위원 본인이 안건 담당자일 때는 안건에 대한 설명을 했다.</p>
      <p>그리고 본인 담당 안건이거나 본인 업무와 관련이 있는 안건에 대해, 학부모위원이나 지역위원이 질문을 하면 답변을 했다.</p>
      <p>그러나 그 외의 경우에는 발언을 하지 않았다.</p>
      <p>&nbsp;</p>
      <p>교사의 대표로서, 안건의 심의를 위해 회의에 참석하였다면, 자신의 의견을 표명해야 한다.</p>
      <p>안건을 설명하고 질의에 답변하는 것은 단지 안건담당자로서의 행동일 뿐이며, 운영위원으로서의 행동이 아니다.</p>
      <p>&nbsp;</p>
      <p>교원위원들은 왜 발언을 하지 않을까?</p>
      <p>다른 교사가 담당한 안건에 대해 이러쿵저러쿵 의견을 표하는 것이, 동료로서 부담스러운 것일까?</p>
      <p>아니면 학교장이 결재한 사안에 대해 감히 의견을 제시하는 것이 부담스러운 것일까?</p>
      <p>어떤 이유에서건, 자신의 의견을 피력할 의지가 없다면, 교원위원은 있을 필요가 없다.</p>
      <p>의사·의결 정족수로서 머릿수를 채우는 것에만 의미를 둔다면, 단순한 거수기로서 학교장의 독재에 정당성을 부여하는 학부모위원이나 지역위원보다 더 큰 문제가 있다고 할 수 있다.</p>
      <p>왜냐하면 그것은 민주적 절차의 파괴행위에 해당되기 때문이다.</p>
      <div className={ss.annotation_wrap}>
        <p>뒤에서도 여러 번 말하겠지만, 중요한 부분이므로 중복이 되더라도 여기서 ‘민주적 절차’에 대한 이야기를 한번 해보자.</p>
        <p className={ss.fbody}>“민주주의는 항상 독재로 변질될 수 있다는 위험을 내포하고 있다.”</p>
        <p>그러한 위험을 잘 드러내는 것이 바로 투표다.</p>
        <p>투표는 종종 진리로 포장된다.</p>
        <p>승자가 독식하는 구조이며, 모든 명분을 집어 삼킨다.</p>
        <p>쉽게 말하면, 투표에서 이기면 정의가 된다는 뜻이다.</p>
        <p>그런데 정말 정의일까?</p>
        <p>그럴 수도 있고 아닐 수도 있다.</p>
        <p>&nbsp;</p>
        <p>투표는 결과다.</p>
        <p>그런데 그 보다 더 중요한 ‘과정’이란 것이 있다.</p>
        <p>어떤 결정을 위해, 우리는 정보를 모아야하고, 정보를 분석해야 하며, 문제를 해결하기위한 방법에 대해 토론(숙의)해야 한다.</p>
        <p>이 과정에 참여하는 사람들에게는 정보와 문제에 대한 학습이 필요하고, 그들 사이에서의 충분한 커뮤니케이션(의견 교환)이 있어야 한다.</p>
        <p>이런 과정이 얼마나 꼼꼼하냐에 따라, 그리고 얼마나 집중하느냐에 따라 결과는 달라진다.</p>
        <p>우리는 모든 구성원들을 위해 분석된 정보를 공개해야 하고, 모든 구성원들이 직·간접으로 토론에 참여(문제에 대한 학습과 커뮤니케이션)할 수 있는 기회를 보장해야 한다.</p>
        <p className={ss.bold}>얼마나 많은 구성원들이 결정 과정에 참여하느냐에 따라 민주주의의 질은 달라진다.</p>
        <p>이러한 (과정에의) 참여는 (결과인) 투표율과는 다른 의미를 가진다.</p>
        <p>바로 이러한 과정이 민주적 절차에 해당된다.</p>
        <p>&nbsp;</p>
        <p>이슈에 따라 다르겠지만, 투표 결과보다 민주적 절차가 더 중요하다고 할 수 있다.</p>
        <p>이런 과정을 통해서 구성원들은 학습을 하고 성장한다.</p>
        <p>문제 해결 능력이 좋아지고 정책이 성숙해지며, 갈등을 완화하는 방법을 찾게 된다.</p>
        <p>정보는 공개되어야 하고, 정보의 왜곡은 감시되어야 하며, 토론은 방해받지 않아야 하고 정보와 마찬가지로 공개되어야 하며, 참여할 수 있는 기회는 충분히 제공되어야 한다.</p>
        <p className={ss.bold}>과정이 부실한(민주적 절차라 할 수 없는) 절차를 민주적 절차라고 말하는 자는, 무지하거나 독재자이거나 둘 중 하나이다.</p>
        <p>&nbsp;</p>
        <p>그리고 이와 같은 과정을 생략한 결과는, 독재로 이어진다.</p>
        <p>독재의 나쁜 점은, 주권을 빼앗는 것에 있다.</p>
        <p className={ss.bold}>전체 구성원의 권리는 제한되고, 소수의 구성원이 권리의 많은 부분을 차지하게 된다.</p>
        <p>사회의 권리에는 총량이 존재한다.</p>
        <p>누군가 더 많은 권리를 가지려면, 다른 누군가의 권리를 빼앗아야 한다.</p>
        <p className={ss.bold}>독재의 본질은 총량이 정해진 권리를, 소수가 다수로부터 빼앗아 차지하는 것이다.</p>
        <p>&nbsp;</p>
        <p>학교의 구성원들이, 형식적인 주권행사를 할 뿐 실질적인 주권은 빼앗긴 상태, 그리고 그러한 사실을 인지 못하는 것이 바로 학교운영위원회의 모습이다.</p>
        <p>이와 관련한 이야기는 이미 앞에서 했고, 지금도 하고 있으며, 앞으로도 계속 나올 것이다.</p>
        <p>(학교의 주권이 누구에게 있느냐는 이야기는 좀 길어서 나중에 따로 살펴볼 것이다.)</p>
        <p>&nbsp;</p>
        <p>참고로, 민주주의에서의 올바른 투표는, 올바른 과정(민주적 절차)을 거친 투표여야 한다.</p>
        <p>(학교운영위원회에서의 올바른 심의도 올바른 과정을 거쳐야 한다.)</p>
        <p>올바른 과정을 거친 투표 결과라면 정의라고 불러도 좋을 것이다.</p>
        <p>우리가 아무리 노력해도, 반드시 진리만 선택할 것이라고는 장담할 수는 없다.</p>
        <p>하지만, 진리는 아닐지라도, 노력한 결과를 정의라 할 수 있는 이유는, <span className={ss.bold}>이러한 노력을 거친 결과야말로 ‘구성원들이 스스로 책임지려는 의지’를 단단하게 하고, ‘개선하고자 하는 노력’을 지속할 수 있는 힘을 부여하기 때문이다.</span></p>
        <p>그러므로 올바른 과정을 거친 선택은, 훗날 개선이 필요할지언정, 현재로서는 최선의 선택이라고 할 수 있다.</p>
      </div>
      <p>&nbsp;</p>
      <p>교원위원이 의견을 말하지 않는 것은, (학교 구성원으로서의) 교사의 참여기회를 박탈한 것과 같으며, 토론이라는 과정을 방해하는 행위와 같다.</p>
      <p>학교운영위원회가 학교민주주의의 핵심기구로서 자기 역할을 다 할 수 있으려면, 올바른 심의를 해야 한다.</p>
      <p>그것은 올바른 과정(민주적 절차)의 준수를 의미한다.</p>
      <p>따라서 교원위원의 침묵은, 운영위원회의 어용기구화를 넘어서 시스템(민주적 절차로 운영되어야 하는 시스템) 자체를 부정(파괴)하는 중대한 문제라 할 수 있다.</p>
      <div className={ss.annotation_wrap}>
        <p>학교운영위원회는 구성원들에게 정보를 감추고 제공하지 않는다.</p>
        <p>구성원들이 참여할 기회를 제공하지 않는다.</p>
        <p>구성원들의 학습도 지원하지 않는다.</p>
        <p>그리고 회의에서는 토론하지 않는다.</p>
        <p>위에서 말했듯이, <span className={ss.bold}>올바른 과정을 생략한 결과는, 반드시 독재로 이어진다.</span></p>
      </div>
      <p>&nbsp;</p>
      <p>운영위원회는 공동체의 민주적 의사 결정을 위한 기구이며, 학교장과는 완전히 독립된 기구이다.</p>
      <p>그러나 이를 믿는 교원위원은 없는 것 같다.</p>
      <p>&nbsp;</p>
      <p>여섯 번째, 학부모위원의 이기심.</p>
      <p>첫 회의 후 회식 때, 교사들의 말을 경청하며 자녀들의 이야기를 하던 학부모위원들의 태도를 보면서, 독도 씨는 그들이 자녀들을 학교에 볼모로 맡겨 놓은 을의 입장이라 판단했다.</p>
      <p>이와 관련하여, 우리는 우선 ‘학부모가 왜 학교에 가는지’를 살펴보고 넘어가도록 하자.(좀 길어지겠지만 설명이 필요한 부분이다.)</p>
      <p>&nbsp;</p>
      <p>학부모는 왜 학교에 가는 것일까?</p>
      <p>&nbsp;</p>
      <p>학부모가 학교에 가는 경우를 살펴보면 다음과 같다.</p>
      <p>첫째, 보통의 학부모라면, 학부모총회에 가거나, 공개수업을 참관하기 위해 가거나, 자녀상담을 위해 담임교사를 만나러 가는 것이 학교에 가는 주요 경우이다.</p>
      <p>그밖에 사건·사고가 발생하여 소환되거나, 개인적인 용무로 방문하거나, 학부모회가 주관하는 교육활동에 참여하거나, 체육대회·축제·입학식·졸업식 등의 행사에 참여하기 위해 가는 경우가 있으며, 흔하지 않은 경우로는 토론회 참여 등이 있다.</p>
      <p>둘째, 선출(학부모회 임원, 학급 대표, 학교운영위원)되거나, 위촉(각종 위원회 위원)되거나, 봉사활동(도서관지원, 급식지원, 등하교지도 등)에 지원한 학부모라면, 해당 업무를 하기 위해 학교에 가는 일이 추가된다.</p>
      <p>&nbsp;</p>
      <p>이 중에서, ‘학부모와 학교가 대면하는 행사’ 중 가장 규모가 큰 학부모총회에 대해서 살펴보자.</p>
      <p>&nbsp;</p>
      <p>1년에 한 번 있는, 학년 초의 학부모총회 때에는, 많은 학부모가 학교를 찾는다.</p>
      <p>학부모총회에 참석하면, 학교에서 진행하는 행사와 학부모회에서 진행하는 행사 등 두 가지 종류의 행사에 참여하게 된다.</p>
      <p>학교에서 진행하는 행사에는 학교생활·교육과정·연간학사일정 등에 대한 안내와, 교장·교감을 비롯하여 각 학급 담임교사 및 교과담당 교사의 장내 인사 등이 있다.</p>
      <p>그리고 학부모회가 진행하는 행사에는 임원선출·임원소개·전년도결산·감사보고 등이 있다.</p>
      <p>학부모총회에서의 전체 학부모 대상 행사가 끝나면, 학부모들은 각 학급별 교실로 이동하여 자녀의 담임교사를 만난다.(담임교사를 먼저 만나고 나서 전체 행사를 하는 경우도 있다.)</p>
      <p>담임교사는 학급의 학부모들에게 앞으로 1년 동안 자녀들을 어떻게 지도할 것인지 안내하고, 개별상담 일정이나 방식에 대해 안내하며, 학부모들의 협조가 필요한 여러 사항에 대해 말한다.</p>
      <p>그리고 학부모들이 궁금한 것을 질문하면 그에 답변을 하기도 한다.</p>
      <p>&nbsp;</p>
      <p>학부모총회를 찾는 학부모의 수는 학교별, 학년별로 다르다.</p>
      <p>초등학교 1학년의 경우 보통 절반 이상의 학부모가 참여한다.(자녀가 첫 아이일 경우는 대부분 참석한다.) 그러나 학년이 올라갈수록 점점 줄어들어, 중·고등학교의 경우 대략 10~20% 수준의 참석률을 보인다.</p>
      <p>다만 교육열이 높은(입시에 대한 기대가 높은) 학교의 경우 30%를 쉽게 넘어가고, 일반고가 아닌 특목고나 자사고 같은 경우 90% 이상의 참석률이 나오기도 한다.</p>
      <div className={ss.annotation_wrap}>
        <p>이 수치는 독도 씨가 10여 년간 직접 경험해 본 수치이기도 하고, 교육시민단체 활동을 통해 만나본 여러 지역의 많은 학교 학부모들과의 대화를 통해 산출해 낸 수치이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>학부모가 학부모총회에 가는 이유는 위의 내용에서 쉽게 찾을 수 있다.</p>
      <p>학부모총회에서 학부모회가 진행하는 행사를 제외하면, 모든 행사의 내용은 ‘안내’에 해당된다.</p>
      <p>즉, 안내를 들으려고(받으려고) 가는 것이다.</p>
      <p>필요에 따라서는 담임교사에게 질문을 하고 답변을 들을 수도 있는데, 이 또한 안내에 해당된다.</p>
      <div className={ss.annotation_wrap}>
        <p>안내가 아닌 것이 하나 있는데, ‘학부모들의 협조가 필요한 여러 사항’이 그것이다.</p>
        <p>그것에 대해서는 아래에서 설명하겠다.</p>
      </div>
      <p>&nbsp;</p>
      <p>이 모든 안내를 한 단어로 표현하자면, ‘정보’이다.</p>
      <p>학부모는 자녀의 학교생활과 관련한 정보를 얻고자 학부모총회에 간다.</p>
      <p>자녀의 학교생활에 있어서 부모가 알아야할 것들(기본적인 생활인권규정이나 학업성적관리규정, 행정절차 등)과, 자녀의 선생님, 교육과정, 교육수준, 학교시설, 학사일정 등 학부모에게는 궁금한 것이 무척 많다.</p>
      <p>자녀가 어릴수록, 학교에 잘 적응할 수 있을지, 교우관계는 원만하게 잘 형성이 될지, 밝고 건강하게 학교생활을 잘 해나갈지 걱정이 많으므로 학교에 대해 궁금한 것이 많을 것이다.</p>
      <p>또한 진학에 대한 열망이 클수록, 자녀가 학업에 열중할 수 있는 환경인지, 교사들이 잘 가르칠 수 있을지, 성적관리는 어떻게 해야 하는지, 진학지도는 어떤 방향으로 하는지 등이 궁금할 것이다.</p>
      <p>그러므로 ‘정보’의 요구량이 많은, 초등학교 저학년 학부모와 교육열이 높은 학교 학부모의 학부모총회 참석률이 높은 것은 당연한 현상이다.</p>
      <p>&nbsp;</p>
      <p>그리고 한 번 참석함으로써 모든 궁금증을 다 해결할 수 없기 때문에, 같은 고민을 하는 다른 학부모들을 만나서, 각종 정보를 교환(공유)하는 네트워크를 형성하려고 한다.</p>
      <p>그렇기 때문에 학부모는, 많은 학부모가 모이는 학부모총회를 더욱 중요하게 생각한다.</p>
      <p>&nbsp;</p>
      <p>자녀가 많이 성장하여 학교 적응에 대한 걱정이 별로 없고, 수년간 정보를 많이 획득하여 정보 요구량이 적으며, 친한 학부모가 여럿 생겨서 이미 정보 공유가 용이할 경우(새로운 네트워크 형성이 꼭 필요하지는 않은 경우), 학부모의 학부모총회 참석 욕구는 크게 줄어든다.</p>
      <p>그러다보니 학년이 올라갈수록 참석률이 저조해진다.</p>
      <p>즉, 학교와 교육에 대한 관심이 줄어서 참석률이 저조해지는 것 아니라, 정보 요구량이 줄어들어서 그런 것이다.</p>
      <div className={ss.annotation_wrap}>
        <p>참석하고 싶은데 참석하지 못하는 특별한 사정이 있는 경우를 제외하면, 학부모총회에 한 번도 참석하지 않는 학부모는, 학교생활은 자녀가 알아서 하는 것이므로 학부모가 쫒아 다닐 필요가 없다고 생각하거나, 애초에 자녀에게 관심이 없는 학부모인데, 그 비율은 매우 낮다.</p>
      </div>
      <p>&nbsp;</p>
      <p>여기서 한 가지 살펴볼 것이 있다.</p>
      <p>위에서 설명한 바와 같이, 학년이 올라갈수록 학부모총회 참석 의지가 점차 줄어드는 것이 일반적이기는 하지만, 중·고등학교의 참석률이 10~20%로 크게 낮아지는 이유는, <span className={ss.quo_mini}>‘가보고 싶기는 한데, 그것 때문에…….’</span>라는, 가고자 하는 의지를 크게 저해하는 두 가지 요소가 있기 때문이다.</p>
      <p>&nbsp;</p>
      <p>하나는 학부모총회 시간(일정)이다.</p>
      <p>직장을 다니는 학부모의 경우, 평일 낮 시간에 학교에 가는 것은 휴가를 내야하는 일이기 때문에 용이하지 않다.(이를 고려하여 휴일에 하거나 저녁에 하는 학교도 있다.)</p>
      <p>초등학교 저학년 때는 휴가를 내서라도 꼭 참석하려고 하지만, 고학년으로 갈수록 휴가에 대한 부담보다 참석 필요성보다 낮아지므로 참석률이 저조해진다.</p>
      <p>&nbsp;</p>
      <p>또 다른 하나는, 바로 ‘학부모들의 협조가 필요한 여러 사항’ 때문이다.</p>
      <p>담임교사는 학부모들에게, 반대표(학급 대표, 즉 학급 학부모회 임원)를 선출해달라고 요청하고, 각종 위원회의 위원이 되어달라고 요청하며, 각종 봉사활동에 참여해달라고 협조를 요청한다.</p>
      <p>이러한 일은, 시간을 빼앗기는 일이고, 남 앞에 나서야하는 일이고, 어려운 업무일 수도 있기 때문에 하고 싶지 않은 일인 경우가 많다.</p>
      <p>간혹 서로 하려고 경쟁하는 경우도 있지만, 대부분의 경우는 맡기 싫어서 서로 눈치를 보고 딴청을 피운다.</p>
      <p>그러다보니, 참석하는 사람이 적을수록 담임교사의 요청을 피하기 어려워지고, 그래서 참석률이 더욱 저조해지는 악순환이 발생한다.</p>
      <p>그래서 학부모총회에 가려면, 이러한 요청을 (피할 수 없을 경우에는) 받아들이겠다는 각오가 되어 있어야 한다.</p>
      <p>&nbsp;</p>
      <p>그러므로 만약 학부모총회의 참석률을 높이려 한다면, 저녁시간이나 휴일로 일정을 조정하거나, 반대표 선출 등을 다른 날에 진행하는 것이 좋다.</p>
      <div className={ss.annotation_wrap}>
        <p>학부모총회 참석률이 높으면 학부모회 활동에도 긍정적인 영향을 끼칠 수 있다.</p>
        <p>이와 관련해서는 나중에 학부모회 이야기에서 다루겠다.</p>
      </div>
      <p>&nbsp;</p>
      <p>한편, 담임교사의 요청을 피하지 못해서, 반대표나 봉사활동 등을 맡기 싫은데 결국은 맡게 되는 학부모의 마음은 어떠할까?</p>
      <p>겉으로는 대개, <span className={ss.quo_mini}>‘누군가는 해야 하니까, 내가 희생하겠다.’</span>고 말하거나, <span className={ss.quo_mini}>‘선생님이 저렇게까지 부탁하는데 인정상 해야겠다.’</span>고 말한다.</p>
      <p>그런데 속마음도 겉과 같은 사람이 있는가 하면, 속으로는 다른 마음이 있는 사람도 많다.</p>
      <p>그 마음에는 대략 세 가지 정도의 심리가 복합적으로 작용한다.</p>
      <p>하나는 ‘기브 앤 테이크’다. 학교의 도움을 받았으니(정보를 얻었으니), 학교에서 뭔가를 요청한다면 그만큼 해줘야 한다는 심리다.</p>
      <p>다른 하나도 ‘기브 앤 테이크’이다. 담임선생님이 간곡히 부탁하는데 거절하면, <span className={ss.quo_mini}>‘나를 이기적이라 생각하고 내 아이도 밉게 보지는 않을까?’</span>하는 심리다.(좋지 않은 쪽으로 주고받는 것이다.)</p>
      <p>그리고 마지막 하나도 역시 ‘기브 앤 테이크’다.</p>
      <p><span className={ss.quo_mini}>‘내가 학교에서 시키는 일을 잘하면, 우리 애가 담임한테 잘 보이겠지. 그러면 뭐라도 이득이 있겠지.’</span>라는 심리다.(싫어도 하는 것에 따른 보상으로 생각한다.)</p>
      <p>&nbsp;</p>
      <p>많은 학부모들이 이런 심리를 가지고 있어서, 이를 인지상정이라고 생각한다.</p>
      <p>그렇지만 이런 마음은 버려야한다.</p>
      <p>학교에서 정보를 얻는 것은 지극히 당연한 권리이다.</p>
      <p>따로 대가를 지불할 필요가 없다.</p>
      <p>그리고 담임교사의 요청을 들어주지 않아도 자녀에게 피해가 가지는 않으며, 요청을 들어준다고 해서 이득이 되지도 않는다.</p>
      <p>대부분의 교사들은 올바르게 행동한다.</p>
      <p>간혹 바르지 못한 교사가 있다면, 이는 내 자녀의 미래와 우리 교육의 미래를 위해서 적극적으로 바로잡아야 할 일이다.</p>
      <p>따라서 ‘기브 앤 테이크’라 생각하고 행동하는 것은 바람직하지 않다.</p>
      <p>&nbsp;</p>
      <p>참고로, 저조한 참석률을 이겨내고 학교에 온 학부모가, 싫어도 할 수 없이 학급 학부모회 임원이나 각종 위원회의 위원으로 위촉될 경우, 그래도 자기 역할을 다 하고자 하는 사람이 있기야 하겠지만, 대부분의 경우 형식적으로 업무를 수행한다.</p>
      <p>학교는 학교대로 규정과 지침에 따라 어쩔 수 없이 각종 위원회를 구성하지만, <span className={ss.quo_mini}>‘위원회를 만들고, 소집하여 회의하고, 회의록 작성하여 보고하는 것들을 모두 제대로 하려면 시간을 많이 빼앗기고 신경 써야 할 것이 많다. 게다가 학부모들은 잘 몰라서 하나하나 설명해줘야 한다. 그냥 담당 교사가 알아서 진행하는 것이 더 빠르고 효율적이다.’</span>라고 생각하므로, 위원회를 형식적으로 운영한다.</p>
      <p>학부모와 교사 모두 비자발적이므로, 해당 위원회의 업무 질은 하락할 수밖에 없다.</p>
      <p>&nbsp;</p>
      <p>이제, 보통의 학부모가 학교에 가는 이유를 정리해보자.</p>
      <p>가장 큰 행사인 학부모총회에 참석하는 이유는 정보를 얻기 위함이다.</p>
      <p>위에서 설명하지는 않았지만, 공개수업 참관이나 자녀상담을 위해 학교에 가는 것도 따지고 보면 정보를 얻기 위해서 가는 것이다.</p>
      <p>이러한 주요 행사들의 공통점은 ‘학부모를 대상으로 하며, 학부모와 학교(교사)가 만나는 것’이다.</p>
      <p>반면에 체육대회, 입학식, 졸업식, 축제 등에 참석하는 것은, 학생이 주인공인 행사에 학부모는 관객으로서 참석하는 것이다.</p>
      <p>이 경우들은 학부모와 학교가 만나는 것이 아니므로, 학부모가 학교에 가는 이유를 찾는 경우에서 제외한다.</p>
      <p>결국 특별한 일로 소환되거나, 개인적인 용무를 위해 가는 것이 아니라면, 보통의 학부모가 (학교와 만나기 위해) 학교에 가는 이유는 ‘정보’를 얻기 위함이라고 결론내릴 수 있다.</p>
      <p>&nbsp;</p>
      <p>그리고 다음으로는, 이번 이야기의 핵심이라고 할 수 있는, 선출되거나, 위촉되거나, 봉사활동에 지원한 학부모가 학교에 가는 이유를 알아보자.</p>
      <p>&nbsp;</p>
      <p>위에서, 담임교사의 요청에 대해, ‘간혹 서로 하려고 경쟁하는 경우도 있지만, 대부분의 경우는 맡기 싫어서 서로 눈치를 보고 딴청을 피운다.’고 표현했는데, 바로 이 문장이 학부모 활동을 받아들이는 두 유형을 구분하는 문장이다.</p>
      <p>즉, 하고 싶은 사람과 하기 싫은 사람으로 구분하면 된다.</p>
      <div className={ss.annotation_wrap}>
        <p><span className={ss.fbody}>‘딱히 하고 싶지도 않지만, 그렇다고 하기 싫지도 않다.’</span>는 사람도 있다.</p>
        <p>좋고 싫은 정도의 차이는 다양하다.</p>
        <p>하지만 선택을 해야 하는 경우라면, 누구라도 둘 중 하나에는 속한다.</p>
        <p>만일 정확히 중립인 사람이 있다면, 이 사람은 ‘하고 싶은 사람’이라고 간주하자.</p>
      </div>
      <p>&nbsp;</p>
      <p>하기 싫은 이유는 위에서 설명한대로 시간을 빼앗기는 일이고, 남 앞에 나서야하는 일이고, 어려운 업무일 수도 있기 때문이다.</p>
      <p>이 사람들을 다시 둘로 나누면, 어쨌든 맡았으니까 자신의 책임을 다 하는 사람과, 시키니까 하기는 하는데 대충대충 형식적으로 하는 사람으로 나눌 수 있다.</p>
      <p>그러나 책임감이 있든 없든, 열심히 하든 아니든, 두 부류 모두 하기 싫은데 맡은 것이므로 ‘억지로’ 하는 것이다.</p>
      <p>그러니까 하기 싫은 사람이 학교에 가는 이유는, ‘맡은 업무 때문에 억지로 가는 것’이라고 할 수 있다.</p>
      <p>&nbsp;</p>
      <p>그렇다면 하고 싶어서 맡은 사람은 어떨까?</p>
      <p>학부모회 임원이나 학교운영위원, 반대표(학급 학부모회의 대표이며, 학교 학부모회의 대의원이다), 각 위원회의 위원, 봉사활동 등을 하고 싶어 하는 사람을 목적에 따라 유형별로 나누자면 크게 두 가지의 유형으로 나눌 수 있다.</p>
      <p>하나는 공익을 위해서 업무를 맡는 유형이다.</p>
      <div className={ss.annotation_wrap}>
        <p>공익이란 위에서 설명한 ‘민주적 절차’에 의해 결정되는 공동체의 이익을 의미한다.</p>
      </div>
      <p>이들은 자신들의 활동이, 자녀가 다니는 학교를 위한 활동이라고 여긴다.</p>
      <p>자신의 자녀를 위한 활동이기도 하지만, 자신의 자녀만을 위한 활동이라고는 생각하지 않는다.</p>
      <p>학교의 교육환경이 좋아지면 모든 학생들에게 이익이 되고, 결국 내 자녀에게 이익이 될 것이라고 판단한다.</p>
      <p>공교육(학교)에서 이상적이라고 홍보하는 학부모 유형이고, 바람직한 교육공동체의 구성원이라 할 수 있다.</p>
      <div className={ss.annotation_wrap}>
        <p>하지만 아이러니하게도 현실의 학교는 이런 학부모를 좋아하지 않는다.</p>
      </div>
      <p>&nbsp;</p>
      <p>다른 하나는 사익을 위해서 업무를 맡는 유형이다.</p>
      <p>이 유형은 자신의 이익을 위해 직위를 이용하는 유형인데, 세부적으로 보면 생각보다 다양한 유형이 있다.</p>
      <p>학교 내 직위를 자신의 명예로 생각하고 탐하는 사람도 있고, 겉으로는 공익을 위한 활동이라고 하지만 이를 정치적 발판으로 이용하려는 사람도 있으며, 상대적으로 드물지만 자신의 직업이나 종교 관련하여 영업이나 포교(홍보) 활동에 활용하기 위해서 학부모 활동을 하려는 사람도 있다.</p>
      <p>여러 유형 중 압도적으로 가장 많이 보이는 세부 유형은 자신의 자녀에게 이득이 될 것이라 생각하고 학부모 활동을 하는 사람들이다.</p>
      <p>하기 싫은데 업무를 맡는 사람들의 심리에도 나타나지만, 많은 학부모들은, <span className={ss.quo_mini}>‘내가 학교에 자주 가고 활동을 열심히 하면 교사들의 눈에 쉽게 띄고, 담임교사나 교장에게 좋은 인상을 심어주면, 내 아이에게 좋은 영향을 줄 것이다.’</span>라는 믿음을 가지고 있다.</p>
      <p>&nbsp;</p>
      <p>학부모들은, 오래전부터 그랬고 독도 씨가 청소년 시절에도 그랬으며 불과 몇 년 전까지만 해도 촌지를 건네주면서 자녀를 잘 봐달라고 했다.</p>
      <p>촌지가 횡행하던 그 시절에는, 촌지를 못 주거나 액수가 부족하면 봉사 활동으로 때워야한다는 생각을 하는 학부모가 많았다.</p>
      <p>그러다가 최근 청탁금지법이 시행되면서 불법찬조금 및 촌지가 서서히 사라지고 있는데, 이제는 더욱 열심히 행동으로 때워야한다는 생각을 하는 듯하다.</p>
      <div className={ss.annotation_wrap}>
        <p>촌지는 아직도 완전히 근절되지 않았다.</p>
        <p>뉴스에서도 간간히 등장하는 이슈인데, 독도 씨가 직접 겪은 사례가 있어서 나중에 다루어질 예정이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>돈이든 활동이든 학부모가 노력(?)하는 모습을 보여야 자녀에게 이득이 된다는 다수의 믿음은 꽤 공고하다.</p>
      <p>위에서도 말했지만, 이는 잘못된 믿음이다.</p>
      <p>다만, 올바르지 않은 교사들이 가끔 있어서, 이러한 잘못된 믿음을 계속해서 심어주고 있기 때문에, 그 믿음이 쉽사리 깨지지 않는다는 문제가 있다.</p>
      <div className={ss.annotation_wrap}>
        <p>게다가 이런 민감한 ‘정보’는 학부모들의 네트워크를 통해서 생각보다 빠르고 넓게, 더욱 과장되어 확산되기 마련이다.</p>
        <p>마치 사교육처럼 ‘남들 다 하는데 나만 하지 않으면 손해’라는 불안감을 안겨준다.</p>
      </div>
      <p>&nbsp;</p>
      <p>교사가 학생을 어여삐 보고 관심을 주면 학생의 자존감은 높아지고, 이는 자신감으로 이어진다. 겉으로 우리는, 교사들이 가능한 한 모든 학생들에게 이러한 관심을 주기 위해 노력한다고 믿는다.</p>
      <p>교과 성적(수행 평가 등)이나 교내 수상이 주관적 평가에 달려 있을 때, 겉으로 우리는, 교사들이 공정한 평가를 위한 평가 기준을 만들고 공개하여, 참여하는 학생들이 무리 없이 납득할 수 있도록 노력한다고 믿는다.</p>
      <p>학생에게 어떤 사건·사고(학교 폭력, 규정 위반 등)가 발생하여 문제가 되었을 때, 겉으로 우리는, 교사들이 규정에 따라 공정하게 처리하기 위해 노력한다고 믿는다.</p>
      <p>&nbsp;</p>
      <p>하지만 많은 수의 학부모들이, 속으로는 그렇게 믿지 않는다.</p>
      <p>&nbsp;</p>
      <p>대부분의 교사들이 올바르게 행동하더라도, 일부 교사들이 잘못하면 신뢰는 깨지게 된다.</p>
      <p>다만, 학교 운영 시스템이 올바르게 작동된다면, 일부 교사의 잘못은 바로 잡히게 될 것이고, 신뢰는 회복될 것이다.</p>
      <p>그러므로 ‘학생에 대한 관심, 주관적 평가, 규정에 따른 처리 등’이 공정하리라는 기대가, 교사 개인에게 거는 기대라면 신뢰가 어렵겠지만, 학교 운영 시스템에 거는 기대라면 신뢰해볼 수 있다.</p>
      <p>하지만 불행하게도, 현재까지의 학교 운영 시스템은 믿을만하지 않았다.</p>
      <p>따라서 다수의 학부모가 잘못된 믿음을 가지고 있는 것은 학교의 탓이다.</p>
      <p>&nbsp;</p>
      <p>공교육은, 이런 정책 저런 정책을 시행하고, 이런 규정 저런 규정을 잔뜩 만들어 놓고, 그러므로 모든 아이를 공정하게 대하고, 공정하게 평가할 것이라고 자신 있게 큰소리쳐 왔지만, 현재까지는 학교에 대한 불신을 해소하지 못했다.</p>
      <p>가장 중요한 학교자치 시스템이 30년째 엉망인 것을 보면, 공교육이 말하는 ‘해결책’과 ‘자신감’은 그저 공허한 메아리일 뿐이라는 것을 알 수 있다.</p>
      <div className={ss.annotation_wrap}>
        <p>이와 관련해서는 나중에 ‘학교생활기록부와 학교자치’ 이야기에서 더 다룰 것이다.</p>
        <p>미리 말해두지만, 학교의 교육평가 시스템 등이 공정하게 작동되려면, 학교자치 시스템이 올바르게 돌아가야 한다.</p>
        <p>그런데 대부분의 학교장은 학교자치 시스템이 올바르게 돌아가는 것을 원하지 않는다.</p>
      </div>
      <p>&nbsp;</p>
      <p>참고로, 사익을 탐하는 유형 중에는 권력을 탐하는 유형도 있다.</p>
      <p>권력을 휘두르고 싶어서, 소위 ‘완장’을 차는 것이 좋아서, 학부모회 임원이며 운영위원을 하고 싶어 하는 사람이다.</p>
      <p>후술하겠지만, 이들은 학교자치의 가장 큰 적이며, 학부모가 일으키는 사건·사고의 중심에는 대개 이들이 있다.</p>
      <p>이들은 교장의 가장 확실한 첨병(전위, 선봉대)으로서 학교자치를 망가뜨린다.</p>
      <p>하지만 가장 충성스러운 학부모는 아니다.(자녀만 바라보는 학부모가 가장 충성스럽다.)</p>
      <p>평상시에는 필요이상으로 충성하지만, 본인의 지위가 흔들리는 경우에는 언제든지 교장의 적으로 돌변하는 유형이다.</p>
      <p>자신의 권력에 도움이 되면 아군이고, 방해가 되면 적군이기 때문에, 학교장을 적으로 여기고 공격하는 경우도 있지만, 학교장의 그늘을 벗어나는 순간 그 허망한 권력은 사라진다.</p>
      <div className={ss.annotation_wrap}>
        <p>자기 마음대로 학부모회를 주무르다가 교장에게 버림받고 쓸쓸히 퇴장한 학부모의 이야기가 있는데, 뒤에서 등장할 것이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>이제 학부모가 학교에 가는 이유를 모두 정리해보자.</p>
      <p>보통의 학부모는 ‘정보’를 얻기 위해 학교에 간다.</p>
      <p>선출되거나, 위촉되거나, 봉사활동에 지원한 학부모는 맡은 바 업무 때문에 학교에 간다.</p>
      <p>싫음에도 불구하고 업무를 맡은 이유는, 피할 수 없어서 맡는 것이다.</p>
      <p>좋아서 스스로 업무를 맡는 이유는, 공익을 위한 것이거나 사익을 탐하기 위한 것이다.</p>
      <p>그리고 사익을 추구하는 사람들의 대부분은 자신의 자녀에게 이익이 돌아올 것이라는 잘못된 믿음을 가지고 있으며, 잘못된 믿음임에도 불구하고 어느 정도 그러한 믿음이 현실화되는 것은 학교 운영 시스템이 제대로 작동되지 않고 있기 때문이다.</p>
      <div className={ss.annotation_wrap}>
        <p>임춘옥 교장이 독도 씨에게 자녀의 수상 여부를 놓고 거래를 제안한 것은 ‘잘못된 믿음’이 현실이라는 것을 적나라하게 드러낸 것이며, 이는 한비중학교의 운영 시스템이 엉망이라는 것을 보여준 단적인 사례다.</p>
        <p>또한 첫 회의의 회식 자리에서 교원위원들과 학부모위원들이 자녀를 중심으로 대화를 나눈 것도 마찬가지로 잘못된 행위이며 시스템의 부실을 드러낸 행위이다.</p>
        <p>이러한 대화는 지극히 사적인 대화이며, 불공정한 대화이다.</p>
        <p>독도 씨가 이후 10여 년 간, 자녀의 이득을 놓고 거래 제안을 받은 일은 더 이상 없었지만, 교원위원들과 학부모위원들이 자녀를 중심에 놓고 대화를 나눈 사례는 고등학교에서도 발생했고, 다른 학교에서도 그러하다는 이야기를 자주 들을 수 있었다.</p>
        <p>따라서 많은 학교에서, 이러한 시스템 부실은 흔하게 존재한다고 할 수 있다.</p>
        <p>학교 운영 시스템은 곧 학교자치를 뜻한다.</p>
        <p>학교 운영 시스템이 제대로 작동된다는 것은, 민주적 절차가 정립됨을 의미하고, 공정성과 투명성이 확보됨을 의미한다.</p>
        <p>또한 문제가 발생했을 때 올바르게 수정할 수 있는 확률이 시간이 지날수록 높아진다는 것을 의미한다.</p>
        <p>하지만 지난 30년간 학교자치는 잠자고 있었고, 교육의 문제는 무엇 하나 제대로 해결되지 않았다.</p>
        <p>교육이 백년지대계라면, 미래의 백년을 위해서, <span className={ss.bold}>지금 당장 학교자치를 깨워야 한다.</span></p>
      </div>
      <p>&nbsp;</p>
      <p>‘학부모가 학교에 가는 이유’를 한 번은 설명을 해야 해서, 이야기가 길어졌다.</p>
      <p>이제 다시 앞의 문제로 돌아가서 학부모위원의 이기심에 대해 말해보자.</p>
      <p>&nbsp;</p>
      <p>한비중학교의 학부모위원들은 스스로 운영위원이 된 사람들이다.</p>
      <p>그런데 그들은 (회의에 임하는 태도와 회식에서의 태도를 보았을 때) 공익을 추구하기 위해서가 아니라 자녀의 이득을 바라고 운영위원에 지원한 것이라고 독도 씨는 판단했다.</p>
      <p>자녀의 이익을 바라기 때문에 교장의 뜻을 거스를 수 없으며(거수기로 행동할 수밖에 없으며), 그렇기 때문에 대부분의 안건은 날치기로 통과되었다.</p>
      <p>이는 결국 공동체의 이익에 반하는 행위이다.</p>
      <p>독도 씨는 첫 회의에서, 그리고 첫 회식에서, 공익을 생각하지 않고 자녀의 이익만을 추구한 학부모위원들의 이기심이 매우 불편했던 것이다.</p>
    </div>
  )
}

export default Year2015_19