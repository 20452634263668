import React from 'react'
import styles from './MainText.module.css'

const Prologue06 = () => {
  return (
    <div className={styles.wrap}>
      <h1 className={styles.title}>6. 민주주의의 의미.</h1>
      <p>규정을 개정할 수 있는 사람이 ‘규정을 만드는 사람’이라고 했다.</p>
      <p>&nbsp;</p>
      <p>헌법의 예를 들어 보면, 헌법 제10장 제128조에서 제130조까지, 헌법은 대통령이나 국회의원 과반수가 발의하고, 국회의원 3분의2 이상의 찬성으로 의결한 후, 국민투표로 결정한다고 명시되어 있다.</p>
      <p>요약하자면, “국회의원이나 대통령이 안건을 만들고, 결정은 국민이 한다.”가 된다.</p>
      <p>그러니까 헌법의 개정 권한은 국민에게 있다.</p>
      <p>즉, 헌법을 만드는 사람은 바로 국민이다.</p>
      <p>&nbsp;</p>
      <p>‘규정을 만드는 사람’이란 어떤 의미를 갖는가?</p>
      <p>&nbsp;</p>
      <p>규정은 그 규정으로 운영되는 조직의 약속이다.</p>
      <p>조직의 정체성을 정의하고 조직의 기본 질서를 정하는 규정이 누군가에 의해 만들어졌다면, 그 누군가는 해당 조직의 주권자(주인, 의사결정자)이다.</p>
      <p>내가 입사한 회사의 규정(정관)을 내가 결정할 수 있는 권한이 없다면, 나는 회사의 주권자가 아니다.</p>
      <p>아마도 그 회사의 규정을 결정하는 사람은 주주일 텐데, 그렇다면 회사의 주권자는 주주가 될 것이다.(소위 주식회사가 그런 것이다.)</p>
      <p>그러니까 <span className={styles.bold}>규정을 만드는 사람이란 바로 주권자</span>를 의미한다.</p>
      <p>&nbsp;</p>
      <p>사회의 법은 그 사회의 주권자가 만드는 것인데, 헌법의 개정 권한이 국민에게 있으므로, 국민이 바로 대한민국의 주권자임을 알 수 있다.</p>
      <div className={styles.annotation_wrap}>
        <p>주권은 국가(조직)의 최종 의사 결정 권력으로 정의된다.</p>
        <p>대한민국의 경우, 국가의 모든 의사 결정이 헌법을 벗어날 수 없으며, 국민 주권을 정의한 것이 헌법이므로, 헌법(최상위 질서)의 개정 권한이 곧 주권이라고 할 수 있다.</p>
      </div>
      <p>&nbsp;</p>
      <p>사회의 주권이 구성원인 시민에게 있는, 시민(民)이 주(主)권자인 정치체제가 바로 민주(民主)주의이다.</p>
      <p>즉, 대한민국의 헌법은 국민이 만들기 때문에 국민 주권이 성립되며, 국민이 주권을 가졌으므로 대한민국은 민주주의 국가이다.</p>
      <div className={styles.annotation_wrap}>
        <p>이하 이 책에서의 주권은, 국가나 학교 등의 사회 조직에서, 최상위의 규정(법령)과 그 규정에서 위임한 하위 규정을 결정하는 권리, 그리고 그 규정에 따른 행위나 정책 등을 결정하는 권리라고 정한다.</p>
      </div>
      <p>&nbsp;</p>
      <p>학교의 모든 규정에도 규정의 개정에 대한 조항이 존재한다.</p>
      <p>그 조항을 살펴보면, 규정은 누가 만드는 것인지, 주권자는 누구인지를 알 수 있다.</p>
      <p>그리고 이제 우리는, 매우 오래 회자되었지만 여전히 정답이 무엇이냐에 대해서는 의견이 분분한 어떤 질문에도 답을 내릴 수 있을 것이다.</p>
      <p>&nbsp;</p>
      <p className={styles.bold}>학교의 주인은 누구인가?</p>
    </div>
  )
}

export default Prologue06